import React, { Component } from 'react';
import { FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import moment from 'moment';
import Button from '../../elements/CustomButton/CustomButton.jsx';
import {validateInputs} from '../../helper';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import Select from 'react-select';
import 'react-day-picker/lib/style.css';
import {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';

class GenerationSettingsRow extends Component {
    constructor(props) {
        super(props);
        const settings = this.props.settings;
        settings.days = settings.days || [];
        this.state = {
            isEditing: false,
            isFormLoading: false,
            offer: settings.offer,
            quantity: settings.quantity,
            postHour: Math.floor(settings.postTime / 3600),
            postMinute: Math.floor((settings.postTime % 3600) / 60),
            removeHour: Math.floor(settings.removeTime / 3600),
            removeMinute: Math.floor((settings.removeTime % 3600) / 60),
            currDays: settings.days.map((day) => {
                return {
                    value: day,
                    label: day ? capitaliseFirstLetter(day) : 'null'
                }
            }),
            numDays: settings.numDays,
            startDate: moment.unix(settings.startDate).format('DD/MM/YYYY'),
            
        };
        
        this.handleEdit = this.handleEdit.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleGenerate = this.handleGenerate.bind(this);
    }

    handleDelete() {
        if (!window.confirm('Are you sure you want to delete?')) {
            return;
        }
        fetch(`/generationSettings/${this.props.settings._id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'access-token': this.props.token
            }
        }).then((result) => {
            return result.json();
        }).then((result) => {
            if (result.status !== 200) {
                this.props.addNotification({
                    title: <span data-notify="icon" className="pe-7s-attention" />,
                    message: <strong>{result.details}</strong>,
                    level: 'error'
                });
                return;
            }
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-like2" />,
                message: <strong>{result.details}</strong>,
                level: 'success'
            });

            this.setState({ isExpanded: false });
            this.props.loadGenerationSettings();
        });
    }

    handleStartDateChange(selectedDay) {
        const date = moment(selectedDay).format('DD/MM/YYYY');
        const name = 'startDate';
        this.setState({ [name]: date });
    }

    handleEdit() {
         this.setState({ isEditing: true });
    }

    handleGenerate() {
        if (!window.confirm('Are you sure you want to generate listings?')) {
            return;
        }
        const body = JSON.stringify({
            generationSettingsIds: [this.props.settings._id]
        });
        fetch(`/generationSettings/generate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'access-token': this.props.token
            },
            body
        }).then((result) => {
            return result.json();
        }).then((result) => {
            if (result.status !== 201) {
                this.props.addNotification({
                    title: <span data-notify="icon" className="pe-7s-attention" />,
                    message: <strong>{result.details}</strong>,
                    level: 'error'
                });
                return;
            }
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-like2" />,
                message: <strong>{result.details}</strong>,
                level: 'success'
            });

            this.setState({ isExpanded: false });
            this.props.loadGenerationSettings();
        });
    }

    handleSave(e) {
        if (!window.confirm('Are you sure you want to save?')) {
            return;
        }

        const isInputsValid = validateInputs(e.target.closest('tbody'), this);
        if (!isInputsValid) {
            return;
        }

        const generationSettingsId = this.props.settings.generationSettingsId;
        const offer = this.state.offer;
        const quantity = this.state.quantity;     
        const postHour = this.state.postHour;
        const postMinute = this.state.postMinute;
        const removeHour = this.state.removeHour;
        const removeMinute = this.state.removeMinute;
        const days = this.state.currDays.map((day) => {
            return day.value
        });
        const startDate = moment(this.state.startDate, 'DD/MM/YYYY').unix();
        const numDays = this.state.numDays;

        const body = JSON.stringify({
            offer,
            quantity,
            postTime: postHour * 3600 + postMinute * 60,
            removeTime: removeHour * 3600 + removeMinute * 60,
            days: JSON.stringify(days),
            startDate,
            numDays
        });
        
        fetch(`/generationSettings/${generationSettingsId}` , {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'access-token': this.props.token
            },
            body
        }).then((result) => {
            return result.json();
        }).then((result) => {
            if (result.status !== 200) {
                this.props.addNotification({
                    title: <span data-notify="icon" className="pe-7s-attention" />,
                    message: <strong>{result.details}</strong>,
                    level: 'error'
                });
                return;
            }
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-like2" />,
                message: <strong>{result.details}</strong>,
                level: 'success'
            });

            this.setState({ isEditing: false });
            this.props.loadGenerationSettings();
        });
    }

    handleChange(e) {
          this.setState({ [e.target.name]: e.target.value });
    }

    handleCancel() {
        const settings = this.props.settings;
        this.setState({
            isEditing: false,
            offer: settings.offer,
            quantity: settings.quantity,
            postHour: Math.floor(settings.postTime / 3600),
            postMinute: Math.floor((settings.postTime % 3600) / 60),
            removeHour: Math.floor(settings.removeTime / 3600),
            removeMinute: Math.floor((settings.removeTime % 3600) / 60),
            currDays: settings.days,
            numDays: settings.numDays,
            startDate: moment.unix(settings.startDate).format('DD/MM/YYYY'),
        });
    }

    render() {
        const product = this.props.product;
        const branch = this.props.branch;
        const settings = this.props.settings;
        return (
            <tbody>
                <tr className={this.state.isEditing ? 'is-editing-row' : ''}>
                    <td>{branch.merchant.name + ' (' + branch.placeType + ')'}</td>
                    <td>{product.name}</td>
                    <td>{settings.offer}</td>
                    <td>{settings.quantity}</td>
                    <td>{padToDoubleDigits(Math.floor(settings.postTime / 3600)) + ':' + padToDoubleDigits(Math.floor((settings.postTime % 3600) / 60))}</td>
                    <td>{padToDoubleDigits(Math.floor(settings.removeTime / 3600)) + ':' + padToDoubleDigits(Math.floor((settings.removeTime % 3600) / 60))}</td>
                    <td>{JSON.stringify(settings.days).replace(/[[\]"]/g, '')}</td>
                    <td>{moment.unix(settings.startDate).format('DD/MM/YYYY')}</td>
                    <td>{settings.numDays}</td>
                    <td className="text-nowrap">
                        <Button className="table-action-button action-button btn-fill"
                                bsStyle="warning"
                                type="button"
                                onClick={this.state.isEditing ? this.handleSave : this.handleEdit}
                        >
                            {this.state.isEditing ? <i className="fas fa-check"></i> : <i className="fas fa-edit"></i>}
                        </Button>
                        {this.state.isEditing ?
                                <Button className="table-action-button action-button btn-fill"
                                        bsStyle="warning"
                                        type="button"
                                        onClick={this.handleCancel}>
                                    <i className="fas fa-times"></i>
                                </Button> : null
                        }
                        <Button className="table-action-button action-button btn-fill"
                                bsStyle="warning"
                                type="button"
                                onClick={this.handleGenerate}
                        >
                            <i className="fas fa-upload"></i>
                        </Button>
                        <Button className="table-action-button action-button btn-fill"
                                bsStyle="danger"
                                type="button"
                                onClick={this.handleDelete}
                        >
                            <i className="fas fa-trash"></i>
                        </Button>
                    </td>
                </tr>
                {this.state.isEditing ?
                    <tr>
                        <td colSpan={11}>
                        <FormGroup>
                            <ControlLabel>Enter App Price</ControlLabel>
                            <FormControl
                                type="number"
                                placeholder="App Price"
                                name="offer"
                                step="0.05"
                                min="0"
                                value={this.state.offer}
                                onChange={this.handleChange}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Enter Quantity</ControlLabel>
                            <FormControl
                                type="number"
                                placeholder="Quantity"
                                name="quantity"
                                step="1"
                                min="0"
                                value={this.state.quantity}
                                onChange={this.handleChange}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel className="label-block" >Enter Post Time</ControlLabel>
                            <FormControl
                                className="time-input datetime-input"
                                type="number"
                                name="postHour"
                                placeholder="hh"
                                min="0"
                                max="23"
                                step="1"
                                value={this.state.postHour}
                                onChange={this.handleChange}
                                data-time-type="post"
                                required
                            />
                            <span className="time-separator">:</span>
                            <FormControl
                                className="time-input datetime-input"
                                type="number"
                                name="postMinute"
                                placeholder="mm"
                                min="0"
                                max="59"
                                step="1"
                                value={this.state.postMinute}
                                onChange={this.handleChange}
                                data-time-type="post"
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel className="label-block" >Enter Remove Time</ControlLabel>
                            <FormControl
                                className="time-input datetime-input"
                                type="number"
                                name="removeHour"
                                placeholder="hh"
                                min={this.state.postHour}
                                max="23"
                                step="1"
                                value={this.state.removeHour}
                                onChange={this.handleChange}
                                data-time-type="remove"
                                required
                            />
                            <span className="time-separator">:</span>
                            <FormControl
                                className="time-input datetime-input"
                                type="number"
                                name="removeMinute"
                                placeholder="mm"
                                min={this.state.postHour === this.state.removeHour ? this.state.postMinute : 0}
                                max="59"
                                step="1"
                                value={this.state.removeMinute}
                                onChange={this.handleChange}
                                data-time-type="remove"
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Select Days</ControlLabel>
                            <Select
                                options={[
                                    {value: 'mon', label: 'Mon'},
                                    {value: 'tue', label: 'Tue'},
                                    {value: 'wed', label: 'Wed'},
                                    {value: 'thu', label: 'Thu'},
                                    {value: 'fri', label: 'Fri'},
                                    {value: 'sat', label: 'Sat'},
                                    {value: 'sun', label: 'Sun'},
                                ]}
                                multi={true}
                                placeholder="select days"
                                value={this.state.currDays}
                                onChange={(val) => {
                                    this.setState({currDays: val});
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel className="label-block" >Enter Start Date</ControlLabel>
                            <DayPickerInput
                                inputProps={{
                                    className: 'form-control date-input datetime-input',
                                    name: 'startDate'
                                }}
                                value={this.state.startDate}
                                onDayChange={this.handleStartDateChange}
                                placeholder="DD/MM/YYYY"
                                format="DD/MM/YYYY"
                                formatDate={formatDate}
                                parseDate={parseDate}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Enter No. of Days</ControlLabel>
                            <FormControl
                                type="number"
                                placeholder="No. of Days"
                                name="numDays"
                                step="1"
                                min="0"
                                value={this.state.numDays}
                                onChange={this.handleChange}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Button className="table-action-button action-button btn-fill"
                                bsStyle="warning"
                                type="button"
                                onClick= {this.handleSave}>
                            <i className="fas fa-check"></i>
                            </Button>
                            <Button className="table-action-button action-button btn-fill"
                                    bsStyle="warning"
                                    type="button"
                                    onClick={this.handleCancel}>
                                <i className="fas fa-times"></i>
                            </Button> 
                         </FormGroup>
                        </td>
                    </tr> : null
                }
            </tbody>
        );
    }
}

function padToDoubleDigits(i) {
    const stringI = i.toString();
    let paddedStringI = stringI;
    for (let j = stringI.length; j < 2; j += 1) {
        paddedStringI = '0' + paddedStringI;
    }

    return paddedStringI;
}

function capitaliseFirstLetter(s) {
    return s.charAt(0).toUpperCase() + s.slice(1);
}

export default GenerationSettingsRow;
