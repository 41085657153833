import axios from "../service/HttpManager";
import { fetchCustomer } from "./customer";
import { fetchProduct } from "./product";
import { fetchBranch, fetchBranchFulfilmentSlot } from "./branch";
import { fetchListingByProductId } from "./listing";
import { isProductAddon } from "../helper";

export const fetchTransactionsByBranchId = async (
  branchId,
  from,
  to,
  accessToken,
  page = 1,
  pageSize = 10
) => {
  const response = await axios.post(
    "/order/get-by-time-frame",
    {
      id: branchId,
      createdAtFrom: from,
      createdAtTo: to,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: { page, pageSize },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return getDataOfOrders(response.data, accessToken, branchId);
};

export const fetchAllTransactions = async (accessToken, page = 1, pageSize = 10) => {
  const response = await axios.post("/order/all", null, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: { page, pageSize },
  });
  if (response.status !== 201) throw new Error(response.data.message);
  return getDataOfOrders(response.data, accessToken);
};

export const fetchTransactionsByTimeFrame = async (from, to, accessToken, page = 1, pageSize = 10) => {
  const response = await axios.post(
    "/order/get-by-time-frame",
    {
      createdAtFrom: from,
      createdAtTo: to,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: { page, pageSize },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return getDataOfOrders(response.data, accessToken);
};

const getDataOfOrders = async (inputOrders, accessToken, branchId) => {
  let orders = inputOrders;
  const customerMap = {};
  const merchantBranchFulfilmentSlotMap = {};
  orders.forEach((order) => {
    customerMap[order.CustomerId] = null;
    order.OrderFulfilmentDetail.forEach((detail) => {
      merchantBranchFulfilmentSlotMap[detail.MerchantBranchFulfilmentSlotId] = null;
    });
  });
  const customers = await Promise.all(
    Object.keys(customerMap).map(async (customerId) => {
      try {
        return await fetchCustomer(Number(customerId), accessToken);
      } catch (error) {
        console.log(error);
        return {
          Id: Number(customerId),
          CustomerGuid: "",
          Username: "",
          FullName: "",
          Email: "",
          Gender: "0",
          Birthday: null,
          ContactNumber: "",
          CountryCode: "",
          IsVerified: false,
          VerifiedDate: null,
          IsDisplayTutorial: true,
          IsActive: false,
          ReferralId: "",
          CreatedAt: 0,
          CreatedBy: "",
          ModifiedAt: 0,
          ModifiedBy: "",
        };
      }
    })
  );
  const orderFulfilmentDetails = await Promise.all(
    Object.keys(merchantBranchFulfilmentSlotMap).map(async (slotId) => {
      try {
        return await fetchBranchFulfilmentSlot(slotId, accessToken);
      } catch (error) {
        console.log(error);
        return {
          Id: Number(slotId),
          MerchantBranchId: -1,
          MerchantBranchFulfilmentSlotGuid: "",
          MerchantBranchFulfilmentSlotTypeId: 1,
          FulfilmentSlotType: "Pick-up",
          FulfilmentSlotStartTime: 0,
          FulfilmentSlotEndTime: 0,
          IsActive: false,
          CreatedAt: 0,
          CreatedBy: "",
          ModifiedAt: 0,
          ModifiedBy: "",
        };
      }
    })
  );
  // const orderProducts = await Promise.all(
  //   orders.map((order) => fetchOrderProductDetail(order.Id, accessToken, branchId))
  // );
  const orderLineItems = await Promise.all(
    orders.map((order) => fetchOrderLineItems(order.Id, accessToken))
  );
  const orderBranchDetails = await Promise.all(
    orders.map((order) => fetchOrderBranchDetail(order.Id, accessToken))
  );
  orders = orders.map((order) => {
    order.Customer = customers.find((c) => c.Id === order.CustomerId);
    switch (order.TransactionTypeId) {
      case 1:
        order.TransactionType = "Pick-up";
        break;
      case 2:
        order.TransactionType = "Delivery";
        break;
      case 3:
        order.TransactionType = "Walk-in";
        break;
      case 4:
        order.TransactionType = "Others";
        break;
      default:
        order.TransactionType = "Unknown";
        break;
    }
    order.OrderFulfilmentDetail.map(
      (detail) =>
        (detail.MerchantBranchFulfilmentSlot = orderFulfilmentDetails.find(
          (d) => d.Id === detail.MerchantBranchFulfilmentSlotId
        ))
    );
    // order.OrderProducts = orderProducts.find((e) => !!e[order.Id])[order.Id] ?? [];
    order.OrderLineItems = orderLineItems.find((e) => !!e[order.Id])[order.Id] ?? [];
    // order.OrderProducts = order.OrderProducts.sort((a, b) => {
    //   if ((a.Listing.IsAddOn && b.Listing.IsAddOn) || (!a.Listing.IsAddOn && !b.Listing.IsAddOn)) {
    //     return a.CreatedAt - b.CreatedAt;
    //   } else {
    //     return a.Listing.IsAddOn ? 1 : -1;
    //   }
    // });
    order.OrderLineItems = order.OrderLineItems.sort((a, b) => {
      const isAAddon = isProductAddon(a.ProductNameAtOrder);
      const isBAddon = isProductAddon(b.ProductNameAtOrder);
      if ((isAAddon && isBAddon) || (!isAAddon && !isBAddon)) {
        return a.CreatedAt - b.CreatedAt;
      } else {
        return isAAddon ? 1 : -1;
      }
    });
    order.OrderBranchDetail =
      orderBranchDetails.find((d) => !!d.find((i) => i.OrderId === order.Id)) ?? [];
    return order;
  });
  console.log(orders);
  return orders;
};

export const createOrder = async (data, accessToken) => {
  const response = await axios.post("/order/create", data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  console.log(response);
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const createTransaction = async (data, accessToken) => {
  const response = await axios.post("/order/create", data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  console.log(response);
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const fetchOrderLineItems = async (orderId, accessToken) => {
  const response = await axios.post(
    `/order/order-line-item/get-by-order-id`,
    { orderId },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  let lineItems = response.data;
  const branchIds = [...new Set(lineItems.map((i) => i.MerchantBranchId))];
  const branches = await Promise.all(
    branchIds.map(async (branchId) => {
      try {
        return await fetchBranch(Number(branchId), accessToken);
      } catch (error) {
        return {
          Id: Number(branchId),
          MerchantId: -1,
          Name: "N/A",
          Description: "",
          Address: "",
          PostalCode: "",
          Latitude: 0,
          Longitude: 0,
          ContactNumber: "",
          OpeningHours: "",
          IsActive: false,
          CreatedAt: 0,
          CreatedBy: "",
          ModifiedAt: 0,
          ModifiedBy: "",
        };
      }
    })
  );
  lineItems = lineItems.map((lineItem) => {
    lineItem.MerchantBranch = branches.find((b) => b.Id === lineItem.MerchantBranchId);
    return lineItem;
  });
  return { [orderId]: lineItems };
};

export const createOrderLineItem = async (data, accessToken) => {
  const response = await axios.post("/order/order-line-item/create", data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const fetchOrderProductDetail = async (orderId, accessToken, branchId) => {
  const response = await axios.post(
    "/order/order-product-detail/get-by-order-id",
    {
      orderId,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  let orderProductDetail = response.data;
  const productMap = {};
  orderProductDetail.forEach((d) => {
    productMap[d.MerchantBranchProductId] = (productMap[d.MerchantBranchProductId] ?? 0) + 1;
  });
  const productIds = Object.keys(productMap);
  let products = await Promise.all(
    productIds.map(async (productId) => {
      try {
        return await fetchProduct(productId, accessToken);
      } catch (error) {
        return {
          Id: Number(productId),
          ProductGuid: "",
          MerchantBranchId: -1,
          Name: "N/A",
          Description: "",
          Currency: "SGD",
          Price: "0",
          Instructions: "",
          Surcharge: null,
          Size: null,
          Unit: "",
          Expiry: 0,
          SKU: null,
          IsActive: true,
          MerchantBranchProductImage: [],
          MerchantBranchProductTag: [],
        };
      }
    })
  );
  let listings = await Promise.all(
    productIds.map((productId) =>
      fetchListingByProductId(productId, orderProductDetail[0].CreatedAt, accessToken)
    )
  );
  const branchMap = {};
  if (branchId) {
    products = products.filter((product) => product.MerchantBranchId === branchId);
  }
  products = products.map((product) => {
    product.Quantity = productMap[product.Id];
    const listing = listings.find((l) => !!l && l.MerchantBranchProductId === product.Id);
    product.Listing = listing;
    branchMap[product.MerchantBranchId] = null;
    return product;
  });
  const branchIds = Object.keys(branchMap);
  const branches = await Promise.all(
    branchIds.map(async (branchId) => {
      try {
        return await fetchBranch(Number(branchId), accessToken);
      } catch (error) {
        return {
          Id: Number(branchId),
          MerchantId: -1,
          Name: "N/A",
          Description: "",
          Address: "",
          PostalCode: "",
          Latitude: 0,
          Longitude: 0,
          ContactNumber: "",
          OpeningHours: "",
          IsActive: false,
          CreatedAt: 0,
          CreatedBy: "",
          ModifiedAt: 0,
          ModifiedBy: "",
        };
      }
    })
  );
  products = products.map((product) => {
    product.MerchantBranch = branches.find((b) => b.Id === product.MerchantBranchId);
    return product;
  });
  return { [orderId]: products };
};

export const fetchOrderBranchDetail = async (orderId, accessToken) => {
  const response = await axios.post(
    "/order/order-branch-detail/get-by-order-id",
    {
      orderId,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  let orderBranchDetail = response.data;
  // const branches = await Promise.all(
  //   orderBranchDetail.map((d) => fetchBranch(d.MerchantBranchId, accessToken))
  // );
  // orderBranchDetail = orderBranchDetail.map((detail) => {
  //   detail.MerchantBranch = branches.find((b) => b.Id === detail.MerchantBranchId);
  //   return detail;
  // });
  return orderBranchDetail;
};

export const createOrderStatus = async (orderId, orderStatusTypeId, remarks, accessToken) => {
  const response = await axios.post(
    "/order/order-status/create",
    {
      orderId,
      orderStatusTypeId,
      remarks,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  console.log(response);
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};
