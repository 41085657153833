import axios from "../service/HttpManager";
import { fetchProduct } from "./product";

export const fetchListings = async (branchId, accessToken, page = 1, pageSize = 10) => {
  const response = await axios.post(
    "/merchant/merchant-branch-product-listing/getByPartnerMerchantBranchId",
    {
      id: branchId,
      filterPostRemoveAt: false,
      isActive: true,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: { page, pageSize },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const fetchEventListings = async (branchId, accessToken, page = 1, pageSize = 200) => {
  const listings = await fetchListings(branchId, accessToken, page, pageSize);
  let productIds = listings.map((l) => l.MerchantBranchProductId);
  productIds = [...new Set(productIds)];
  const products = await Promise.all(productIds.map((productId) => fetchProduct(productId, accessToken)));
  listings.forEach(listing => {
    listing.Product = products.find(p => p.Id === listing.MerchantBranchProductId);
    if (!listing.Product.MerchantBranchProductTag) {
      console.log(listing);
    }

  });
  const eventListings = listings.filter((l) => l.Product.MerchantBranchProductTag.some((t) => t.Tag === "event"));
  return eventListings;
};

export const fetchListing = async (listingId, accessToken) => {
  const response = await axios.post(
    "/merchant/merchant-branch-product-listing/getById",
    {
      id: listingId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const fetchListingByProductId = async (productId, purchaseAt, accessToken) => {
  const response = await axios.post(
    "/merchant/merchant-branch-product-listing/getByMerchantBranchProductId",
    {
      id: productId,
      filterPostRemoveAt: false,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  const listings = response.data;
  let listing;
  if (!!purchaseAt) {
    listing = listings.find((l) => l.PostAt <= purchaseAt && l.RemoveAt >= purchaseAt);
  }
  if (!listing) {
    listing = listings[0];
  }
  return listing;
};

export const createListing = async (data, accessToken) => {
  const response = await axios.post("/merchant/merchant-branch-product-listing/create", data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const updateListing = async (listingId, data, accessToken) => {
  const response = await axios.post(
    "/merchant/merchant-branch-product-listing/update",
    {
      id: listingId,
      ...data,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const updateListingPositions = async (data, accessToken) => {
  const responses = await Promise.all(
    data.map((item) => {
      const data = {
        id: item.listingId,
        position: item.position,
      };
      return axios.post("/merchant/merchant-branch-product-listing/update", data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    })
  );
  if (responses.some((response) => response.status !== 201)) {
    throw new Error(responses.find((response) => response.status !== 201).data.message);
  }
  return responses.map((r) => r.data);
};

export const deleteListing = async (listingId, accessToken) => {
  const response = await axios.post(
    "/merchant/merchant-branch-product-listing/delete",
    { id: listingId },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};
