import React, { Component } from 'react';
import { Grid, Row, Table, FormControl, FormGroup, ControlLabel, Button, Tabs, Tab } from 'react-bootstrap';
import Select from 'react-select';
import moment from 'moment';

import ProperCard from '../../components/Card/ProperCard.jsx';
import TransactionRow, { VOID_TRANSACTION_STATUS } from '../../components/TransactionRow/TransactionRow.jsx';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { validateInputs, resetValidity, unixToExcelTime, generateBranchReportExcelFile } from '../../helper';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { connect } from 'react-redux';

import ReactExport from "react-data-export";
import api from '../../api';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const ORDER_STATUS_PENDING = 1;

class Transactions extends Component {
  constructor(props) {
    super(props);

    const currentDate = moment().format('DD/MM/YYYY');
    const currentHour = moment().format('HH');
    const currentMinute = moment().format('mm');
    const currentTimestamp = getUnixFromDateHourMinute(currentDate, currentHour, currentMinute);

    this.years = [];
    for (let i = 2018; i <= moment().year(); i += 1) {
      this.years.push(i);
    }

    this.state = {
      storeType: 'buffet',
      branch: null,
      merchant: null,
      merchants: [],
      transactionList: [],
      transactions: [],
      voidTransactions: [],
      month: moment().format('MMM'),
      year: moment().year(),
      products: [],
      productOptions: [],
      productId: '',
      redeemedDate: currentDate,
      redeemedHour: currentHour,
      redeemedMinute: currentMinute,
      redeemedAt: currentTimestamp,
      user: '',
      promocode: '',
      offer: '',
      quantity: '',
      staffname: '',
      pageSize: 10,
      page: 1,
      isFull: false,
      isLoading: false,
    };
  }

  componentDidMount() {
    const isAdmin = this.props.roles.includes('admin');
    if (isAdmin) {
      this.loadMerchants();
    }
    this.loadMerchant();
    this.loadBranch();
    this.loadTransactions();
    this.loadProducts();
  }

  loadMerchants = async () => {
    const { token } = this.props.auth;
    try {
      const merchants = await api.fetchMerchants(token);
      this.setState({ merchants });
    } catch (error) {
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{error.message}</strong>,
        level: 'error'
      });
    }
  }

  loadMerchant = async () => {
    const { token, branchId } = this.props.auth;
    try {
      const branch = await api.fetchBranch(branchId, token);
      const merchant = await api.fetchMerchant(branch.MerchantId, token);
      this.setState({ merchant, branch });
    } catch (error) {
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{error.message}</strong>,
        level: 'error'
      });
    }
  }

  loadBranch = async () => {
    try {
      const { branchId, token } = this.props.auth;
      const branchStoreTypes = await api.fetchBranchStoreTypes(branchId, token);
      const allStoreTypes = await api.fetchStoreTypes(token);
      const storeTypeOptions = allStoreTypes.map(type => ({ value: type.Id, label: type.Type }));
      const storeTypes = branchStoreTypes.map(storeType => {
        const type = storeTypeOptions.find(t => t.value === storeType.StoreTypeId)
        return { value: storeType.StoreTypeId, label: type?.label };
      });
      this.setState({
        storeType: storeTypes[0]
      })
    } catch (err) {
      console.log(err);
    }
  }

  loadTransactions = async (shouldLoadMore = false) => {
    const { branchId, token } = this.props.auth;
    const { pageSize, page, month, year } = this.state;
    this.setState({ isLoading: true })
    try {
      const from = moment(`01 ${month} ${year}`, 'DD MMM YYYY');
      const to = moment(from).add(1, 'months');
      const newPage = shouldLoadMore ? page + 1 : 1;
      let transactions = await api.fetchTransactionsByBranchId(branchId, from.unix(), to.unix(), token, newPage, pageSize);
      if (shouldLoadMore) {
        transactions.unshift(...this.state.transactionList);
      }
      const isFull = transactions.length < pageSize * newPage;

      const availableTransactions = transactions
        .filter(t => t.OrderStatus.length === 0 || t.OrderStatus[t.OrderStatus.length - 1].OrderStatusTypeId !== VOID_TRANSACTION_STATUS);
      this.setState({
        transactionList: transactions,
        transactions: getSortedBranchTransactions(availableTransactions),
        voidTransactions: getSortedBranchTransactions(transactions
          .filter(t => t.OrderStatus.length > 0 && t.OrderStatus[t.OrderStatus.length - 1].OrderStatusTypeId === VOID_TRANSACTION_STATUS)),
        page: newPage,
        isLoading: false,
        isFull
      });
    } catch (err) {
      console.log(err);
      this.setState({ isLoading: false })
    }
  }

  loadProducts = async () => {
    const { branchId, token } = this.props.auth;
    try {
      const products = await api.fetchProducts(branchId, token, 1, 500);
      const productOptions = products.map(product => ({
        label: `${product.Name} (${product.Currency} ${Number(product.Price).toFixed(2)})`,
        value: product.Id,
      }));
      this.setState({ productOptions, products });
    } catch (err) {
      console.log(err);
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handlePeriodChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    }, () => {
      this.loadTransactions();
    });
  }

  handleRedeemedDateChange = (selectedDay) => {
    this.handleDayChange('redeemed', selectedDay);
  }

  handleDayChange = (timeType, selectedDay) => {
    resetValidity(document.getElementById('add-redemption-form'));
    const date = moment(selectedDay).format('DD/MM/YYYY');
    const name = timeType + 'Date';
    this.setState({ [name]: date }, () => {
      this.handleDateTimeChange(timeType);
    });
  }

  handleTimeChange = (e) => {
    const targetName = e.target.name;
    const targetValue = e.target.value;
    const timeType = e.target.getAttribute('data-time-type');
    this.setState({ [targetName]: targetValue }, () => {
      this.handleDateTimeChange(timeType);
    });
  }

  handleDateTimeChange = (timeType) => {
    const dateFieldName = timeType + 'Date';
    const hourFieldName = timeType + 'Hour';
    const minuteFieldName = timeType + 'Minute';

    const date = this.state[dateFieldName]
    const hour = this.state[hourFieldName]
    const minute = this.state[minuteFieldName]

    const timestamp = getUnixFromDateHourMinute(date, hour, minute);

    this.setState({
      [timeType + 'At']: timestamp
    });
  }

  handleAdd = async (e) => {
    const isInputsValid = validateInputs(e.target.closest('form'), this);
    if (!isInputsValid) {
      return;
    }

    const { branchId, token } = this.props.auth;

    const productId = this.state.productId;
    const offer = parseFloat(parseFloat(this.state.offer).toFixed(2));
    const quantity = Number(this.state.quantity);
    const redeemedAt = this.state.redeemedAt;
    const user = this.state.user;
    const promoCode = this.state.promocode;
    const staffName = this.state.staffname;

    const product = this.state.products.find(p => p.Id === productId);
    if (!product) {
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{`Product with ID ${productId} not found`}</strong>,
        level: 'error'
      });
      return;
    }

    this.setState({ isLoading: true });

    try {
      let customerId = null;

      if (EMAIL_REGEX.test(user.toLowerCase())) {
        const customerResult = await api.fetchCustomerByEmail(user, this.props.token);
        console.log('fetchCustomerByEmail', customerResult);
        customerId = customerResult.Id;
      } else {
        const customerResult = await api.fetchCustomerByUsername(user, this.props.token);
        console.log('fetchCustomerByUsername', customerResult);
        customerId = customerResult.Id;
      }

      if (!customerId) {
        this.props.addNotification({
          title: <span data-notify="icon" className="pe-7s-attention" />,
          message: <strong>{`Customer ${user} not found`}</strong>,
          level: 'error'
        });
        return;
      }

      let promoCodeId = null;
      if (!!promoCode) {
        const promoCodeResult = await api.fetchPromoCodeByCode(promoCode, this.props.token);
        console.log('fetchPromoCodeByCode', promoCodeResult);
        promoCodeId = promoCodeResult.Id;
      }

      const orderResult = await api.createOrder({
        customerId: customerId,
        promocodeId: promoCodeId,
        transactionTypeId: 1,
        paymentType: "cashier",
        additionalFees: 0,
        offerTotal: offer * quantity,
        nettTotal: offer * quantity,
      }, this.props.token);
      console.log('createOrder', orderResult);

      // create order status
      const remarksObject = {
        staffName: staffName,
        containerCount: 0,
      };
      const orderStatusResult = await api.createOrderStatus(orderResult.Id, ORDER_STATUS_PENDING, JSON.stringify(remarksObject), this.props.token);
      console.log('createOrderStatus', orderStatusResult);

      // create order line item
      const lineItemResult = await api.createOrderLineItem({
        orderId: orderResult.Id,
        merchantBranchProductId: productId,
        merchantBranchId: branchId,
        quantity: quantity,
        priceAtOrder: parseFloat(product.Price),
        offerAtOrder: offer,
        offerTotalOrder: offer * quantity,
        productNameAtOrder: product.Name ?? '',
        productPhotoAtOrder: product.MerchantBranchProductImage[0]?.ImageBlob ?? '',
        saleType: 'ORDER',
      }, this.props.token);
      console.log('createOrderLineItem', lineItemResult);

      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-like2" />,
        message: <strong>Transaction created</strong>,
        level: 'success'
      });
      this.setState({
        productId: '',
        offer: '',
        quantity: '',
        user: '',
        promocode: '',
        staffname: '',
        transactionList: [],
        transactions: [],
        voidTransactions: [],
        pageSize: 10,
        page: 1,
        isFull: false,
        isLoading: false,
      });
      this.loadTransactions();
    } catch (error) {
      console.log(error);
      this.setState({ isLoading: false });
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{error.message}</strong>,
        level: 'error'
      });
      return;
    }
  }

  exportValidTransactions = async (transactions) => {
    const isAdmin = this.props.roles.includes('admin');
    const { merchant, branch, month, year } = this.state;
    const buffer = await generateBranchReportExcelFile(transactions, month, year, branch.BranchName, merchant.Name, isAdmin);
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    const fileName = `[treatsure] Transaction tally for ${merchant.Name} (${branch.BranchName}) - ${month} ${year}`;
    link.download = fileName;;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  render() {
    const storeType = this.state.storeType;
    const months = moment.monthsShort();
    const isAdmin = this.props.roles.includes('admin');

    const transactionsInPeriod = this.state.transactions;

    const voidTransactionsInPeriod = this.state.voidTransactions;

    return (
      <div className="content" style={{ paddingLeft: 30, paddingRight: 30 }}>
        <div className={`loader-overlay ${this.state.isLoading ? '' : 'hidden'}`}>
          <div className='loader-container'>
            <div className="loader-circle" />
            <p className="loader-text">Loading in progress, please wait a few seconds</p>
          </div>
        </div>
        <Grid fluid>
          {(isAdmin || storeType === 'buffets') && <Row>
            <ProperCard
              title="Add a Transaction"
            >
              <form id="add-redemption-form">
                <FormGroup>
                  <ControlLabel>Enter Staff Name</ControlLabel>
                  <FormControl
                    type="text"
                    placeholder="Staff Name"
                    name="staffname"
                    value={this.state.staffname}
                    onChange={this.handleChange}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Select Product</ControlLabel>
                  <Select
                    name="productId"
                    options={
                      this.state.productOptions.map((option) => ({ value: option.value, label: option.label }))
                    }
                    placeholder="Select Product"
                    value={this.state.productId}
                    onChange={(target) => {
                      this.setState({ productId: target ? target.value : '' });
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Enter Customer Email or Username</ControlLabel>
                  <FormControl
                    type="text"
                    placeholder="Customer Email or Username"
                    name="user"
                    value={this.state.user}
                    onChange={this.handleChange}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Enter App Price</ControlLabel>
                  <FormControl
                    type="number"
                    placeholder="App Price"
                    name="offer"
                    step="0.01"
                    min="0"
                    value={this.state.offer}
                    onChange={this.handleChange}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Enter Quantity</ControlLabel>
                  <FormControl
                    type="number"
                    placeholder="Quantity"
                    name="quantity"
                    step="1"
                    min="0"
                    value={this.state.quantity}
                    onChange={this.handleChange}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel className="label-block" >Enter Transaction Time</ControlLabel>
                  <FormControl
                    className="hidden"
                    placeholder="Transaction Time"
                    name="redeemedAt"
                    value={this.state.redeemedAt}
                    onChange={this.handleChange}
                  />
                  <DayPickerInput
                    inputProps={{
                      className: 'form-control date-input datetime-input',
                      name: 'redeemedDate'
                    }}
                    value={this.state.redeemedDate}
                    onDayChange={this.handleRedeemedDateChange}
                    placeholder="DD/MM/YYYY"
                    format="DD/MM/YYYY"
                    formatDate={formatDate}
                    parseDate={parseDate}
                  />
                  <FormControl
                    className="time-input datetime-input"
                    type="number"
                    name="redeemedHour"
                    placeholder="hh"
                    min="0"
                    max="23"
                    step="1"
                    value={this.state.redeemedHour}
                    onChange={this.handleTimeChange}
                    data-time-type="redeemed"
                    required
                  />
                  <span className="time-separator">:</span>
                  <FormControl
                    className="time-input datetime-input"
                    type="number"
                    name="redeemedMinute"
                    placeholder="mm"
                    min="0"
                    max="59"
                    step="1"
                    value={this.state.redeemedMinute}
                    onChange={this.handleTimeChange}
                    data-time-type="redeemed"
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Enter Promocode</ControlLabel>
                  <FormControl
                    type="text"
                    placeholder="Promocode"
                    name="promocode"
                    value={this.state.promocode}
                    onChange={this.handleChange}
                  />
                </FormGroup>
                <Button className="table-action-button action-button btn-fill"
                  bsStyle="warning"
                  type="button"
                  onClick={this.handleAdd}>
                  <i className="fas fa-plus"></i> Add transaction
                </Button>
              </form>
            </ProperCard>
          </Row>}
          <Row>
            <ProperCard>
              <FormGroup>
                <ControlLabel>Select Period</ControlLabel>
                <FormControl
                  className="month-select"
                  componentClass="select"
                  placeholder="Select Month"
                  name="month"
                  value={this.state.month}
                  onChange={this.handlePeriodChange}
                  required >
                  {
                    months.map((option) => {
                      return <option key={option} value={option}>{option}</option>;
                    })
                  }
                </FormControl>
                <FormControl
                  className="year-select"
                  componentClass="select"
                  placeholder="Select Year"
                  name="year"
                  value={this.state.year}
                  onChange={this.handlePeriodChange}
                  required >
                  {
                    this.years.map((option) => {
                      return <option key={option} value={option}>{option}</option>;
                    })
                  }
                </FormControl>
                <Button className="table-action-button action-button btn-fill"
                  bsStyle="warning"
                  type="button"
                  style={{ marginLeft: 10 }}
                  disabled={this.state.isFull}
                  onClick={() => this.loadTransactions(true)}>
                  Load More
                </Button>
              </FormGroup>
              <Tabs defaultActiveKey={1} id="trans-tabs">
                <Tab eventKey={1} title="Valid Transactions">
                  <br />
                  {/* <ExcelDownloadButton
                    transactions={this.state.transactions}
                    year={this.state.year}
                    month={this.state.month}
                    isAdmin={isAdmin}
                  /> */}
                  <Button className="table-action-button action-button btn-fill"
                    bsStyle="success"
                    onClick={() => this.exportValidTransactions(transactionsInPeriod)}
                    type="button" >
                    <i className="fas fa-table"></i> Export selected month to excel
                  </Button>
                  <TransactionTab
                    {...this.props}
                    merchants={isAdmin ? this.state.merchants : [this.state.merchant]}
                    transactions={transactionsInPeriod}
                    accountId={this.props.accountId}
                    storeType={storeType}
                    token={this.props.token}
                    loadTransactions={this.loadTransactions}
                  />
                </Tab>
                <Tab eventKey={2} title="Void Transactions">
                  <TransactionTab
                    {...this.props}
                    merchants={isAdmin ? this.state.merchants : [this.state.merchant]}
                    transactions={voidTransactionsInPeriod}
                    accountId={this.props.accountId}
                    storeType={storeType}
                    isVoid={true}
                    token={this.props.token}
                    loadTransactions={this.loadTransactions}
                  />
                </Tab>
              </Tabs>
            </ProperCard>
          </Row>
        </Grid>
      </div>
    );
  }
}
const compareTransactions = (a, b) => {
  // Use redeemeedAt by default, use createdAt as a fallback
  // let aTime = a.redemptions[0] ? a.redemptions[0].redeemedAt : a.createdAt;
  // let bTime = b.redemptions[0] ? b.redemptions[0].redeemedAt : b.createdAt;
  // return aTime - bTime;
  return b.CreatedAt - a.CreatedAt;
};

function getSortedBranchTransactions(branchTransactions) {
  const transactionsToSort = [];
  for (const transaction of branchTransactions) {
    transactionsToSort.push(JSON.parse(JSON.stringify(transaction)));
  }

  const compareTransactions = (a, b) => {
    // Use redeemeedAt by default, use createdAt as a fallback
    // let aTime = a.redemptions[0] ? a.redemptions[0].redeemedAt : a.CreatedAt;
    // let bTime = b.redemptions[0] ? b.redemptions[0].redeemedAt : b.CreatedAt;
    return b.CreatedAt - a.CreatedAt;
  };

  transactionsToSort.sort(compareTransactions);

  console.log(transactionsToSort);

  return transactionsToSort;
}

function padToDoubleDigits(i) {
  const stringI = i.toString();
  let paddedStringI = stringI;
  for (let j = stringI.length; j < 2; j += 1) {
    paddedStringI = '0' + paddedStringI;
  }

  return paddedStringI;
}

function getUnixFromDateHourMinute(date, hour, minute) {
  // returns unix timestamp from date hour minute based on local timezone
  const timeString = date
    + padToDoubleDigits(hour)
    + padToDoubleDigits(minute);

  return moment(timeString, 'DD/MM/YYYYHHmm').unix();
}


function TransactionTab(props) {
  const isVoid = props.isVoid;
  const transactions = props.transactions || [];
  const storeType = props.storeType;
  const isAdmin = props.roles.includes('admin');

  let transactionGross = 0;
  let transactionNett = 0;
  for (const transaction of transactions) {
    transactionGross += (transaction.OfferTotal ? Number(transaction.OfferTotal) : 0);
    transactionNett += (transaction.NettTotal ? Number(transaction.NettTotal) : 0);
  }
  return (
    <div>
      <h4>{isVoid ? 'Void Transactions' : 'Valid Transactions'}</h4>
      {transactions.length <= 0 ? <div className="not-found-text"><i>No transactions were found for the selected period</i></div> :
        <Table hover bordered responsive className="transactions-table">
          <thead>
            <tr>
              <th rowSpan={2}>#</th>
              {(isAdmin || storeType !== 'buffets') && <th rowSpan={2}>Customer Username</th>}
              {isAdmin && <th rowSpan={2}>Customer Email</th>}
              <th rowSpan={2}>Order ID</th>
              <th rowSpan={2}>Branch Name</th>
              <th rowSpan={2}>Created At</th>
              <th colSpan={3}>Order Details</th>
              <th rowSpan={2}>Payment Type</th>
              <th rowSpan={2}>Transaction Type</th>
              <th rowSpan={2}>Gross Total</th>
              <th rowSpan={2}>Nett Total</th>
              <th rowSpan={2}>Status</th>
              <th rowSpan={2}>Actions</th>
            </tr>
            <tr>
              <th>Product Name</th>
              <th>App price</th>
              <th>Quantity</th>
            </tr>
          </thead>
          {
            transactions.map((transaction, i) => {
              return (
                <TransactionRow
                  {...props}
                  transaction={transaction}
                  i={i}
                  key={`${transaction.Id}-${i}`}
                  storeType={storeType}
                  isVoid={isVoid}
                  token={props.token}
                  accountId={props.accountId}
                  merchants={props.merchants}
                  loadTransactions={props.loadTransactions} isAdmin={isAdmin}
                />
              )
            })
          }
          <tbody>
            <tr>
              {/*Different number of columns seen by admins vs regular users*/}
              <td colSpan={isAdmin ? 10 : storeType !== 'buffets' ? 9 : 8}></td>
              <td>Total</td>
              <td>{transactionGross.toFixed(2)}</td>
              <td>{transactionNett.toFixed(2)}</td>
              <td></td>
            </tr>
          </tbody>
        </Table>
      }
    </div>
  );
}

function ExcelDownloadButton(props) {
  const yearMonth = props.year + props.month;
  let cellColor = "ffffffff";

  const button = (
    <Button className="table-action-button action-button btn-fill"
      bsStyle="success"
      type="button" >
      <i className="fas fa-table"></i> Export selected month to Excel
    </Button>);
  let dataSet = [
    {
      columns: [
        { title: "Order Id", width: { wpx: 100 } },
        { title: "Branch Name", width: { wpx: 100 } },
        { title: "Created At", width: { wpx: 180 } },
        { title: "Product Name", width: { wpx: 200 } },
        { title: "App price", width: { wpx: 100 } },
        { title: "Quantity", width: { wpx: 100 } },
        { title: "Payment Type", width: { wpx: 100 } },
        { title: "Transaction Type", width: { wpx: 100 } },
        { title: "Gross Total", width: { wpx: 100 } },
        { title: "Nett Total", width: { wpx: 100 } },
      ],
      data: []
    }
  ];
  if (props.isAdmin) {
    dataSet[0].columns.splice(0, 0,
      { title: "Customer username", width: { wpx: 150 } },
      { title: "Customer Email", width: { wpx: 200 } }
    );
  }

  let filename = 'Transactions';

  let monthTransactions = props.transactions;

  if (monthTransactions.length > 0) {
    let totalGross = 0;
    let totalNett = 0;
    filename = monthTransactions[0].OrderLineItems[0].MerchantBranch.BranchName.substring(0, 10) // Only upto first 10 letters of Branch Name
      + "_Transactions_"
      + yearMonth;

    monthTransactions.sort(compareTransactions);
    monthTransactions.forEach((transaction) => {
      if (transaction.OrderLineItems.length === 0) {
        // This transaction has no redemption => no row in the table
        return;
      }
      let rows = [];
      cellColor = cellColor === "ffffc9a6" ? "ffffffff" : "ffffc9a6"; // alternate row colour
      transaction.OrderLineItems.forEach((lineItem) => {
        let excelTime = unixToExcelTime(lineItem.CreatedAt);

        rows.push([
          { value: transaction.OrderNumber.substring(8, 18).toUpperCase(), style: { fill: { patternType: "solid", fgColor: { rgb: cellColor } } } },
          { value: lineItem.MerchantBranch.BranchName, style: { fill: { patternType: "solid", fgColor: { rgb: cellColor } } } },
          { value: excelTime, style: { fill: { patternType: "solid", fgColor: { rgb: cellColor } }, numFmt: "dd/mm/yyyy   hh:mm AM/PM" } },
          { value: lineItem.ProductNameAtOrder, style: { fill: { patternType: "solid", fgColor: { rgb: cellColor } } } },
          { value: Number(lineItem.OfferAtOrder), style: { numFmt: "$0.00", fill: { patternType: "solid", fgColor: { rgb: cellColor } } } },
          { value: lineItem.Quantity, style: { fill: { patternType: "solid", fgColor: { rgb: cellColor } } } },
          { value: transaction.PaymentType && transaction.PaymentType.startsWith('card') ? 'Card' : 'Others', style: { fill: { patternType: "solid", fgColor: { rgb: cellColor } } } },
          { value: transaction.TransactionType || '', style: { fill: { patternType: "solid", fgColor: { rgb: cellColor } } } },
          { value: "", style: { fill: { patternType: "solid", fgColor: { rgb: cellColor } } } },
          { value: "", style: { fill: { patternType: "solid", fgColor: { rgb: cellColor } } } }
        ]);
        if (props.isAdmin) {
          rows[rows.length - 1].splice(0, 0,
            { value: transaction.Customer.Username, style: { fill: { patternType: "solid", fgColor: { rgb: cellColor } } } },
            { value: transaction.Customer.Email, style: { fill: { patternType: "solid", fgColor: { rgb: cellColor } } } }
          );
        }
      })
      totalGross += Number(transaction.OfferTotal);
      totalNett += Number(transaction.NettTotal);


      // Conditional to handle extra columns for admin
      rows[rows.length - 1][props.isAdmin ? 10 : 8] = { value: Number(transaction.OfferTotal), style: { numFmt: "$0.00", fill: { patternType: "solid", fgColor: { rgb: cellColor } } } };
      rows[rows.length - 1][props.isAdmin ? 11 : 9] = { value: Number(transaction.NettTotal), style: { numFmt: "$0.00", fill: { patternType: "solid", fgColor: { rgb: cellColor } } } };
      dataSet[0].data.push(...rows);
    });

    dataSet[0].data.push(["", "", "", "", "", "", "", ...(props.isAdmin ? ["", ""] : []), "Total",
      { value: totalGross, style: { numFmt: "$0.00" } },
      { value: totalNett, style: { numFmt: "$0.00" } }
    ])
  }


  return (
    <ExcelFile element={button} filename={filename}>
      <ExcelSheet dataSet={dataSet} name="Transactions" />
    </ExcelFile>
  );
}

export default connect(state => ({
  auth: state.auth
}))(Transactions);
