import React, { Component } from 'react';
import { Grid, Row, Col, Thumbnail, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import 'react-select/dist/react-select.css';
import api from '../../api';
import { connect } from 'react-redux';

class SelectBranch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFormLoading: false,
            branches: [],
            merchants: [],
            storeTypes: [],
            storeTypeOptions: [],
            selectedStoreType: null,
        };
    }

    componentDidMount() {
        this.loadBranches();
    }

    loadBranches = async () => {
        const { token, branchesPermissions, roles } = this.props.auth;
        let branches = [];
        let storeTypes = [];
        if (roles.includes('admin')) {
            storeTypes = await api.fetchStoreTypes(token);
            const storeTypeOptions = storeTypes.map(type => ({ value: type.Id, label: type.Type }));
            const selectedStoreType = storeTypeOptions[0].value;
            branches = await api.fetchBranchesByStoreTypeId(selectedStoreType, token, 1, 200);
            const merchants = await api.fetchMerchants(token);

            this.setState({
                branches,
                merchants,
                storeTypes,
                storeTypeOptions,
                selectedStoreType,
            });
        } else {
            branches = (await Promise.all(branchesPermissions.map(permission => api.fetchBranch(permission.id, token))))
                .filter(r => r !== null);
            const merchantIds = [...new Set(branches.map(b => b.MerchantId))];
            const merchants = await Promise.all(merchantIds.map(merchantId => api.fetchMerchant(merchantId, token)));
            this.setState({
                branches,
                merchants,
                storeTypes: [],
                storeTypeOptions: [],
                selectedStoreType: null
            });
        }
    }

    handleStoreTypeChange = async (e) => {
        const { token } = this.props.auth;
        const storeTypeId = e.target.value;
        this.setState({ isFormLoading: true });
        try {
            const branches = await api.fetchBranchesByStoreTypeId(storeTypeId, token, 1, 200);
            this.setState({
                selectedStoreType: storeTypeId,
                branches,
                isFormLoading: false,
            })
        } catch (error) {
            this.setState({ isFormLoading: false });
        }
    }

    render() {
        const isAdmin = this.props.auth.roles.includes('admin');

        return (
            <div className="select-content">
                <Grid>
                    <Row>
                        <Col>
                            <h2 className="text-center">Select Branch</h2>
                            <FormGroup hidden={!isAdmin}>
                                <ControlLabel>Select Store Type</ControlLabel>
                                <FormControl
                                    className="month-select"
                                    componentClass="select"
                                    placeholder="Select Store Type"
                                    name="selectedStoreType"
                                    value={this.state.selectedStoreType}
                                    onChange={this.handleStoreTypeChange}>
                                    {
                                        this.state.storeTypeOptions.map((option) => {
                                            return <option key={option.value} value={option.value}>{option.label}</option>;
                                        })
                                    }
                                </FormControl>
                            </FormGroup>
                            {this.state.branches.map((branch) => {
                                return (
                                    <div key={branch.Id} onClick={() => this.props.selectBranch(branch.Id)}>
                                        <BranchCard
                                            branch={branch}
                                            key={branch.Id}
                                            merchant={this.state.merchants.find(m => m.Id === branch.MerchantId)} />
                                    </div>
                                );
                            })}
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

function BranchCard(props) {
    return (
        <Thumbnail className="branch-card text-center">
            <img src={props.merchant?.Logo} style={{ objectFit: 'contain' }} width="100px" height="100px" align="right" alt="" />
            <h3>{props.branch.BranchName}</h3>
            <p>{props.merchant?.Name}</p>
        </Thumbnail>
    );
}

export default connect(state => ({
    auth: state.auth
}))(SelectBranch);
