import React from 'react';
import ReactDOM from 'react-dom';

import {
    HashRouter,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';

import App from './containers/App/App.jsx';

import './assets/css/bootstrap.min.css';
import './assets/css/animate.min.css';
import './assets/sass/light-bootstrap-dashboard.css';
import './assets/css/demo.css';
import './assets/css/pe-icon-7-stroke.css';
import './assets/css/common.css';
import Login from './views/Login/Login';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import { connect, Provider } from 'react-redux';

function PrivateRouteComponent({ children, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          rest.auth.token !== null ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
}

const PrivateRoute = connect(state => ({
    auth: state.auth
}))(PrivateRouteComponent);

ReactDOM.render((
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <HashRouter>
                <Switch>
                    <Route path="/login" component={Login} />
                    <PrivateRoute path="/">
                        <Route path="/" component={App} />
                    </PrivateRoute>
                </Switch>
            </HashRouter>
        </PersistGate>
    </Provider>
), document.getElementById('root'));
