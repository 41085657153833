import React, { Component } from 'react';
import { OverlayTrigger, Tooltip, FormGroup, FormControl, ControlLabel, Carousel, Row, Col } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import moment from 'moment';
import Compress from "react-image-file-resizer";

import Button from '../../elements/CustomButton/CustomButton.jsx';
import { validateInputs } from '../../helper.js';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import api from '../../api/index.js';
import Helpers from '../../Helpers.js';
import FormInputs from '../FormInputs/FormInputs.jsx';

class EventRow extends Component {
  constructor(props) {
    super(props);
    const listing = this.props.listing;
    const product = listing.Product;
    const activeImages = product.MerchantBranchProductImage ? product.MerchantBranchProductImage.filter(image => image.IsActive).sort((a, b) => b.CreatedAt - a.CreatedAt) : [];
    console.log(activeImages);
    const eventObject = Helpers.getEventObject(product.Description);
    this.state = {
      isEditing: false,
      name: product.Name,
      currency: product.Currency,
      price: product.Price,
      payAsYouWish: parseFloat(product.Price) === 0,
      description: eventObject.description,
      locationName: eventObject.location,
      locationInstruction: eventObject.instruction,
      address: eventObject.address,
      postal: eventObject.postalCode,
      photo: activeImages[0]?.ImageBlob,
      merchantBranchProductImages: activeImages,
      productImages: [null],
      productImageIndex: 0,
      eventDate: moment.unix(listing.PostAt).format('DD/MM/YYYY'),
      expiredHour: moment().format('HH'),
      expiredMinute: moment().format('mm'),
      openingHour: moment.unix(listing.PostAt).format('HH'),
      openingMinute: moment.unix(listing.PostAt).format('mm'),
      closingHour: moment.unix(listing.RemoveAt).format('HH'),
      closingMinute: moment.unix(listing.RemoveAt).format('mm'),
      total: listing.ItemCount,
      remaining: listing.ItemRemaining,
    };
  }

  handleEdit = () => {
    // When the edit window opens, display only 2 decimal places
    this.setState((state) => ({ isEditing: true, price: Number(state.price).toFixed(2) }));
  }

  handleProductImageSelect = async (file, index) => {
    try {
      const image = await resizeFile(file);
      document.getElementById(`${this.props.listing.Product.Id}-product-img-${index}`).src = URL.createObjectURL(image);
      this.setState(oldState => {
        oldState.productImages[index] = image;
        return { productImages: oldState.productImages };
      });
    } catch (err) {
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{err.message}</strong>,
        level: 'error'
      });
    }
  }

  handleCarouselSelect = (selectedIndex) => {
    this.setState({ productImageIndex: selectedIndex })
  }

  handleAddProductImage = () => {
    this.setState(oldState => {
      oldState.productImages.push(null);
      return {
        productImages: oldState.productImages,
        productImageIndex: oldState.productImages.length - 1
      };
    });
  }

  handleRemoveProductImage = async (i) => {
    if (!window.confirm('Are you sure you want to delete?')) {
      return;
    }

    this.setState({ isFormLoading: true });
    try {
      const listing = this.props.listing;
      const product = listing.Product;
      const activeImages = product.MerchantBranchProductImage.filter(image => image.IsActive)
        .sort((a, b) => b.CreatedAt - a.CreatedAt)
      const imageId = activeImages[i].Id;
      await api.deleteProductImage(imageId, this.props.token);
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-like2" />,
        message: <strong>Product image deleted.</strong>,
        level: 'success'
      });
      const updatedImages = this.state.merchantBranchProductImages;
      updatedImages.splice(i, 1);
      this.setState({
        merchantBranchProductImages: updatedImages,
      });
    } catch (error) {
      console.log(error);
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{error.message}</strong>,
        level: 'error'
      });
    } finally {
      this.setState({ isFormLoading: false });
    }
  }

  handleSave = async (e) => {

    if (!window.confirm('Are you sure you want to save?')) {
      return;
    }

    const isInputsValid = validateInputs(e.target.closest('tr'), this);
    if (!isInputsValid) {
      return;
    }
    const listing = this.props.listing;
    const product = listing.Product;
    const productId = product.Id;

    try {
      const location = await api.fetchAddressGeocoding(this.state.postal);

      const description = {
        description: this.state.description,
        location: this.state.locationName,
        lat: location.lat.toString(),
        lng: location.lng.toString(),
        instruction: this.state.locationInstruction,
        address: this.state.address,
        postalCode: this.state.postal,
      };

      const productPrice = this.state.payAsYouWish ? 0 : parseFloat(this.state.price).toFixed(2);

      const productData = {
        name: this.state.name,
        merchantBranchId: product.MerchantBranchId,
        description: JSON.stringify(description),
        currency: this.state.currency,
        price: productPrice,
        instructions: product.Instructions,
        surcharge: 0,
        unit: product.Unit,
        expiry: product.Expiry,
        isActive: true
      };

      const result = await api.updateProduct(productId, productData, this.props.token);
      console.log(result);

      const productImages = this.state.productImages.filter(productImage => productImage !== null);
      console.log(productImages);
      await Promise.all(productImages.map(image => api.uploadProductImage(productId, image, this.props.token)));

      const eventDate = this.state.eventDate;
      const openingHour = this.state.openingHour;
      const openingMinute = this.state.openingMinute;
      let postAt = moment(eventDate, 'DD/MM/YYYY').startOf('day').unix();
      postAt = postAt + openingHour * 3600 + openingMinute * 60;
      const closingHour = this.state.closingHour;
      const closingMinute = this.state.closingMinute;
      let removeAt = moment(eventDate, 'DD/MM/YYYY').startOf('day').unix();
      removeAt = removeAt + closingHour * 3600 + closingMinute * 60;

      const listingData = {
        offer: productPrice,
        itemCount: this.state.total,
        itemRemaining: this.state.remaining,
        isAddOn: this.state.isAddOn,
        postAt: postAt,
        removeAt: removeAt,
      }
      const listingResult = await api.updateListing(listing.Id, listingData, this.props.token);
      console.log(listingResult);

      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-like2" />,
        message: <strong>Event updated.</strong>,
        level: 'success'
      });
      this.setState({ isEditing: false });
      this.props.loadEvents();
    } catch (error) {
      console.log(error);
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{error.message}</strong>,
        level: 'error'
      });
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleCheckbox = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  }

  handleEventDateChange = (selectedDay) => {
    const date = moment(selectedDay).format('DD/MM/YYYY');
    this.setState({ eventDate: date });
  }

  handleCancel = () => {
    const listing = this.props.listing;
    const product = listing.Product;
    const activeImages = product.MerchantBranchProductImage ? product.MerchantBranchProductImage.filter(image => image.IsActive) : [];
    const eventObject = Helpers.getEventObject(product.Description);
    this.setState({
      isEditing: false,
      name: product.Name,
      currency: product.Currency,
      price: product.Price,
      payAsYouWish: parseFloat(product.Price) === 0,
      description: eventObject.description,
      locationName: eventObject.location,
      locationInstruction: eventObject.instruction,
      address: eventObject.address,
      postal: eventObject.postalCode,
      photo: activeImages[0]?.ImageBlob,
      merchantBranchProductImages: activeImages,
      productImages: [null],
      productImageIndex: 0,
      eventDate: moment.unix(listing.PostAt).format('DD/MM/YYYY'),
      expiredHour: moment().format('HH'),
      expiredMinute: moment().format('mm'),
      openingHour: moment.unix(listing.PostAt).format('HH'),
      openingMinute: moment.unix(listing.PostAt).format('mm'),
      closingHour: moment.unix(listing.RemoveAt).format('HH'),
      closingMinute: moment.unix(listing.RemoveAt).format('mm'),
      total: listing.ItemCount,
      remaining: listing.ItemRemaining,
    });
  }

  render() {
    const listing = this.props.listing;
    const product = listing.Product;
    const photo = this.state.photo;
    const activeImages = product.MerchantBranchProductImage ? product.MerchantBranchProductImage.filter(image => image.IsActive)
      .sort((a, b) => b.CreatedAt - a.CreatedAt) : [];
    let productImage;
    if (photo) {
      const tooltip = (
        <Tooltip className="custom-tooltip" id={'tooltip-' + product.Id}>
          <img className="product-image-zoomed" src={photo} alt="" />
        </Tooltip>
      );
      productImage = (
        <OverlayTrigger placement="left" overlay={tooltip}>
          <img className="product-image" src={photo} alt="" />
        </OverlayTrigger>);
    } else {
      productImage = <span className="no-field-text text-nowrap"><em>No Photo</em></span>;
    }

    const i = this.props.i;
    const productPriceCellContents = parseFloat(product.Price).toFixed(2);
    const eventObject = Helpers.getEventObject(product.Description);
    const productDescriptionCellContents = (ellipsize(eventObject.description, 100) || <div className="no-field-text text-nowrap"><em>No Description</em></div>);
    const eventDate = moment.unix(listing.PostAt).format('DD/MM/YYYY');
    const openingTime = moment.unix(listing.PostAt).format('h:mm a');
    const closingTime = moment.unix(listing.RemoveAt).format('h:mm a');
    return (
      <tbody>
        <tr className={this.state.isEditing ? 'is-editing-row' : ''}>
          <td className="checkbox-cell"><input type="checkbox" name="product-checkbox" value={product.Id} onChange={this.props.handleCheck} /></td>
          <td>{i + 1}</td>
          <td>{product.Name}</td>
          <td>{this.state.payAsYouWish ? 'Pay-as-you-wish' : `${product.Currency} ${productPriceCellContents}`}</td>
          <td className="text-formatted description-cell">{productDescriptionCellContents}</td>
          <td className="text-formatted">{eventDate}</td>
          <td className="text-formatted">{openingTime}</td>
          <td className="text-formatted">{closingTime}</td>
          <td className="text-formatted">{listing.ItemCount}</td>
          <td className="text-formatted">{listing.ItemRemaining}</td>
          <td>{productImage}</td>
          <td className="text-nowrap">
            <Button
              className="table-action-button action-button btn-fill"
              bsStyle="warning"
              type="button"
              onClick={this.state.isEditing ? this.handleSave : this.handleEdit}
            >
              {this.state.isEditing ? <i className="fas fa-check"></i> : <i className="fas fa-edit"></i>}
            </Button>
            {this.state.isEditing &&
              <Button
                className="table-action-button action-button btn-fill"
                bsStyle="warning"
                type="button"
                onClick={this.handleCancel}>
                <i className="fas fa-times"></i>
              </Button>
            }
          </td>
        </tr>
        {this.state.isEditing ?
          <tr>
            <td colSpan={9}>
              <FormGroup>
                <ControlLabel>Enter Event Name</ControlLabel>
                <FormControl
                  type="text"
                  placeholder="Name"
                  name="name"
                  value={this.state.name}
                  onChange={this.handleChange}
                  required
                />
              </FormGroup>
              <FormGroup>
                <ControlLabel>Enter Description</ControlLabel>
                <FormControl
                  componentClass="textarea"
                  placeholder="Description"
                  name="description"
                  value={this.state.description}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormInputs
                ncols={["col-md-6", "col-md-6"]}
                proprieties={[
                  {
                    label: "location name",
                    type: "text",
                    bsClass: "form-control",
                    placeholder: "location name",
                    value: this.state.locationName,
                    name: "locationName",
                    onChange: this.handleChange,
                    spellCheck: false
                  },
                  {
                    label: "location instruction",
                    type: "text",
                    bsClass: "form-control",
                    placeholder: "location instruction",
                    value: this.state.locationInstruction,
                    name: "locationInstruction",
                    onChange: this.handleChange,
                    spellCheck: false
                  },
                ]}
              />
              <FormInputs
                ncols={["col-md-6", "col-md-6"]}
                proprieties={[
                  {
                    label: "location address",
                    type: "text",
                    bsClass: "form-control",
                    placeholder: "address",
                    value: this.state.address,
                    name: "address",
                    onChange: this.handleChange,
                    spellCheck: false
                  },
                  {
                    label: "postal code",
                    type: "text",
                    bsClass: "form-control",
                    placeholder: "postal code",
                    value: this.state.postal,
                    name: "postal",
                    onChange: this.handleChange,
                    spellCheck: false
                  }
                ]}
              />
              <Row>
                <Col sm={1}>
                  <FormGroup>
                    <ControlLabel>Currency</ControlLabel>
                    <FormControl
                      type="text"
                      placeholder="Currency"
                      name="currency"
                      value={this.state.currency}
                      onChange={this.handleChange}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col sm={4}>
                  <FormGroup>
                    <ControlLabel>Enter Redeem Price</ControlLabel>
                    <FormControl
                      type="number"
                      placeholder="Price"
                      name="price"
                      step="0.01"
                      min="0"
                      value={this.state.price}
                      onChange={this.handleChange}
                      disabled={this.state.payAsYouWish}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <input className="admin-checkbox" type="checkbox"
                  name="payAsYouWish"
                  onChange={this.handleCheckbox}
                  checked={this.state.payAsYouWish} />
                <span className="admin-label" style={{ marginBottom: 0 }}>Pay as you wish</span>
              </FormGroup>
              <FormGroup>
                <ControlLabel>Enter Total</ControlLabel>
                <FormControl
                  type="number"
                  placeholder="Total"
                  name="total"
                  onChange={this.handleChange}
                  value={this.state.total}
                  step="1"
                  min="0"
                  required
                  disabled={!this.props.roles.includes('admin')}
                />
              </FormGroup>
              <FormGroup>
                <ControlLabel>Enter Remaining</ControlLabel>
                <FormControl
                  type="number"
                  placeholder="Remaining"
                  name="remaining"
                  onChange={this.handleChange}
                  value={this.state.remaining}
                  step="1"
                  max={this.state.total}
                  min="0"
                  required
                />
              </FormGroup>
              <FormGroup>
                <ControlLabel className="label-block" >Enter Collection Date & Time</ControlLabel>
                <span className="admin-label" >Collect on</span>
                <DayPickerInput
                  inputProps={{
                    className: 'form-control date-input datetime-input',
                    name: 'eventDate'
                  }}
                  value={this.state.eventDate}
                  onDayChange={this.handleEventDateChange}
                  placeholder="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                  formatDate={formatDate}
                  parseDate={parseDate}
                />
                <span className="admin-label" >From</span>
                <FormControl
                  className="time-input datetime-input"
                  type="number"
                  name="openingHour"
                  placeholder="hh"
                  min="0"
                  max="23"
                  step="1"
                  value={this.state.openingHour}
                  onChange={this.handleChange}
                />
                <span className="time-separator">:</span>
                <FormControl
                  className="time-input datetime-input"
                  type="number"
                  name="openingMinute"
                  placeholder="mm"
                  min="0"
                  max="59"
                  step="1"
                  value={this.state.openingMinute}
                  onChange={this.handleChange}
                />
                <span className="admin-label" >To</span>
                <FormControl
                  className="time-input datetime-input"
                  type="number"
                  name="closingHour"
                  placeholder="hh"
                  min="0"
                  max="23"
                  step="1"
                  value={this.state.closingHour}
                  onChange={this.handleChange}
                />
                <span className="time-separator">:</span>
                <FormControl
                  className="time-input datetime-input"
                  type="number"
                  name="closingMinute"
                  placeholder="mm"
                  min="0"
                  max="59"
                  step="1"
                  value={this.state.closingMinute}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                {photo ? <img className="product-image-zoomed" src={photo} alt="" /> : null}
              </FormGroup>
              <h4>Event Images</h4>
              <Carousel interval={null} activeIndex={this.state.productImageIndex} onSelect={this.handleCarouselSelect}>
                {
                  this.state.productImages.map((image, index) => {
                    return (<Carousel.Item key={index}>
                      <Dropzone onDropAccepted={files => this.handleProductImageSelect(files[0], index)} accept="image/png, image/jpeg">
                        {({ getRootProps, getInputProps, isDragActive }) => (
                          <section>
                            <div {...getRootProps({ style: { ...dragNDropStyle, ...(isDragActive ? { borderColor: '#ff5c0e' } : {}) } })}>
                              <img className="branch-product-image" style={{ height: 100, marginLeft: 'auto', marginRight: 'auto' }} id={`${product.Id}-product-img-${index}`} alt="" />
                              <input {...getInputProps()} />
                              <p>Drag 'n' drop, or click to select file</p>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </Carousel.Item>)
                  })
                }
              </Carousel>
              <br />
              <Button className="table-action-button action-button btn-fill"
                bsStyle="warning"
                type="button"
                onClick={this.handleAddProductImage}>
                <i className="fas fa-plus"></i> Add Image
              </Button>
              {activeImages.length > 0 && <Carousel interval={null} >
                {
                  activeImages.map((image, i) => {
                    return (
                      <Carousel.Item key={i}>
                        <img className="branch-product-image"
                          style={{ height: 100, marginLeft: 'auto', marginRight: 'auto' }}
                          src={image.ImageBlob} alt="" />
                        <Button
                          className="btn-round"
                          round
                          bsStyle="warning"
                          style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                          onClick={() => this.handleRemoveProductImage(i)}
                        >
                          <i className="fas fa-times"></i>
                        </Button>
                      </Carousel.Item>
                    )
                  })
                }
              </Carousel>}
              <br />
              <FormGroup>
                <Button
                  className="table-action-button action-button btn-fill"
                  bsStyle="warning"
                  type="button"
                  onClick={this.handleSave}>
                  <i className="fas fa-check"></i>
                </Button>
                <Button
                  className="table-action-button action-button btn-fill"
                  bsStyle="warning"
                  type="button"
                  onClick={this.handleCancel}>
                  <i className="fas fa-times"></i>
                </Button>
              </FormGroup>

            </td>
          </tr> : null
        }
      </tbody>
    );
  }
}

function ellipsize(text, length) {
  text = text || '';
  text = text.trim();
  return text.length > length ? text.substring(0, length) + '...' : text;
}

const dragNDropStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '5px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const resizeFile = (file) =>
  new Promise((resolve) => {
    Compress.imageFileResizer(
      file,
      480,
      480,
      "PNG",
      70,
      0,
      (uri) => {
        resolve(uri);
      },
      "blob"
    );
  });

export default EventRow;
