import axios from "../service/HttpManager";

export const fetchFeedbacks = async (accessToken, page = 1, pageSize = 10) => {
  const response = await axios.post("/customer/feedback/all", null, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: { page, pageSize },
  });
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const deleteFeedback = async (feedbackId, accessToken) => {
  const response = await axios.post(
    "/customer/feedback/delete",
    { id: feedbackId },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};
