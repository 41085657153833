import React, { Component } from 'react';
import { OverlayTrigger, Tooltip, FormGroup, FormControl, ControlLabel, Carousel, Row, Col } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import moment from 'moment';
import Compress from "react-image-file-resizer";
import Select from 'react-select';

import Button from '../../elements/CustomButton/CustomButton.jsx';
import { validateInputs } from '../../helper';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import api from '../../api';

const PRODUCT_TAG_OPTIONS = [
    { value: 'produce', label: 'produce' },
    { value: 'snacks', label: 'snacks' },
    { value: 'dairy & drinks', label: 'dairy & drinks' },
    { value: 'bakes', label: 'bakes' },
    { value: 'others', label: 'others' },
    { value: 'expiring', label: 'expiring' },
    { value: 'beverages', label: 'beverages', },
    { value: 'dairy/eggs', label: 'dairy/eggs', },
]

class ProductRow extends Component {
    constructor(props) {
        super(props);
        const expiredAtMoment = this.props.product.Expiry ? moment.unix(this.props.product.Expiry) : moment();
        const activeImages = this.props.product.MerchantBranchProductImage.filter(image => image.IsActive);
        console.log(activeImages);
        this.state = {
            isEditing: false,
            isShowingListings: false,
            isShowingSteals: false,
            name: this.props.product.Name,
            currency: this.props.product.Currency,
            price: this.props.product.Price,
            unitOfMeasure: this.props.product.Unit || '',
            photo: activeImages[0]?.ImageBlob,
            merchantBranchProductImages: activeImages,
            productImages: [null],
            productImageIndex: 0,
            productTag: this.props.product.MerchantBranchProductTag.filter(t => t.IsActive),
            description: this.props.product.Description,
            instructions: this.props.product.Instructions,
            noExpiry: !this.props.product.Expiry,
            expiredDate: expiredAtMoment.format('DD/MM/YYYY') || moment().format('DD/MM/YYYY'),
            expiredHour: expiredAtMoment.format('HH'),
            expiredMinute: expiredAtMoment.format('mm'),
            productTags: this.props.product.MerchantBranchProductTag.filter(t => t.IsActive).map(t => t.Tag),
        };
    }

    toggleListings = () => {
        this.setState({ isShowingListings: !this.state.isShowingListings });
    }

    toggleSteals = () => {
        this.setState({ isShowingSteals: !this.state.isShowingSteals });
    }

    handleEdit = () => {
        // When the edit window opens, display only 2 decimal places
        this.setState((state) => ({ isEditing: true, price: Number(state.price).toFixed(2) }));
    }

    handleProductImageSelect = async (file, index) => {
        try {
            const image = await resizeFile(file);
            document.getElementById(`${this.props.product.Id}-product-img-${index}`).src = URL.createObjectURL(image);
            this.setState(oldState => {
                oldState.productImages[index] = image;
                return { productImages: oldState.productImages };
            });
        } catch (err) {
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-attention" />,
                message: <strong>{err.message}</strong>,
                level: 'error'
            });
        }
    }

    handleCarouselSelect = (selectedIndex) => {
        this.setState({ productImageIndex: selectedIndex })
    }

    handleAddProductImage = () => {
        this.setState(oldState => {
            oldState.productImages.push(null);
            return {
                productImages: oldState.productImages,
                productImageIndex: oldState.productImages.length - 1
            };
        });
    }

    handleRemoveProductImage = async (i) => {
        if (!window.confirm('Are you sure you want to delete?')) {
            return;
        }

        this.setState({ isFormLoading: true });
        try {
            const activeImages = this.props.product.MerchantBranchProductImage.filter(image => image.IsActive)
                .sort((a, b) => b.CreatedAt - a.CreatedAt)
            const imageId = activeImages[i].Id;
            await api.deleteProductImage(imageId, this.props.token);
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-like2" />,
                message: <strong>Product image deleted.</strong>,
                level: 'success'
            });
            const updatedImages = this.state.merchantBranchProductImages;
            updatedImages.splice(i, 1);
            this.setState({
                merchantBranchProductImages: updatedImages,
            });
        } catch (error) {
            console.log(error);
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-attention" />,
                message: <strong>{error.message}</strong>,
                level: 'error'
            });
        } finally {
            this.setState({ isFormLoading: false });
        }
    }

    handleSave = async (e) => {

        if (!window.confirm('Are you sure you want to save?')) {
            return;
        }

        const isInputsValid = validateInputs(e.target.closest('tr'), this);
        if (!isInputsValid) {
            return;
        }
        const productId = this.props.product.Id;

        const expiredHour = this.state.expiredHour;
        const expiredMinute = this.state.expiredMinute;
        const expiredDate = moment(this.state.expiredDate, 'DD/MM/YYYY').startOf('day').unix();
        const expireDate = expiredDate + expiredHour * 3600 + expiredMinute * 60;

        const data = {
            name: this.state.name,
            merchantBranchId: this.props.branchId,
            description: this.state.description,
            currency: this.state.currency,
            price: this.state.price,
            instructions: this.state.instructions,
            surcharge: 0,
            unit: this.state.unitOfMeasure,
            expiry: this.state.noExpiry ? null : expireDate,
            isActive: true
        }

        try {
            const result = await api.updateProduct(productId, data, this.props.token);
            console.log(result);

            const productImages = this.state.productImages.filter(productImage => productImage !== null);
            console.log(productImages);
            await Promise.all(productImages.map(image => api.uploadProductImage(productId, image, this.props.token)));

            const originalTags = this.props.product.MerchantBranchProductTag.filter(t => t.IsActive);
            console.log(`originalTags: ${originalTags}`);
            console.log(`productTags: ${this.state.productTags}`);

            const addedProductTags = this.state.productTags.filter(tag => !originalTags.find(t => t.Tag === tag));
            const deletedProductTags = originalTags.filter(t => !this.state.productTags.find(tag => tag === t.Tag));
            console.log(`addedProductTags: ${addedProductTags}`);
            console.log(`deletedProductTags: ${deletedProductTags}`);
            await Promise.all(addedProductTags.map(tag => api.createProductTag(productId, tag, this.props.token)));
            await Promise.all(deletedProductTags.map(tag => api.deleteProductTag(tag.Id, this.props.token)));

            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-like2" />,
                message: <strong>Product updated.</strong>,
                level: 'success'
            });
            this.setState({ isEditing: false });
            this.props.loadProducts();
        } catch (error) {
            console.log(error);
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-attention" />,
                message: <strong>{error.message}</strong>,
                level: 'error'
            });
        }
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleCheckbox = (e) => {
        this.setState({ [e.target.name]: e.target.checked });
    }

    handleExpiredDateChange = (selectedDay) => {
        const date = moment(selectedDay).format('DD/MM/YYYY');
        const name = 'expiredDate';
        this.setState({ [name]: date });
    }

    handleCancel = () => {
        const expiredAtMoment = this.props.product.Expiry ? moment.unix(this.props.product.Expiry) : moment();
        const activeImages = this.props.product.MerchantBranchProductImage.filter(image => image.IsActive);
        this.setState({
            isEditing: false,
            name: this.props.product.Name,
            currency: this.props.product.Currency,
            price: this.props.product.Price,
            unitOfMeasure: this.props.product.Unit || '',
            photo: activeImages[0]?.ImageBlob,
            productImages: [null],
            merchantBranchProductImages: activeImages,
            productImageIndex: 0,
            description: this.props.product.Description,
            instructions: this.props.product.Instructions,
            noExpiry: !this.props.product.Expiry,
            expiredDate: expiredAtMoment.format('DD/MM/YYYY') || moment().format('DD/MM/YYYY'),
            expiredHour: expiredAtMoment.format('HH'),
            expiredMinute: expiredAtMoment.format('mm')
        });
    }

    render() {
        const product = this.props.product;
        const photo = this.state.photo;
        const activeImages = this.props.product.MerchantBranchProductImage.filter(image => image.IsActive)
            .sort((a, b) => b.CreatedAt - a.CreatedAt);
        let productImage;
        if (photo) {
            const tooltip = (
                <Tooltip className="custom-tooltip" id={'tooltip-' + product.Id}>
                    <img className="product-image-zoomed" src={photo} alt="" />
                </Tooltip>
            );
            productImage = (
                <OverlayTrigger placement="left" overlay={tooltip}>
                    <img className="product-image" src={photo} alt="" />
                </OverlayTrigger>);
        } else {
            productImage = <span className="no-field-text text-nowrap"><em>No Photo</em></span>;
        }

        const i = this.props.i;
        const productPriceCellContents = parseFloat(this.props.product.Price).toFixed(2);
        const productUnitCellContents = (ellipsize(this.props.product.Unit, 100) || <div className="no-field-text text-nowrap"><em>NA</em></div>);
        const productDescriptionCellContents = (ellipsize(this.props.product.Description, 100) || <div className="no-field-text text-nowrap"><em>No Description</em></div>);
        const productInstructionsCellContents = (ellipsize(this.props.product.Instructions, 100) || <div className="no-field-text text-nowrap"><em>No Instructions</em></div>);
        const productExpiryDateCellContents = this.props.product.Expiry ? ellipsize(moment.unix(this.props.product.Expiry).format('ddd, D MMM YYYY, HH:mm'), 100) : <div className="no-field-text text-nowrap"><em>No Expiry Date</em></div>;
        return (
            <tbody>
                <tr className={this.state.isEditing ? 'is-editing-row' : ''}>
                    <td className="checkbox-cell"><input type="checkbox" name="product-checkbox" value={this.props.product.Id} onChange={this.props.handleCheck} /></td>
                    <td>{i + 1}</td>
                    <td>{this.props.product.Name}</td>
                    <td>{this.props.product.Currency} {productPriceCellContents}</td>
                    <td className="text-formatted">{productUnitCellContents}</td>
                    <td className="text-formatted description-cell">{productDescriptionCellContents}</td>
                    <td className="text-formatted instructions-cell">{productInstructionsCellContents}</td>
                    <td className="text-formatted">{productExpiryDateCellContents}</td>
                    <td>{productImage}</td>
                    <td className="text-nowrap">
                        <Button
                            className="table-action-button action-button btn-fill"
                            bsStyle="warning"
                            type="button"
                            onClick={this.state.isEditing ? this.handleSave : this.handleEdit}
                        >
                            {this.state.isEditing ? <i className="fas fa-check"></i> : <i className="fas fa-edit"></i>}
                        </Button>
                        {this.state.isEditing &&
                            <Button
                                className="table-action-button action-button btn-fill"
                                bsStyle="warning"
                                type="button"
                                onClick={this.handleCancel}>
                                <i className="fas fa-times"></i>
                            </Button>
                        }
                    </td>
                </tr>
                {this.state.isEditing ?
                    <tr>
                        <td colSpan={9}>
                            <FormGroup>
                                <ControlLabel>Enter Name</ControlLabel>
                                <FormControl
                                    type="text"
                                    placeholder="Name"
                                    name="name"
                                    value={this.state.name}
                                    onChange={this.handleChange}
                                    required
                                />
                            </FormGroup>
                            <Row>
                                <Col sm={4}>
                                    <FormGroup>
                                        <ControlLabel>Enter Currency</ControlLabel>
                                        <FormControl
                                            type="text"
                                            placeholder="Currency"
                                            name="currency"
                                            value={this.state.currency}
                                            onChange={this.handleChange}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <ControlLabel>Enter Original Price</ControlLabel>
                                        <FormControl
                                            type="number"
                                            placeholder="Price"
                                            name="price"
                                            step="0.01"
                                            min="0"
                                            value={this.state.price}
                                            onChange={this.handleChange}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <ControlLabel>Enter Unit of Measurement</ControlLabel>
                                        <FormControl
                                            type="text"
                                            placeholder="Unit of Measurement"
                                            name="unitOfMeasure"
                                            value={this.state.unitOfMeasure}
                                            onChange={this.handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup>
                                <ControlLabel>Enter Description</ControlLabel>
                                <FormControl
                                    componentClass="textarea"
                                    placeholder="Description"
                                    name="description"
                                    value={this.state.description}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>Enter Order Instructions</ControlLabel>
                                <FormControl
                                    componentClass="textarea"
                                    placeholder="Instructions"
                                    name="instructions"
                                    value={this.state.instructions}
                                    onChange={this.handleChange}
                                    disabled={!this.props.roles.includes('admin')}
                                />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel className="label-block" >Enter Expiry Date</ControlLabel>
                                <input className="admin-checkbox" type="checkbox"
                                    name="noExpiry"
                                    onChange={this.handleCheckbox}
                                    checked={this.state.noExpiry} />
                                <span className="admin-label" >No expiry date</span>
                                <DayPickerInput
                                    inputProps={{
                                        className: 'form-control date-input datetime-input',
                                        name: 'expiredDate'
                                    }}
                                    value={this.state.expiredDate}
                                    onDayChange={this.handleExpiredDateChange}
                                    placeholder="DD/MM/YYYY"
                                    format="DD/MM/YYYY"
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                    disabled={this.state.noExpiry}
                                    required={!this.state.noExpiry}
                                />
                                <FormControl
                                    className="time-input datetime-input"
                                    type="number"
                                    name="expiredHour"
                                    placeholder="hh"
                                    min="0"
                                    max="23"
                                    step="1"
                                    value={this.state.expiredHour}
                                    onChange={this.handleChange}
                                    disabled={this.state.noExpiry}
                                    required={!this.state.noExpiry}
                                />
                                <span className="time-separator">:</span>
                                <FormControl
                                    className="time-input datetime-input"
                                    type="number"
                                    name="expiredMinute"
                                    placeholder="mm"
                                    min="0"
                                    max="59"
                                    step="1"
                                    value={this.state.expiredMinute}
                                    onChange={this.handleChange}
                                    disabled={this.state.noExpiry}
                                />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>Product tags</ControlLabel>
                                <Select.Creatable
                                    options={PRODUCT_TAG_OPTIONS}
                                    multi={true}
                                    value={this.state.productTags}
                                    placeholder="select product tags"
                                    onChange={(val) => {
                                        val = val.map((productTagVal) => {
                                            return productTagVal.value;
                                        });
                                        this.setState({ productTags: val });
                                    }}
                                />
                            </FormGroup>
                            <FormGroup>
                                {photo ? <img className="product-image-zoomed" src={photo} alt="" /> : null}
                            </FormGroup>
                            <h4>Product Images</h4>
                            <Carousel interval={null} activeIndex={this.state.productImageIndex} onSelect={this.handleCarouselSelect}>
                                {
                                    this.state.productImages.map((image, index) => {
                                        return (<Carousel.Item key={index}>
                                            <Dropzone onDropAccepted={files => this.handleProductImageSelect(files[0], index)} accept="image/png, image/jpeg">
                                                {({ getRootProps, getInputProps, isDragActive }) => (
                                                    <section>
                                                        <div {...getRootProps({ style: { ...dragNDropStyle, ...(isDragActive ? { borderColor: '#ff5c0e' } : {}) } })}>
                                                            <img className="branch-product-image" style={{ height: 100, marginLeft: 'auto', marginRight: 'auto' }} id={`${this.props.product.Id}-product-img-${index}`} alt="" />
                                                            <input {...getInputProps()} />
                                                            <p>Drag 'n' drop, or click to select file</p>
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </Carousel.Item>)
                                    })
                                }
                            </Carousel>
                            <br />
                            <Button className="table-action-button action-button btn-fill"
                                bsStyle="warning"
                                type="button"
                                onClick={this.handleAddProductImage}>
                                <i className="fas fa-plus"></i> Add Image
                            </Button>
                            {activeImages.length > 0 && <Carousel interval={null} >
                                {
                                    activeImages.map((image, i) => {
                                        return (
                                            <Carousel.Item key={i}>
                                                <img className="branch-product-image"
                                                    style={{ height: 100, marginLeft: 'auto', marginRight: 'auto' }}
                                                    src={image.ImageBlob} alt="" />
                                                <Button
                                                    className="btn-round"
                                                    round
                                                    bsStyle="warning"
                                                    style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                                                    onClick={() => this.handleRemoveProductImage(i)}
                                                >
                                                    <i className="fas fa-times"></i>
                                                </Button>
                                            </Carousel.Item>
                                        )
                                    })
                                }
                            </Carousel>}
                            <br />
                            <FormGroup>
                                <Button
                                    className="table-action-button action-button btn-fill"
                                    bsStyle="warning"
                                    type="button"
                                    onClick={this.handleSave}>
                                    <i className="fas fa-check"></i>
                                </Button>
                                <Button
                                    className="table-action-button action-button btn-fill"
                                    bsStyle="warning"
                                    type="button"
                                    onClick={this.handleCancel}>
                                    <i className="fas fa-times"></i>
                                </Button>
                            </FormGroup>

                        </td>
                    </tr> : null
                }
            </tbody>
        );
    }
}

function ellipsize(text, length) {
    text = text || '';
    text = text.trim();
    return text.length > length ? text.substring(0, length) + '...' : text;
}

const dragNDropStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '5px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const resizeFile = (file) =>
    new Promise((resolve) => {
        Compress.imageFileResizer(
            file,
            480,
            480,
            "PNG",
            70,
            0,
            (uri) => {
                resolve(uri);
            },
            "blob"
        );
    });

export default ProductRow;
