const DEFAULT_EVENT_OBJECT = {
  description: "",
  location: "",
  instruction: "",
  lat: "0",
  lng: "0",
  address: "",
  postalCode: "",
};

const getEventObject = (productDescription) => {
  try {
    const object = JSON.parse(productDescription);
    const eventObject = {
      ...DEFAULT_EVENT_OBJECT,
      ...object,
    };
    return eventObject;
  } catch (e) {
    return DEFAULT_EVENT_OBJECT;
  }
};

export default {
  getEventObject,
};
