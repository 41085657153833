import React, { Component } from 'react';
import { FormGroup, FormControl, ControlLabel, OverlayTrigger, Popover } from 'react-bootstrap';
import moment from 'moment';

import Button from '../../elements/CustomButton/CustomButton.jsx';
import { getPaymentType, validateInputs } from '../../helper';
import api from '../../api';

import paynowLogo from '../../assets/img/logo-paynow.png';
import cashierLogo from '../../assets/img/logo-cashier.png';

import 'react-day-picker/lib/style.css';

const PAYMENT_ICONS = {
  card: <i className='fas fa-credit-card' style={{ fontSize: 30 }}></i>,
  cashier: <img src={cashierLogo} />,
  paynow: <img src={paynowLogo} />,
}

export const PENDING_TRANSACTION_STATUS = 1;
export const COMPLETED_TRANSACTION_STATUS = 2;
export const VOID_TRANSACTION_STATUS = 3;

class TransactionRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isExpanded: false,
      isCompleteExpanded: false,
      reason: '',
      staffname: '',
      transactionMeta: {},
      customerDeliveryDetails: {},
      customerAddress: {},
    };

    if (props.transaction.OrderNumber.length === 36) {
      this.formattedTransactionId = props.transaction.OrderNumber.substring(props.transaction.OrderNumber.length - 12).toUpperCase();
    } else {
      this.formattedTransactionId = props.transaction.OrderNumber.substring(8, 18).toUpperCase();
    }
  }

  handleExpand = () => {
    this.setState({ isExpanded: true });
  }

  handleExpandComplete = () => {
    // this.setState({ isCompleteExpanded: true });
    this.handleSaveComplete();
  }

  handleClose = () => {
    this.setState({ isExpanded: false, isCompleteExpanded: false });
  }

  handleSaveVoid = async (e) => {
    const isInputsValid = validateInputs(e.target.closest('tbody'), this);
    if (!isInputsValid) {
      return;
    }
    if (!window.confirm('Are you sure you want to save?')) {
      return;
    }
    const transactionId = this.props.transaction.Id;
    const accountId = this.props.accountId;
    const reason = this.state.reason;
    const staffname = this.state.staffname;

    const remarks = `${staffname} (${accountId}): ${reason}`;

    try {
      const result = await api.createOrderStatus(transactionId, 3, remarks, this.props.token);
      console.log(result);
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-like2" />,
        message: <strong>Transaction is voided.</strong>,
        level: 'success'
      });
      this.setState({ isExpanded: false });
      this.props.loadTransactions();
    } catch (error) {
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{error.message}</strong>,
        level: 'error'
      });
    }
  }

  handleSaveComplete = async () => {
    if (!window.confirm('Are you sure you want to complete?')) {
      return;
    }
    const transactionId = this.props.transaction.Id;
    const accountId = this.props.accountId;
    const reason = this.state.reason;
    const staffname = this.state.staffname;

    // const remarks = `${staffname} (${accountId}): ${reason}`;
    const remarks = '';

    try {
      const result = await api.createOrderStatus(transactionId, 2, remarks, this.props.token);
      console.log(result);
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-like2" />,
        message: <strong>Transaction is completed.</strong>,
        level: 'success'
      });
      this.setState({ isCompleteExpanded: false });
      this.props.loadTransactions();
    } catch (error) {
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{error.message}</strong>,
        level: 'error'
      });
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleCancel = () => {
    this.setState({
      isExpanded: false,
      isCompleteExpanded: false,
      reason: ''
    });
  }

  handleGetCustomerAddress = async () => {
    try {
      const customerDeliveryDetails = await api.fetchCustomerAddressByCustomerId(this.props.transaction.CustomerId, this.props.token);
      delete customerDeliveryDetails.CreatedAt;
      delete customerDeliveryDetails.CreatedBy;
      delete customerDeliveryDetails.Id;
      delete customerDeliveryDetails.ModifiedAt;
      delete customerDeliveryDetails.ModifiedBy;
      delete customerDeliveryDetails.IsActive;
      delete customerDeliveryDetails.CustomerId;
      this.setState({
        customerAddress: customerDeliveryDetails || { Note: 'No Details' }
      });
    } catch (error) {
      console.log(error);
      this.setState({
        customerAddress: { Note: 'No Details' }
      });
    }
  }

  handleGetCustomerDeliveryDetails = async () => {
    const fulfilmentDetail = this.props.transaction.OrderFulfilmentDetail;
    if (!fulfilmentDetail || fulfilmentDetail.length === 0) {
      this.setState({
        customerDeliveryDetails: { Note: 'No Details' }
      });
      return;
    }

    const customerDeliveryDetails = await api.fetchCustomerAddress(fulfilmentDetail[0].CustomerAddressId, this.props.token);
    delete customerDeliveryDetails.CreatedAt;
    delete customerDeliveryDetails.CreatedBy;
    delete customerDeliveryDetails.Id;
    delete customerDeliveryDetails.ModifiedAt;
    delete customerDeliveryDetails.ModifiedBy;
    delete customerDeliveryDetails.IsActive;
    delete customerDeliveryDetails.CustomerId;
    this.setState({
      customerDeliveryDetails: customerDeliveryDetails || { Note: 'No Details' }
    });
  }

  handleGetTransactionMeta = async () => {
    if (this.props.transaction.TransactionType === 'Delivery') {
      this.handleGetCustomerDeliveryDetails();
    }
  }

  prettifyObj(obj) {
    if (!obj) {
      return <div>N/A</div>
    }
    var rows = [];
    for (const [index, value] of Object.entries(obj)) {
      rows.push(<div key={index}><b style={{ textTransform: 'capitalize' }}>{index}</b>: {value}</div>)
    }
    return (
      <div>{rows}</div>
    )
  }

  stripePopover = (<Popover id="popover-positioned-top" title="Stripe Id">
    {this.props.transaction.stripeTransaction ? this.props.transaction.stripeTransaction.StripeTransactionId : ''}
  </Popover>);

  deliveryPickupPopover = (customerDeliveryDetails) => {
    const fulfilmentSlot = this.props.transaction.OrderFulfilmentDetail.length > 0 ? this.props.transaction.OrderFulfilmentDetail[0].MerchantBranchFulfilmentSlot : null;
    const statusList = this.props.transaction.OrderStatus ?? [];
    let remarks = statusList[0]?.Remarks ?? null;
    const transactionType = this.props.transaction.TransactionType;

    let fulfilmentDate = fulfilmentSlot?.DateTime;
    let containerCount = '';
    let fulfilmentTimeSlot = fulfilmentSlot?.Description;

    try {
      const remarksObject = JSON.parse(remarks);
      fulfilmentDate = remarksObject?.fulfilmentDate;
      fulfilmentTimeSlot = remarksObject?.fulfilmentTimeSlot;
      containerCount = remarksObject?.containerCount;
      remarks = remarksObject?.remarks;
    } catch (error) {
      console.log(error);
    }

    switch (transactionType) {
      case 'Delivery':
        return (<Popover id="delivery-pickup-popover-positioned-top" title={'Details ' + this.formattedTransactionId}>
          {this.prettifyObj(customerDeliveryDetails)}
          <br />
          <div><b>Delivery Slot</b>: {fulfilmentTimeSlot}</div>
          <div><b>Fulfilment Date</b>: {fulfilmentDate}</div>
          <div><b>Container Quantity</b>: {containerCount}</div>
          <div><b>Remarks</b>: {remarks}</div>
        </Popover>);
      case 'Pick-up':
        return (<Popover id="delivery-pickup-popover-positioned-top" title={'Details ' + this.formattedTransactionId}>
          <div><b>DateTime</b>: {fulfilmentDate}</div>
          <div><b>Pickup Slot</b>: {fulfilmentTimeSlot}</div>
          <div><b>Fulfilment Date</b>: {fulfilmentDate}</div>
          <div><b>Container Quantity</b>: {containerCount}</div>
          <div><b>Remarks</b>: {remarks}</div>
        </Popover>)
      default:
        return <Popover id="delivery-pickup-popover-positioned-top" title="Details"><div>{'No Data'}</div></Popover>;
    }
  };

  customerDeliveryDetailsPopover = (value) =>
  (<Popover id="delivery-details-popover-positioned-top" style={{ width: '276px', minHeight: '235px' }} title='Customer Details'>
    {this.prettifyObj(value)}
  </Popover>)

  render() {
    const transaction = this.props.transaction;
    const storeType = this.props.storeType;
    const isAdmin = this.props.isAdmin;
    const isUrlAdmin = this.props.location.pathname.split('/').includes('admin')
    const paymentType = getPaymentType(transaction);
    const orderStatusTypeId = transaction.OrderStatus.length > 0 ? transaction.OrderStatus[transaction.OrderStatus.length - 1].OrderStatusTypeId : 0;
    let orderStatus = 'N/A';
    switch (orderStatusTypeId) {
      case 0:
        orderStatus = 'N/A';
        break;
      case PENDING_TRANSACTION_STATUS:
        // orderStatus = 'ACTIVE';
        orderStatus = 'Pending';
        break;
      case COMPLETED_TRANSACTION_STATUS:
        // orderStatus = 'INACTIVE';
        orderStatus = 'Completed';
        break;
      case VOID_TRANSACTION_STATUS:
        orderStatus = 'Void';
        break;
      default:
        orderStatus = 'Unknown';
        break;
    }
    if (!transaction.PaymentType || !transaction.OrderLineItems || transaction.OrderLineItems.length === 0) {
      orderStatus += ' (Invalid)';
    }
    const products = transaction.OrderLineItems.length > 0 ? transaction.OrderLineItems : [null];
    const productRows = products.map((lineItem, j) => {
      const merchants = this.props.merchants ?? [];
      const merchant = merchants.find(m => m.Id === lineItem?.MerchantBranch?.MerchantId);
      return (
        <tr key={lineItem ? (transaction.Id + lineItem.Id + j) : transaction.OrderNumber}>
          {(j === 0) && <td rowSpan={products.length}>{this.props.i + 1}</td>}
          {(j === 0 && (isAdmin || storeType !== 'buffets')) && <td rowSpan={products.length}>
            {transaction.Customer.Username && isAdmin && isUrlAdmin ?
              <OverlayTrigger onClick={this.handleGetCustomerAddress}
                trigger={['click']} placement="top"
                overlay={this.customerDeliveryDetailsPopover(this.state.customerAddress)}
                rootClose>
                <div><b>{transaction.Customer.Username}</b></div>
              </OverlayTrigger> : (transaction.Customer.Username ? <div>{transaction.Customer.Username}</div> : <div className="no-field-text text-nowrap"><em>NA</em></div>)}
          </td>}
          {(j === 0 && isAdmin) && <td rowSpan={products.length}>{transaction.Customer.Email}</td>}
          {(j === 0) && <td rowSpan={products.length}>{this.formattedTransactionId}</td>}
          <td>{lineItem?.MerchantBranch ? `${merchant?.Name} (${lineItem?.MerchantBranch?.BranchName})` : <div className="no-field-text text-nowrap"><em>NA</em></div>}</td>
          {(j === 0) && <td rowSpan={products.length}>{transaction.CreatedAt ? moment.unix(transaction.CreatedAt).format('ddd, D MMM YYYY, HH:mm') : 'N/A'}</td>}
          <td>{lineItem?.ProductNameAtOrder ?? <div className="no-field-text text-nowrap"><em>NA</em></div>}</td>
          <td>{lineItem?.OfferAtOrder ? Number(lineItem.OfferAtOrder).toFixed(2) : <div className="no-field-text text-nowrap"><em>NA</em></div>}</td>
          <td>{lineItem?.Quantity ?? <div className="no-field-text text-nowrap"><em>NA</em></div>}</td>
          {(j === 0) && <td rowSpan={products.length}>{paymentType ? <OverlayTrigger trigger={paymentType === 'card' ? ['click'] : []} placement="top" overlay={this.stripePopover} rootClose>
            {PAYMENT_ICONS[paymentType] ?? <div>{paymentType}</div>}
          </OverlayTrigger> : <div className="no-field-text text-nowrap"><em>NA</em></div>}</td>}
          {(j === 0) && <td rowSpan={products.length}>{transaction.TransactionType && isAdmin && isUrlAdmin ?
            <OverlayTrigger onClick={this.handleGetTransactionMeta}
              trigger={['click']} placement="top"
              overlay={this.deliveryPickupPopover(this.state.customerDeliveryDetails)}
              rootClose>
              <div><b>{transaction.TransactionType}</b></div>
            </OverlayTrigger> :
            (transaction.TransactionType ? <div>{transaction.TransactionType}</div> :
              <div className="no-field-text text-nowrap"><em>NA</em></div>)}
          </td>}
          {(j === 0) && <td rowSpan={products.length}>{Number(transaction.OfferTotal).toFixed(2)}</td>}
          {(j === 0) && <td rowSpan={products.length}>{Number(transaction.NettTotal).toFixed(2)}</td>}
          {(j === 0) && <td rowSpan={products.length}>{orderStatus}</td>}
          {(j === 0) &&
            <td rowSpan={products.length}>
              {isAdmin && <div>
                {this.props.isVoid &&
                  <Button className="table-action-button action-button btn-fill"
                    bsStyle="warning"
                    type="button"
                    onClick={this.state.isExpanded ? this.handleClose : this.handleExpand} >
                    <i className="fas fa-question-circle"></i>
                  </Button>}
                {(orderStatus !== 'Void' && !this.state.isCompleteExpanded) &&
                  <span>
                    <Button className="table-action-button action-button btn-fill"
                      bsStyle={this.state.isExpanded ? 'warning' : 'danger'}
                      type="button"
                      onClick={this.state.isExpanded ? this.handleSaveVoid : this.handleExpand} >
                      {this.state.isExpanded ? <i className="fas fa-check"></i> : <i className="fas fa-ban"></i>}
                    </Button>
                    <Button className={`table-action-button action-button btn-fill ${this.state.isExpanded ? "" : "hidden"}`}
                      bsStyle="warning"
                      type="button"
                      onClick={this.handleCancel}>
                      <i className="fas fa-times"></i>
                    </Button>
                  </span>}
              </div>}
              {(orderStatus === 'Pending' && !this.state.isExpanded) &&
                <span>
                  <Button className="table-action-button action-button btn-fill"
                    bsStyle={this.state.isCompleteExpanded ? 'warning' : 'success'}
                    type="button"
                    onClick={this.state.isCompleteExpanded ? this.handleSaveComplete : this.handleExpandComplete} >
                    {this.state.isCompleteExpanded ? <i className="fas fa-check"></i> : <i className="fas fa-check"></i>}
                  </Button>
                  <Button className={`table-action-button action-button btn-fill ${this.state.isCompleteExpanded ? "" : "hidden"}`}
                    bsStyle="warning"
                    type="button"
                    onClick={this.handleCancel}>
                    <i className="fas fa-times"></i>
                  </Button>
                </span>}
            </td>
          }
        </tr>
      )
    });

    return (
      <tbody>
        {productRows}
        <tr className={(this.state.isExpanded || this.state.isCompleteExpanded) ? '' : 'hidden'}>
          <td colSpan={8}>
            {orderStatus === 'Void' &&
              <div>
                <div><strong>Staff name:</strong><br />{transaction.OrderStatus.find(o => o.OrderStatusTypeId === 3)?.CreatedBy}</div>
                <div className="text-formatted"><strong>Reason for voiding:</strong><br />{transaction.OrderStatus.find(o => o.OrderStatusTypeId === 3)?.Remarks}</div>
              </div>}
            {orderStatus !== 'Void' &&
              <div>
                <FormGroup>
                  <ControlLabel>Enter Staff Name</ControlLabel>
                  <FormControl
                    type="text"
                    placeholder="Staff name"
                    name="staffname"
                    onChange={this.handleChange}
                    value={this.state.staffname}
                    required
                  />
                </FormGroup>
                {this.state.isExpanded && <FormGroup>
                  <ControlLabel>Enter reason for voiding transaction</ControlLabel>
                  <FormControl
                    componentClass="textarea"
                    placeholder="Reason for voiding transaction"
                    name="reason"
                    onChange={this.handleChange}
                    value={this.state.reason}
                    required
                  />
                </FormGroup>}
                {this.state.isCompleteExpanded && <FormGroup>
                  <ControlLabel>Enter remarks</ControlLabel>
                  <FormControl
                    componentClass="textarea"
                    placeholder="Remarks"
                    name="reason"
                    onChange={this.handleChange}
                    value={this.state.reason}
                    required
                  />
                </FormGroup>}
              </div>
            }
          </td>
        </tr>
      </tbody>
    );
  }
}

export default TransactionRow;
