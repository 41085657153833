import axios from "../service/HttpManager";

const customersMap = {};

export const fetchCustomer = async (customerId, accessToken) => {
  const cacheCustomer = customersMap[customerId];
  if (!!cacheCustomer) return cacheCustomer;

  const response = await axios.post(
    "/customer/getOne",
    { id: customerId },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  const customer = response.data;
  customersMap[customerId] = customer;
  return customer;
};

export const fetchCustomerByEmail = async (email, accessToken) => {
  const response = await axios.post(
    "/customer/getByEmail",
    { email },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const fetchCustomerByUsername = async (username, accessToken) => {
  const response = await axios.post(
    "/customer/getByUserName",
    { username },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const fetchCustomerAddress = async (addressId, accessToken) => {
  const response = await axios.post(
    "/customer/address/getOneById",
    { id: addressId },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const fetchCustomerAddressByCustomerId = async (customerId, accessToken) => {
  const response = await axios.post(
    "/customer/address/getOneByCustomerId",
    { id: customerId },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const resetCustomerPassword = async (customerGuid, password, accessToken) => {
  const response = await axios.post(
    "/customer/updatePassword",
    { customerGuid, password },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const deleteCustomer = async (customerId, accessToken) => {
  const response = await axios.post(
    "/customers/delete",
    { id: customerId },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};
