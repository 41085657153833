import React, { Component } from 'react';
import { Grid, Row, Col, Table, Button, FormControl, ControlLabel, FormGroup, Carousel } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import moment from 'moment';
import Compress from "react-image-file-resizer";
import Select from 'react-select';

import ProperCard from '../../components/Card/ProperCard.jsx';
import ProductRow from '../../components/ProductRow/ProductRow.jsx';
import { validateInputs } from '../../helper';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { connect } from 'react-redux';
import XLSX from 'xlsx';
import api from '../../api';

const PRODUCT_TAG_OPTIONS = [
  { value: 'produce', label: 'produce' },
  { value: 'snacks', label: 'snacks' },
  { value: 'dairy & drinks', label: 'dairy & drinks' },
  { value: 'bakes', label: 'bakes' },
  { value: 'others', label: 'others' }
]

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTableLoading: false,
      products: [],
      name: '',
      currency: 'SGD',
      price: '',
      unitOfMeasure: '',
      description: '',
      productImages: [null],
      productImageIndex: 0,
      instructions: '',
      noExpiry: false,
      expiredDate: null,
      expiredHour: null,
      expiredMinute: null,
      productTags: [],
      productsToDelete: {},
      productsToUpload: [],
      bulkUploading: false,
    };
  }

  componentDidMount() {
    this.loadProducts();
  }

  loadProducts = async () => {
    const { token, branchId } = this.props.auth;
    try {
      const products = await api.fetchProducts(branchId, token, 1, 500);
      this.setState({ products });
    } catch (error) {
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{error.message}</strong>,
        level: 'error'
      });
    }
  }

  handleExpiredDateChange = (selectedDay) => {
    const date = moment(selectedDay).format('DD/MM/YYYY');
    const name = 'expiredDate';
    this.setState({ [name]: date });
  }

  handleFile = (e) => {
    const files = e.target.files, f = files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const headers = {};
      let products = [];
      for (let z in worksheet) {
        if (z[0] === '!') continue;
        //parse out the column, row, and value
        var tt = 0;
        for (var i = 0; i < z.length; i++) {
          if (!isNaN(z[i])) {
            tt = i;
            break;
          }
        };
        var col = z.substring(0, tt);
        var row = parseInt(z.substring(tt));
        var value = worksheet[z].v;

        //store header names
        if (row === 1 && value) {
          headers[col] = value;
          continue;
        }

        if (!products[row]) products[row] = {};
        products[row][headers[col]] = value;
      }
      //drop those first two rows which are empty
      products.shift();
      products.shift();
      console.log(products);
      this.setState({ productsToUpload: products });
    };
    reader.readAsArrayBuffer(f);
  }

  bulkUpload = async () => {
    // TODO: use correct fields
    this.setState({ bulkUploading: true });
    const { token, branchId } = this.props.auth;
    try {
      for (let index in this.state.productsToUpload) {
        const product = this.state.productsToUpload[index];
        product.merchantBranchId = branchId;
        const tag = product.tag;
        delete product.tag;
        const result = await api.createProduct(product, token);
        const productId = result.Id;
        if (tag) {
          await api.createProductTag(productId, tag, token);
        }
      }
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-like2" />,
        message: <strong>Bulk upload success!</strong>,
        level: 'success'
      });
      this.setState({ bulkUploading: false });
      this.loadProducts();
    } catch (error) {
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{error.message}</strong>,
        level: 'error'
      });
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleCheckbox = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  }

  handleProductImageSelect = async (file, index) => {
    try {
      const image = await resizeFile(file);
      document.getElementById(`product-img-${index}`).src = URL.createObjectURL(image);
      this.setState(oldState => {
        oldState.productImages[index] = image;
        return { productImages: oldState.productImages };
      });
    } catch (err) {
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{err.message}</strong>,
        level: 'error'
      });
    }
  }

  handleCarouselSelect = (selectedIndex) => {
    this.setState({ productImageIndex: selectedIndex })
  }

  handleAddProductImage = () => {
    this.setState(oldState => {
      oldState.productImages.push(null);
      return {
        productImages: oldState.productImages,
        productImageIndex: oldState.productImages.length - 1
      };
    });
  }

  handleCheckAll = (e) => {
    const checkboxes = document.getElementsByName('product-checkbox');
    const productsToDelete = {};
    for (const checkbox of checkboxes) {
      const checked = e.target.checked;
      checkbox.checked = checked;
      productsToDelete[checkbox.value] = checked;
    }
    this.setState({ productsToDelete });
  }

  handleCheck = (e) => {
    const productsToDelete = JSON.parse(JSON.stringify(this.state.productsToDelete));
    productsToDelete[e.target.value] = e.target.checked;
    this.setState({ productsToDelete });
  }

  handleAdd = async (e) => {
    const isInputsValid = validateInputs(e.target.closest('form'), this);
    if (!isInputsValid) {
      return;
    }

    const branchId = this.props.auth.branchId;

    const expiredHour = this.state.expiredHour;
    const expiredMinute = this.state.expiredMinute;
    const expiredDate = moment(this.state.expiredDate, 'DD/MM/YYYY').startOf('day').unix();
    const expireDate = expiredDate + expiredHour * 3600 + expiredMinute * 60;

    const data = {
      name: this.state.name,
      merchantBranchId: branchId,
      description: this.state.description,
      currency: this.state.currency,
      price: this.state.price,
      instructions: this.state.instructions,
      surcharge: 0,
      unit: this.state.unitOfMeasure,
      expiry: this.state.noExpiry ? null : expireDate,
      isActive: true
    }

    try {
      const result = await api.createProduct(data, this.props.token);
      console.log(result);
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-like2" />,
        message: <strong>Product created.</strong>,
        level: 'success'
      });

      const productId = result.Id;

      await Promise.all(this.state.productImages.filter(i => i !== null)
        .map(image => api.uploadProductImage(productId, image, this.props.token)));

      await Promise.all(this.state.productTags.map(tag => api.createProductTag(productId, tag, this.props.token)));

      this.state.productImages.map((image, index) => document.getElementById(`product-img-${index}`).src = '');
      this.setState({
        name: '',
        currency: 'SGD',
        price: '',
        unitOfMeasure: '',
        description: '',
        instructions: '',
        productImageIndex: 0
      }, () => {
        setTimeout(() => {
          this.setState({
            productImages: [null]
          })
        }, 1000);
      });
      this.loadProducts();
    } catch (err) {
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{err.message}</strong>,
        level: 'error'
      });
    }
  }

  handleDelete = async () => {
    if (!window.confirm('Are you sure you want to delete?')) {
      return;
    }

    const productIds = []
    Object.keys(this.state.productsToDelete).forEach((productId) => {
      if (this.state.productsToDelete[productId]) {
        productIds.push(productId);
      }
    });

    if (productIds.length === 0) {
      return;
    }

    try {
      await Promise.all(productIds.map(productId => api.deleteProduct(productId, this.props.token)));
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-like2" />,
        message: <strong>Products deleted.</strong>,
        level: 'success'
      });

      this.setState({ productsToDelete: {} });
      this.loadProducts();
    } catch (error) {
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{error.message}</strong>,
        level: 'error'
      });
    }
  }

  render() {
    return (
      <div className="content" style={{ paddingLeft: 30, paddingRight: 30 }}>
        <Grid fluid>
          <Row>
            <ProperCard
              title="Add a Product">
              <form>
                <div className={`loader-overlay ${this.state.isFormLoading ? '' : 'hidden'}`}>
                  <div className="loader" />
                </div>
                <FormGroup>
                  <ControlLabel>Enter Name</ControlLabel>
                  <FormControl
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                    required
                  />
                </FormGroup>
                <Row>
                  <Col sm={4}>
                    <FormGroup>
                      <ControlLabel>Enter Currency</ControlLabel>
                      <FormControl
                        type="text"
                        placeholder="Currency"
                        name="currency"
                        value={this.state.currency}
                        onChange={this.handleChange}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={4}>
                    <FormGroup>
                      <ControlLabel>Enter Original Price</ControlLabel>
                      <FormControl
                        type="number"
                        placeholder="Price"
                        name="price"
                        step="0.01"
                        min="0"
                        value={this.state.price}
                        onChange={this.handleChange}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={4}>
                    <FormGroup>
                      <ControlLabel>Enter Unit of Measurement</ControlLabel>
                      <FormControl
                        type="text"
                        placeholder="Unit of Measurement"
                        name="unitOfMeasure"
                        value={this.state.unitOfMeasure}
                        onChange={this.handleChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <ControlLabel>Enter Description</ControlLabel>
                  <FormControl
                    componentClass="textarea"
                    placeholder="Description"
                    name="description"
                    value={this.state.description}
                    onChange={this.handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Enter Order Instructions</ControlLabel>
                  <FormControl
                    componentClass="textarea"
                    placeholder="Instructions"
                    name="instructions"
                    value={this.state.instructions}
                    onChange={this.handleChange}
                    disabled={!this.props.roles.includes('admin')}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel className="label-block" >Enter Expiry Date</ControlLabel>
                  <input className="admin-checkbox" type="checkbox"
                    name="noExpiry"
                    onChange={this.handleCheckbox}
                    checked={this.state.noExpiry} />
                  <span className="admin-label" >No expiry date</span>
                  <DayPickerInput
                    inputProps={{
                      className: 'form-control date-input datetime-input',
                      name: 'expiredDate'
                    }}
                    value={this.state.expiredDate}
                    onDayChange={this.handleExpiredDateChange}
                    placeholder="DD/MM/YYYY"
                    format="DD/MM/YYYY"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    disabled={this.state.noExpiry}
                    required={!this.state.noExpiry}
                  />
                  <FormControl
                    className="time-input datetime-input"
                    type="number"
                    name="expiredHour"
                    placeholder="hh"
                    min="0"
                    max="23"
                    step="1"
                    value={this.state.expiredHour}
                    onChange={this.handleChange}
                    disabled={this.state.noExpiry}
                    required={!this.state.noExpiry}
                  />
                  <span className="time-separator">:</span>
                  <FormControl
                    className="time-input datetime-input"
                    type="number"
                    name="expiredMinute"
                    placeholder="mm"
                    min="0"
                    max="59"
                    step="1"
                    value={this.state.expiredMinute}
                    onChange={this.handleChange}
                    disabled={this.state.noExpiry}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Product tags</ControlLabel>
                  <Select.Creatable
                    options={PRODUCT_TAG_OPTIONS}
                    multi={true}
                    value={this.state.productTags}
                    placeholder="select product tags"
                    onChange={(val) => {
                      val = val.map((productTagVal) => {
                        return productTagVal.value;
                      });
                      this.setState({ productTags: val });
                    }}
                  />
                </FormGroup>
                <h4>Product Images</h4>
                <Carousel interval={null} activeIndex={this.state.productImageIndex} onSelect={this.handleCarouselSelect}>
                  {
                    this.state.productImages.map((image, index) => {
                      return (<Carousel.Item key={index}>
                        <Dropzone onDropAccepted={files => this.handleProductImageSelect(files[0], index)} accept="image/png, image/jpeg">
                          {({ getRootProps, getInputProps, isDragActive }) => (
                            <section>
                              <div {...getRootProps({ style: { ...dragNDropStyle, ...(isDragActive ? { borderColor: '#ff5c0e' } : {}) } })}>
                                <img className="branch-product-image" style={{ height: 100, marginLeft: 'auto', marginRight: 'auto' }} id={`product-img-${index}`} alt="" />
                                <input {...getInputProps()} />
                                <p>Drag 'n' drop, or click to select file</p>
                              </div>
                            </section>
                          )}
                        </Dropzone>
                      </Carousel.Item>)
                    })
                  }
                </Carousel>
                <br />
                <Button className="table-action-button action-button btn-fill"
                  bsStyle="warning"
                  style={{ marginBottom: 10 }}
                  type="button"
                  onClick={this.handleAddProductImage}>
                  <i className="fas fa-plus"></i> Add Product Image
                </Button>
                <br />
                <Button className="table-action-button action-button btn-fill"
                  bsStyle="warning"
                  type="button"
                  onClick={this.handleAdd}>
                  Create Product
                </Button>
              </form>
            </ProperCard>
          </Row>
          <Row>
            <ProperCard title="Bulk Upload">
              <div className={`loader-overlay ${this.state.bulkUploading ? '' : 'hidden'}`}>
                <div className="loader" />
              </div>
              <a href="/product-form.xlsx" download>
                <i className="fas fa-download" /> Download Form
              </a>
              <FormGroup style={{ marginTop: 10 }}>
                <ControlLabel>Select File</ControlLabel>
                <FormControl
                  type="file"
                  name="tmp"
                  onChange={this.handleFile}
                  id="bulk-upload"
                  accept=".xlsx,.csv"
                  multiple
                />
              </FormGroup>
              <Button
                className="table-action-button action-button btn-fill"
                bsStyle="warning"
                type="button"
                onClick={this.bulkUpload}>
                <i className="fas fa-upload" /> Bulk Upload
              </Button>
            </ProperCard>
          </Row>
          <Row>
            <ProperCard
              title="Products"
              ctTableResponsive
            >
              <form>
                <Table hover bordered id="products-table" responsive>
                  <thead>
                    <tr>
                      <th className="checkbox-cell"><input type="checkbox" onChange={this.handleCheckAll} /></th>
                      <th>#</th>
                      <th>Name</th>
                      <th>Original Price</th>
                      <th>UOM</th>
                      <th>Description</th>
                      <th>Instructions</th>
                      <th>Expiry Date</th>
                      <th>Image</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  {
                    this.state.products.map((product, i) => {
                      return <ProductRow
                        {...this.props}
                        key={product.Id}
                        product={product}
                        i={i}
                        loadProducts={this.loadProducts}
                        token={this.props.token}
                        handleCheck={this.handleCheck} />;
                    })
                  }
                </Table>
                <Button className="btn-fill action-button"
                  bsStyle="warning"
                  type="button"
                  onClick={this.handleDelete}>
                  <i className="fas fa-trash"></i> Delete Rows
                </Button>
              </form>
            </ProperCard>
          </Row>
        </Grid>
      </div>
    );
  }
}

function ellipsize(text, length) {
  text = text || '';
  text = text.trim();
  return text.length > length ? text.substring(0, length) + '...' : text;
}

const dragNDropStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '5px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const resizeFile = (file) =>
  new Promise((resolve) => {
    Compress.imageFileResizer(
      file,
      480,
      480,
      "PNG",
      70,
      0,
      (uri) => {
        resolve(uri);
      },
      "blob"
    );
  });

export default connect(state => ({
  auth: state.auth
}))(Products);
