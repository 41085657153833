import axios from "../service/HttpManager";

export const fetchPromoCodes = async (accessToken, page = 1, pageSize = 200) => {
  const response = await axios.post("/order/promocode/all", null, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: { page, pageSize },
  });
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const fetchPromoCodeByCode = async (promoCode, accessToken) => {
  const response = await axios.post(
    "/order/promocode/getByCode",
    { code: promoCode },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const createPromoCode = async (data, accessToken) => {
  const response = await axios.post("/order/promocode/create", data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const updatePromoCode = async (data, accessToken) => {
  const response = await axios.post("/order/promocode/update", data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const deletePromoCode = async (promoCodeId, accessToken) => {
  const response = await axios.post(
    "/order/promocode/delete",
    {
      id: promoCodeId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const fetchPromoCodeFilterByPromoCodeId = async (
  promoCodeId,
  accessToken,
  page = 1,
  pageSize = 200
) => {
  const response = await axios.post(
    "/order/promocodeFilter/getByPromocodeId",
    {
      promocodeId: promoCodeId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: { page, pageSize },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const createPromoCodeFilter = async (data, accessToken) => {
  const response = await axios.post("/order/promocodeFilter/create", data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const updatePromoCodeFilter = async (data, accessToken) => {
  const response = await axios.post("/order/promocodeFilter/update", data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};
