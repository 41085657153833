import React, { Component } from 'react';
import {
    Grid, Row, Col, Alert,
    FormGroup, ControlLabel, FormControl
} from 'react-bootstrap';
import { jwtDecode } from "jwt-decode";

import { ContentCard } from '../../components/ContentCard/ContentCard.jsx';
import Button from '../../elements/CustomButton/CustomButton.jsx';
import 'react-select/dist/react-select.css';

import logo from '../../assets/img/logo-orange-2.png';
import { connect } from 'react-redux';
import { login } from '../../actions';
import api from '../../api';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            isError: false,
            error: '',
            isLoading: false
        };
    }

    handleInput = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleClick = () => {
        this.submitForm()
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.submitForm();
            e.target.blur();
        }
    }

    submitForm = async () => {
        const username = this.state.username;
        const password = this.state.password;

        this.setState({ isLoading: true, isError: false });

        let isFetchCancelled = false;
        const timeout = setTimeout(() => {
            isFetchCancelled = true;
            this.setState({
                isLoading: false, isError: true,
                error: 'Timed out while attempting to login. Please try again.'
            });
        }, 9000);

        try {
            const result = await api.login(username, password);
            if (isFetchCancelled) {
                throw Error('Timed out while attempting to login. Please try again.');
            }

            clearTimeout(timeout);
            const token = result.access_token;
            const refreshToken = result.refresh_token;
            localStorage.setItem('refreshToken', refreshToken);
            const decodedToken = jwtDecode(token);
            const accountId = decodedToken.sub;
            const roleList = await api.fetchRoles(token);
            const roles = [...new Set(decodedToken.au.map(i => i.r).flat())].map(r => {
                const role = roleList.find(item => item.RoleGuid === r);
                if (!role) {
                    return null;
                }
                return role.RoleName;
            }).filter(i => i !== null);
            let branchesPermissions = decodedToken.au.map(i => {
                const role = roleList.find(item => item.RoleGuid === i.r[0]);
                if (!role) {
                    return null;
                }
                let writable = [];
                switch (role.RoleName) {
                    case 'admin':
                        writable = [
                            "user profile",
                            "products",
                            "listings",
                            "events",
                            "transactions",
                        ];
                        break;
                    case 'merchant-manager':
                        writable = [
                            "user profile",
                            "products",
                            "listings",
                            "transactions",
                        ];
                        break;
                    case 'merchant-staff':
                        writable = [
                            "user profile",
                            "listings",
                            "transactions",
                        ];
                        break;
                    case 'merchant-user':
                        writable = [
                            "user profile",
                            "transactions"
                        ];
                        break;
                    case 'event-manager':
                        writable = [
                            "user profile",
                            "events",
                            "transactions",
                        ];
                        break;
                }
                return {
                    id: i.b,
                    writable,
                }
            }) || [];
            branchesPermissions = branchesPermissions.filter(i => i !== null);

            this.setState({ isLoading: false });
            this.props.dispatch(login(token, accountId, branchesPermissions, roles));
            let { from } = this.props.location.state || { from: { pathname: `/${roles.includes('admin') ? 'admin' : ''}` } };
            this.props.history.replace(from);
        } catch (error) {
            clearTimeout(timeout);
            console.log("error", error);
            this.setState({
                isLoading: false,
                isError: true,
                error: error.message
            });
        }
    }

    render() {
        let content =
            (<div>
                <div className={'loader-overlay ' + (this.state.isLoading ? '' : 'hidden')}><div className="loader"></div></div>
                <img id="login-logo" src={logo} alt="logo" />
                <form id="login-form">
                    {this.state.isError && (<Alert bsStyle="danger">
                        <span>{this.state.error}</span>
                    </Alert>)}
                    <FormGroup>
                        <ControlLabel>Username</ControlLabel>
                        <FormControl
                            autoFocus
                            type="text"
                            placeholder="username"
                            name="username"
                            onInput={this.handleInput}
                            onKeyPress={this.handleKeyPress}
                            spellCheck={false}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>password</ControlLabel>
                        <FormControl
                            type="password"
                            placeholder="password"
                            name="password"
                            onInput={this.handleInput}
                            onKeyPress={this.handleKeyPress}
                        />
                    </FormGroup>
                    <Button
                        bsStyle="warning"
                        type="button"
                        block
                        fill
                        onClick={this.handleClick}
                    >
                        login
                    </Button>
                </form>
            </div>);

        return (
            <div id="login-container">
                <Grid>
                    <Row>
                        <Col md={4} mdOffset={4}>
                            <div className="login-card">
                                <ContentCard content={content} />
                            </div>
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default connect(state => ({
    auth: state.auth
}))(Login);
