import React, { Component } from 'react';
import { Table, FormGroup, FormControl, Row, Col, ControlLabel, Label, Carousel, Modal } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import moment from 'moment';
import Compress from "react-image-file-resizer";
import { FormInputs } from '../../components/FormInputs/FormInputs.jsx';
import Button from '../../elements/CustomButton/CustomButton.jsx';
import Select from 'react-select';
import { validateInputs } from '../../helper';
import api from '../../api';

const AREA_OPTIONS = [
  { value: 'North', label: 'North' },
  { value: 'South', label: 'South' },
  { value: 'East', label: 'East' },
  { value: 'West', label: 'West' },
  { value: 'Central', label: 'Central' }
];

class BranchRow extends Component {
  constructor(props) {
    super(props);

    const branch = this.props.branch;

    this.state = {
      show: false,
      disclaimerExisted: false,
      disclaimer: '',
      isEditing: false,
      isFormLoading: false,
      branchName: branch.BranchName,
      email: branch.Email || '',
      description: branch.BranchDescription || '',
      address1: branch.AddressLine1 || '',
      address2: branch.AddressLine2 || '',
      city: branch.City || '',
      state: branch.State || '',
      postal: branch.PostalCode || '',
      officeNumber: branch.OfficeNumber || '',
      mobileNumber: branch.MobileNumber || '',
      area: branch.Area || '',
      latitude: branch.Latitude || '',
      longitude: branch.Longitude || '',
      branchStoreTypes: [],
      storeTypes: [],
      locationType: branch.LocationType || '',
      website: branch.MerchantBranchMediaLink.find(l => l.MediaType === 'website')?.Link || '',
      facebook: branch.MerchantBranchMediaLink.find(l => l.MediaType === 'facebook')?.Link || '',
      instagram: branch.MerchantBranchMediaLink.find(l => l.MediaType === 'instagram')?.Link || '',
      branchFoodCategories: [],
      foodCategories: [],
      announcement: branch.Announcement || '',
      branchImages: [null],
      branchImageIndex: 0,
      merchantBranchImages: branch.MerchantBranchImage || [],
      operatingHoursDescription: branch.AvailabilityDescription || '',
      currDays: [],
      currBegin: '',
      currEnd: '',
      currTimeSlots: [],
      branchFulfilmentSlots: [],
      deliveryFulfilmentSlots: [],
      pickupFulfilmentSlots: [],
      deliveryFulfilmentSlotOptions: [],
      pickupFulfilmentSlotOptions: [],
    };
  }

  handleSelectChange = (name, val) => {
    this.setState({ [name]: val });
  }

  handleAddTimeSlot = async () => {
    const currBegin = this.state.currBegin;
    const currEnd = this.state.currEnd;

    if (this.state.currDays.length === 0 || !currBegin || !currEnd) {
      return;
    }

    this.setState({ isFormLoading: true });

    const branchId = this.props.branch.Id;

    const begin = moment(currBegin, 'HH:mm').diff(moment().startOf('day'), 'seconds');
    const end = moment(currEnd, 'HH:mm').diff(moment().startOf('day'), 'seconds');
    const days = this.state.currDays.map(day => day.value);
    try {
      for (const day of days) {
        const schedule = {
          day,
          opening: begin,
          closing: end,
          lastRedeem: end
        }
        const result = await api.createBranchSchedule(branchId, schedule, this.props.token);
        console.log(result);
      }

      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-like2" />,
        message: <strong>Schedule added.</strong>,
        level: 'success'
      });

      const currTimeSlots = this.state.currTimeSlots;
      currTimeSlots.push({
        days, begin: currBegin, end: currEnd
      });
      this.setState({ currTimeSlots });
    } catch (error) {
      console.log(error);
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{error.message}</strong>,
        level: 'error'
      });
    } finally {
      this.setState({ isFormLoading: false });
    }
  }

  handleDeleteTimeSlot = async (i) => {
    if (!window.confirm('Are you sure you want to delete?')) {
      return;
    }

    this.setState({ isFormLoading: true });
    try {
      const currTimeSlots = this.state.currTimeSlots.slice();
      const deletedSlot = currTimeSlots.splice(i, 1);
      const ids = deletedSlot[0].ids;
      for (const id of ids) {
        const result = await api.deleteBranchSchedule(id, this.props.token);
        console.log(result);
      }

      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-like2" />,
        message: <strong>Schedule deleted.</strong>,
        level: 'success'
      });

      this.setState({ currTimeSlots });
    } catch (error) {
      console.log(error);
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{error.message}</strong>,
        level: 'error'
      });
    } finally {
      this.setState({ isFormLoading: false });
    }
  }

  handleDelete = async () => {
    if (!window.confirm('Are you sure you want to delete?')) {
      return;
    }

    this.setState({ isFormLoading: true });
    try {
      const result = await api.deleteBranch(this.props.branch.Id, this.props.token);
      console.log(result);
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-like2" />,
        message: <strong>Branch deleted.</strong>,
        level: 'success'
      });
      this.setState({ isExpanded: false });
      this.props.loadBranches();
    } catch (error) {
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{error.message}</strong>,
        level: 'error'
      });
    } finally {
      this.setState({ isFormLoading: false });
    }
  }

  handleEdit = async () => {
    this.setState({ isFormLoading: true });
    try {
      const branchStoreTypes = await api.fetchBranchStoreTypes(this.props.branch.Id, this.props.token);
      const storeTypes = branchStoreTypes.map(storeType => {
        const type = this.props.storeTypeOptions.find(t => t.value === storeType.StoreTypeId)
        return { value: storeType.StoreTypeId, label: type?.label };
      });
      const schedules = await api.fetchBranchSchedule(this.props.branch.Id, this.props.token);
      const currTimeSlots = getCurrTimeSlots(schedules);
      const branchFoodCategories = await api.fetchBranchFoodCategories(this.props.branch.Id, this.props.token);
      const foodCategories = branchFoodCategories.map(foodCategory => {
        const type = this.props.foodCategoryOptions.find(t => t.value === foodCategory.FoodCategoryId)
        return { value: foodCategory.FoodCategoryId, label: type?.label };
      });
      const branchFulfilmentSlots = await api.fetchBranchFulfilmentSlots(this.props.branch.Id, this.props.token);
      const deliveryFulfilmentSlots = branchFulfilmentSlots.filter(s => s.FulfilmentTypeId === 1)
        .map(slot => ({ value: slot.Id, label: slot.Description }));
      const pickupFulfilmentSlots = branchFulfilmentSlots.filter(s => s.FulfilmentTypeId === 2)
        .map(slot => ({ value: slot.Id, label: slot.Description }));
      this.setState({
        isEditing: true,
        currTimeSlots,
        branchStoreTypes,
        storeTypes,
        branchFoodCategories,
        foodCategories,
        isFormLoading: false,
        deliveryFulfilmentSlotOptions: deliveryFulfilmentSlots,
        pickupFulfilmentSlotOptions: pickupFulfilmentSlots,
        branchFulfilmentSlots,
        deliveryFulfilmentSlots,
        pickupFulfilmentSlots,
      });
    } catch (error) {
      console.log(error);
      this.setState({ isEditing: false, isFormLoading: false });
    }
  }

  handleSave = async (e) => {
    if (!window.confirm('Are you sure you want to save?')) {
      return;
    }

    const isInputsValid = validateInputs(e.target.closest('tbody'), this);
    if (!isInputsValid) {
      return;
    }

    const branchId = this.props.branch.Id;
    const data = {
      id: this.props.branch.Id,
      email: this.state.email,
      branchName: this.state.branchName,
      addressLine1: this.state.address1,
      addressLine2: this.state.address2,
      postalCode: this.state.postal,
      area: this.state.area,
      latitude: parseFloat(this.state.latitude),
      longitude: parseFloat(this.state.longitude),
      locationType: this.state.locationType,
      city: this.state.city,
      state: this.state.state,
      officeNumber: this.state.officeNumber,
      faxNumber: this.state.faxNumber,
      mobileNumber: this.state.mobileNumber,
      availabilityDescription: this.state.operatingHoursDescription,
      branchDescription: this.state.description,
      announcement: this.state.announcement,
    }

    const storeTypes = this.state.storeTypes;
    const addedStoreTypes = storeTypes.filter(type => !(this.state.branchStoreTypes.find(t => t.StoreTypeId === type.value)));
    const removedStoreTypes = this.state.branchStoreTypes.filter(t => !storeTypes.find(type => type.value === t.StoreTypeId));
    const website = this.state.website;
    const facebook = this.state.facebook;
    const instagram = this.state.instagram;

    const foodCategories = this.state.foodCategories;
    const addedFoodCategories = foodCategories.filter(type => !(this.state.branchFoodCategories.find(t => t.FoodCategoryId === type.value)));
    const removedFoodCategories = this.state.branchFoodCategories.filter(t => !foodCategories.find(type => type.value === t.FoodCategoryId));

    const deliveryFulfilmentSlots = this.state.deliveryFulfilmentSlots;
    const deliveryBranchFulfilmentSlots = this.state.branchFulfilmentSlots.filter(s => s.FulfilmentTypeId === 1);
    const addedDeliveryFulfilmentSlots = deliveryFulfilmentSlots.filter(slot => !(deliveryBranchFulfilmentSlots.find(s => s.Id === slot.value)));
    const removedDeliveryFulfilmentSlots = deliveryBranchFulfilmentSlots.filter(s => !deliveryFulfilmentSlots.find(slot => slot.value === s.Id));

    const pickupFulfilmentSlots = this.state.pickupFulfilmentSlots;
    const pickupBranchFulfilmentSlots = this.state.branchFulfilmentSlots.filter(s => s.FulfilmentTypeId === 2);
    const addedPickupFulfilmentSlots = pickupFulfilmentSlots.filter(slot => !(pickupBranchFulfilmentSlots.find(s => s.Id === slot.value)));
    const removedPickupFulfilmentSlots = pickupBranchFulfilmentSlots.filter(s => !pickupFulfilmentSlots.find(slot => slot.value === s.Id));

    const branchImages = this.state.branchImages.filter(image => image !== null);

    try {
      const result = await api.updateBranch(branchId, data, this.props.token);
      console.log(result);

      await Promise.all([
        ...addedStoreTypes.map(type => api.createBranchStoreType(branchId, type.value, this.props.token)),
        ...removedStoreTypes.map(type => api.deleteBranchStoreType(type.Id, this.props.token)),
      ]);

      await Promise.all([
        this.updateMediaLink(website, "website"),
        this.updateMediaLink(facebook, "facebook"),
        this.updateMediaLink(instagram, "instagram"),
      ])

      await Promise.all([
        ...addedFoodCategories.map(type => api.createBranchFoodCategory(branchId, type.value, this.props.token)),
        ...removedFoodCategories.map(type => api.deleteBranchFoodCategory(type.Id, type.FoodCategoryId, this.props.token)),
      ])

      await Promise.all([
        ...addedDeliveryFulfilmentSlots.map(slot => api.createBranchFulfilmentSlot(branchId, 1, slot.label, this.props.token)),
        ...removedDeliveryFulfilmentSlots.map(slot => api.deleteBranchFulfilmentSlot(slot.Id, this.props.token)),
      ])

      await Promise.all([
        ...addedPickupFulfilmentSlots.map(slot => api.createBranchFulfilmentSlot(branchId, 2, slot.label, this.props.token)),
        ...removedPickupFulfilmentSlots.map(slot => api.deleteBranchFulfilmentSlot(slot.Id, this.props.token)),
      ])

      await Promise.all([
        ...branchImages.map(image => api.uploadBranchImage(branchId, image, this.props.token)),
      ])

      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-like2" />,
        message: <strong>Branch updated.</strong>,
        level: 'success'
      });
      this.state.branchImages.map((image, index) => document.getElementById(`${this.props.branch.Id}-branch-img-${index}`).src = '');
      this.setState({
        isEditing: false,
        branchImages: [null],
      });
      this.props.loadBranches();
    } catch (error) {
      console.log(error);
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{error.message}</strong>,
        level: 'error'
      });
    }
  }

  updateMediaLink = async (link, mediaType) => {
    const links = this.props.branch.MerchantBranchMediaLink;
    const oldLink = links.find(l => l.MediaType === mediaType);
    if (!oldLink) {
      if (!!link) {
        return api.createBranchLink(this.props.branch.Id, link, mediaType, this.props.token);
      } else {
        return;
      }
    } else if (oldLink.Link !== link) {
      return api.updateBranchLink(this.props.branch.Id, oldLink.Id, link, mediaType, this.props.token);
    } else {
      return;
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handlePhotoSelect = async (file, index) => {
    try {
      const image = await resizeFile(file);
      document.getElementById(`${this.props.branch.Id}-branch-img-${index}`).src = URL.createObjectURL(image);
      this.setState(oldState => {
        oldState.branchImages[index] = image;
        return { branchImages: oldState.branchImages };
      });
    } catch (err) {
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{err.message}</strong>,
        level: 'error'
      });
    }
  }

  handleCarouselSelect = (selectedIndex) => {
    this.setState({ branchImageIndex: selectedIndex })
  }

  handleAddBranchImage = () => {
    this.setState(oldState => {
      oldState.branchImages.push(null);
      return {
        branchImages: oldState.branchImages,
        branchImageIndex: oldState.branchImages.length - 1
      };
    });
  }

  handleRemoveBranchImage = async (i) => {
    if (!window.confirm('Are you sure you want to delete?')) {
      return;
    }

    this.setState({ isFormLoading: true });
    try {
      const activeImages = branch.MerchantBranchImage.filter(image => image.IsActive).sort((a, b) => b.CreatedAt - a.CreatedAt);
      const imageId = activeImages[i].Id;
      await api.deleteBranchImage(imageId, this.props.token);
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-like2" />,
        message: <strong>Branch image deleted.</strong>,
        level: 'success'
      });
      const updatedImages = this.state.merchantBranchImages;
      updatedImages.splice(i, 1);
      this.setState({
        merchantBranchImages: updatedImages,
      });
    } catch (error) {
      console.log(error);
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{error.message}</strong>,
        level: 'error'
      });
    } finally {
      this.setState({ isFormLoading: false });
    }
  }

  handleCancel = () => {
    const branch = this.props.branch;
    this.setState({
      isEditing: false,
      email: branch.Email || '',
      description: branch.BranchDescription || '',
      address1: branch.AddressLine1 || '',
      address2: branch.AddressLine2 || '',
      city: branch.City || '',
      state: branch.State || '',
      postal: branch.PostalCode || '',
      officeNumber: branch.OfficeNumber || '',
      mobileNumber: branch.MobileNumber || '',
      area: branch.Area || '',
      latitude: branch.Latitude || '',
      longitude: branch.Longitude || '',
      locationType: branch.LocationType || '',
      branchStoreTypes: [],
      storeTypes: [],
      website: branch.MerchantBranchMediaLink.find(l => l.MediaType === 'website')?.Link || '',
      facebook: branch.MerchantBranchMediaLink.find(l => l.MediaType === 'facebook')?.Link || '',
      instagram: branch.MerchantBranchMediaLink.find(l => l.MediaType === 'instagram')?.Link || '',
      branchFoodCategories: [],
      foodCategories: [],
      announcement: branch.Announcement || '',
      branchImages: [null],
      branchImageIndex: 0,
      merchantBranchImages: branch.MerchantBranchImage || [],
      operatingHoursDescription: branch.AvailabilityDescription || '',
      currDays: [],
      currBegin: '',
      currEnd: '',
      currTimeSlots: [],
      branchFulfilmentSlots: [],
      deliveryFulfilmentSlots: [],
      pickupFulfilmentSlots: [],
      deliveryFulfilmentSlotOptions: [],
      pickupFulfilmentSlotOptions: [],
    });
  }

  handleShowDisclaimer = async () => {
    const terms = this.props.branch.MerchantBranchTerm;
    let disclaimer, disclaimerExisted;
    if (terms.length > 0) {
      disclaimer = terms[0].Term.Description;
      disclaimerExisted = true;
    } else {
      disclaimerExisted = false;
    }
    this.setState({ show: true, disclaimer, disclaimerExisted });
  }

  handleHideDisclaimer = () => {
    this.setState({ show: false });
  }

  updateDisclaimer = async () => {
    let result;
    const { branch, token } = this.props;
    try {
      if (this.state.disclaimerExisted) {
        result = await api.updateBranchDisclaimer(branch.Id, branch.MerchantBranchTerm[0].TermId, this.state.disclaimer, token);
      } else {
        result = await api.createBranchDisclaimer(branch.Id, this.state.disclaimer, token);
      }
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-like2" />,
        message: <strong>Terms & Condition added.</strong>,
        level: 'success'
      });
      this.handleHideDisclaimer();
    } catch (error) {
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{error.message}</strong>,
        level: 'error'
      });
    }
  }

  renderModal = () => {
    return (
      <Modal
        show={this.state.show}
        onHide={this.handleHideDisclaimer}
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-lg">
            Terms & Conditions
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <FormControl
              componentClass="textarea"
              placeholder="Please input terms & conditions..."
              name="disclaimer"
              value={this.state.disclaimer}
              onChange={this.handleChange}
              required
            />
          </FormGroup>

        </Modal.Body>
        <Modal.Footer>
          <Button className="table-action-button action-button btn-fill"
            bsStyle="warning"
            type="button"
            onClick={this.updateDisclaimer}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  render() {
    const branch = this.props.branch;
    const merchant = this.props.merchant;
    const activeImages = branch.MerchantBranchImage.filter(image => image.IsActive).sort((a, b) => b.CreatedAt - a.CreatedAt);
    const i = this.props.i;

    const address =
      ((branch.AddressLine1 || '') + ' '
        + (branch.AddressLine2 || '') + ' '
        + (branch.City || '') + ' '
        + (branch.State || '') + ' '
        + (branch.PostalCode || '') + ' ').replace(/\s/g, ' ').trim();

    return (
      <tbody>
        <tr className={this.state.isEditing ? 'is-editing-row' : ''}>
          <td>{branch.Id}</td>
          <td>{merchant.Name}</td>
          <td>{branch.BranchName}</td>
          <td>{branch.Email || <span className="no-field-text text-nowrap"><em>No Email</em></span>}</td>
          <td>{address || <span className="no-field-text text-nowrap"><em>No Address</em></span>}</td>
          <td>{branch.Area}</td>
          <td className="text-nowrap">
            <Button className="table-action-button action-button btn-fill"
              bsStyle="warning"
              type="button"
              onClick={this.state.isEditing ? this.handleSave : this.handleEdit}
            >
              {this.state.isEditing ? <i className="fas fa-check"></i> : <i className="fas fa-edit"></i>}
            </Button>
            {this.state.isEditing ?
              <Button className="table-action-button action-button btn-fill"
                bsStyle="warning"
                type="button"
                onClick={this.handleCancel}>
                <i className="fas fa-times"></i>
              </Button> : null
            }
            <Button className="table-action-button action-button btn-fill"
              bsStyle="danger"
              type="button"
              onClick={this.handleDelete}
            >
              <i className="fas fa-trash"></i>
            </Button>
          </td>
        </tr>
        {this.state.isEditing &&
          <tr>
            <td colSpan={11}>
              <div className={'loader-overlay ' + (this.state.isFormLoading ? '' : 'hidden')}><div className="loader"></div></div>
              <FormInputs
                ncols={["col-md-12"]}
                proprieties={[
                  {
                    label: "branch name",
                    type: "text",
                    bsClass: "form-control",
                    placeholder: "branch name",
                    value: this.state.branchName,
                    name: "branchName",
                    onChange: this.handleChange,
                    spellCheck: false
                  }
                ]}
              />
              <FormInputs
                ncols={["col-md-12"]}
                proprieties={[
                  {
                    label: "email address",
                    type: "email",
                    bsClass: "form-control",
                    placeholder: "email address",
                    value: this.state.email,
                    name: "email",
                    onChange: this.handleChange,
                    spellCheck: false
                  }
                ]}
              />
              <FormGroup>
                <ControlLabel>Enter Description</ControlLabel>
                <FormControl
                  componentClass="textarea"
                  placeholder="description"
                  name="description"
                  value={this.state.description}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormInputs
                ncols={["col-md-6", "col-md-6"]}
                proprieties={[
                  {
                    label: "address line 1",
                    type: "text",
                    bsClass: "form-control",
                    placeholder: "address line 1",
                    value: this.state.address1,
                    name: "address1",
                    onChange: this.handleChange,
                    spellCheck: false
                  },
                  {
                    label: "address line 2",
                    type: "text",
                    bsClass: "form-control",
                    placeholder: "address line 2",
                    value: this.state.address2,
                    name: "address2",
                    onChange: this.handleChange,
                    spellCheck: false
                  }
                ]}
              />
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <ControlLabel>Select Area</ControlLabel>
                    <FormControl
                      componentClass="select"
                      placeholder="Select Area"
                      name="area"
                      value={this.state.area}
                      onChange={this.handleChange}
                      required >
                      <option disabled value="">Select Area</option>
                      {
                        AREA_OPTIONS.map((option, i) => {
                          return <option key={option.value} value={option.value}>{option.label}</option>;
                        })
                      }
                    </FormControl>
                  </FormGroup>
                </Col>
              </Row>
              <FormInputs
                ncols={["col-md-4", "col-md-4", "col-md-4"]}
                proprieties={[
                  {
                    label: "city",
                    type: "text",
                    bsClass: "form-control",
                    placeholder: "city",
                    value: this.state.city,
                    name: "city",
                    onChange: this.handleChange,
                    spellCheck: false
                  },
                  {
                    label: "state",
                    type: "text",
                    bsClass: "form-control",
                    placeholder: "state",
                    value: this.state.state,
                    name: "state",
                    onChange: this.handleChange,
                    spellCheck: false
                  },
                  {
                    label: "postal code",
                    type: "text",
                    bsClass: "form-control",
                    placeholder: "postal code",
                    value: this.state.postal,
                    name: "postal",
                    onChange: this.handleChange,
                    spellCheck: false
                  }
                ]}
              />
              <FormInputs
                ncols={["col-md-4", "col-md-4", "col-md-4"]}
                proprieties={[
                  {
                    label: "latitude",
                    type: "text",
                    bsClass: "form-control",
                    placeholder: "latitude",
                    value: this.state.latitude,
                    name: "latitude",
                    onChange: this.handleChange,
                    spellCheck: false
                  },
                  {
                    label: "longitude",
                    type: "text",
                    bsClass: "form-control",
                    placeholder: "longitude",
                    value: this.state.longitude,
                    name: "longitude",
                    onChange: this.handleChange,
                    spellCheck: false
                  },
                  {
                    label: "location type",
                    type: "text",
                    bsClass: "form-control",
                    placeholder: "location type",
                    value: this.state.locationType,
                    name: "locationType",
                    onChange: this.handleChange,
                    spellCheck: false
                  }
                ]}
              />
              <FormInputs
                ncols={["col-md-6", "col-md-6"]}
                proprieties={[
                  {
                    label: "office number",
                    type: "text",
                    bsClass: "form-control",
                    placeholder: "office number",
                    value: this.state.officeNumber,
                    name: "officeNumber",
                    onChange: this.handleChange,
                    spellCheck: false
                  },
                  {
                    label: "mobile number",
                    type: "text",
                    bsClass: "form-control",
                    placeholder: "mobile number",
                    value: this.state.mobileNumber,
                    name: "mobileNumber",
                    onChange: this.handleChange,
                    spellCheck: false
                  }
                ]}
              />
              <FormInputs
                ncols={["col-md-4", "col-md-4", "col-md-4"]}
                proprieties={[
                  {
                    label: "website",
                    type: "text",
                    bsClass: "form-control",
                    placeholder: "website",
                    value: this.state.website,
                    name: "website",
                    onChange: this.handleChange,
                    spellCheck: false
                  },
                  {
                    label: "facebook",
                    type: "text",
                    bsClass: "form-control",
                    placeholder: "facebook",
                    value: this.state.facebook,
                    name: "facebook",
                    onChange: this.handleChange,
                    spellCheck: false
                  },
                  {
                    label: "instagram",
                    type: "text",
                    bsClass: "form-control",
                    placeholder: "instagram",
                    value: this.state.instagram,
                    name: "instagram",
                    onChange: this.handleChange,
                    spellCheck: false
                  }
                ]}
              />
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <ControlLabel>Store Types</ControlLabel>
                    <Select.Creatable
                      options={this.props.storeTypeOptions}
                      multi={true}
                      value={this.state.storeTypes}
                      placeholder="select store types"
                      onChange={(val) => {
                        this.setState({ storeTypes: val });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <ControlLabel>food categories</ControlLabel>
                    <Select.Creatable
                      options={this.props.foodCategoryOptions}
                      multi={true}
                      value={this.state.foodCategories}
                      placeholder="select food categories"
                      onChange={(val) => {
                        this.setState({ foodCategories: val });
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <ControlLabel>Enter Announcement</ControlLabel>
                <FormControl
                  componentClass="textarea"
                  placeholder="announcement"
                  name="announcement"
                  value={this.state.announcement}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <ControlLabel>delivery fulfilment slots</ControlLabel>
                <Select.Creatable
                  options={this.state.deliveryFulfilmentSlotOptions}
                  multi={true}
                  value={this.state.deliveryFulfilmentSlots}
                  shouldKeyDownEventCreateNewOption={_ref6 => {
                    const keyCode = _ref6.keyCode;
                    switch (keyCode) {
                      case 9: // TAB
                      case 13: // ENTER
                        return true;
                      default:
                        return false;
                    }
                  }}
                  placeholder="select delivery fulfilment slots, eg: Mon, 6pm-8pm"
                  onChange={(val) => {
                    console.log(val);
                    this.setState({ deliveryFulfilmentSlots: val });
                  }}
                  newOptionCreator={input => {
                    const option = { value: input.label, label: input.label }
                    return option;
                  }}
                  onNewOptionClick={option => {
                    const deliveryFulfilmentSlotOptions = this.state.deliveryFulfilmentSlotOptions;
                    deliveryFulfilmentSlotOptions.unshift(option);
                    this.setState({
                      deliveryFulfilmentSlotOptions,
                    });
                  }}
                // onCreateOption={inputValue => {
                //     const fulfilmentSlots = this.state.fulfilmentSlots;
                //     fulfilmentSlots.add({ value: inputValue, label: inputValue });
                //     this.setState({ fulfilmentSlots })
                // }}
                />
              </FormGroup>
              <FormGroup>
                <ControlLabel>pickup fulfilment slots</ControlLabel>
                <Select.Creatable
                  options={this.state.pickupFulfilmentSlotOptions}
                  multi={true}
                  value={this.state.pickupFulfilmentSlots}
                  shouldKeyDownEventCreateNewOption={_ref6 => {
                    const keyCode = _ref6.keyCode;
                    switch (keyCode) {
                      case 9: // TAB
                      case 13: // ENTER
                        return true;
                      default:
                        return false;
                    }
                  }}
                  placeholder="select pickup fulfilment slots, eg: Mon, 6pm-8pm"
                  onChange={(val) => {
                    console.log(val);
                    this.setState({ pickupFulfilmentSlots: val });
                  }}
                  newOptionCreator={input => {
                    const option = { value: input.label, label: input.label }
                    return option;
                  }}
                  onNewOptionClick={option => {
                    const pickupFulfilmentSlotOptions = this.state.pickupFulfilmentSlotOptions;
                    pickupFulfilmentSlotOptions.unshift(option);
                    this.setState({
                      pickupFulfilmentSlotOptions,
                    });
                  }}
                />
              </FormGroup>
              <h4>Branch Images</h4>
              <Carousel interval={null} activeIndex={this.state.branchImageIndex} onSelect={this.handleCarouselSelect}>
                {
                  this.state.branchImages.map((image, index) => {
                    return (<Carousel.Item key={index}>
                      <Dropzone onDropAccepted={files => this.handlePhotoSelect(files[0], index)} accept="image/png, image/jpeg">
                        {({ getRootProps, getInputProps, isDragActive }) => (
                          <section>
                            <div {...getRootProps({ style: { ...dragNDropStyle, ...(isDragActive ? { borderColor: '#ff5c0e' } : {}) } })}>
                              <img className="branch-product-image" style={{ height: 100, marginLeft: 'auto', marginRight: 'auto' }} id={`${this.props.branch.Id}-branch-img-${index}`} alt="" />
                              <input {...getInputProps()} />
                              <p>Drag 'n' drop, or click to select file</p>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </Carousel.Item>)
                  })
                }
              </Carousel>
              <br />
              <Button className="table-action-button action-button btn-fill"
                bsStyle="warning"
                type="button"
                onClick={this.handleAddBranchImage}>
                <i className="fas fa-plus"></i> Add Image
              </Button>
              {activeImages.length > 0 && <Carousel interval={null} >
                {
                  activeImages.map((image, i) => {
                    return (
                      <Carousel.Item key={i}>
                        <img className="branch-product-image"
                          style={{ height: 100, marginLeft: 'auto', marginRight: 'auto' }}
                          src={image.ImageBlob} alt="" />
                        <Button
                          className="btn-round"
                          round
                          bsStyle="warning"
                          style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                          onClick={() => this.handleRemoveBranchImage(i)}
                        >
                          <i className="fas fa-times"></i>
                        </Button>
                      </Carousel.Item>
                    )
                  })
                }
              </Carousel>}
              <h4>Schedule Description</h4>
              <FormGroup>
                <ControlLabel>Enter Schedule Description</ControlLabel>
                <FormControl
                  componentClass="textarea"
                  placeholder="schedule"
                  name="operatingHoursDescription"
                  value={this.state.operatingHoursDescription}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <h4>Schedule Settings</h4>
              <Row>
                <Col sm={6}>
                  <FormGroup>
                    <ControlLabel>Select Days</ControlLabel>
                    <Select
                      options={[
                        { value: 'mon', label: 'Mon' },
                        { value: 'tue', label: 'Tue' },
                        { value: 'wed', label: 'Wed' },
                        { value: 'thu', label: 'Thu' },
                        { value: 'fri', label: 'Fri' },
                        { value: 'sat', label: 'Sat' },
                        { value: 'sun', label: 'Sun' },
                      ]}
                      multi={true}
                      placeholder="select days"
                      value={this.state.currDays}
                      onChange={(val) => {
                        this.setState({ currDays: val });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <FormGroup>
                    <ControlLabel>Begin</ControlLabel>
                    <FormControl
                      type="time"
                      placeholder="begin time"
                      onChange={this.handleChange}
                      value={this.state.currBegin}
                      name="currBegin"
                    />
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <FormGroup>
                    <ControlLabel>End</ControlLabel>
                    <FormControl
                      type="time"
                      placeholder="end time"
                      onChange={this.handleChange}
                      value={this.state.currEnd}
                      name="currEnd"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Button className="table-action-button action-button btn-fill"
                bsStyle="warning"
                type="button"
                onClick={this.handleAddTimeSlot}>
                <i className="fas fa-plus"></i> Add TimeSlot
              </Button>
              <Row>
                <Col md={12}>
                  <Table striped hover responsive>
                    <thead>
                      <tr>
                        <th>days</th>
                        <th>begin</th>
                        <th>end</th>
                        <th width="10%"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.currTimeSlots.map((timeSlot, i) => {
                          return (
                            <tr key={Math.random()}>
                              <td>{JSON.stringify(timeSlot.days).replace(/[[\]"]/g, '')}</td>
                              <td>{timeSlot.begin}</td>
                              <td>{timeSlot.end}</td>
                              <td width="10%">
                                <Button className="table-action-button action-button btn-fill"
                                  bsStyle="danger"
                                  type="button"
                                  onClick={() => {
                                    this.handleDeleteTimeSlot(i);
                                  }}
                                >
                                  <i className="fas fa-trash"></i>
                                </Button>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Button className="table-action-button action-button btn-fill"
                bsStyle="warning"
                type="button"
                style={{ marginBottom: 10 }}
                onClick={this.handleShowDisclaimer}>
                Terms & Conditions
              </Button>
              {this.renderModal()}
              <FormGroup>
                <Button className="table-action-button action-button btn-fill"
                  bsStyle="warning"
                  type="button"
                  onClick={this.handleSave}>
                  <i className="fas fa-check"></i>
                </Button>
                <Button className="table-action-button action-button btn-fill"
                  bsStyle="warning"
                  type="button"
                  onClick={this.handleCancel}>
                  <i className="fas fa-times"></i>
                </Button>
              </FormGroup>
            </td>
          </tr>
        }
      </tbody>
    );
  }
}

function getCurrTimeSlots(schedule) {
  const timeSlotMap = {};

  for (const timeSlot of schedule) {
    const entry = timeSlotMap[timeSlot.Opening] || {};
    const days = entry[timeSlot.Closing]?.days || new Set();
    const ids = entry[timeSlot.Closing]?.ids || new Set();
    days.add(timeSlot.Day);
    ids.add(timeSlot.Id);
    entry[timeSlot.Closing] = { days, ids };
    timeSlotMap[timeSlot.Opening] = entry;
  }

  const currTimeSlots = [];

  for (const opening of Object.keys(timeSlotMap)) {
    for (const closing of Object.keys(timeSlotMap[opening])) {
      currTimeSlots.push({
        days: [...timeSlotMap[opening][closing].days],
        ids: [...timeSlotMap[opening][closing].ids],
        begin: moment().startOf('day').seconds(opening).format('HH:mm'),
        end: moment().startOf('day').seconds(closing).format('HH:mm')
      });
    }
  }
  return currTimeSlots
}

const dragNDropStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '5px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const resizeFile = (file) =>
  new Promise((resolve) => {
    Compress.imageFileResizer(
      file,
      480,
      480,
      "PNG",
      70,
      0,
      (uri) => {
        resolve(uri);
      },
      "blob"
    );
  });

export default BranchRow;
