import React, { Component } from 'react';
import { Grid, Row, Col, Button, FormControl, FormGroup, ControlLabel, Carousel } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import Compress from "react-image-file-resizer";
import Select from 'react-select';
import ProperCard from '../../components/Card/ProperCard.jsx';
import { connect } from 'react-redux';
import api from '../../api';
// import NotificationRow from 'components/NotificationRow/NotificationRow.jsx';
// import {thArray, tdArray} from 'variables/Variables.jsx';

const BANNERS_MAP = {
  1: 'buffetsHome',
  2: 'groceriesHome',
  3: 'shopsHome',
}

class Notifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isTableLoading: true,
      bannerTypes: [],
      bannerType: null,
      banners: [],
      newBanners: [null],
      bannerIndex: 0,
      //notifications: [],
      message: ''
    };

    // prevent loading as it takes too long
    // look into optimising
    // this.loadNotifications();
  }

  componentDidMount() {
    this.loadStoreTypes();
  }

  loadStoreTypes = async () => {
    const { token } = this.props.auth;
    try {
      const storeTypes = await api.fetchStoreTypes(token);
      console.log(storeTypes);
      const bannerTypes = storeTypes.map(t => ({
        value: BANNERS_MAP[t.Id] ?? `${t.Type}Home`,
        label: t.Type.charAt(0).toUpperCase() + t.Type.slice(1)
      }));
      bannerTypes.unshift({ value: 'Home', label: 'Home' });
      this.setState({
        bannerTypes,
        bannerType: bannerTypes[0]
      }, this.loadBanner);
    } catch (err) {
      console.log(err);
    }
  }

  loadBanner = async () => {
    const { token } = this.props.auth;
    try {
      const banners = await api.fetchBanners(this.state.bannerType.value, token);
      this.setState({
        banners
      });
    } catch (err) {
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{err.message}</strong>,
        level: 'error'
      });
      this.setState({ banners: [] });
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSelectBannerType = (val) => {
    const bannerType = val;
    if (!bannerType) return;
    this.setState({ bannerType }, this.loadBanner);
  }

  handlePhotoSelect = async (file, index) => {
    try {
      const image = await resizeFile(file);
      document.getElementById(`${this.state.bannerType.value}-${index}`).src = URL.createObjectURL(image);
      this.setState(oldState => {
        oldState.newBanners[index] = image;
        return { newBanners: oldState.newBanners };
      });
    } catch (err) {
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{err.message}</strong>,
        level: 'error'
      });
    }
  }

  handleCarouselSelect = (selectedIndex) => {
    this.setState({ bannerIndex: selectedIndex })
  }

  handleAddBanner = () => {
    this.setState(oldState => {
      oldState.newBanners.push(null);
      return {
        newBanners: oldState.newBanners,
        bannerIndex: oldState.newBanners.length - 1
      };
    });
  }

  handleUploadBanners = async () => {
    const { token } = this.props.auth;
    try {
      const banners = this.state.newBanners.filter(b => b !== null);
      let bannerId = this.state.banners[0]?.Id;
      if (!bannerId) {
        const result = await api.createBanner(this.state.bannerType.value, token);
        bannerId = result.Id;
      }

      await Promise.all(banners.map(banner => api.uploadBanner(bannerId, banner, token)));
      this.state.newBanners.map((_, index) => document.getElementById(`${this.state.bannerType.value}-${index}`).src = '');
      this.setState({
        bannerIndex: 0
      }, () => {
        setTimeout(() => {
          this.setState({
            newBanners: [null],
          });
        }, 1000);
      });
      this.loadBanner();
    } catch (err) {
      console.log(err);
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{err.message}</strong>,
        level: 'error'
      });
    }
  }

  handleRemoveBannerImage = async (i) => {
    if (!window.confirm('Are you sure you want to delete?')) {
      return;
    }

    this.setState({ isFormLoading: true });
    try {
      const bannerImages = this.state.banners[0]?.BannerImages?.filter(i => i.IsActive).sort((a, b) => b.CreatedAt - a.CreatedAt) ?? [];
      const imageId = bannerImages[i].Id;
      await api.deleteBannerImage(imageId, this.props.token);
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-like2" />,
        message: <strong>Banner image deleted.</strong>,
        level: 'success'
      });
      // const updatedImages = this.state.merchantBranchProductImages;
      // updatedImages.splice(i, 1);
      // this.setState({
      //   merchantBranchProductImages: updatedImages,
      // });
      this.loadBanner();
    } catch (error) {
      console.log(error);
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{error.message}</strong>,
        level: 'error'
      });
    } finally {
      this.setState({ isFormLoading: false });
    }
  }

  handleSend = () => {
    if (!window.confirm('Are you sure you want to send notification?')) {
      return;
    }
    const message = this.state.message;

    const body = JSON.stringify({ message });
    fetch('/notifications/send', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'access-token': this.props.token
      },
      body
    }).then((result) => {
      return result.json();
    }).then((result) => {
      if (result.status !== 200) {
        this.props.addNotification({
          title: <span data-notify="icon" className="pe-7s-attention" />,
          message: <strong>{result.details}</strong>,
          level: 'error'
        });
        return;
      }
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-like2" />,
        message: <strong>{result.details}</strong>,
        level: 'success'
      });

      this.setState({
        message: ''
      });
      //this.loadNotifications();
    });
  }

  loadNotifications = () => {
    return fetch('/notifications', {
      method: 'GET',
      headers: {
        'access-token': this.props.token
      }
    }).then((result) => {
      if (result.ok) {
        return result.json();
      }
      return null;
    }).then((result) => {
      if (result.status !== 200) {
        return;
      }

      const notifications = result.result.notifications;

      const compare = (a, b) => {
        return b.createdAt - a.createdAt;
      };

      notifications.sort(compare);
      this.setState({ notifications });
      return notifications;
    });
  }

  render() {
    const bannerImages = this.state.banners[0]?.BannerImages?.filter(i => i.IsActive).sort((a, b) => b.CreatedAt - a.CreatedAt) ?? [];
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <ProperCard
                title="Notifications"
              >
                <div>
                  <form>
                    <h4>Send Notification</h4>
                    <div className={'loader-overlay ' + (this.state.isFormLoading ? '' : 'hidden')}><div className="loader"></div></div>
                    <FormGroup>
                      <ControlLabel>Enter Message</ControlLabel>
                      <FormControl
                        componentClass="textarea"
                        placeholder="Message"
                        name="message"
                        value={this.state.message}
                        onChange={this.handleChange}
                        required
                      />
                    </FormGroup>
                    <Button className="table-action-button action-button btn-fill"
                      bsStyle="warning"
                      type="button"
                      onClick={this.handleSend}>
                      <i className="fa fa-paper-plane"></i> Send Notification
                    </Button>
                  </form>
                  {/*
                    //look into optimising
                    <form>
                        <h4>Sent Notifications</h4>
                        <Table hover bordered responsive className="orders-table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Message</th>
                                    <th>Date</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            {   
                                this.state.notifications.map((notification, i) => {
                                    return <NotificationRow {...this.props} key={notification._id} notification={notification} i={i} loadNotifications={this.loadNotifications} token={this.props.token} handleCheck={this.handleCheck}/>;
                                })
                            }
                        </Table>
                        </form>*/}
                </div>
              </ProperCard>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ProperCard title="Banners">
                <FormGroup>
                  <ControlLabel>Select Banner Type</ControlLabel>
                  <Select.Creatable
                    options={this.state.bannerTypes}
                    placeholder="Select Banner Type"
                    value={this.state.bannerType}
                    onChange={this.handleSelectBannerType}
                  />
                </FormGroup>
                <Carousel interval={null} activeIndex={this.state.bannerIndex} onSelect={this.handleCarouselSelect}>
                  {
                    this.state.newBanners.map((feature, index) => {
                      return (<Carousel.Item key={index}>
                        <Dropzone onDropAccepted={files => this.handlePhotoSelect(files[0], index)} accept="image/png, image/jpeg">
                          {({ getRootProps, getInputProps, isDragActive }) => (
                            <section>
                              <div {...getRootProps({ style: { ...dragNDropStyle, ...(isDragActive ? { borderColor: '#ff5c0e' } : {}) } })}>
                                {this.state.bannerType && <img
                                  className="branch-product-image"
                                  style={{ height: 100, marginLeft: 'auto', marginRight: 'auto' }}
                                  id={`${this.state.bannerType.value}-${index}`}
                                  alt=""
                                />}
                                <input {...getInputProps()} />
                                <p>Drag 'n' drop, or click to select file</p>
                              </div>
                            </section>
                          )}
                        </Dropzone>
                      </Carousel.Item>)
                    })
                  }
                </Carousel>
                <br />
                <Button className="table-action-button action-button btn-fill"
                  bsStyle="warning"
                  type="button"
                  onClick={this.handleAddBanner}>
                  <i className="fas fa-plus" /> Add Banner
                </Button>
                <Button className="table-action-button action-button btn-fill"
                  bsStyle="warning"
                  type="button"
                  onClick={this.handleUploadBanners}>
                  <i className="fas fa-upload" /> Upload Banners
                </Button>
                {bannerImages.length > 0 && <Carousel interval={null} >
                  {
                    bannerImages.map((banner, i) => {
                      return (
                        <Carousel.Item key={i}>
                          <img
                            className="branch-product-image"
                            style={{ height: 100, marginLeft: 'auto', marginRight: 'auto' }}
                            src={banner.ImageUrl}
                            alt=""
                          />
                          <Button
                            className="btn-round"
                            round
                            bsStyle="warning"
                            style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                            onClick={() => this.handleRemoveBannerImage(i)}
                          >
                            <i className="fas fa-times"></i>
                          </Button>
                        </Carousel.Item>
                      )
                    })
                  }
                </Carousel>}
              </ProperCard>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const dragNDropStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '5px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const resizeFile = (file) =>
  new Promise((resolve) => {
    Compress.imageFileResizer(
      file,
      480,
      480,
      "PNG",
      70,
      0,
      (uri) => {
        resolve(uri);
      },
      "blob"
    );
  });

export default connect(state => ({
  auth: state.auth
}))(Notifications);
