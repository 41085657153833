import React, { Component } from 'react';
import { Row, Col, FormGroup, ControlLabel, FormControl, Table } from 'react-bootstrap';

import ProperCard from '../../components/Card/ProperCard.jsx';
import AccountRow from '../../components/AccountRow/AccountRow.jsx';
import Button from '../../elements/CustomButton/CustomButton.jsx';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { validateInputs } from '../../helper';
import { connect } from 'react-redux';
import api from '../../api';

class Accounts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFormLoading: false,
            username: '',
            email: '',
            password: '',
            confirmPassword: '',
            accounts: [],
            branches: [],
            merchants: [],
            permittedBranches: [],
            selectedMerchant: null,
            selectedBranch: null,
            selectedRole: null,
            pageSize: 50,
            page: 1,
            isFull: false,
        };
    }

    componentDidMount() {
        this.loadAccounts();
        this.loadMerchants();
    }

    loadAccounts = async (shouldLoadMore = false) => {
        const { token } = this.props.auth;
        const { pageSize, page } = this.state;
        this.setState({ isFormLoading: true });
        try {
            const newPage = shouldLoadMore ? page + 1 : 1;
            let accounts = await api.fetchAccounts(token, newPage, pageSize);
            if (shouldLoadMore) {
                accounts.unshift(...this.state.accounts);
            }
            const isFull = accounts.length < pageSize * newPage;
            this.setState({
                accounts,
                isFull,
                page: newPage,
                isFormLoading: false,
            });
        } catch (error) {
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-attention" />,
                message: <strong>{error.message}</strong>,
                level: 'error'
            });
            this.setState({ isFormLoading: false });
        }
    }

    loadMerchants = async () => {
        const { token } = this.props.auth;
        try {
            const merchants = await api.fetchMerchants(token);
            this.setState({ merchants });
        } catch (error) {
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-attention" />,
                message: <strong>{error.message}</strong>,
                level: 'error'
            });
        }
    }

    loadBranches = async (merchantId) => {
        const { token } = this.props.auth;
        try {
            let branches = await api.fetchBranchesByMerchantId(merchantId, token);
            this.setState({
                branches,
            });
        } catch (error) {
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-attention" />,
                message: <strong>{error.message}</strong>,
                level: 'error'
            });
        }
    }

    handleAddPermission = () => {
        const selectedBranch = this.state.selectedBranch;
        const selectedRole = this.state.selectedRole;

        const permittedBranches = this.state.permittedBranches;
        permittedBranches.push({
            MerchantBranchId: selectedBranch.value,
            BranchName: selectedBranch.label,
            Role: { RoleId: selectedRole.value, RoleName: selectedRole.label }
        });

        this.setState({ permittedBranches });
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleChangeMerchant = (e) => {
        this.setState({ selectedMerchant: e });
        this.loadBranches(e.value)
    }

    handleAdd = async (e) => {
        const isInputsValid = validateInputs(e.target.closest('form'), this);
        if (!isInputsValid) {
            return;
        }

        const username = this.state.username;
        const email = this.state.email;
        const password = this.state.password;

        const data = {
            username,
            email,
            password,
        };

        try {
            const response = await api.createAccount(data, this.props.token);
            const accountId = response.Id;
            const addedBranches = this.state.permittedBranches;
            await Promise.all(addedBranches.map((branch) => api.createAccountMerchantBranch(accountId, branch.MerchantBranchId, branch.Role.RoleId, this.props.token)));

            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-like2" />,
                message: <strong>Account created.</strong>,
                level: 'success'
            });

            this.setState({
                username: '',
                password: '',
                email: '',
                confirmPassword: '',
                permittedBranches: [],
                selectedBranch: null,
                selectedRole: null,
            });

            this.loadAccounts();
        } catch (error) {
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-attention" />,
                message: <strong>{error.message}</strong>,
                level: 'error'
            });
        }
    }

    deletePermission = (i) => {
        const permittedBranches = this.state.permittedBranches.slice();
        permittedBranches.splice(i, 1);
        this.setState({ permittedBranches });
    }

    render() {
        return (
            <div className="content">
                <ProperCard title="Add Account">
                    <div>
                        <form>
                            <div className={`loader-overlay ${this.state.isFormLoading ? '' : 'hidden'}`}>
                                <div className="loader" />
                            </div>
                            <FormGroup>
                                <ControlLabel>Enter Username</ControlLabel>
                                <FormControl
                                    type="text"
                                    placeholder="Username"
                                    name="username"
                                    value={this.state.username}
                                    onChange={this.handleChange}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>Enter Email</ControlLabel>
                                <FormControl
                                    type="email"
                                    placeholder="Email"
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>Enter Password</ControlLabel>
                                <FormControl
                                    type="password"
                                    placeholder="Password"
                                    name="password"
                                    value={this.state.password}
                                    onChange={this.handleChange}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>Confirm Password</ControlLabel>
                                <FormControl
                                    type="password"
                                    placeholder="Confirm Password"
                                    name="confirmPassword"
                                    value={this.state.confirmPassword}
                                    onChange={this.handleChange}
                                    required
                                />
                            </FormGroup>

                            <h4>Permissions</h4>
                            <Row>
                                <Col sm={4}>
                                    <FormGroup>
                                        <ControlLabel>Select Merchant</ControlLabel>
                                        <Select
                                            options={this.state.merchants.map((merchant) => {
                                                return {
                                                    value: merchant.Id,
                                                    label: merchant.Name
                                                }
                                            })}
                                            multi={false}
                                            placeholder="select merchant"
                                            value={this.state.selectedMerchant}
                                            onChange={this.handleChangeMerchant}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <ControlLabel>Select Branch</ControlLabel>
                                        <Select
                                            options={this.state.branches.map((branch) => {
                                                return {
                                                    value: branch.Id,
                                                    label: branch.BranchName
                                                }
                                            })}
                                            multi={false}
                                            placeholder="select branch"
                                            value={this.state.selectedBranch}
                                            onChange={(val) => {
                                                this.setState({ selectedBranch: val });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <ControlLabel>Select Role</ControlLabel>
                                        <Select
                                            options={[
                                                { value: 1, label: 'admin' },
                                                { value: 2, label: 'merchant-manager' },
                                                { value: 3, label: 'merchant-staff' },
                                                { value: 4, label: 'merchant-user' },
                                                { value: 5, label: 'event-manager' },
                                            ]}
                                            multi={false}
                                            placeholder="select role"
                                            value={this.state.selectedRole}
                                            onChange={(val) => {
                                                this.setState({ selectedRole: val });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Button className="table-action-button action-button btn-fill"
                                bsStyle="warning"
                                type="button"
                                onClick={this.handleAddPermission}>
                                <i className="fas fa-plus"></i> Add Permission
                            </Button>
                            <Row>
                                <Col md={12}>
                                    <Table striped hover responsive>
                                        <thead>
                                            <tr>
                                                <th>Branches</th>
                                                <th>Roles</th>
                                                <th width="10%"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.permittedBranches.map((branch, i) => {
                                                return (
                                                    <tr key={Math.random()}>
                                                        <td>{branch.BranchName}</td>
                                                        <td>{branch.Role.RoleName}</td>
                                                        <td width="10%">
                                                            <Button className="table-action-button action-button btn-fill"
                                                                bsStyle="danger"
                                                                type="button"
                                                                onClick={() => {
                                                                    this.deletePermission(i);
                                                                }}
                                                            >
                                                                <i className="fas fa-trash"></i>
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Button className="table-action-button action-button btn-fill"
                                bsStyle="warning"
                                type="button"
                                onClick={this.handleAdd}>
                                <i className="fas fa-plus"></i> Add Account
                            </Button>
                        </form>
                        <form id="edit-branch-form">
                            <FormGroup>
                                <h4>Accounts</h4>
                                <Button className="table-action-button action-button btn-fill"
                                    bsStyle="warning"
                                    type="button"
                                    style={{ marginLeft: 10, minWidth: 86 }}
                                    disabled={this.state.isFull || this.state.isFormLoading !== false}
                                    onClick={() => this.loadAccounts(true)}>
                                    {!this.state.isFull ? 'Load More' : 'Loaded All'}
                                </Button>
                            </FormGroup>
                            <Table hover bordered responsive className="accounts-table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Username</th>
                                        <th>Email</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                {
                                    this.state.accounts.map((account, i) => {
                                        return (
                                            <AccountRow
                                                {...this.props}
                                                key={account.Id}
                                                token={this.props.token}
                                                account={account} i={i}
                                                loadAccounts={this.loadAccounts}
                                                merchants={this.state.merchants}
                                            />
                                        );
                                    })
                                }
                            </Table>
                        </form>
                    </div>
                </ProperCard>
            </div>
        );
    }
}

export default connect(state => ({
    auth: state.auth
}))(Accounts);
