import React, { Component } from 'react';
import {
    Table, Button, FormControl, Tabs, Tooltip, OverlayTrigger,
    Tab, FormGroup, ControlLabel, Row, Col, Form,
    Radio,
} from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Select from 'react-select';
import moment from 'moment';

import ProperCard from '../../components/Card/ProperCard.jsx';
import ListingRow from '../../components/ListingRow/ListingRow.jsx';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { validateInputs, resetValidity } from '../../helper';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { connect } from 'react-redux';
import api from '../../api';

const LISTING_TYPES = {
    CURRENT: {
        index: 1,
        tabTitle: 'Current Listings',
        checkboxType: 'current-listings-checkbox'
    },
    UPCOMING: {
        index: 2,
        tabTitle: 'Upcoming Listings',
        checkboxType: 'upcoming-listings-checkbox'
    },
    EXPIRED: {
        index: 3,
        tabTitle: 'Expired Listings',
        checkboxType: 'expired-listings-checkbox'
    }
};

const EXTEND_AMOUNT_OPTIONS = [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
    { label: 6, value: 6 },
    { label: 7, value: 7 },
    { label: 8, value: 8 },
    { label: 9, value: 9 },
    { label: 10, value: 10 },
]

const EXTEND_UNIT_OPTIONS = [
    { label: 'Week', value: 'week' },
    { label: 'Month', value: 'month' },
]

class Listings extends Component {
    constructor(props) {
        super(props);
        this.tabsRef = React.createRef();
        const currentDate = moment().format('DD/MM/YYYY');
        const currentHour = moment().format('HH');
        const currentMinute = moment().format('mm');
        const currentTimestamp = getUnixFromDateHourMinute(currentDate, currentHour, currentMinute);
        this.state = {
            isFormLoading: false,
            listings: [[], [], []],
            productId: '',
            offer: '',
            quantity: '',
            isAddOn: false,
            isAvailablePerDay: false,
            isListingByPeriod: true,
            currDays: [],
            postAt: currentTimestamp,
            postDate: currentDate,
            postHour: currentHour,
            postMinute: currentMinute,
            removeAt: currentTimestamp,
            removeDate: currentDate,
            removeHour: currentHour,
            removeMinute: currentMinute,
            listingsToDelete: {},
            listingPositions: [],
            numDays: 14,
            products: [],
            productOptions: [],
            extendAmount: 1,
            extendUnit: 'week',
        };
    }

    componentDidMount() {
        this.loadProducts().then(this.loadListings);
    }

    loadListings = async () => {
        const { branchId, token } = this.props.auth;
        try {
            let listings = await api.fetchListings(branchId, token, 1, 1000);
            const now = Math.floor(Date.now() / 1000);
            listings = listings.sort((a, b) => a.PostAt - b.PostAt);
            this.setState({
                listings: [
                    listings.filter(listing => listing.PostAt <= now && listing.RemoveAt > now),
                    listings.filter(listing => listing.PostAt > now),
                    listings.filter(listing => listing.RemoveAt <= now)
                ],
                listingPositions: listings.filter(listing => listing.RemoveAt > now).sort((a, b) => a.Position - b.Position)
            });
        } catch (err) {
            console.log(err);
        }
    }

    loadProducts = async () => {
        const { branchId, token } = this.props.auth;
        try {
            const products = await api.fetchProducts(branchId, token, 1, 500);
            const productOptions = products.map(product => ({
                label: `${product.Name} (${product.Currency} ${Number(product.Price).toFixed(2)})`,
                value: product.Id,
            }));
            this.setState({ productOptions, products });
        } catch (err) {
            console.log(err);
        }
    }

    handlePostDateChange = (selectedDay) => {
        this.handleDayChange('post', selectedDay);
    }

    handleRemoveDateChange = (selectedDay) => {
        this.handleDayChange('remove', selectedDay);
    }

    handleDayChange = (timeType, selectedDay) => {
        resetValidity(document.getElementById('add-listing-form'));
        const date = moment(selectedDay).format('DD/MM/YYYY');
        const name = timeType + 'Date';
        this.setState({ [name]: date }, () => {
            this.handleDateTimeChange(timeType);
        });
    }

    handleTimeChange = (e) => {
        const targetName = e.target.name;
        const targetValue = e.target.value;
        const timeType = e.target.getAttribute('data-time-type');
        this.setState({ [targetName]: targetValue }, () => {
            this.handleDateTimeChange(timeType);
        });
    }

    handleDateTimeChange = (timeType) => {
        const dateFieldName = timeType + 'Date';
        const hourFieldName = timeType + 'Hour';
        const minuteFieldName = timeType + 'Minute';

        const date = this.state[dateFieldName]
        const hour = this.state[hourFieldName]
        const minute = this.state[minuteFieldName]

        const timestamp = getUnixFromDateHourMinute(date, hour, minute);

        this.setState({
            [timeType + 'At']: timestamp
        });
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleCheckAll = (e) => {
        const checkboxes = document.getElementsByName(e.target.value);
        const listingsToDelete = {};
        for (const checkbox of checkboxes) {
            const checked = e.target.checked;
            checkbox.checked = checked;
            listingsToDelete[checkbox.value] = checked;
        }
        this.setState({ listingsToDelete });
    }

    handleCheckbox = (e) => {
        this.setState({ [e.target.name]: e.target.checked });
    }

    handleCheck = (e) => {
        const listingsToDelete = JSON.parse(JSON.stringify(this.state.listingsToDelete))
        listingsToDelete[e.target.value] = e.target.checked;
        this.setState({ listingsToDelete });
    }

    handleAdd = async (e) => {
        const isInputsValid = validateInputs(e.target.closest('form'), this);
        if (!isInputsValid) {
            return;
        }

        this.setState({ isFormLoading: true });

        const data = {
            merchantBranchProductId: this.state.productId,
            offer: this.state.offer,
            itemCount: this.state.quantity,
            itemRemaining: this.state.quantity,
            isAddOn: this.state.isAddOn,
            postAt: this.state.postAt,
            position: 0,
            removeAt: this.state.removeAt,
            partnerMerchantBranchId: this.props.auth.branchId,
        };

        const days = this.state.currDays.map((day) => day.value);

        try {
            if (this.state.isListingByPeriod) {
                const result = await api.createListing(data, this.props.auth.token);
                console.log(result);
            } else {
                const dataList = [];
                const postDate = moment(this.state.postAt * 1000);
                const dayDiff = this.state.numDays;
                for (let i = 0; i < dayDiff; i++) {
                    const currentPostDate = postDate.clone().add(i, 'day');
                    const day = currentPostDate.format('ddd');
                    if (!days.includes(day)) {
                        continue;
                    }
                    const postAt = currentPostDate.unix();
                    const removeAt = currentPostDate.clone().set("hour", this.state.removeHour)
                        .set("minute", this.state.removeMinute).unix();
                    dataList.push({ ...data, postAt, removeAt });
                }
                console.log(dataList);
                await Promise.all(dataList.map(data => api.createListing(data, this.props.auth.token)));
            }

            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-like2" />,
                message: <strong>Listing created.</strong>,
                level: 'success'
            });

            this.setState({
                productId: '',
                offer: '',
                quantity: '',
                isAddOn: false,
            });
            this.loadListings();
        } catch (error) {
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-attention" />,
                message: <strong>{error.message}</strong>,
                level: 'error'
            });
        } finally {
            this.setState({ isFormLoading: false });
        }
    }

    handleExtend = async () => {
        const listingIds = []
        Object.keys(this.state.listingsToDelete).forEach((listingId) => {
            if (this.state.listingsToDelete[listingId]) {
                listingIds.push(listingId);
            }
        });

        if (listingIds.length === 0) {
            return;
        }

        this.setState({ isFormLoading: true });

        const extendAmount = this.state.extendAmount;
        const extendUnit = this.state.extendUnit;

        try {
            const dataList = [];
            const activeTabKey = this.tabsRef.current._values.activeKey;
            console.log(this.state.listings[activeTabKey - 1]);
            for (let i = 0; i < listingIds.length; i++) {
                const listingId = listingIds[i];
                console.log(listingId);
                const listing = this.state.listings[activeTabKey - 1].find(l => l.Id === Number(listingId));
                const postDate = moment(listing.PostAt * 1000);
                const removeDate = moment(listing.RemoveAt * 1000);
                for (let j = 0; j < extendAmount; j++) {
                    const newPostAt = postDate.clone().add(j + 1, extendUnit).unix();
                    const newRemoveAt = removeDate.clone().add(j + 1, extendUnit).unix();
                    const data = {
                        merchantBranchProductId: listing.MerchantBranchProductId,
                        offer: listing.Offer,
                        itemCount: listing.ItemCount,
                        itemRemaining: listing.ItemRemaining,
                        isAddOn: listing.IsAddOn,
                        position: listing.Position,
                        partnerMerchantBranchId: listing.PartnerMerchantBranchId,
                        postAt: newPostAt,
                        removeAt: newRemoveAt,
                    }
                    dataList.push(data);
                }
            }
            console.log(dataList);
            await Promise.all(dataList.map(data => api.createListing(data, this.props.auth.token)));
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-like2" />,
                message: <strong>Listings extended.</strong>,
                level: 'success'
            });
            this.setState({
                extendAmount: 1,
                extendUnit: 'week'
            })
            this.loadListings();
        } catch (error) {
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-attention" />,
                message: <strong>{error.message}</strong>,
                level: 'error'
            });
        } finally {
            this.setState({ isFormLoading: false });
        }

    }

    handleDelete = async () => {
        if (!window.confirm('Are you sure you want to delete?')) {
            return;
        }
        const listingIds = []
        Object.keys(this.state.listingsToDelete).forEach((listingId) => {
            if (this.state.listingsToDelete[listingId]) {
                listingIds.push(listingId);
            }
        });

        if (listingIds.length === 0) {
            return;
        }

        try {
            const result = await Promise.all(
                listingIds.map(listingId => api.deleteListing(listingId, this.props.auth.token))
            );
            console.log(result);
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-like2" />,
                message: <strong>Listings deleted.</strong>,
                level: 'success'
            });
            this.setState({ listingsToDelete: {} });
            this.loadListings();
        } catch (error) {
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-attention" />,
                message: <strong>{error.message}</strong>,
                level: 'error'
            });
        }
    }

    handleSavePosition = async (e) => {
        const listings = this.state.listingPositions.map((listing, i) => {
            return {
                listingId: listing.Id,
                position: i + 1,
            };
        });
        try {
            const result = await api.updateListingPositions(listings, this.props.auth.token);
            console.log(result);
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-like2" />,
                message: <strong>Listing positions saved.</strong>,
                level: 'success'
            });
            const now = Math.floor(Date.now() / 1000);
            const newListingPositions = result.filter(listing => listing.RemoveAt > now).sort((a, b) => a.Position - b.Position)
            this.setState({
                listingPositions: newListingPositions
            });
        } catch (err) {
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-attention" />,
                message: <strong>{err.message}</strong>,
                level: 'error'
            });
        }
    }

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const listingPositions = reorder(
            this.state.listingPositions,
            result.source.index,
            result.destination.index
        );

        this.setState({ listingPositions });
    }

    renderTab = (listingType) => {
        const listings = this.state.listings[listingType.index - 1];
        return (
            <Tab eventKey={listingType.index} title={listingType.tabTitle}>
                <h4>{listingType.tabTitle}</h4>
                <Table hover bordered responsive className="listings-table">
                    <thead>
                        <tr>
                            <th className="checkbox-cell"><input value={listingType.checkboxType} type="checkbox" onChange={this.handleCheckAll} /></th>
                            <th>#</th>
                            <th>Photo</th>
                            <th>Name</th>
                            <th>Original Price ($)</th>
                            <th>App Price ($)</th>
                            <th>Total</th>
                            <th>Remaining</th>
                            <th>Add-on</th>
                            <th>Post At</th>
                            <th>Remove At</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    {
                        listings.map((listing, i) => {
                            return <ListingRow {...this.props}
                                checkboxType={listingType.checkboxType}
                                key={listing.Id}
                                listing={listing}
                                product={this.state.products.find(p => p.Id === listing.MerchantBranchProductId)}
                                i={i}
                                loadListings={this.loadListings}
                                token={this.props.auth.token}
                                handleCheck={this.handleCheck} />;
                        })
                    }
                </Table>
                <Button className="btn-fill action-button"
                    bsStyle="warning"
                    type="button"
                    onClick={this.handleDelete}>
                    <i className="fas fa-trash"></i> Delete Rows
                </Button>
                <div style={{ display: 'inline-block', marginLeft: 20 }}>
                    <Button className="btn-fill action-button"
                        bsStyle="warning"
                        type="button"
                        onClick={this.handleExtend}>
                        <i className="fas fa-trash"></i> Extend Rows
                    </Button>
                    <Select
                        name="extendAmount"
                        options={EXTEND_AMOUNT_OPTIONS}
                        placeholder="Select extend amount"
                        value={this.state.extendAmount}
                        onChange={(target) => {
                            this.setState({ extendAmount: target ? target.value : '' });
                        }}
                        clearable={false}
                        searchable={false}
                        autosize
                        required
                    />
                    <Select
                        name="extendUnit"
                        options={EXTEND_UNIT_OPTIONS}
                        placeholder="Select extend unit"
                        value={this.state.extendUnit}
                        onChange={(target) => {
                            this.setState({ extendUnit: target ? target.value : '' });
                        }}
                        clearable={false}
                        searchable={false}
                        autosize
                        required
                    />
                </div>
            </Tab>
        );
    }

    render() {
        const listingPositionsTab = (
            <Tab eventKey={4} title="Listing Positions">
                <h4>Listing Positions</h4>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                            >
                                {this.state.listingPositions.map((listing, i) => ListinghDraggableRow(listing, this.state.products, i))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <Button className="save-positions-button btn-fill"
                    bsStyle="warning"
                    type="button"
                    onClick={this.handleSavePosition}>
                    <i className="fas fa-save"></i> Save Positions
                </Button>
            </Tab>
        );

        return (
            <div className="content" style={{ paddingLeft: 30, paddingRight: 30 }}>
                <ProperCard
                    title="Listings"
                >
                    <div>
                        <h4>Add a Listing</h4>
                        <form id="add-listing-form">
                            <div className={'loader-overlay ' + (this.state.isFormLoading ? '' : 'hidden')}>
                                <div className="loader" />
                            </div>
                            <FormGroup>
                                <ControlLabel>Select Product</ControlLabel>
                                <Select
                                    name="productId"
                                    options={this.state.productOptions}
                                    placeholder="Select Product"
                                    value={this.state.productId}
                                    onChange={(target) => {
                                        this.setState({ productId: target ? target.value : '' });
                                    }}
                                />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>Enter App Price</ControlLabel>
                                <FormControl
                                    type="number"
                                    placeholder="App Price"
                                    name="offer"
                                    step="0.01"
                                    min="0"
                                    value={this.state.offer}
                                    onChange={this.handleChange}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>Enter Quantity</ControlLabel>
                                <FormControl
                                    type="number"
                                    placeholder="Quantity"
                                    name="quantity"
                                    step="1"
                                    min="0"
                                    value={this.state.quantity}
                                    onChange={this.handleChange}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <input className="admin-checkbox" type="checkbox"
                                    name="isAddOn"
                                    checked={this.state.isAddOn}
                                    onChange={this.handleCheckbox} />
                                <span className="admin-label" >Add-on</span>
                            </FormGroup>
                            <FormGroup>
                                <Radio defaultChecked
                                    onChange={e => this.setState({ isListingByPeriod: true })}
                                    name="radioGroup" inline>
                                    Listing by period
                                </Radio>{' '}
                                <Radio name="radioGroup" inline
                                    onChange={e => this.setState({ isListingByPeriod: false })}>
                                    Listing by day of week
                                </Radio>{' '}
                            </FormGroup>
                            <FormGroup className={this.state.isListingByPeriod ? "" : "hidden"}>
                                <ControlLabel className="label-block" >Enter Post At</ControlLabel>
                                <FormControl
                                    className="hidden"
                                    placeholder="Post At"
                                    name="postAt"
                                    value={this.state.postAt}
                                    onChange={this.handleChange}
                                />
                                <DayPickerInput
                                    inputProps={{
                                        className: 'form-control date-input datetime-input',
                                        name: 'postDate'
                                    }}
                                    value={this.state.postDate}
                                    onDayChange={this.handlePostDateChange}
                                    placeholder="DD/MM/YYYY"
                                    format="DD/MM/YYYY"
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                />
                                <ControlLabel className="label-block visible-xs time-label" >Enter Post Time</ControlLabel>
                                <FormControl
                                    className="time-input datetime-input"
                                    type="number"
                                    name="postHour"
                                    placeholder="hh"
                                    min="0"
                                    max="23"
                                    step="1"
                                    value={this.state.postHour}
                                    onChange={this.handleTimeChange}
                                    data-time-type="post"
                                    required
                                />
                                <span className="time-separator">:</span>
                                <FormControl
                                    className="time-input datetime-input"
                                    type="number"
                                    name="postMinute"
                                    placeholder="mm"
                                    min="0"
                                    max="59"
                                    step="1"
                                    value={this.state.postMinute}
                                    onChange={this.handleTimeChange}
                                    data-time-type="post"
                                    required
                                />
                            </FormGroup>
                            <FormGroup className={this.state.isListingByPeriod ? "" : "hidden"}>
                                <ControlLabel className="label-block">Enter Remove At</ControlLabel>
                                <FormControl
                                    className="hidden"
                                    placeholder="Remove At"
                                    name="removeAt"
                                    value={this.state.removeAt}
                                    onChange={this.handleChange}
                                />
                                <DayPickerInput
                                    inputProps={{
                                        className: 'form-control date-input datetime-input',
                                        name: 'removeDate'
                                    }}
                                    value={this.state.removeDate}
                                    onDayChange={this.handleRemoveDateChange}
                                    placeholder="DD/MM/YYYY"
                                    format="DD/MM/YYYY"
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                />
                                <ControlLabel className="label-block visible-xs time-label" >Enter Remove Time</ControlLabel>
                                <FormControl
                                    className="time-input datetime-input"
                                    type="number"
                                    name="removeHour"
                                    placeholder="hh"
                                    min={moment(this.state.postDate, 'DD/MM/YYYY').isSame(moment(this.state.removeDate, 'DD/MM/YYYY')) ? this.state.postHour : 0}
                                    max="23"
                                    step="1"
                                    value={this.state.removeHour}
                                    onChange={this.handleTimeChange}
                                    data-time-type="remove"
                                    required
                                />
                                <span className="time-separator">:</span>
                                <FormControl
                                    className="time-input datetime-input"
                                    type="number"
                                    name="removeMinute"
                                    placeholder="mm"
                                    min={moment(this.state.postDate, 'DD/MM/YYYY').isSame(moment(this.state.removeDate, 'DD/MM/YYYY')) && this.state.postHour === this.state.removeHour ? this.state.postMinute : 0}
                                    max="59"
                                    step="1"
                                    value={this.state.removeMinute}
                                    onChange={this.handleTimeChange}
                                    data-time-type="remove"
                                    required
                                />
                            </FormGroup>
                            <FormGroup className={this.state.isListingByPeriod ? "hidden" : ""}>
                                <ControlLabel className="label-block" >Enter Post Time</ControlLabel>
                                <FormControl
                                    className="time-input datetime-input"
                                    type="number"
                                    name="postHour"
                                    placeholder="hh"
                                    min="0"
                                    max="23"
                                    step="1"
                                    value={this.state.postHour}
                                    onChange={this.handleTimeChange}
                                    data-time-type="post"
                                    required
                                />
                                <span className="time-separator">:</span>
                                <FormControl
                                    className="time-input datetime-input"
                                    type="number"
                                    name="postMinute"
                                    placeholder="mm"
                                    min="0"
                                    max="59"
                                    step="1"
                                    value={this.state.postMinute}
                                    onChange={this.handleTimeChange}
                                    data-time-type="post"
                                    required
                                />
                            </FormGroup>
                            <FormGroup className={this.state.isListingByPeriod ? "hidden" : ""}>
                                <ControlLabel className="label-block visible-xs time-label" >Enter Remove Time</ControlLabel>
                                <FormControl
                                    className="time-input datetime-input"
                                    type="number"
                                    name="removeHour"
                                    placeholder="hh"
                                    min={moment(this.state.postDate, 'DD/MM/YYYY').isSame(moment(this.state.removeDate, 'DD/MM/YYYY')) ? this.state.postHour : 0}
                                    max="23"
                                    step="1"
                                    value={this.state.removeHour}
                                    onChange={this.handleTimeChange}
                                    data-time-type="remove"
                                    required
                                />
                                <span className="time-separator">:</span>
                                <FormControl
                                    className="time-input datetime-input"
                                    type="number"
                                    name="removeMinute"
                                    placeholder="mm"
                                    min={moment(this.state.postDate, 'DD/MM/YYYY').isSame(moment(this.state.removeDate, 'DD/MM/YYYY')) && this.state.postHour === this.state.removeHour ? this.state.postMinute : 0}
                                    max="59"
                                    step="1"
                                    value={this.state.removeMinute}
                                    onChange={this.handleTimeChange}
                                    data-time-type="remove"
                                    required
                                />
                            </FormGroup>
                            <FormGroup className={this.state.isListingByPeriod ? "hidden" : ""}>
                                <ControlLabel>Select Days</ControlLabel>
                                <Select
                                    options={[
                                        { value: 'Mon', label: 'Mon' },
                                        { value: 'Tue', label: 'Tue' },
                                        { value: 'Wed', label: 'Wed' },
                                        { value: 'Thu', label: 'Thu' },
                                        { value: 'Fri', label: 'Fri' },
                                        { value: 'Sat', label: 'Sat' },
                                        { value: 'Sun', label: 'Sun' },
                                    ]}
                                    multi={true}
                                    placeholder="select days, left empty to be available all days"
                                    value={this.state.currDays}
                                    onChange={(val) => {
                                        this.setState({ currDays: val });
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={this.state.isListingByPeriod ? "hidden" : ""}>
                                <ControlLabel className="label-block" >Enter Start Date</ControlLabel>
                                <DayPickerInput
                                    inputProps={{
                                        className: 'form-control date-input datetime-input',
                                        name: 'postDate'
                                    }}
                                    value={this.state.postDate}
                                    onDayChange={this.handlePostDateChange}
                                    placeholder="DD/MM/YYYY"
                                    format="DD/MM/YYYY"
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                />
                            </FormGroup>
                            <FormGroup className={this.state.isListingByPeriod ? "hidden" : ""}>
                                <ControlLabel>Enter No. of Days</ControlLabel>
                                <FormControl
                                    type="number"
                                    placeholder="No. of Days"
                                    name="numDays"
                                    step="1"
                                    min="0"
                                    value={this.state.numDays}
                                    onChange={this.handleChange}
                                    required
                                />
                            </FormGroup>
                            <Button className="table-action-button action-button btn-fill"
                                bsStyle="warning"
                                type="button"
                                onClick={this.handleAdd}>
                                <i className="fas fa-plus"></i> Add listing
                            </Button>
                        </form>
                        <br />
                        <form id="edit-listing-form">
                            <Tabs defaultActiveKey={1} id={1} ref={this.tabsRef}>
                                {this.renderTab(LISTING_TYPES.CURRENT)}
                                {this.renderTab(LISTING_TYPES.UPCOMING)}
                                {this.renderTab(LISTING_TYPES.EXPIRED)}
                                {listingPositionsTab}
                            </Tabs>
                        </form>
                    </div>
                </ProperCard>
            </div>
        );
    }
}

const ListinghDraggableRow = (listing, products, i) => {
    const product = products.find(p => p.Id === listing.MerchantBranchProductId);
    const photo = product.MerchantBranchProductImage[0]?.ImageBlob;
    let productPhoto;
    if (photo) {
        const tooltip = (
            <Tooltip className="custom-tooltip" id={`tooltip-${listing.Id}`}>
                <img className="logo-zoomed" src={photo} alt="logo" />
            </Tooltip>
        );
        productPhoto = (
            <OverlayTrigger placement="left" overlay={tooltip}>
                <img className="merchant-logo-drag" src={photo} alt="logo" />
            </OverlayTrigger>);
    } else {
        productPhoto = <span className="no-field-text text-nowrap"><em>No Logo</em></span>;
    }

    return (
        <Draggable key={listing.Id} draggableId={listing.Id} index={i}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                    )}
                >
                    <span className="merchant-position">{listing.Position}</span>
                    {productPhoto}
                    <span className="merchant-name">{product.Name}</span>
                </div>
            )}
        </Draggable>
    );
}

function getItemStyle(isDragging, draggableStyle) {
    return {
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',
        margin: `0 0 8px 0`,
        border: '1px #ddd solid',

        // change background colour if dragging
        background: isDragging ? 'lightgreen' : 'white',

        // styles we need to apply on draggables
        ...draggableStyle,
    };
}

function reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
}

function padToDoubleDigits(i) {
    const stringI = i.toString();
    let paddedStringI = stringI;
    for (let j = stringI.length; j < 2; j += 1) {
        paddedStringI = '0' + paddedStringI;
    }

    return paddedStringI;
}

function getUnixFromDateHourMinute(date, hour, minute) {
    // returns unix timestamp from date hour minute based on local timezone
    const timeString = date
        + padToDoubleDigits(hour)
        + padToDoubleDigits(minute);

    return moment(timeString, 'DD/MM/YYYYHHmm').unix();
}

export default connect(state => ({
    auth: state.auth
}))(Listings);
