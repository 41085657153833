import axios from "../service/HttpManager";

export const fetchGenerationSettings = async accessToken => {
  const response = await axios.get(`/generationSettings`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    }
  });
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};
