import React, { Component } from 'react';
import { Table, FormGroup, FormControl, Row, Col, ControlLabel } from 'react-bootstrap';
import moment from 'moment';
import Button from '../../elements/CustomButton/CustomButton.jsx';
import { validateInputs } from '../../helper';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import api from '../../api/index.js';

class PromocodeRow extends Component {
    constructor(props) {
        super(props);

        const promocode = this.props.promocode;
        const rules = [];
        // promocode.filters.forEach((filter) => {
        //     let applicableValue = '';
        //     if (filter.applicableType === 'CUSTOMER') {
        //         applicableValue = filter.applicableValue;
        //     } else if (filter.applicableType === 'BRANCH') {
        //         applicableValue = props.branchEntities[filter.applicableValue];
        //     } else if (filter.applicableType === 'MERCHANT') {
        //         applicableValue = props.merchantEntities[filter.applicableValue];
        //     }
        //     rules.push({
        //         applicableType: filter.applicableType,
        //         applicableValue
        //     })
        // });
        const expiredAtMoment = moment.unix(promocode.ExpireAt);
        this.state = {
            isEditing: false,
            isFormLoading: false,
            code: promocode.Code,
            isLimited: promocode.PromoCount === 'LIMITED',
            remaining: promocode.PromoRemaining || 0,
            type: promocode.PromoType,
            discount: promocode.PromoValue,
            applicableType: 'CUSTOMER',
            applicableValue: '',
            rules,
            entities: [],
            expiredDate: expiredAtMoment.format('DD/MM/YYYY'),
            expiredHour: expiredAtMoment.format('HH'),
            expiredMinute: expiredAtMoment.format('mm')
        };

        this.handleEdit = this.handleEdit.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleAddRule = this.handleAddRule.bind(this);
        this.deleteRule = this.deleteRule.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.handleExpiredDateChange = this.handleExpiredDateChange.bind(this);
    }

    handleExpiredDateChange(selectedDay) {
        const date = moment(selectedDay).format('DD/MM/YYYY');
        const name = 'expiredDate';
        this.setState({ [name]: date });
    }

    handleCheck(e) {
        this.setState({ [e.target.name]: e.target.checked });
    }

    handleAddRule() {
        const valueInput = document.getElementById('value-input');
        if (!this.state.applicableValue.trim()) {
            valueInput.setCustomValidity('Please fill out this field');
            valueInput.reportValidity();
            return;
        }

        valueInput.setCustomValidity('');
        const applicableType = this.state.applicableType;
        const applicableValue = this.state.applicableValue;

        const rules = this.state.rules;
        rules.push({
            applicableType, applicableValue
        });
        this.setState({ rules });
    }

    handleSelectChange(e) {
        this.setState({ [e.target.name]: e.target.value });
        if (e.target.value === 'BRANCH') {
            this.setState({ entities: this.props.branchEntities, applicableValue: JSON.stringify(this.props.branchEntities[0]) });
        } else if (e.target.value === 'MERCHANT') {
            this.setState({ entities: this.props.merchantEntities, applicableValue: JSON.stringify(this.props.merchantEntities[0]) });
        } else {
            this.setState({ entities: [], applicableValue: '' });
        }
    }

    deleteRule(i) {
        const rules = this.state.rules.slice();
        rules.splice(i, 1);
        this.setState({ rules });
    }

    handleDelete = async () => {
        if (!window.confirm('Are you sure you want to delete')) {
            return;
        }

        try {
            const response = await api.deletePromoCode(this.props.promocode.Id, this.props.token);
            console.log(response);
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-like2" />,
                message: <strong>Promocode is deleted.</strong>,
                level: 'success'
            });

            this.setState({ isExpanded: false });
            this.props.loadPromocodes();
        } catch (error) {
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-attention" />,
                message: <strong>{error.message}</strong>,
                level: 'error'
            });
        }
    }

    handleEdit = async () => {
        this.setState({ isFormLoading: true });
        try {
            const response = await api.fetchPromoCodeFilterByPromoCodeId(this.props.promocode.Id, this.props.token);
            const rules = response.map(filter => {
                let applicableValue = '';
                if (filter.applicableType === 'CUSTOMER') {
                    applicableValue = filter.ApplicableValue;
                } else if (filter.applicableType === 'BRANCH') {
                    applicableValue = props.branchEntities[filter.ApplicableValue];
                } else if (filter.applicableType === 'MERCHANT') {
                    applicableValue = props.merchantEntities[filter.ApplicableValue];
                }
                return ({
                    id: filter.Id,
                    applicableType: filter.ApplicableType,
                    applicableValue
                });
            })
            this.setState({
                isFormLoading: false,
                isEditing: true,
                rules,
            });

        } catch (error) {
            console.log(error);
            this.setState({ isEditing: false, isFormLoading: false });
        }
    }

    handleSave = async (e) => {
        if (!window.confirm('Are you sure you want to save?')) {
            return;
        }
        const isInputsValid = validateInputs(e.target.closest('tbody'), this);
        if (!isInputsValid) {
            return;
        }

        const expiredHour = this.state.expiredHour;
        const expiredMinute = this.state.expiredMinute;
        const expiredDate = moment(this.state.expiredDate, 'DD/MM/YYYY').startOf('day').unix();
        const expiredAt = expiredDate + expiredHour * 3600 + expiredMinute * 60;

        const rules = [];
        this.state.rules.forEach((rule) => {
            const currRule = Object.assign({}, rule);
            currRule.applicableValue = rule.applicableType === 'CUSTOMER'
                ? rule.applicableValue : JSON.parse(rule.applicableValue).value;
            rules.push(currRule);
        })

        const data = {
            id: this.props.promocode.Id,
            code: this.state.code,
            promoCount: this.state.isLimited ? 'LIMITED' : 'UNLIMITED',
            promoRemaining: this.state.remaining,
            promoType: this.state.type,
            promoValue: this.state.discount,
            expireAt: expiredAt,
        };

        try {
            const response = await api.updatePromoCode(data, this.props.token);
            console.log(response);
            // TODO: remove when API is available
            // await Promise.all(rules.map(rule => ({
            //     promocodeId: response.Id,
            //     applicableType: rule.applicableType,
            //     applicableValue: rule.applicableValue,
            // })).map(data => api.createPromoCodeFilter(data, this.props.token)));
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-like2" />,
                message: <strong>Promocode is updated.</strong>,
                level: 'success'
            });
            this.setState({ isEditing: false });
            this.props.loadPromocodes();
        } catch (error) {
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-attention" />,
                message: <strong>{error.message}</strong>,
                level: 'error'
            });
        }
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleCancel() {
        const promocode = this.props.promocode;
        const rules = [];

        const expiredAtMoment = moment.unix(promocode.ExpireAt);
        this.setState({
            isEditing: false,
            code: promocode.Code,
            isLimited: promocode.PromoCount === 'LIMITED',
            remaining: promocode.PromoRemaining || 0,
            type: promocode.PromoType,
            discount: promocode.PromoValue,
            applicableType: 'CUSTOMER',
            applicableValue: '',
            rules,
            entities: [],
            expiredDate: expiredAtMoment.format('DD/MM/YYYY'),
            expiredHour: expiredAtMoment.format('HH'),
            expiredMinute: expiredAtMoment.format('mm')
        });
    }

    render() {
        const promocode = this.props.promocode;
        const i = this.props.i;

        return (
            <tbody>
                <tr className={this.state.isEditing ? 'is-editing-row' : ''}>
                    <td>{i + 1}</td>
                    <td>{promocode.Code}</td>
                    <td>{promocode.PromoCount === 'LIMITED' ? promocode.PromoRemaining : 'UNLIMITED'}</td>
                    <td>{`${promocode.PromoValue} ${promocode.PromoType === 'FIXED' ? '($)' : '(%)'}`}</td>
                    <td>{moment.unix(promocode.ExpireAt).format('ddd, D MMM YYYY, HH:mm')}</td>
                    <td className="text-nowrap">
                        <Button className="table-action-button action-button btn-fill"
                            bsStyle="warning"
                            type="button"
                            onClick={this.state.isEditing ? this.handleSave : this.handleEdit}
                        >
                            {this.state.isEditing ? <i className="fas fa-check"></i> : <i className="fas fa-edit"></i>}
                        </Button>
                        {this.state.isEditing ?
                            <Button className="table-action-button action-button btn-fill"
                                bsStyle="warning"
                                type="button"
                                onClick={this.handleCancel}>
                                <i className="fas fa-times"></i>
                            </Button> : null
                        }
                        <Button className="table-action-button action-button btn-fill"
                            bsStyle="danger"
                            type="button"
                            onClick={this.handleDelete}
                        >
                            <i className="fas fa-trash"></i>
                        </Button>
                    </td>
                </tr>
                {this.state.isEditing ?
                    <tr>
                        <td colSpan={11}>
                            <div className={'loader-overlay ' + (this.state.isFormLoading ? '' : 'hidden')}><div className="loader"></div></div>
                            <FormGroup>
                                <ControlLabel>Enter Code</ControlLabel>
                                <FormControl
                                    type="text"
                                    placeholder="Code"
                                    name="code"
                                    value={this.state.code}
                                    onChange={this.handleChange}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <input className="promocode-checkbox" type="checkbox"
                                    name="isLimited"
                                    checked={this.state.isLimited}
                                    onChange={this.handleCheck} />
                                <span className="promocode-label" >Is Limited</span>
                            </FormGroup>
                            <FormGroup className={this.state.isLimited ? '' : 'hidden'}>
                                <ControlLabel>Enter Remaining</ControlLabel>
                                <FormControl
                                    type="number"
                                    placeholder="remaining"
                                    name="remaining"
                                    value={this.state.remaining}
                                    onChange={this.handleChange}
                                    required
                                />
                            </FormGroup>

                            <FormGroup>
                                <ControlLabel>Select Type</ControlLabel>
                                <FormControl
                                    componentClass="select"
                                    placeholder="Select Type"
                                    name="type"
                                    value={this.state.type}
                                    onChange={this.handleChange}
                                    required >
                                    <option value="FIXED">FIXED ($)</option>
                                    <option value="PERCENTAGE">PERCENTAGE (%)</option>
                                </FormControl>
                            </FormGroup>

                            <FormGroup>
                                <ControlLabel>Enter Value</ControlLabel>
                                <FormControl
                                    type="number"
                                    placeholder="Value"
                                    name="discount"
                                    value={this.state.discount}
                                    onChange={this.handleChange}
                                    required
                                />
                            </FormGroup>

                            <FormGroup>
                                <ControlLabel className="label-block" >Enter Expired At</ControlLabel>
                                <DayPickerInput
                                    inputProps={{
                                        className: 'form-control date-input datetime-input',
                                        name: 'expiredDate'
                                    }}
                                    value={this.state.expiredDate}
                                    onDayChange={this.handleExpiredDateChange}
                                    placeholder="DD/MM/YYYY"
                                    format="DD/MM/YYYY"
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                />
                                <FormControl
                                    className="time-input datetime-input"
                                    type="number"
                                    name="expiredHour"
                                    placeholder="hh"
                                    min="0"
                                    max="23"
                                    step="1"
                                    value={this.state.expiredHour}
                                    onChange={this.handleChange}
                                    required
                                />
                                <span className="time-separator">:</span>
                                <FormControl
                                    className="time-input datetime-input"
                                    type="number"
                                    name="expiredMinute"
                                    placeholder="mm"
                                    min="0"
                                    max="59"
                                    step="1"
                                    value={this.state.expiredMinute}
                                    onChange={this.handleChange}
                                    required
                                />
                            </FormGroup>

                            <h4>Rules</h4>
                            <Row>
                                <Col sm={6}>
                                    <FormGroup>
                                        <ControlLabel>Select Entity Type</ControlLabel>
                                        <FormControl
                                            componentClass="select"
                                            placeholder="Select Entity"
                                            name="applicableType"
                                            value={this.state.applicableType}
                                            onChange={this.handleSelectChange}>
                                            <option value="CUSTOMER">CUSTOMER</option>
                                            <option value="BRANCH">BRANCH</option>
                                            <option value="MERCHANT">MERCHANT</option>
                                        </FormControl>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    {this.state.applicableType === 'CUSTOMER' ?
                                        <FormGroup>
                                            <ControlLabel>Enter Email or Username</ControlLabel>
                                            <FormControl
                                                type="text"
                                                placeholder="Email or Username"
                                                id="value-input"
                                                name="applicableValue"
                                                value={this.state.applicableValue}
                                                onChange={this.handleChange}
                                            />
                                        </FormGroup> :
                                        <FormGroup>
                                            <ControlLabel>Select Entity</ControlLabel>
                                            <FormControl
                                                componentClass="select"
                                                placeholder="Select Entity Type"
                                                id="value-input"
                                                name="applicableValue"
                                                value={this.state.applicableValue}
                                                onChange={this.handleChange}>
                                                {
                                                    this.state.entities.map((entity) => {
                                                        return <option key={entity.value} value={JSON.stringify(entity)}>{entity.name}</option>
                                                    })
                                                }
                                            </FormControl>
                                        </FormGroup>
                                    }
                                </Col>
                            </Row>
                            <Button className="table-action-button action-button btn-fill"
                                bsStyle="warning"
                                type="button"
                                onClick={this.handleAddRule}>
                                <i className="fas fa-plus"></i> Add Rule
                            </Button>
                            <Row>
                                <Col md={12}>
                                    <Table striped hover responsive>
                                        <thead>
                                            <tr>
                                                <th>Entity Type</th>
                                                <th>Entity</th>
                                                <th width="10%"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.rules.map((rule, i) => {
                                                    return (
                                                        <tr key={Math.random()}>
                                                            <td>{rule.applicableType}</td>
                                                            <td>{
                                                                rule.applicableType === 'CUSTOMER' ? rule.applicableValue : rule.applicableValue.name
                                                            }</td>
                                                            <td width="10%">
                                                                <Button className="table-action-button action-button btn-fill"
                                                                    bsStyle="danger"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        this.deleteRule(i);
                                                                    }}
                                                                >
                                                                    <i className="fas fa-trash"></i>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <FormGroup>
                                <Button className="table-action-button action-button btn-fill"
                                    bsStyle="warning"
                                    type="button"
                                    onClick={this.handleSave}>
                                    <i className="fas fa-check"></i>
                                </Button>
                                <Button className="table-action-button action-button btn-fill"
                                    bsStyle="warning"
                                    type="button"
                                    onClick={this.handleCancel}>
                                    <i className="fas fa-times"></i>
                                </Button>
                            </FormGroup>
                        </td>
                    </tr> : null
                }
            </tbody>
        );
    }
}

export default PromocodeRow;
