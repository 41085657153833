import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import HeaderLinks from '../Header/HeaderLinks.jsx';

import imagine from '../../assets/img/food-background.png';
import logo from '../../assets/img/logo-white-2.png';

import appRoutes from '../../routes/app.jsx';
import { connect } from 'react-redux';

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth
        }
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    activeRoute = (routeName) => {
        return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth });
    }

    render() {
        const { branchId, branchesPermissions, roles } = this.props.auth;
        let permissions;
        if (roles.includes('admin')) {
            permissions = {
                id: branchId,
                writable: [
                    "user profile",
                    "products",
                    "listings",
                    "events",
                    "transactions"
                ]
            };
        } else {
            permissions = branchesPermissions.find(p => p.id === branchId);
        }
        const sidebarBackground = {
            backgroundImage: 'url("")'
        };
        return (
            <div id="sidebar" className="sidebar" data-color="orange" data-image={imagine}>
                <div className="sidebar-background" style={sidebarBackground}></div>
                <div className="logo">
                    <a href="/#/dashboard" className="simple-text logo-normal">
                        <div className="logo-img">
                            <img src={logo} alt="logo_image" />
                        </div>
                    </a>
                </div>
                <div className="sidebar-wrapper">
                    <ul className="nav">
                        {this.state.width <= 991 ? (<HeaderLinks {...this.props} />) : null}
                        {
                            appRoutes.filter((prop, key) => !prop.redirect && permissions.writable.includes(prop.name.toLowerCase())).map((prop, key) => {

                                return (
                                    <li className={prop.upgrade ? "active active-pro" : this.activeRoute(prop.path)} key={key}>
                                        <NavLink to={prop.path} className="nav-link" activeClassName="active">
                                            {prop.icon ? <i className={prop.icon}></i> : <span className={"sidebar-icon glyphicon " + prop.glyphicon} aria-hidden="true" />}
                                            <p>{prop.name}</p>
                                        </NavLink>
                                    </li>
                                );
                            })
                        }
                    </ul>
                </div>
            </div>
        );
    }
}

export default connect(state => ({
    auth: state.auth
}))(Sidebar);
