import axios from "../service/HttpManager";
import { fetchBranch } from "./branch";

export const login = async (username, password) => {
  const response = await axios.post(
    "/auth/merchant-login",
    { username, password },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (response.status !== 200) throw new Error(response.data.message);
  return response.data;
};

export const fetchAccount = async (accountId, accessToken) => {
  const response = await axios.post(
    "/merchant/account/getOne",
    { id: accountId },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const fetchAccounts = async (accessToken, page = 1, pageSize = 200) => {
  const response = await axios.post("/merchant/account/all", null, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: { page, pageSize },
  });
  if (response.status !== 201) throw new Error(response.data.message);
  let accounts = response.data;
  // accounts = await Promise.all(accounts.map(async (account) => {
  //   const branches = await fetchAccountMerchantBranches(account.Id, accessToken);
  //   account.Branches = branches;
  //   return account;
  // }));
  return accounts;
};

export const fetchAccountMerchantBranches = async (accountId, accessToken, page = 1, pageSize = 20) => {
  const response = await axios.post(
    "/merchant/account-merchant-branches/getByAccountId",
    {
      id: accountId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: { page, pageSize },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  const data = response.data;
  const branchIds = data.map((item) => item.MerchantBranchId);
  const accountBranchIds = data.map((item) => item.Id);
  let branches = await Promise.all(
    branchIds.map(async (branchId) => {
      try {
        return await fetchBranch(Number(branchId), accessToken);
      } catch (error) {
        console.error(`Failed to fetch branch with ID ${branchId}:`, error);
        return null;
      }
    })
  );
  let roles = await Promise.all(
    accountBranchIds.map(async (accountBranchId) => {
      try {
        return await fetchAccountMerchantBranchRoles(accountBranchId, accessToken);
      } catch (error) {
        console.error(`Failed to fetch roles for account branch with ID ${accountBranchId}:`, error);
        return null;
      }
    })
  );

  branches = branches.filter((branch) => branch !== null);
  roles = roles.filter((role) => !!role);
  const output = data.map((item) => {
    const branch = branches.find((branch) => branch.Id === item.MerchantBranchId);
    const role = roles.find((r) => r.AccountMerchantBranchId === item.Id);
    switch (role.RoleId) {
      case 1:
        role.RoleName = "admin";
        break;
      case 2:
        role.RoleName = "merchant-manager";
        break;
      case 3:
        role.RoleName = "merchant-staff";
        break;
      case 4:
        role.RoleName = "merchant-user";
        break;
      case 5:
        role.RoleName = "event-manager";
        break
      default:
        role.RoleName = "unknown";
        break;
    }
    branch.Role = role;
    return branch;
  });
  return output;
};

export const fetchAccountMerchantBranchRoles = async (
  accountBranchId,
  accessToken,
  page = 1,
  pageSize = 10
) => {
  const response = await axios.post(
    "/merchant/account-merchant-branch-role/getByAccountMerchantBranchId",
    {
      id: accountBranchId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: { page, pageSize },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data[0];
};

export const createAccount = async (data, accessToken) => {
  const response = await axios.post("/merchant/account/create", data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const updateAccount = async (data, accessToken) => {
  const response = await axios.post("/merchant/account/update", data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const deleteAccount = async (accountId, accessToken) => {
  const response = await axios.post(
    "merchant/account/delete",
    { id: accountId },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const fetchAccountMerchantBranchByAccountId = async (accountId, accessToken) => {
  const response = await axios.post(
    "/merchant/account-merchant-branches/getByAccountId",
    {
      id: accountId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const createAccountMerchantBranch = async (accountId, branchId, roleId, accessToken) => {
  const response = await axios.post(
    "/merchant/account-merchant-branches/create",
    {
      accountId,
      merchantBranchId: branchId,
      isActive: true,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  const accountMerchantBranchId = response.data.Id;
  await createAcountMerchantBranchRole(
    accountMerchantBranchId,
    roleId,
    accessToken
  );
  return response.data;
};

export const deleteAccountMerchantBranch = async (
  accountMerchantBranchId,
  accountMerchantBranchRoleId,
  accessToken
) => {
  await deleteAcountMerchantBranchRole(accountMerchantBranchRoleId, accessToken);

  const response = await axios.post(
    "/merchant/account-merchant-branches/delete",
    { id: accountMerchantBranchId },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const createAcountMerchantBranchRole = async (accountMerchantBranchId, roleId, accessToken) => {
  const response = await axios.post(
    "/merchant/account-merchant-branch-role/create",
    {
      accountMerchantBranchId,
      roleId,
      isActive: true,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const deleteAcountMerchantBranchRole = async (accountMerchantBranchRoleId, accessToken) => {
  const response = await axios.post(
    "/merchant/account-merchant-branch-role/delete",
    { id: accountMerchantBranchRoleId },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};
