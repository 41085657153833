import React, { Component } from 'react';
import {
    Grid, Row, Col, Alert,
    FormGroup, ControlLabel, FormControl, Table
} from 'react-bootstrap';

import { Card } from '../../components/Card/Card.jsx';
import PromocodeRow from '../../components/PromocodeRow/PromocodeRow.jsx';
import Button from '../../elements/CustomButton/CustomButton.jsx';
import 'react-select/dist/react-select.css';
import { validateInputs } from '../../helper';

import moment from 'moment';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import api from '../../api';
import { connect } from 'react-redux';

class Promocodes extends Component {
    constructor(props) {
        super(props);

        const currentHour = moment().format('HH');
        const currentMinute = moment().format('mm');

        this.state = {
            isFormLoading: false,
            promocodes: [],
            code: '',
            isLimited: true,
            remaining: 0,
            type: 'FIXED',
            discount: 0,
            applicableType: 'CUSTOMER',
            applicableValue: '',
            rules: [],
            entities: [],
            expiredDate: moment().format('DD/MM/YYYY'),
            expiredHour: currentHour,
            expiredMinute: currentMinute
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleAddRule = this.handleAddRule.bind(this);
        this.deleteRule = this.deleteRule.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.handleExpiredDateChange = this.handleExpiredDateChange.bind(this);
    }

    componentDidMount() {
        this.loadBranches();
    }

    loadBranches = async () => {
        const { token, branchesPermissions, roles } = this.props.auth;
        try {
            let branches = [];
            let merchants = [];
            if (roles.includes('admin')) {
                const results = await Promise.all([
                    api.fetchBranches(token, 1, 200),
                    api.fetchMerchants(token)
                ]);
                branches = results[0];
                merchants = results[1];
            } else {
                branches = (await Promise.all(branchesPermissions.map(permission => api.fetchBranch(permission.id, token))))
                    .filter(r => r !== null);
                merchants = branches.map(branch => branch.merchant).reduce((list, item) => {
                    return list.some(m => m._id === item._id) ? list : [...list, item]
                }, []);
            }
            this.setState({
                branchEntities: branches.map(branch => ({
                    name: `${branch.BranchName} (${branch.LocationType})`,
                    value: branch.Id,
                })),
                merchantEntities: merchants.map(merchant => ({
                    name: merchant.Name,
                    value: merchant.Id
                }))
            }, () => {
                this.loadPromocodes();
            })
        } catch (err) {
            console.log(err);
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-attention" />,
                message: <strong>{err.message}</strong>,
                level: 'error'
            });
        }
    }

    handleExpiredDateChange(selectedDay) {
        const date = moment(selectedDay).format('DD/MM/YYYY');
        const name = 'expiredDate';
        this.setState({ [name]: date });
    }

    handleCheck(e) {
        this.setState({ [e.target.name]: e.target.checked });
    }

    handleAddRule() {
        const valueInput = document.getElementById('value-input');
        if (!this.state.applicableValue.trim()) {
            valueInput.setCustomValidity('Please fill out this field');
            valueInput.reportValidity();
            return;
        }

        valueInput.setCustomValidity('');
        const applicableType = this.state.applicableType;
        const applicableValue = this.state.applicableValue;

        const rules = this.state.rules;
        rules.push({
            applicableType, applicableValue
        });
        this.setState({ rules });
    }

    handleSelectChange(e) {
        this.setState({ [e.target.name]: e.target.value });
        if (e.target.value === 'BRANCH') {
            this.setState({ entities: this.state.branchEntities, applicableValue: JSON.stringify(this.state.branchEntities[0]) });
        } else if (e.target.value === 'MERCHANT') {
            this.setState({ entities: this.state.merchantEntities, applicableValue: JSON.stringify(this.state.merchantEntities[0]) });
        } else {
            this.setState({ entities: [], applicableValue: '' });
        }
    }

    deleteRule(i) {
        const rules = this.state.rules.slice();
        rules.splice(i, 1);
        this.setState({ rules });
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleAdd = async (e) => {
        const isInputsValid = validateInputs(e.target.closest('form'), this);
        if (!isInputsValid) {
            return;
        }

        const expiredHour = this.state.expiredHour;
        const expiredMinute = this.state.expiredMinute;
        const expiredDate = moment(this.state.expiredDate, 'DD/MM/YYYY').startOf('day').unix();
        const expiredAt = expiredDate + expiredHour * 3600 + expiredMinute * 60;

        const rules = [];
        this.state.rules.forEach((rule) => {
            const currRule = Object.assign({}, rule);
            currRule.applicableValue = rule.applicableType === 'CUSTOMER'
                ? rule.applicableValue : JSON.parse(rule.applicableValue).value;
            rules.push(currRule);
        })

        const data = {
            code: this.state.code,
            promoCount: this.state.isLimited ? 'LIMITED' : 'UNLIMITED',
            promoRemaining: this.state.remaining,
            promoType: this.state.type,
            promoValue: this.state.discount,
            expireAt: expiredAt,
        };

        try {
            const response = await api.createPromoCode(data, this.props.token);
            console.log(response);
            await Promise.all(rules.map(rule => ({
                promocodeId: response.Id,
                applicableType: rule.applicableType,
                applicableValue: rule.applicableValue,
            })).map(data => api.createPromoCodeFilter(data, this.props.token)));
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-like2" />,
                message: <strong>Promocode is created.</strong>,
                level: 'success'
            });
            this.setState({
                code: '',
                remaining: 0,
                type: 'FIXED',
                discount: 0,
                rules: []
            });
            this.loadPromocodes();
        } catch (error) {
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-attention" />,
                message: <strong>{error.message}</strong>,
                level: 'error'
            });
        }
    }

    loadPromocodes = async () => {
        try {
            const promocodes = await api.fetchPromoCodes(this.props.token);
            promocodes.sort((a, b) => b.CreatedAt - a.CreatedAt)
            this.setState({ promocodes });
        } catch (err) {
            console.log(err);
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-attention" />,
                message: <strong>{err.message}</strong>,
                level: 'error'
            });
        }
    }

    render() {
        let alert;
        if (this.state.status) {
            alert =
                (<Alert bsStyle={this.state.isError ? "danger" : "success"}>
                    <span>{this.state.status}</span>
                </Alert>);
        }

        const promocodesTable = (
            <form id="edit-promocodes-form">
                <h4>Promocodes</h4>
                <Table hover bordered responsive className="promocodes-table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Code</th>
                            <th>Remaining</th>
                            <th>Discount</th>
                            <th>Expired At</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    {
                        this.state.promocodes.map((promocode, i) => {
                            return (
                                <PromocodeRow
                                    {...this.props}
                                    branchEntities={this.state.branchEntities}
                                    merchantEntities={this.state.merchantEntities}
                                    key={promocode.Id}
                                    token={this.props.token}
                                    promocode={promocode} i={i}
                                    loadPromocodes={this.loadPromocodes}
                                />
                            );
                        })
                    }
                </Table>
            </form>
        );

        return (
            <div className="content">
                {alert}
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title="Add Promocode"
                                content={
                                    <div>
                                        <form>
                                            <div className={'loader-overlay ' + (this.state.isFormLoading ? '' : 'hidden')}><div className="loader"></div></div>
                                            <FormGroup>
                                                <ControlLabel>Enter Code</ControlLabel>
                                                <FormControl
                                                    type="text"
                                                    placeholder="Code"
                                                    name="code"
                                                    value={this.state.code}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <input className="promocode-checkbox" type="checkbox"
                                                    name="isLimited"
                                                    checked={this.state.isLimited}
                                                    onChange={this.handleCheck} />
                                                <span className="promocode-label" >Is Limited</span>
                                            </FormGroup>
                                            <FormGroup className={this.state.isLimited ? '' : 'hidden'}>
                                                <ControlLabel>Enter Remaining</ControlLabel>
                                                <FormControl
                                                    type="number"
                                                    placeholder="remaining"
                                                    name="remaining"
                                                    value={this.state.remaining}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <ControlLabel>Select Type</ControlLabel>
                                                <FormControl
                                                    componentClass="select"
                                                    placeholder="Select Type"
                                                    name="type"
                                                    value={this.state.type}
                                                    onChange={this.handleChange}
                                                    required >
                                                    <option value="FIXED">FIXED ($)</option>
                                                    <option value="PERCENTAGE">PERCENTAGE (%)</option>
                                                </FormControl>
                                            </FormGroup>

                                            <FormGroup>
                                                <ControlLabel>Enter Value</ControlLabel>
                                                <FormControl
                                                    type="number"
                                                    placeholder="Value"
                                                    name="discount"
                                                    value={this.state.discount}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <ControlLabel className="label-block" >Enter Expired At</ControlLabel>
                                                <DayPickerInput
                                                    inputProps={{
                                                        className: 'form-control date-input datetime-input',
                                                        name: 'expiredDate'
                                                    }}
                                                    value={this.state.expiredDate}
                                                    onDayChange={this.handleExpiredDateChange}
                                                    placeholder="DD/MM/YYYY"
                                                    format="DD/MM/YYYY"
                                                    formatDate={formatDate}
                                                    parseDate={parseDate}
                                                />
                                                <FormControl
                                                    className="time-input datetime-input"
                                                    type="number"
                                                    name="expiredHour"
                                                    placeholder="hh"
                                                    min="0"
                                                    max="23"
                                                    step="1"
                                                    value={this.state.expiredHour}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                                <span className="time-separator">:</span>
                                                <FormControl
                                                    className="time-input datetime-input"
                                                    type="number"
                                                    name="expiredMinute"
                                                    placeholder="mm"
                                                    min="0"
                                                    max="59"
                                                    step="1"
                                                    value={this.state.expiredMinute}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </FormGroup>

                                            <h4>Rules</h4>
                                            <Row>
                                                <Col sm={6}>
                                                    <FormGroup>
                                                        <ControlLabel>Select Entity Type</ControlLabel>
                                                        <FormControl
                                                            componentClass="select"
                                                            placeholder="Select Entity"
                                                            name="applicableType"
                                                            value={this.state.applicableType}
                                                            onChange={this.handleSelectChange}>
                                                            <option value="CUSTOMER">CUSTOMER</option>
                                                            <option value="BRANCH">BRANCH</option>
                                                            <option value="MERCHANT">MERCHANT</option>
                                                        </FormControl>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={6}>
                                                    {this.state.applicableType === 'CUSTOMER' ?
                                                        <FormGroup>
                                                            <ControlLabel>Enter Email or Username</ControlLabel>
                                                            <FormControl
                                                                type="text"
                                                                placeholder="Email or Username"
                                                                id="value-input"
                                                                name="applicableValue"
                                                                value={this.state.applicableValue}
                                                                onChange={this.handleChange}
                                                            />
                                                        </FormGroup> :
                                                        <FormGroup>
                                                            <ControlLabel>Select Entity</ControlLabel>
                                                            <FormControl
                                                                componentClass="select"
                                                                placeholder="Select Entity Type"
                                                                id="value-input"
                                                                name="applicableValue"
                                                                value={this.state.applicableValue}
                                                                onChange={this.handleChange}>
                                                                {
                                                                    this.state.entities.map((entity) => {
                                                                        return <option key={entity.value} value={JSON.stringify(entity)}>{entity.name}</option>
                                                                    })
                                                                }
                                                            </FormControl>
                                                        </FormGroup>
                                                    }
                                                </Col>
                                            </Row>
                                            <Button className="table-action-button action-button btn-fill"
                                                bsStyle="warning"
                                                type="button"
                                                onClick={this.handleAddRule}>
                                                <i className="fas fa-plus"></i> Add Rule
                                            </Button>
                                            <Row>
                                                <Col md={12}>
                                                    <Table striped hover responsive>
                                                        <thead>
                                                            <tr>
                                                                <th>Entity Type</th>
                                                                <th>Entity</th>
                                                                <th width="10%"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.rules.map((rule, i) => {
                                                                    return (
                                                                        <tr key={i}>
                                                                            <td>{rule.applicableType}</td>
                                                                            <td>{
                                                                                rule.applicableType === 'CUSTOMER' ? rule.applicableValue : JSON.parse(rule.applicableValue).name
                                                                            }</td>
                                                                            <td width="10%">
                                                                                <Button className="table-action-button action-button btn-fill"
                                                                                    bsStyle="danger"
                                                                                    type="button"
                                                                                    onClick={() => {
                                                                                        this.deleteRule(i);
                                                                                    }}
                                                                                >
                                                                                    <i className="fas fa-trash"></i>
                                                                                </Button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                            <Button className="table-action-button action-button btn-fill"
                                                bsStyle="warning"
                                                type="button"
                                                onClick={this.handleAdd}>
                                                <i className="fas fa-plus"></i> Add Promocode
                                            </Button>
                                        </form>
                                        {promocodesTable}
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default connect(state => ({
    auth: state.auth
}))(Promocodes);
