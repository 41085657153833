import React, { Component } from 'react';
import { OverlayTrigger, Tooltip, FormControl, FormGroup } from 'react-bootstrap';
import Compress from 'react-image-file-resizer';
import { FormInputs } from '../../components/FormInputs/FormInputs.jsx';
import Button from '../../elements/CustomButton/CustomButton.jsx';
import { validateInputs } from '../../helper';
import api from '../../api/index.js';

class MerchantRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditing: false,
            isFormLoading: false,
            name: this.props.merchant.Name,
            displayName: this.props.merchant.DisplayName,
            email: this.props.merchant.Email || '',
            website: this.props.merchant.Website || '',
            address1: this.props.merchant.AddressLine1 || '',
            address2: this.props.merchant.AddressLine2 || '',
            address3: this.props.merchant.AddressLine3 || '',
            city: this.props.merchant.City || '',
            state: this.props.merchant.State || '',
            postal: this.props.merchant.PostalCode || '',
            officeNumber: this.props.merchant.OfficeNumber || '',
            mobileNumber: this.props.merchant.MobileNumber || '',
            faxNumber: this.props.merchant.FaxNumber || '',
            logo: this.props.merchant.Logo || '',
        };

        this.handleEdit = this.handleEdit.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handlePhotoSelect = this.handlePhotoSelect.bind(this);
    }

    handleDelete = async() => {
        if (!window.confirm('Are you sure you want to delete?')) {
            return;
        }

        try {
            const response = await api.deleteBranch(this.props.merchant.Id, this.props.token);
            console.log(response);
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-like2" />,
                message: <strong>Merchant deleted.</strong>,
                level: 'success'
            });
            this.setState({ isExpanded: false });
            this.props.loadMerchants();
        } catch (error) {
            error.message
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-attention" />,
                message: <strong>{error.message}</strong>,
                level: 'error'
            });
        }
    }

    handleEdit() {
        this.setState({ isEditing: true });
    }

    handleSave = async (e) => {
        if (!window.confirm('Are you sure you want to save?')) {
            return;
        }

        const isInputsValid = validateInputs(e.target.closest('tbody'), this);
        if (!isInputsValid) {
            return;
        }

        this.setState({ isFormLoading: true });

        const data = {
            id: this.props.merchant.Id,
            name: this.state.name,
            displayName: this.state.displayName,
            email: this.state.email,
            website: this.state.website,
            addressLine1: this.state.address1,
            addressLine2: this.state.address2,
            addressLine3: this.state.address3,
            city: this.state.city,
            state: this.state.state,
            postalCode: this.state.postal,
            officeNumber: this.state.officeNumber,
            mobileNumber: this.state.mobileNumber,
            faxNumber: this.state.faxNumber,
        }

        try {
            const response = await api.updateMerchant(data, this.state.logo, this.props.token);
            console.log(response);
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-like2" />,
                message: <strong>Update merchant success.</strong>,
                level: 'success'
            });
            this.setState({ isEditing: false });
            this.props.loadMerchants();
        } catch(error) {
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-attention" />,
                message: <strong>{error.message}</strong>,
                level: 'error'
            });
        } finally {
            this.setState({ isFormLoading: false });
        }
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handlePhotoSelect = async (e) => {
        try {
            const file = e.target.files[0];
            const image = await resizeFile(file);
            this.setState({ logo: image || '' });
        } catch (err) {
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-attention" />,
                message: <strong>{err.message}</strong>,
                level: 'error'
            });
        }
    }

    handleCancel() {
        this.setState({
            isEditing: false,
            name: this.props.merchant.Name,
            displayName: this.props.merchant.DisplayName,
            email: this.props.merchant.Email || '',
            website: this.props.merchant.Website || '',
            address1: this.props.merchant.AddressLine1 || '',
            address2: this.props.merchant.AddressLine2 || '',
            address3: this.props.merchant.AddressLine3 || '',
            city: this.props.merchant.City || '',
            state: this.props.merchant.State || '',
            postal: this.props.merchant.PostalCode || '',
            officeNumber: this.props.merchant.OfficeNumber || '',
            mobileNumber: this.props.merchant.MobileNumber || '',
            faxNumber: this.props.merchant.FaxNumber || '',
            logo: this.props.merchant.Logo || '',
        });
        document.getElementById('update-merchant-logo-input-' + this.props.merchant.Id).value = '';
    }

    componentWillReceiveProps(nextProps) {
        const merchant = nextProps.merchant;
        this.setState({
            name: merchant.Name,
            displayName: merchant.DisplayName,
            email: merchant.Email || '',
            website: merchant.Website || '',
            address1: merchant.AddressLine1 || '',
            address2: merchant.AddressLine2 || '',
            address3: merchant.AddressLine3 || '',
            city: merchant.City || '',
            state: merchant.State || '',
            postal: merchant.PostalCode || '',
            officeNumber: merchant.OfficeNumber || '',
            mobileNumber: merchant.MobileNumber || '',
            faxNumber: merchant.FaxNumber || '',
            logo: merchant.Logo || '',
        });
    }

    render() {
        const merchant = this.props.merchant;
        const logo = merchant.Logo;
        let merchantLogo;
        const i = this.props.i;
        if (logo) {
            const tooltip = (
                <Tooltip className="custom-tooltip" id={'tooltip-' + merchant.Id}>
                    <img className="logo-zoomed" src={logo} alt="" />
                </Tooltip>
            );
            merchantLogo = (
                <OverlayTrigger placement="left" overlay={tooltip}>
                    <img className="merchant-logo" src={logo} alt="" />
                </OverlayTrigger>);
        } else {
            merchantLogo = <span className="no-field-text text-nowrap"><em>No Logo</em></span>;
        }

        const address =
            ((merchant.AddressLine1 || '') + ' '
                + (merchant.AddressLine2 || '') + ' '
                + (merchant.AddressLine3 || '') + ' '
                + (merchant.City || '') + ' '
                + (merchant.State || '') + ' '
                + (merchant.PostalCode || '') + ' ').replace(/\s/g, ' ').trim();
        const contact =
            'Office: ' + (merchant.OfficeNumber || 'NA')
            + ', Mobile: ' + (merchant.MobileNumber || 'NA');
        return (
            <tbody>
                <tr className={this.state.isEditing ? 'is-editing-row' : ''}>
                    <td>{i + 1}</td>
                    <td>{merchantLogo}</td>
                    <td>{merchant.Name}</td>
                    <td>{merchant.Email || <span className="no-field-text text-nowrap"><em>No Email</em></span>}</td>
                    <td>{address || <span className="no-field-text text-nowrap"><em>No Address</em></span>}</td>
                    <td>{contact || <span className="no-field-text text-nowrap"><em>No Contact</em></span>}</td>
                    <td className="text-nowrap">
                        <Button className="table-action-button action-button btn-fill"
                            bsStyle="warning"
                            type="button"
                            onClick={this.state.isEditing ? this.handleSave : this.handleEdit}
                        >
                            {this.state.isEditing ? <i className="fas fa-check"></i> : <i className="fas fa-edit"></i>}
                        </Button>
                        {this.state.isEditing ?
                            <Button className="table-action-button action-button btn-fill"
                                bsStyle="warning"
                                type="button"
                                onClick={this.handleCancel}>
                                <i className="fas fa-times"></i>
                            </Button> : null
                        }
                        <Button className="table-action-button action-button btn-fill"
                            bsStyle="danger"
                            type="button"
                            onClick={this.handleDelete}
                        >
                            <i className="fas fa-trash"></i>
                        </Button>
                    </td>
                </tr>
                {this.state.isEditing ?
                    <tr>
                        <td colSpan={11}>
                            <span>
                                <img className="form-image" src={merchant.Logo} alt="" />
                                {this.state.isEditing ?
                                    (
                                        <FormControl
                                            type="file"
                                            name="photo"
                                            onChange={this.handlePhotoSelect}
                                            id={'update-merchant-logo-input-' + merchant.Id}
                                            accept="image/png, image/jpeg"
                                        />
                                    ) : null}
                            </span>
                            <FormInputs
                                ncols={["col-md-12"]}
                                proprieties={[
                                    {
                                        label: "merchant name",
                                        type: "text",
                                        bsClass: "form-control",
                                        placeholder: "merchant",
                                        value: this.state.name,
                                        name: "name",
                                        onChange: this.handleChange,
                                        spellCheck: false
                                    }
                                ]}
                            />
                            <FormInputs
                                ncols={["col-md-12"]}
                                proprieties={[
                                    {
                                        label: "display name",
                                        type: "text",
                                        bsClass: "form-control",
                                        placeholder: "display name",
                                        value: this.state.displayName,
                                        name: "displayName",
                                        onChange: this.handleChange,
                                        spellCheck: false
                                    }
                                ]}
                            />
                            <FormInputs
                                ncols={["col-md-12"]}
                                proprieties={[
                                    {
                                        label: "email address",
                                        type: "email",
                                        bsClass: "form-control",
                                        placeholder: "email address",
                                        value: this.state.email,
                                        name: "email",
                                        onChange: this.handleChange,
                                        spellCheck: false
                                    }
                                ]}
                            />
                            <FormInputs
                                ncols={["col-md-12"]}
                                proprieties={[
                                    {
                                        label: "website",
                                        type: "text",
                                        bsClass: "form-control",
                                        placeholder: "website",
                                        value: this.state.website,
                                        name: "website",
                                        onChange: this.handleChange,
                                        spellCheck: false
                                    }
                                ]}
                            />
                            <FormInputs
                                ncols={["col-md-4", "col-md-4", "col-md-4"]}
                                proprieties={[
                                    {
                                        label: "address line 1",
                                        type: "text",
                                        bsClass: "form-control",
                                        placeholder: "address line 1",
                                        value: this.state.address1,
                                        name: "address1",
                                        onChange: this.handleChange,
                                        spellCheck: false
                                    },
                                    {
                                        label: "address line 2",
                                        type: "text",
                                        bsClass: "form-control",
                                        placeholder: "address line 2",
                                        value: this.state.address2,
                                        name: "address2",
                                        onChange: this.handleChange,
                                        spellCheck: false
                                    },
                                    {
                                        label: "address line 3",
                                        type: "text",
                                        bsClass: "form-control",
                                        placeholder: "address line 3",
                                        value: this.state.address3,
                                        name: "address3",
                                        onChange: this.handleChange,
                                        spellCheck: false
                                    }
                                ]}
                            />
                            <FormInputs
                                ncols={["col-md-4", "col-md-4", "col-md-4"]}
                                proprieties={[
                                    {
                                        label: "city",
                                        type: "text",
                                        bsClass: "form-control",
                                        placeholder: "city",
                                        value: this.state.city,
                                        name: "city",
                                        onChange: this.handleChange,
                                        spellCheck: false
                                    },
                                    {
                                        label: "state",
                                        type: "text",
                                        bsClass: "form-control",
                                        placeholder: "state",
                                        value: this.state.state,
                                        name: "state",
                                        onChange: this.handleChange,
                                        spellCheck: false
                                    },
                                    {
                                        label: "postal code",
                                        type: "text",
                                        bsClass: "form-control",
                                        placeholder: "postal code",
                                        value: this.state.postal,
                                        name: "postal",
                                        onChange: this.handleChange,
                                        spellCheck: false
                                    }
                                ]}
                            />
                            <FormInputs
                                ncols={["col-md-4", "col-md-4", "col-md-4"]}
                                proprieties={[
                                    {
                                        label: "office number",
                                        type: "text",
                                        bsClass: "form-control",
                                        placeholder: "office number",
                                        value: this.state.officeNumber,
                                        name: "officeNumber",
                                        onChange: this.handleChange,
                                        spellCheck: false
                                    },
                                    {
                                        label: "mobile number",
                                        type: "text",
                                        bsClass: "form-control",
                                        placeholder: "mobile number",
                                        value: this.state.mobileNumber,
                                        name: "mobileNumber",
                                        onChange: this.handleChange,
                                        spellCheck: false
                                    },
                                    {
                                        label: "fax number",
                                        type: "text",
                                        bsClass: "form-control",
                                        placeholder: "fax number",
                                        value: this.state.faxNumber,
                                        name: "faxNumber",
                                        onChange: this.handleChange,
                                        spellCheck: false
                                    }
                                ]}
                            />
                            <FormGroup>
                                <Button className="table-action-button action-button btn-fill"
                                    bsStyle="warning"
                                    type="button"
                                    onClick={this.handleSave}>
                                    <i className="fas fa-check"></i>
                                </Button>
                                <Button className="table-action-button action-button btn-fill"
                                    bsStyle="warning"
                                    type="button"
                                    onClick={this.handleCancel}>
                                    <i className="fas fa-times"></i>
                                </Button>
                            </FormGroup>
                        </td>
                    </tr> : null
                }
            </tbody>
        );
    }
}

const resizeFile = (file) =>
    new Promise((resolve) => {
        Compress.imageFileResizer(
            file,
            480,
            480,
            "PNG",
            70,
            0,
            (uri) => {
                resolve(uri);
            },
            "blob"
        );
    });

export default MerchantRow;
