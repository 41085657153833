import axios from "axios";

export const fetchAddressGeocoding = async (postalCode) => {
  const response = await axios.get("https://maps.googleapis.com/maps/api/geocode/json", {
    params: {
      address: `${postalCode},+SG`,
      sensor: false,
      key: "AIzaSyCr-3_Yhti95aUuG_YJEHNY5r0-jTWy9o0",
    },
  });
  if (response.status !== 200) throw new Error(response.data);
  if (response.data.status !== "OK") throw new Error(response.data.error_message);
  return response.data.results[0].geometry.location;
};
