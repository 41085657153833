import React, { Component } from 'react';
import {
    Alert, FormGroup, ControlLabel, FormControl, Table, Tab, Tabs, Tooltip, OverlayTrigger
} from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Compress from 'react-image-file-resizer';
import ProperCard from '../../components/Card/ProperCard.jsx';
import MerchantRow from '../../components/MerchantRow/MerchantRow.jsx';
import { FormInputs } from '../../components/FormInputs/FormInputs.jsx';
import Button from '../../elements/CustomButton/CustomButton.jsx';
import 'react-select/dist/react-select.css';
import { validateInputs } from '../../helper';
import api from '../../api';
import { connect } from 'react-redux';

class Merchants extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFormLoading: false,
            name: '',
            displayName: '',
            email: '',
            website: '',
            address1: '',
            address2: '',
            address3: '',
            city: '',
            state: '',
            postal: '',
            officeNumber: '',
            faxNumber: '',
            mobileNumber: '',
            logo: '',
            merchants: [],
            merchantPositions: []
        };
    }

    componentDidMount() {
        this.loadMerchants();
    }

    loadMerchants = async () => {
        if (this.state.merchants.length > 0) return;
        try {
            const { token } = this.props.auth;
            const merchants = await api.fetchMerchants(token, 1, 200, true);
            this.setState({
                merchants,
                merchantPositions: merchants.slice().sort((a, b) => a.Position - b.Position)
            })
        } catch (err) {
            console.log(err);
        }
    }

    handlePhotoSelect = async (e) => {
        try {
            const file = e.target.files[0];
            const image = await resizeFile(file);
            this.setState({ logo: image || '' });
        } catch (err) {
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-attention" />,
                message: <strong>{err.message}</strong>,
                level: 'error'
            });
        }
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleAdd = async (e) => {
        const isInputsValid = validateInputs(e.target.closest('form'), this);
        if (!isInputsValid) {
            return;
        }

        this.setState({ isFormLoading: true });

        const data = {
            name: this.state.name,
            displayName: this.state.displayName,
            email: this.state.email,
            website: this.state.website,
            addressLine1: this.state.address1,
            addressLine2: this.state.address2,
            addressLine3: this.state.address3,
            city: this.state.city,
            state: this.state.state,
            postalCode: this.state.postal,
            officeNumber: this.state.officeNumber,
            mobileNumber: this.state.mobileNumber,
            faxNumber: this.state.faxNumber,
        }

        try {
            await api.createMerchant(data, this.state.logo, this.props.token);
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-like2" />,
                message: <strong>Merchant created.</strong>,
                level: 'success'
            });
            this.setState({
                name: '',
                displayName: '',
                email: '',
                website: '',
                address1: '',
                address2: '',
                address3: '',
                city: '',
                state: '',
                postal: '',
                officeNumber: '',
                mobileNumber: '',
                faxNumber: '',
                logo: ''
            });
            document.getElementById('add-merchant-logo-input').value = '';
            this.loadMerchants();
        } catch (error) {
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-attention" />,
                message: <strong>{error.message}</strong>,
                level: 'error'
            });
        } finally {
            this.setState({ isFormLoading: false });
        }
    }

    handleSavePosition = async (e) => {
        const merchants = this.state.merchantPositions.map((merchant, i) => {
            return {
                merchantId: merchant.Id,
                position: merchant.Position,
            }
        });
        try {
            const result = await api.updateMerchantPositions(merchants, this.props.token);
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-like2" />,
                message: <strong>Merchant positions saved.</strong>,
                level: 'success'
            });
            this.setState({
                merchantPositions: result.slice().sort((a, b) => a.Position - b.Position)
            });
            this.loadMerchants();
        } catch (err) {
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-attention" />,
                message: <strong>{err.message}</strong>,
                level: 'error'
            });
        }
    }

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const merchantPositions = reorder(
            this.state.merchantPositions,
            result.source.index,
            result.destination.index
        );

        this.setState({ merchantPositions });
    }

    render() {
        let alert;
        if (this.state.status) {
            alert =
                (<Alert bsStyle={this.state.isError ? "danger" : "success"}>
                    <span>{this.state.status}</span>
                </Alert>);
        }

        const merchantsTab = (
            <Tab eventKey={1} title="Merchants">
                <h4>Merchants</h4>
                <Table hover bordered responsive className="merchants-table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Logo</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Address</th>
                            <th>Contact</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    {
                        this.state.merchants.map((merchant, i) => {
                            return (
                                <MerchantRow
                                    {...this.props}
                                    key={merchant.Id}
                                    token={this.props.token}
                                    merchant={merchant}
                                    i={i}
                                    loadMerchants={this.loadMerchants}
                                />
                            );
                        })
                    }
                </Table>
            </Tab>
        );

        const merchantPositionsTab = (
            <Tab eventKey={2} title="Merchant Positions">
                <h4>Merchant Positions</h4>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                            >
                                {this.state.merchantPositions.map((merchant, i) => MerchantDraggableRow(merchant, i))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <Button className="save-positions-button btn-fill"
                    bsStyle="warning"
                    type="button"
                    onClick={this.handleSavePosition}>
                    <i className="fas fa-save"></i> Save Positions
                </Button>
            </Tab>
        );

        return (
            <div className="content" style={{ paddingLeft: 30, paddingRight: 30 }}>
                {alert}
                <ProperCard title="Add Merchant">
                    <form>
                        <div className={'loader-overlay ' + (this.state.isFormLoading ? '' : 'hidden')}><div className="loader"></div></div>
                        <FormGroup>
                            <ControlLabel>Add Logo</ControlLabel>
                            <FormControl
                                type="file"
                                name="logo"
                                onChange={this.handlePhotoSelect}
                                id="add-merchant-logo-input"
                                accept="image/png, image/jpeg"
                                required
                            />
                        </FormGroup>

                        <FormInputs
                            ncols={["col-md-12"]}
                            proprieties={[
                                {
                                    label: "merchant name",
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "Merchant Name",
                                    value: this.state.name,
                                    name: "name",
                                    onChange: this.handleChange,
                                    spellCheck: false,
                                    required: true
                                }
                            ]}
                        />
                        <FormInputs
                            ncols={["col-md-12"]}
                            proprieties={[
                                {
                                    label: "merchant display name",
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "Merchant Display Name",
                                    value: this.state.displayName,
                                    name: "displayName",
                                    onChange: this.handleChange,
                                    spellCheck: false,
                                    required: true
                                }
                            ]}
                        />
                        <FormInputs
                            ncols={["col-md-12"]}
                            proprieties={[
                                {
                                    label: "email address",
                                    type: "email",
                                    bsClass: "form-control",
                                    placeholder: "email address",
                                    value: this.state.email,
                                    name: "email",
                                    onChange: this.handleChange,
                                    spellCheck: false
                                }
                            ]}
                        />
                        <FormInputs
                            ncols={["col-md-12"]}
                            proprieties={[
                                {
                                    label: "website",
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "website",
                                    value: this.state.website,
                                    name: "website",
                                    onChange: this.handleChange,
                                    spellCheck: false
                                }
                            ]}
                        />
                        <FormInputs
                            ncols={["col-md-4", "col-md-4", "col-md-4"]}
                            proprieties={[
                                {
                                    label: "address line 1",
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "address line 1",
                                    value: this.state.address1,
                                    name: "address1",
                                    onChange: this.handleChange,
                                    spellCheck: false
                                },
                                {
                                    label: "address line 2",
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "address line 2",
                                    value: this.state.address2,
                                    name: "address2",
                                    onChange: this.handleChange,
                                    spellCheck: false
                                },
                                {
                                    label: "address line 3",
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "address line 3",
                                    value: this.state.address3,
                                    name: "address3",
                                    onChange: this.handleChange,
                                    spellCheck: false
                                }
                            ]}
                        />
                        <FormInputs
                            ncols={["col-md-4", "col-md-4", "col-md-4"]}
                            proprieties={[
                                {
                                    label: "city",
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "city",
                                    value: this.state.city,
                                    name: "city",
                                    onChange: this.handleChange,
                                    spellCheck: false
                                },
                                {
                                    label: "state",
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "state",
                                    value: this.state.state,
                                    name: "state",
                                    onChange: this.handleChange,
                                    spellCheck: false
                                },
                                {
                                    label: "postal code",
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "postal code",
                                    value: this.state.postal,
                                    name: "postal",
                                    onChange: this.handleChange,
                                    spellCheck: false
                                }
                            ]}
                        />
                        <FormInputs
                            ncols={["col-md-4", "col-md-4", "col-md-4"]}
                            proprieties={[
                                {
                                    label: "office number",
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "office number",
                                    value: this.state.officeNumber,
                                    name: "officeNumber",
                                    onChange: this.handleChange,
                                    spellCheck: false
                                },
                                {
                                    label: "mobile number",
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "mobile number",
                                    value: this.state.mobileNumber,
                                    name: "mobileNumber",
                                    onChange: this.handleChange,
                                    spellCheck: false
                                },
                                {
                                    label: "fax number",
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "fax number",
                                    value: this.state.faxNumber,
                                    name: "faxNumber",
                                    onChange: this.handleChange,
                                    spellCheck: false
                                }
                            ]}
                        />
                        <Button className="table-action-button action-button btn-fill"
                            bsStyle="warning"
                            type="button"
                            onClick={this.handleAdd}>
                            <i className="fas fa-plus"></i> Add Merchant
                        </Button>
                    </form>
                    <br />
                    <form id="edit-merchant-form">
                        <Tabs defaultActiveKey={1} id="merchants-tabs">
                            {merchantsTab}
                            {merchantPositionsTab}
                        </Tabs>
                    </form>
                </ProperCard>
            </div>
        );
    }
}

const MerchantDraggableRow = (merchant, i) => {
    const logo = merchant.Logo;
    let merchantLogo;
    if (logo) {
        const tooltip = (
            <Tooltip className="custom-tooltip" id={`tooltip-${merchant.Id}`}>
                <img className="logo-zoomed" src={logo} alt="logo" />
            </Tooltip>
        );
        merchantLogo = (
            <OverlayTrigger placement="left" overlay={tooltip}>
                <img className="merchant-logo-drag" src={logo} alt="logo" />
            </OverlayTrigger>);
    } else {
        merchantLogo = <span className="no-field-text text-nowrap"><em>No Logo</em></span>;
    }

    return (
        <Draggable key={merchant.Id} draggableId={merchant.Id} index={i}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                    )}
                >
                    <span className="merchant-position">{merchant.Id}</span>
                    {merchantLogo}
                    <span className="merchant-name">{merchant.DisplayName}</span>
                </div>
            )}
        </Draggable>
    );
}

function reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
}

function getItemStyle(isDragging, draggableStyle) {
    return {
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',
        margin: `0 0 8px 0`,
        border: '1px #ddd solid',

        // change background colour if dragging
        background: isDragging ? 'lightgreen' : 'white',

        // styles we need to apply on draggables
        ...draggableStyle,
    };
}

const resizeFile = (file) =>
    new Promise((resolve) => {
        Compress.imageFileResizer(
            file,
            480,
            480,
            "PNG",
            70,
            0,
            (uri) => {
                resolve(uri);
            },
            "blob"
        );
    });

export default connect(state => ({
    auth: state.auth
}))(Merchants);
