import axios from "../service/HttpManager";

export const fetchSteals = async accessToken => {
  const response = await axios.get("/steals", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    }
  });
  if (response.status !== 201) return null;
  return response.data;
};

export const deleteSteal = async (stealId, accessToken) => {
  const response = await axios.delete(`/steals/${stealId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    }
  });
  if (response.status !== 201) return null;
  return response.data;
};
