import React, { Component } from 'react';
import { Table, Row, Col, FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import Button from '../../elements/CustomButton/CustomButton.jsx';
import { validateInputs } from '../../helper';
import Select from 'react-select';
import 'react-day-picker/lib/style.css';
import api from '../../api/index.js';

// TODO: api issue, only enable when fixed
const DISABLE_EDIT_ACCOUNT = true;

class AccountRow extends Component {
  constructor(props) {
    super(props);

    const account = this.props.account;
    this.state = {
      isEditing: false,
      isFormLoading: false,
      username: account.Username,
      email: account.Email,
      password: '',
      confirmPassword: '',
      originalPermittedBranches: [],
      permittedBranches: [],
      branches: [],
      selectedMerchant: null,
      selectedBranch: null,
      selectedRole: null,
    };

    this.handleEdit = this.handleEdit.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddPermission = this.handleAddPermission.bind(this);
    this.deletePermission = this.deletePermission.bind(this);
  }

  handleChangeMerchant = (e) => {
    if (!e) {
      return;
    }
    this.setState({ selectedMerchant: e });
    this.loadBranches(e.value)
  }

  loadBranches = async (merchantId) => {
    const { token } = this.props.auth;
    try {
      let branches = await api.fetchBranchesByMerchantId(merchantId, token);
      this.setState({
        branches,
      });
    } catch (error) {
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{error.message}</strong>,
        level: 'error'
      });
    }
  }

  deletePermission(i) {
    const permittedBranches = this.state.permittedBranches.slice();
    permittedBranches.splice(i, 1);
    this.setState({ permittedBranches });
  }

  handleAddPermission() {
    const selectedBranch = this.state.selectedBranch;
    const selectedRole = this.state.selectedRole;

    const permittedBranches = this.state.permittedBranches;
    permittedBranches.push({
      MerchantBranchId: selectedBranch.value,
      BranchName: selectedBranch.label,
      Role: { RoleId: selectedRole.value, RoleName: selectedRole.label }
    });

    this.setState({ permittedBranches });
  }

  handleDelete = async () => {
    if (!window.confirm('Are you sure you want to delete?')) {
      return;
    }
    this.setState({ isFormLoading: true });
    try {
      const response = await api.deleteAccount(this.props.account.Id, this.props.token);
      console.log(response);
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-like2" />,
        message: <strong>Account deleted.</strong>,
        level: 'success'
      });
      this.props.loadAccounts();
    } catch (error) {
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{error.message}</strong>,
        level: 'error'
      });
      this.setState({ isExpanded: false });
    } finally {
      this.setState({ isFormLoading: false });
    }
  }

  handleEdit = async () => {
    this.setState({ isEditing: true, isFormLoading: true });
    try {
      const branches = await api.fetchAccountMerchantBranches(this.props.account.Id, this.props.token);
      console.log(branches);
      this.setState({ originalPermittedBranches: branches, permittedBranches: branches, isFormLoading: false });
    } catch (error) {
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{error.message}</strong>,
        level: 'error'
      });
      this.setState({ isEditing: false, isFormLoading: false });
    }
  }

  handleSave = async (e) => {
    if (!window.confirm('Are you sure you want to save?')) {
      return;
    }
    const isInputsValid = validateInputs(e.target.closest('tbody'), this);
    if (!isInputsValid) {
      return;
    }

    const accountId = this.props.account.Id;
    const username = this.state.username;
    const email = this.state.email;
    const password = this.state.password;

    try {
      if (!DISABLE_EDIT_ACCOUNT) {
        const data = {
          id: accountId,
          username,
          email,
        };
        const response = await api.updateAccount(data, this.props.token);
        console.log(response);
      }

      if (password !== '') {
        if (password !== this.state.confirmPassword) {
          throw new Error('Passwords do not match.');
        }
        const passwordData = {
          id: accountId,
          username,
          password,
        }
        const passwordResponse = await api.updateAccount(passwordData, this.props.token);
        console.log(passwordResponse);
      }

      const addedBranches = this.state.permittedBranches.filter((branch) => branch.Id === undefined);
      const removedBranches = this.state.originalPermittedBranches.filter((branch) => {
        return !this.state.permittedBranches.some((b) => b.Id === branch.Id)
      });

      await Promise.all(addedBranches.map((branch) => api.createAccountMerchantBranch(accountId, branch.MerchantBranchId, branch.Role.RoleId, this.props.token)));
      await Promise.all(removedBranches.map((branch) => api.deleteAccountMerchantBranch(branch.Id, branch.Role.Id, this.props.token)));

      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-like2" />,
        message: <strong>Account updated.</strong>,
        level: 'success'
      });

      this.setState({
        isEditing: false,
        password: '',
        confirmPassword: ''
      });
      this.props.loadAccounts();
    } catch (error) {
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{error.message}</strong>,
        level: 'error'
      });
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleCheck = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  }

  handleCancel = () => {
    const account = this.props.account;
    this.setState({
      isEditing: false,
      username: account.Username,
      email: account.Email,
      password: '',
      confirmPassword: '',
      originalPermittedBranches: [],
      permittedBranches: [],
      selectedBranch: null,
      selectedRole: null,
    });
  }

  render() {
    const account = this.props.account;
    return (
      <tbody>
        <tr className={this.state.isEditing ? 'is-editing-row' : ''}>
          <td>{this.props.i + 1}</td>
          <td>{account.Username}</td>
          <td>{account.Email}</td>
          <td className="text-nowrap">
            <Button className="table-action-button action-button btn-fill"
              bsStyle="warning"
              type="button"
              onClick={this.state.isEditing ? this.handleSave : this.handleEdit}
            >
              {this.state.isEditing ? <i className="fas fa-check"></i> : <i className="fas fa-edit"></i>}
            </Button>
            {this.state.isEditing ?
              <Button className="table-action-button action-button btn-fill"
                bsStyle="warning"
                type="button"
                onClick={this.handleCancel}>
                <i className="fas fa-times"></i>
              </Button> : null
            }
            <Button className="table-action-button action-button btn-fill"
              bsStyle="danger"
              type="button"
              onClick={this.handleDelete}
            >
              <i className="fas fa-trash"></i>
            </Button>
          </td>
        </tr>
        {this.state.isEditing ?
          <tr>
            <td colSpan={11}>
              <FormGroup>
                <ControlLabel>Enter Username</ControlLabel>
                <FormControl
                  type="text"
                  placeholder="Username"
                  name="username"
                  disabled
                  value={this.state.username}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <ControlLabel>Enter Email</ControlLabel>
                <FormControl
                  type="email"
                  placeholder="Email"
                  name="email"
                  disabled={DISABLE_EDIT_ACCOUNT}
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <ControlLabel>Enter New Password</ControlLabel>
                <FormControl
                  type="password"
                  placeholder="New Password"
                  name="password"
                  value={this.state.password}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <ControlLabel>Confirm Password</ControlLabel>
                <FormControl
                  type="password"
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  value={this.state.confirmPassword}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <h4>Permissions</h4>
              <Row>
                <Col sm={4}>
                  <FormGroup>
                    <ControlLabel>Select Merchant</ControlLabel>
                    <Select
                      options={this.props.merchants.map((merchant) => {
                        return {
                          value: merchant.Id,
                          label: merchant.Name
                        }
                      })}
                      multi={false}
                      placeholder="select merchant"
                      value={this.state.selectedMerchant}
                      onChange={this.handleChangeMerchant}
                    />
                  </FormGroup>
                </Col>
                <Col sm={4}>
                  <FormGroup>
                    <ControlLabel>Select Branch</ControlLabel>
                    <Select
                      options={this.state.branches.map((branch) => {
                        return {
                          value: branch.Id,
                          label: branch.BranchName
                        }
                      })}
                      multi={false}
                      placeholder="select branch"
                      value={this.state.selectedBranch}
                      onChange={(val) => {
                        this.setState({ selectedBranch: val });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col sm={4}>
                  <FormGroup>
                    <ControlLabel>Select Role</ControlLabel>
                    <Select
                      options={[
                        { value: 1, label: 'admin' },
                        { value: 2, label: 'merchant-manager' },
                        { value: 3, label: 'merchant-staff' },
                        { value: 4, label: 'merchant-user' },
                        { value: 5, label: 'event-manager' },
                      ]}
                      multi={false}
                      placeholder="select role"
                      value={this.state.selectedRole}
                      onChange={(val) => {
                        this.setState({ selectedRole: val });
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Button className="table-action-button action-button btn-fill"
                bsStyle="warning"
                type="button"
                onClick={this.handleAddPermission}>
                <i className="fas fa-plus"></i> Add Permission
              </Button>
              <Row>
                <Col md={12}>
                  <Table striped hover responsive>
                    <thead>
                      <tr>
                        <th>Branches</th>
                        <th>Roles</th>
                        <th width="10%"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.permittedBranches.map((branch, i) => {
                        return (
                          <tr key={i}>
                            <td>{branch.BranchName}</td>
                            {/* <td>{JSON.stringify(permissions.writable).replace(/[[\]"]/g, '')}</td> */}
                            <td>{branch.Role.RoleName}</td>
                            <td width="10%">
                              <Button className="table-action-button action-button btn-fill"
                                bsStyle="danger"
                                type="button"
                                onClick={() => {
                                  this.deletePermission(i);
                                }}
                              >
                                <i className="fas fa-trash"></i>
                              </Button>
                            </td>
                          </tr>
                        )
                      })
                      }
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <FormGroup>
                <Button className="table-action-button action-button btn-fill"
                  bsStyle="warning"
                  type="button"
                  onClick={this.handleSave}>
                  <i className="fas fa-check"></i>
                </Button>
                <Button className="table-action-button action-button btn-fill"
                  bsStyle="warning"
                  type="button"
                  onClick={this.handleCancel}>
                  <i className="fas fa-times"></i>
                </Button>
              </FormGroup>
            </td>
          </tr> : null
        }
      </tbody>
    );
  }
}

export default AccountRow;
