import React, { Component } from 'react';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import ProperCard from '../../components/Card/ProperCard.jsx';
import Button from '../../elements/CustomButton/CustomButton.jsx';
import { connect } from 'react-redux';
import api from '../../api';

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFormLoading: false,
            customerEmail: '',
            oldPassword: '',
            password: '',
            confirmPassword: ''
        };
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleReset = async () => {
        const { token } = this.props.auth;
        try {
            this.setState({isFormLoading: false});

            if (this.state.password !== this.state.confirmPassword) {
                throw new Error("Confirm password is not correct");
            }
            const password = this.state.password;
            const customer = await api.fetchCustomerByEmail(this.state.customerEmail, token);
            const customerGuid = customer.CustomerGuid;
            const result = await api.resetCustomerPassword(customerGuid, password, token);
            console.log(result);
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-like2" />,
                message: <strong>Successfully reset customer password.</strong>,
                level: 'success'
            });
        } catch (err) {
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-attention" />,
                message: <strong>{err.message}</strong>,
                level: 'error'
            });
        } finally {
            this.setState({isFormLoading: false});
        }
    }

    handleDelete = async () => {
        const { token } = this.props.auth;
        try {
            const customer = await api.fetchCustomerByEmail(this.state.customerEmail);
            const customerId = customer.Id;
            const result = await api.deleteCustomer(customerId, token);
            console.log(result);
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-like2" />,
                message: <strong>Successfully delete customer.</strong>,
                level: 'success'
            });
        } catch (err) {
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-attention" />,
                message: <strong>{err.message}</strong>,
                level: 'error'
            });
        }
    }

    render() {
        return (
            <div className="content" style={{ paddingLeft: 30, paddingRight: 30 }}>
                <ProperCard title="Users">
                    <form>
                        <div className={`loader-overlay ${this.state.isFormLoading ? '' : 'hidden'}`}>
                            <div className="loader" />
                        </div>
                        <FormGroup>
                            <ControlLabel>Enter Customer Email</ControlLabel>
                            <FormControl
                                type="text"
                                placeholder="Customer Email"
                                name="customerEmail"
                                value={this.state.customerEmail}
                                onChange={this.handleChange}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Enter New Password</ControlLabel>
                            <FormControl
                                type="password"
                                placeholder="Password"
                                name="password"
                                value={this.state.password}
                                onChange={this.handleChange}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Confirm Password</ControlLabel>
                            <FormControl
                                type="password"
                                placeholder="Confirm Password"
                                name="confirmPassword"
                                value={this.state.confirmPassword}
                                onChange={this.handleChange}
                                required
                            />
                        </FormGroup>
                        <Button
                            className="table-action-button action-button btn-fill"
                            bsStyle="warning"
                            type="button"
                            onClick={this.handleReset}>
                            Reset Password
                        </Button>
                        <Button
                            className="table-action-button action-button btn-fill"
                            bsStyle="danger"
                            type="button"
                            onClick={this.handleDelete}>
                            <i className="fas fa-trash" /> Delete User
                        </Button>
                    </form>
                </ProperCard>
            </div>
        )
    }
}

export default connect(state => ({
    auth: state.auth
}))(Users);
