import React, { Component } from 'react';
import {
    Route,
    Switch,
    Redirect
} from 'react-router-dom';
import NotificationSystem from 'react-notification-system';
import { jwtDecode } from "jwt-decode";

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Sidebar from '../../components/Sidebar/Sidebar';
import SelectBranch from '../../views/SelectBranch/SelectBranch.jsx'
import Admin from '../../views/Admin/Admin.jsx'

import { style } from '../../variables/Variables.jsx';

import appRoutes from '../../routes/app.jsx';
import api from '../../api';
import { connect } from 'react-redux';
import { logout, loadAccount, selectBranch, refreshToken } from '../../actions';
import Emitter from '../../service/Emitter.js';

class App extends Component {
    constructor(props) {
        super(props);
        this.notificationSystem = React.createRef();
        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        this.loadAccount();
        Emitter.on('token-expired', () => {
            this.logout();
        });
        Emitter.on('token-refreshed', (accessToken) => {
            console.log("Token refreshed", accessToken);
            this.props.dispatch(refreshToken(accessToken));
        });
    }

    componentWillUnmount() {
        Emitter.off('token-expired');
    }

    loadAccount = async () => {
        const { token, roles, branchesPermissions } = this.props.auth;

        if (!token) {
            this.setState({ isLoading: false });
            console.log("Missing token");
            this.logout();
            return;
        }

        const decodedToken = jwtDecode(token);
        const accountId = decodedToken.sub;
        // const expiredTimestamp = decodedToken.exp;
        // if (expiredTimestamp < Date.now() / 1000) {
        //     this.setState({ isLoading: false });
        //     console.log("Token expired");
        //     this.logout();
        //     return;
        // }

        if (!roles || !branchesPermissions) {
            this.setState({ isLoading: false });
            console.log("Invalid user");
            this.logout();
            return;
        }

        this.props.dispatch(loadAccount(branchesPermissions, roles));

        let branchId = this.props.auth.branchId;
        if (!branchId) {
            if (branchesPermissions.length == 0) {
                this.setState({ isLoading: false });
                this.logout();
            } else {
                branchId = branchesPermissions[0].id;
                this.props.dispatch(selectBranch(branchId));
            }
        }
        this.setState({ isLoading: false });
    }

    addNotification = (notificationObject) => {
        const notification = this.notificationSystem.current || this.notificationSystem;
        notification?.addNotification(notificationObject);
    }

    selectBranch = (branchId) => {
        this.props.dispatch(selectBranch(branchId));
        window.location.href = '#';
    }

    logout = () => {
        this.props.dispatch(logout());
    }

    loadBranch = async () => {
        const { token, branchId } = this.props.auth;
        try {
            const branch = await api.fetchBranch(branchId, token);
            this.setState({ branch });
        } catch (err) {
            console.log(err);
        }
    }

    componentDidUpdate(e) {
        if (window.innerWidth < 993 && e.history.location.pathname !== e.location.pathname && document.documentElement.className.indexOf('nav-open') !== -1) {
            document.documentElement.classList.toggle('nav-open');
        }
    }

    renderSwiches = () => {
        const { branchId, branchesPermissions, roles } = this.props.auth;
        let permissions;
        if (roles.includes('admin')) {
            permissions = {
                id: branchId,
                writable: [
                    "user profile",
                    "products",
                    "listings",
                    "events",
                    "transactions",
                ]
            };
        } else {
            permissions = branchesPermissions.find(p => p.id === branchId);
        }
        return appRoutes.filter((prop) => permissions.writable.includes(prop.name.toLowerCase())).map((prop, key) => {

            if (prop.redirect) {
                return (
                    <Redirect from={prop.path} to={prop.to} key={key} />
                );
            }

            return (
                <Route path={prop.path} key={key} render={
                    (props) => <prop.component {...props}
                        addNotification={this.addNotification}
                        token={this.props.auth.token}
                        accountId={this.props.auth.accountId}
                        roles={this.props.auth.roles} />} />
            );
        });
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div className="loader-fullscreen">
                    <div className="loader"></div>
                </div>
            );
        }

        return (
            <div className="wrapper">
                <NotificationSystem ref={ref => this.notificationSystem = ref} style={style} />
                <Switch>

                    {this.props.auth.roles.includes('admin') && <Route path="/admin" name="admin" render={(props) => {
                        return (<Admin {...props}
                            addNotification={this.addNotification}
                            token={this.props.auth.token}
                            accountId={this.props.auth.accountId}
                            roles={this.props.auth.roles}
                            logout={this.logout} />)
                    }} />}
                    <Route
                        path="/select"
                        name="SelectBranch"
                        render={(props) => (
                            <SelectBranch {...props} selectBranch={this.selectBranch} />
                        )} />
                    <Route
                        path="/"
                        name=""
                        render={(props) => (
                            <div>
                                <Sidebar {...this.props} logout={this.logout} />
                                <div id="main-panel" className="main-panel">
                                    <Header {...this.props} roles={this.props.auth.roles} logout={this.logout} />
                                    <Switch>{this.renderSwiches()}</Switch>
                                    <Footer />
                                </div>
                            </div>
                        )} />
                </Switch>
            </div>
        );
    }
}

export default connect(state => ({
    auth: state.auth
}))(App);
