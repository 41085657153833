import React, { Component } from 'react';
import { Grid, Row, Col, Table, Button, FormControl, FormGroup, ControlLabel } from 'react-bootstrap';
import moment from 'moment';

import Card from '../../components/Card/Card.jsx';
import GenerationSettingsRow from '../../components/GenerationSettingsRow/GenerationSettingsRow.jsx';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import Select from 'react-select';
import { validateInputs } from '../../helper';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import api from '../../api';
import { connect } from 'react-redux';

class GenerateListings extends Component {
    constructor(props) {
        super(props);

        const currentHour = moment().format('HH');
        const currentMinute = moment().format('mm');

        this.state = {
            isTableLoading: true,
            branchId: '',
            productId: '',
            offer: '',
            quantity: '',
            productOptions: [],
            productIdToProductMap: {},
            branchOptions: [],
            branchIdToBranchMap: {},
            postHour: currentHour,
            postMinute: currentMinute,
            removeHour: currentHour,
            removeMinute: currentMinute,
            currDays: [],
            numDays: 14,
            startDate: moment().format('DD/MM/YYYY'),
            generationSettings: [],
            bulkStartDate: moment().format('DD/MM/YYYY')

        };

        this.loadGenerationSettings();
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        const { token, branchesPermissions, roles } = this.props.auth;
        let branches = [];
        if (roles.includes('admin')) {
            branches = await api.fetchBranches(token);
        } else {
            branches = (await Promise.all(branchesPermissions.map(permission => api.fetchBranch(permission.id, token))))
                .filter(r => r !== null);
        }

        const branchIdToBranchMap = {}
        const branchOptions = [];

        const productIdToProductMap = {};
        const productOptions = [];

        let branchId = '';
        let productId = '';
        for (const branch of branches) {
            if (!branchId) {
                branchId = branch.Id;
            }

            branchIdToBranchMap[branch.Id] = branch;
            branchOptions.push({ label: branch.MerchantId + ' (' + branch.PlaceType + ')', value: branch.Id});
            // for (const product of branch.products) {

            //     if (!productId) {
            //         productId = product._id;
            //     }
            //     productIdToProductMap[product._id] = product;

            //     if (product.branchId === branchId) {
            //         productOptions.push({ label: product.name + ' ($' + product.price.toFixed(2) + ')', value: product._id});
            //     }
            // }
        }

        this.setState({branchId, branchOptions, branchIdToBranchMap, productId, productOptions, productIdToProductMap});
    }

    deleteSettings = (i) => {
        const generationSettings = this.state.generationSettings.slice();
        generationSettings.splice(i, 1);
        this.setState({generationSettings});
    }

    handleBulkStartDateChange = (selectedDay) => {
        this.handleDayChange('bulkStart', selectedDay);
    }

    handleStartDateChange = (selectedDay) => {
        this.handleDayChange('start', selectedDay);
    }

    handleRemoveDateChange = (selectedDay) => {
        this.handleDayChange('remove', selectedDay);
    }

    handleDayChange = (timeType, selectedDay) => {
        const date = moment(selectedDay).format('DD/MM/YYYY');
        const name = timeType + 'Date';
        this.setState({ [name]: date });
    }

    handleTimeChange = (e) => {
        const targetName = e.target.name;
        const targetValue = e.target.value;
        this.setState({ [targetName]: targetValue });
    }
    
    handleDateTimeChange = (timeType) => {
        const dateFieldName = timeType + 'Date';
        const hourFieldName = timeType + 'Hour';
        const minuteFieldName = timeType + 'Minute';

        const date = this.state[dateFieldName]
        const hour = this.state[hourFieldName]
        const minute = this.state[minuteFieldName]

        const timestamp = getUnixFromDateHourMinute(date, hour, minute);

        this.setState({
            [timeType + 'At']: timestamp
        });
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleBranchChange = (target) => {

        if (target == null) {
            // User pressed the x button which clears the input
            // We do not allow the input to be blank, so do not change the state  
            return;
        }
        const branch = this.state.branchIdToBranchMap[target.value];
        const productOptions = [];
        for (const product of branch.products) {
            productOptions.push({ label: product.name + ' ($' + product.price.toFixed(2) + ')', value: product._id});
        }

        const productId = branch.products[0] ?  branch.products[0]._id : null;
        this.setState({
            branchId: branch._id,
            productOptions,
            productId
        });
    }

    handleCheckAll = (e) => {
        const checkboxes = document.getElementsByName(e.target.value);
        const listingsToDelete = {};
        for (const checkbox of checkboxes) {
            const checked = e.target.checked;
            checkbox.checked = checked;
            listingsToDelete[checkbox.value] = checked;
        }
        this.setState({ listingsToDelete });
    }

    handleCheck = (e) => {
        const listingsToDelete = JSON.parse(JSON.stringify(this.state.listingsToDelete))        
        listingsToDelete[e.target.value] = e.target.checked;
        this.setState({ listingsToDelete });
    }

    handleAdd = (e) => {
        const isInputsValid = validateInputs(e.target.closest('form'), this);
        if (!isInputsValid) {
            return;
        }

        const productId = this.state.productId;
        const offer = this.state.offer;
        const quantity = this.state.quantity;
        const postHour = this.state.postHour;
        const postMinute = this.state.postMinute;
        const removeHour = this.state.removeHour;
        const removeMinute = this.state.removeMinute;
        const days = this.state.currDays.map((day) => {
            return day.value
        });
        const startDate = moment(this.state.startDate, 'DD/MM/YYYY').unix();
        const numDays = this.state.numDays;

        const body = JSON.stringify({
            productId,
            offer,
            quantity,
            postTime: postHour * 3600 + postMinute * 60,
            removeTime: removeHour * 3600 + removeMinute * 60,
            days: JSON.stringify(days),
            startDate,
            numDays
        });

        fetch('/generationSettings', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'access-token': this.props.token
            },
            body
        }).then((result) => {
            return result.json();
        }).then((result) => {
            if (result.status !== 201) {
                this.props.addNotification({
                    title: <span data-notify="icon" className="pe-7s-attention" />,
                    message: <strong>{result.details}</strong>,
                    level: 'error'
                });
                return;
            }
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-like2" />,
                message: <strong>{result.details}</strong>,
                level: 'success'
            });

            this.loadGenerationSettings();
        })
    }

    handleBulkUpdate = (e) => {
        const bulkStartDate = moment(this.state.bulkStartDate, 'DD/MM/YYYY').startOf('day').unix();

        const body = JSON.stringify({
            startDate: bulkStartDate
        });

        fetch('/generationSettings/date', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'access-token': this.props.token
            },
            body
        }).then((result) => {
            return result.json();
        }).then((result) => {
            if (result.status !== 200) {
                this.props.addNotification({
                    title: <span data-notify="icon" className="pe-7s-attention" />,
                    message: <strong>{result.details}</strong>,
                    level: 'error'
                });
                return;
            }
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-like2" />,
                message: <strong>{result.details}</strong>,
                level: 'success'
            });

            this.loadGenerationSettings();
        })
    }

    handleGenerateAll() {
        const body = JSON.stringify({
            generationSettingsIds: ['ALL']
        });

        fetch('/generationSettings/generate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'access-token': this.props.token
            },
            body
        }).then((result) => {
            return result.json();
        }).then((result) => {
            if (result.status !== 201) {
                this.props.addNotification({
                    title: <span data-notify="icon" className="pe-7s-attention" />,
                    message: <strong>{result.details}</strong>,
                    level: 'error'
                });
                return;
            }
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-like2" />,
                message: <strong>{result.details}</strong>,
                level: 'success'
            });

            this.loadGenerationSettings();
        })
    }

    loadGenerationSettings = async () => {
        try {
            const generationSettings = await api.fetchGenerationSettings(this.props.auth.token);
            this.setState({generationSettings});
        } catch (err) {
            console.log(err);
        }
    }

    render() {
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title="Generate Listings"
                                content={
                                    <div>
                                        <h4>Add Generation Settings</h4>
                                        <form id="generate-listings-form">
                                            <FormGroup>
                                                <ControlLabel>Select Branch</ControlLabel>
                                                <Select
                                                    name="branchId"
                                                    options={
                                                        this.state.branchOptions.map((option) => ({value : option.value, label:option.label}))
                                                    }
                                                    placeholder="Select Branch"
                                                    value={this.state.branchId}
                                                    onChange={this.handleBranchChange}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <ControlLabel>Select Product</ControlLabel>
                                                <Select
                                                    name="productId"
                                                    options={
                                                        this.state.productOptions.map((option) => ({value : option.value, label:option.label}))
                                                    }
                                                    placeholder="Select Product"
                                                    value={this.state.productId}
                                                    onChange={(target) => {
                                                        this.setState({productId: target? target.value : ''});
                                                    }}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <ControlLabel>Enter App Price</ControlLabel>
                                                <FormControl
                                                    type="number"
                                                    placeholder="App Price"
                                                    name="offer"
                                                    step="0.05"
                                                    min="0"
                                                    value={this.state.offer}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <ControlLabel>Enter Quantity</ControlLabel>
                                                <FormControl
                                                    type="number"
                                                    placeholder="Quantity"
                                                    name="quantity"
                                                    step="1"
                                                    min="0"
                                                    value={this.state.quantity}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <ControlLabel className="label-block" >Enter Post Time</ControlLabel>
                                                <FormControl
                                                    className="time-input datetime-input"
                                                    type="number"
                                                    name="postHour"
                                                    placeholder="hh"
                                                    min="0"
                                                    max="23"
                                                    step="1"
                                                    value={this.state.postHour}
                                                    onChange={this.handleChange}
                                                    data-time-type="post"
                                                    required
                                                />
                                                <span className="time-separator">:</span>
                                                <FormControl
                                                    className="time-input datetime-input"
                                                    type="number"
                                                    name="postMinute"
                                                    placeholder="mm"
                                                    min="0"
                                                    max="59"
                                                    step="1"
                                                    value={this.state.postMinute}
                                                    onChange={this.handleChange}
                                                    data-time-type="post"
                                                    required
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <ControlLabel className="label-block" >Enter Remove Time</ControlLabel>
                                                <FormControl
                                                    className="time-input datetime-input"
                                                    type="number"
                                                    name="removeHour"
                                                    placeholder="hh"
                                                    min={this.state.postHour}
                                                    max="23"
                                                    step="1"
                                                    value={this.state.removeHour}
                                                    onChange={this.handleChange}
                                                    data-time-type="remove"
                                                    required
                                                />
                                                <span className="time-separator">:</span>
                                                <FormControl
                                                    className="time-input datetime-input"
                                                    type="number"
                                                    name="removeMinute"
                                                    placeholder="mm"
                                                    min={this.state.postHour === this.state.removeHour ? this.state.postMinute : 0}
                                                    max="59"
                                                    step="1"
                                                    value={this.state.removeMinute}
                                                    onChange={this.handleChange}
                                                    data-time-type="remove"
                                                    required
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <ControlLabel>Select Days</ControlLabel>
                                                <Select
                                                    options={[
                                                        {value: 'mon', label: 'Mon'},
                                                        {value: 'tue', label: 'Tue'},
                                                        {value: 'wed', label: 'Wed'},
                                                        {value: 'thu', label: 'Thu'},
                                                        {value: 'fri', label: 'Fri'},
                                                        {value: 'sat', label: 'Sat'},
                                                        {value: 'sun', label: 'Sun'},
                                                    ]}
                                                    multi={true}
                                                    placeholder="select days"
                                                    value={this.state.currDays}
                                                    onChange={(val) => {
                                                        this.setState({currDays: val});
                                                    }}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <ControlLabel className="label-block" >Enter Start Date</ControlLabel>
                                                <DayPickerInput
                                                    inputProps={{
                                                        className: 'form-control date-input datetime-input',
                                                        name: 'startDate'
                                                    }}
                                                    value={this.state.startDate}
                                                    onDayChange={this.handleStartDateChange}
                                                    placeholder="DD/MM/YYYY"
                                                    format="DD/MM/YYYY"
                                                    formatDate={formatDate}
                                                    parseDate={parseDate}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <ControlLabel>Enter No. of Days</ControlLabel>
                                                <FormControl
                                                    type="number"
                                                    placeholder="No. of Days"
                                                    name="numDays"
                                                    step="1"
                                                    min="0"
                                                    value={this.state.numDays}
                                                    onChange={this.handleChange}
                                                    required
                                                />
                                            </FormGroup>
                                            <Button className="table-action-button action-button btn-fill"
                                                    bsStyle="warning"
                                                    type="button"
                                                    onClick={this.handleAdd}>
                                                <i className="fas fa-plus"></i> Add generation settings
                                            </Button>
                                            <Row>
                                                <Col md={12}>
                                                    <h4>Bulk Actions</h4>
                                                    <FormGroup>
                                                        <ControlLabel className="label-block" >Enter Start Date</ControlLabel>
                                                        <DayPickerInput
                                                            inputProps={{
                                                                className: 'form-control date-input datetime-input',
                                                                name: 'startDate'
                                                            }}
                                                            value={this.state.bulkStartDate}
                                                            onDayChange={this.handleBulkStartDateChange}
                                                            placeholder="DD/MM/YYYY"
                                                            format="DD/MM/YYYY"
                                                            formatDate={formatDate}
                                                            parseDate={parseDate}
                                                        />
                                                    </FormGroup>
                                                    <Button className="table-action-button action-button btn-fill"
                                                            bsStyle="warning"
                                                            type="button"
                                                            onClick={this.handleBulkUpdate}>
                                                        <i className="fas fa-edit"></i> Update All Dates
                                                    </Button>
                                                    <Button className="table-action-button action-button btn-fill"
                                                            bsStyle="warning"
                                                            type="button"
                                                            onClick={this.handleGenerateAll}>
                                                        <i className="fas fa-upload"></i> Generate All
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <h4>Manage Generation Settings / Generate Listings</h4>
                                                    <Table bordered hover responsive className="settings-table"> 
                                                        <thead>
                                                            <tr>
                                                                <th>branch</th>
                                                                <th>product</th>
                                                                <th>offer</th>
                                                                <th>quantity</th>
                                                                <th>post time</th>
                                                                <th>remove time</th>
                                                                <th>days</th>
                                                                <th>start date</th>
                                                                <th>no. of days</th>
                                                                <th>actions</th>
                                                            </tr>
                                                        </thead>
                                                        {
                                                            this.state.generationSettings.filter(settings => !!this.state.productIdToProductMap[settings.productId])
                                                            .filter(settings => !!this.state.branchIdToBranchMap[this.state.productIdToProductMap[settings.productId].branchId])
                                                            .map((settings, i) => {
                                                                const product = this.state.productIdToProductMap[settings.productId];
                                                                const branch = this.state.branchIdToBranchMap[product.branchId];
                                                                //TODO: remove this fix
                                                                settings.days = settings.days === "2" ? ["mon"] : settings.days;
                                                                return (
                                                                    <GenerationSettingsRow
                                                                        {...this.props}
                                                                        key={settings._id}
                                                                        settings={settings}
                                                                        product={product}
                                                                        branch={branch}
                                                                        loadGenerationSettings={this.loadGenerationSettings}
                                                                        token={this.props.token}/>
                                                                )
                                                            })
                                                        }
                                                    </Table>
                                                </Col>
                                            </Row>
                                        </form>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

function padToDoubleDigits(i) {
    const stringI = i.toString();
    let paddedStringI = stringI;
    for (let j = stringI.length; j < 2; j += 1) {
        paddedStringI = '0' + paddedStringI;
    }

    return paddedStringI;
}

function getUnixFromDateHourMinute(date, hour, minute) {
    // returns unix timestamp from date hour minute based on local timezone
    const timeString = date
            + padToDoubleDigits(hour)
            + padToDoubleDigits(minute);

    return moment(timeString, 'DD/MM/YYYYHHmm').unix();
}

export default connect(state => ({
    auth: state.auth
}))(GenerateListings);
