import React, { Component } from 'react';
import { OverlayTrigger, Tooltip, FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import moment from 'moment';

import Button from '../../elements/CustomButton/CustomButton.jsx';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { validateInputs, resetValidity } from '../../helper';

import 'react-day-picker/lib/style.css';
import {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import api from '../../api/index.js';

class ListingRow extends Component {
    constructor(props) {
        super(props);
        const postAtMoment = moment.unix(this.props.listing.PostAt);
        const removeAtMoment = moment.unix(this.props.listing.RemoveAt);

        this.state = {
            isEditing: false,
            isFormLoading: false,
            offer: this.props.listing.Offer,
            total: this.props.listing.ItemCount,
            remaining: this.props.listing.ItemRemaining,
            isAddOn: this.props.listing.IsAddOn || false,
            postAt: this.props.listing.PostAt,
            postDate: postAtMoment.format('DD/MM/YYYY'),
            postHour: postAtMoment.format('HH'),
            postMinute: postAtMoment.format('mm'),
            removeAt: this.props.listing.RemoveAt,
            removeDate: removeAtMoment.format('DD/MM/YYYY'),
            removeHour: removeAtMoment.format('HH'),
            removeMinute: removeAtMoment.format('mm')
        };

        this.handleEdit = this.handleEdit.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleDateTimeChange = this.handleDateTimeChange.bind(this);
        this.handlePostDateChange = this.handlePostDateChange.bind(this);
        this.handleRemoveDateChange = this.handleRemoveDateChange.bind(this);
        this.handleTimeChange = this.handleTimeChange.bind(this);
        this.handleDayChange = this.handleDayChange.bind(this);
    }

    handleEdit() {
        this.setState({ isEditing: true });
    }

    handleSave = async (e) => {
        if (!window.confirm('Are you sure you want to save?')) {
            return;
        }

        const isInputsValid = validateInputs(e.target.closest('tbody'), this);
        if (!isInputsValid) {
            return;
        }

        this.setState({ isFormLoading: true });

        const listingId = this.props.listing.Id
        const data = {
            offer: this.state.offer,
            itemCount: this.state.total,
            itemRemaining: this.state.remaining,
            isAddOn: this.state.isAddOn,
            postAt: this.state.postAt,
            removeAt: this.state.removeAt,
        };

        try {
            const result = await api.updateListing(listingId, data, this.props.token);
            console.log(result);
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-like2" />,
                message: <strong>Listing updated.</strong>,
                level: 'success'
            });
            this.setState({ isEditing: false, isFormLoading: false });
            this.props.loadListings();
        } catch (error) {
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-attention" />,
                message: <strong>{error.message}</strong>,
                level: 'error'
            });
            this.setState({ isFormLoading: false });
        }
    }

    handlePostDateChange(selectedDay) {
        this.handleDayChange('post', selectedDay);
    }

    handleRemoveDateChange(selectedDay) {
        this.handleDayChange('remove', selectedDay);
    }

    handleDayChange(timeType, selectedDay) {
        resetValidity(document.getElementById('edit-listing-form'));
        const date = moment(selectedDay).format('DD/MM/YYYY');
        const name = timeType + 'Date';
        this.setState({ [name]: date }, () => {
            this.handleDateTimeChange(timeType);
        });
    }

    handleTimeChange(e) {
        const targetName = e.target.name;
        const targetValue = e.target.value;
        const timeType = e.target.getAttribute('data-time-type');
        this.setState({ [targetName]: targetValue }, () => {
            this.handleDateTimeChange(timeType);
        });
    }

    handleDateTimeChange(timeType) {
        const dateFieldName = timeType + 'Date';
        const hourFieldName = timeType + 'Hour';
        const minuteFieldName = timeType + 'Minute';

        const date = this.state[dateFieldName]
        const hour = this.state[hourFieldName]
        const minute = this.state[minuteFieldName]

        const timestamp = getUnixFromDateHourMinute(date, hour, minute);

        this.setState({
            [timeType + 'At']: timestamp
        });
    }

    handleCheckbox = (e) => {
        this.setState({[e.target.name]: e.target.checked});
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleCancel = () => {
        const postAtMoment = moment.unix(this.props.listing.PostAt);
        const removeAtMoment = moment.unix(this.props.listing.RemoveAt);
        this.setState({
            isEditing: false,
            offer: this.props.listing.Offer,
            total: this.props.listing.ItemCount,
            remaining: this.props.listing.ItemRemaining,
            isAddOn: this.props.listing.IsAddOn,
            postAt: this.props.listing.PostAt,
            postDate: postAtMoment.format('DD/MM/YYYY'),
            postHour: postAtMoment.format('HH'),
            postMinute: postAtMoment.format('mm'),
            removeAt: this.props.listing.RemoveAt,
            removeDate: removeAtMoment.format('DD/MM/YYYY'),
            removeHour: removeAtMoment.format('HH'),
            removeMinute: removeAtMoment.format('mm')
        });
    }

    componentWillReceiveProps(nextProps) {
        const listing = nextProps.listing;
        const postAtMoment = moment.unix(listing.PostAt);
        const removeAtMoment = moment.unix(listing.RemoveAt);
        this.setState({
            offer: listing.Offer,
            total: listing.ItemCount,
            remaining: listing.ItemRemaining,
            isAddOn: listing.IsAddOn || false,
            postAt: listing.PostAt,
            postDate: postAtMoment.format('DD/MM/YYYY'),
            postHour: postAtMoment.format('HH'),
            postMinute: postAtMoment.format('mm'),
            removeAt: listing.RemoveAt,
            removeDate: removeAtMoment.format('DD/MM/YYYY'),
            removeHour: removeAtMoment.format('HH'),
            removeMinute: removeAtMoment.format('mm')
        });
    }

    render() {
        const product = this.props.product;
        let productImageCellContents;
        const activeImages = this.props.product.MerchantBranchProductImage.filter(image => image.IsActive);
        const photo = activeImages[0]?.ImageBlob;
        if (!photo) {
            productImageCellContents = <div className="no-field-text text-nowrap"><em>No Photo</em></div>;
        } else {
            const tooltip = (
                <Tooltip className="custom-tooltip" id={'tooltip-' + product.Id}>
                    <img className="product-image-zoomed" src={photo} alt="" />
                </Tooltip>
            );
            productImageCellContents = (
                <OverlayTrigger placement="right" overlay={tooltip}>
                    <img className="product-image" src={photo} alt="" />
                </OverlayTrigger>
            );
        }

        const i = this.props.i;
        return (
            <tbody>
                <tr className={this.state.isEditing ? 'is-editing-row' : ''}>
                    <td className="checkbox-cell"><input type="checkbox" name={this.props.checkboxType} value={this.props.listing.Id} onChange={this.props.handleCheck} /></td>
                    <td>{i + 1}</td>
                    <td>{productImageCellContents}</td>
                    <td>{product.Name}</td>
                    <td>{product.Currency} {Number(product.Price).toFixed(2)}</td>
                    <td>{this.props.listing.Offer.toFixed(2)}</td>
                    <td>{this.props.listing.ItemCount}</td>
                    <td>{this.props.listing.ItemRemaining}</td>
                    <td>
                        <input className="admin-checkbox" type="checkbox"
                            name="isAddOn"
                            disabled
                            checked={this.state.isAddOn} />
                        <span className="admin-label" >Add-on</span>
                    </td>
                    <td>{moment.unix(this.props.listing.PostAt).format('ddd, D MMM YYYY, HH:mm')}</td>
                    <td>{moment.unix(this.props.listing.RemoveAt).format('ddd, D MMM YYYY, HH:mm')}</td>
                    <td className="text-nowrap">
                        <Button className="table-action-button action-button btn-fill"
                            bsStyle="warning"
                            type="button"
                            onClick={this.state.isEditing ? this.handleSave : this.handleEdit}
                        >
                            {this.state.isEditing ? <i className="fas fa-check"></i> : <i className="fas fa-edit"></i>}
                        </Button>
                        {this.state.isEditing ?
                            <Button className="table-action-button action-button btn-fill"
                                bsStyle="warning"
                                type="button"
                                onClick={this.handleCancel}>
                                <i className="fas fa-times"></i>
                            </Button> : null
                        }
                    </td>
                </tr>
                {this.state.isEditing &&
                    <tr>
                        <td colSpan={12}>
                            <div className={'loader-overlay ' + (this.state.isFormLoading ? '' : 'hidden')}><div className="loader"></div></div>
                            <FormGroup>
                                <ControlLabel>Enter App Price</ControlLabel>
                                <FormControl
                                    type="number"
                                    placeholder="App Price"
                                    name="offer"
                                    onChange={this.handleChange}
                                    value={this.state.offer}
                                    step="0.01"
                                    min="0"
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>Enter Total</ControlLabel>
                                <FormControl
                                    type="number"
                                    placeholder="Total"
                                    name="total"
                                    onChange={this.handleChange}
                                    value={this.state.total}
                                    step="1"
                                    min="0"
                                    required
                                    disabled={!this.props.roles.includes('admin')}
                                />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>Enter Remaining</ControlLabel>
                                <FormControl
                                    type="number"
                                    placeholder="Remaining"
                                    name="remaining"
                                    onChange={this.handleChange}
                                    value={this.state.remaining}
                                    step="1"
                                    max={this.state.total}
                                    min="0"
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <input className="admin-checkbox" type="checkbox"
                                    name="isAddOn"
                                    checked={this.state.isAddOn}
                                    onChange={this.handleCheckbox} />
                                <span className="admin-label" >Add-on</span>
                            </FormGroup>
                            <FormGroup className="text-nowrap">
                                <ControlLabel className="label-block" >Enter Post At</ControlLabel>
                                <FormControl
                                    className="hidden"
                                    placeholder="Post At"
                                    name="postAt"
                                    value={this.state.postAt}
                                    onChange={this.handleChange}
                                />
                                <DayPickerInput
                                    inputProps={{
                                        className: 'form-control date-input datetime-input',
                                        name: 'postDate'
                                    }}
                                    value={this.state.postDate}
                                    onDayChange={this.handlePostDateChange}
                                    placeholder="DD/MM/YYYY"
                                    format="DD/MM/YYYY"
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                />
                                <FormControl
                                    className="time-input datetime-input"
                                    type="number"
                                    name="postHour"
                                    placeholder="hh"
                                    min="0"
                                    max="23"
                                    step="1"
                                    value={this.state.postHour}
                                    onChange={this.handleTimeChange}
                                    data-time-type="post"
                                    required
                                />
                                <span className="time-separator">:</span>
                                <FormControl
                                    className="time-input datetime-input"
                                    type="number"
                                    name="postMinute"
                                    placeholder="mm"
                                    min="0"
                                    max="59"
                                    step="1"
                                    value={this.state.postMinute}
                                    onChange={this.handleTimeChange}
                                    data-time-type="post"
                                    required
                                />
                            </FormGroup>
                            <FormGroup className="text-nowrap">
                                <ControlLabel className="label-block">Enter Remove At</ControlLabel>
                                <FormControl
                                    className="hidden"
                                    placeholder="Remove At"
                                    name="removeAt"
                                    value={this.state.removeAt}
                                    onChange={this.handleChange}
                                />
                                <DayPickerInput
                                    inputProps={{
                                        className: 'form-control date-input datetime-input',
                                        name: 'removeDate'
                                    }}
                                    value={this.state.removeDate}
                                    onDayChange={this.handleRemoveDateChange}
                                    placeholder="DD/MM/YYYY"
                                    format="DD/MM/YYYY"
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                />
                                <FormControl
                                    className="time-input datetime-input"
                                    type="number"
                                    name="removeHour"
                                    placeholder="hh"
                                    min={moment(this.state.postDate, 'DD/MM/YYYY').isSame(moment(this.state.removeDate, 'DD/MM/YYYY')) ? this.state.postHour : 0}
                                    max="23"
                                    step="1"
                                    value={this.state.removeHour}
                                    onChange={this.handleTimeChange}
                                    data-time-type="remove"
                                    required
                                />
                                <span className="time-separator">:</span>
                                <FormControl
                                    className="time-input datetime-input"
                                    type="number"
                                    name="removeMinute"
                                    placeholder="mm"
                                    min={moment(this.state.postDate, 'DD/MM/YYYY').isSame(moment(this.state.removeDate, 'DD/MM/YYYY')) && this.state.postHour === this.state.removeHour ? this.state.postMinute : 0}
                                    max="59"
                                    step="1"
                                    value={this.state.removeMinute}
                                    onChange={this.handleTimeChange}
                                    data-time-type="remove"
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Button className="table-action-button action-button btn-fill"
                                    bsStyle="warning"
                                    type="button"
                                    onClick={this.handleSave}>
                                    <i className="fas fa-check"></i>
                                </Button>
                                <Button className="table-action-button action-button btn-fill"
                                    bsStyle="warning"
                                    type="button"
                                    onClick={this.handleCancel}>
                                    <i className="fas fa-times"></i>
                                </Button>
                            </FormGroup>
                        </td>
                    </tr>
                }
            </tbody>
        );
    }
}

function padToDoubleDigits(i) {
    const stringI = i.toString();
    let paddedStringI = stringI;
    for (let j = stringI.length; j < 2; j += 1) {
        paddedStringI = '0' + paddedStringI;
    }

    return paddedStringI;
}

function getUnixFromDateHourMinute(date, hour, minute) {
    // returns unix timestamp from date hour minute based on local timezone
    const timeString = date
        + padToDoubleDigits(hour)
        + padToDoubleDigits(minute);

    return moment(timeString, 'DD/MM/YYYYHHmm').unix();
}

export default ListingRow;
