import React, { Component } from 'react';
import 'react-select/dist/react-select.css';
import {
    Route,
    Switch,
    Redirect
} from 'react-router-dom';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import AdminSidebar from '../../components/AdminSidebar/AdminSidebar';

import adminRoutes from '../../routes/admin.jsx';

class Admin extends Component {
    render() { 
        return (
            <div className="wrapper">
                <AdminSidebar {...this.props} />
                <div id="main-panel" className="main-panel">
                    <Header {...this.props} />
                        <Switch>
                            {
                                adminRoutes.map((prop,key) => {
                                    if (prop.redirect) {
                                        return <Redirect from={prop.path} to={prop.to} key={key} />;
                                    }

                                    return (
                                        <Route path={prop.path} key={key} render={
                                            (props) => <prop.component {...props}
                                                {...this.props}
                                                token={this.props.token}
                                                accountId={this.props.accountId}
                                                roles={this.props.roles} />}/>
                                    );
                                })
                            }
                        </Switch>
                    <Footer />
                </div>
            </div>
        );
    }
}

export default Admin;
