import Dashboard from '../views/Dashboard/Dashboard';
import UserProfile from '../views/UserProfile/UserProfile';
import Products from '../views/Products/Products';
import Listings from '../views/Listings/Listings';
import Transactions from '../views/Transactions/Transactions';
import Events from '../views/Events/Events';

const appRoutes = [
    { path: "/dashboard", name: "Dashboard", icon: "pe-7s-graph", component: Dashboard },
    { path: "/user", name: "User Profile", icon: "pe-7s-user", component: UserProfile },
    { path: "/products", name: "Products", icon: "pe-7s-coffee", component: Products },
    { path: "/listings", name: "Listings", icon: "pe-7s-note2", component: Listings },
    { path: "/events", name: "Events", icon: "pe-7s-note2", component: Events },
    { path: "/transactions", name: "Transactions", icon: "pe-7s-repeat", component: Transactions },
    { redirect: true, path:"/", to:"/dashboard", name: "Dashboard" },
    { redirect: true, path:"/", to:"/user", name: "User Profile" },
    { redirect: true, path:"/", to:"/products", name: "Products" },
    { redirect: true, path:"/", to:"/listings", name: "Listings" },
    { redirect: true, path:"/", to:"/events", name: "Events" },
    { redirect: true, path:"/", to:"/redemptions", name: "Redemptions" },
    { redirect: true, path:"/", to:"/transactions", name: "Transactions" },
];

export default appRoutes;
