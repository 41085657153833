import React, { Component } from 'react';
import { Grid, Row, Col, Table, Button, FormControl, ControlLabel, FormGroup, Tab, Tabs } from 'react-bootstrap';
import { validateInputs } from '../../helper';
import moment from 'moment';
import Select from 'react-select';
import _ from 'lodash';

import Card from '../../components/Card/Card.jsx';
import OrderRow from '../../components/OrderRow/OrderRow.jsx';

import api from '../../api';
import { unixToExcelTime } from '../../helper'
import ReactExport from "react-data-export";
import { connect } from 'react-redux';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ORDER_STATUS = {
    PENDING: 'PENDING',
    CONFIRMED: 'CONFIRMED',
    COMPLETED: 'COMPLETED'
}

class CombinedOrders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            currentTab: 1,
            orders: [],
            branches: [],
            branchOptions: [],
            listingOptions: [],
            checkedOrders: [],
            customerId: '',
            branchId: '',
            listingId: '',
            quantity: '',
        };
    }

    componentDidMount() {
        this.loadActiveListings().then(this.loadOrders);
    }

    loadActiveListings = async () => {
        const { token, branchesPermissions, roles } = this.props.auth;
        try {
            let listings = await api.fetchListings({ limit: 50 }, token);
            // Check permission for branches
            if (!roles.includes('admin')) {
                listings = listings.filter(listing => branchesPermissions.some(p => p.id === listing.product.branch._id));
            }
            const currentTime = new Date().getTime() / 1000;
            const ONE_WEEK = 7 * 86400;
            // Add only those listings that are ORDERs and are active OR expired within the last week
            listings = listings.filter(listing => listing.postAt <= currentTime && currentTime - ONE_WEEK < listing.removeAt && listing.saleType === "ORDER");
            const branches = listings.map(listing => listing.product.branch).reduce((list, item) => {
                return list.some(b => b._id === item._id) ? list : [...list, item];
            }, []);
            this.setState({
                listings,
                branches,
                branchId: branches.length > 0 ? branches[0]._id : '',
                branchOptions: branches.map(b => ({ value: b._id, label: b.placeType })),
                isLoading: false
            }, this.updateListingOptions);
        } catch (err) {
            console.log(err);
            this.setState({ isLoading: false });
        }
    }

    loadOrders = async () => {
        const { token } = this.props.auth;
        this.setState({ isLoading: true });
        let orders = [];

        try {
            orders = await api.fetchOrders(token);
            orders = orders.filter(o => !!o.listing)
                .map(order => {
                    const branch = this.state.branches.find(b => b._id === order.listing.product.branchId);
                    const placeType = branch ? branch.placeType : '';
                    return { ...order, branchName: placeType };
                })
                .sort((a, b) => b.CreatedAt - a.CreatedAt);
        } catch (error) {
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-attention" />,
                message: <strong>{error.message}</strong>,
                level: 'error'
            });
        } finally {
            this.setAllCheckboxes(false);
            this.setState({
                orders,
                isLoading: false,
            });
        }
    }

    updateListingOptions = () => {
        let branchId = this.state.branchId;
        const branchListings = this.state.listings.filter(l => l.product.branch._id === branchId);
        if (!branchListings) {
            return;
        }
        let listingOptions = branchListings.map((listing) => {
            let listingName = listing.product.name + moment(listing.postAt * 1000).format(' (DD/MM/YY HH:mm - ') + moment(listing.removeAt * 1000).format('DD/MM/YY HH:mm)');
            return {
                value: listing._id,
                label: listingName,
                postAt: listing.postAt,
                removeAt: listing.removeAt,
            }
        })
        listingOptions.sort((a, b) => {
            return b.postAt - a.postAt;
        })
        this.setState({ listingOptions });
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleAdd = async (e) => {
        const isInputsValid = validateInputs(e.target.closest('form'), this);
        if (!isInputsValid) {
            return;
        }

        const data = {
            listingId: this.state.listingId,
            quantity: parseInt(this.state.quantity, 10)
        }

        try {
            await api.createOrder(this.state.customerId, data, this.props.token);
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-like2" />,
                message: <strong>Successfully added order</strong>,
                level: 'success'
            });
            this.loadOrders();
        } catch (err) {
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-like2" />,
                message: <strong>{err.message}</strong>,
                level: 'error'
            });
        }
    }

    handleCheck = (e) => {
        // Each checkbox has custom data-attributes for listingId and customerId
        const orderKey = {
            listingId: e.target.dataset.listingId,
            customerId: e.target.dataset.customerId
        }
        // A function to match orders by the orderKey
        const findOrderByKey = (order) => {
            return order.listing.listingId === orderKey.listingId && order.customer.customerId === orderKey.customerId;
        }
        const checkedOrders = this.state.checkedOrders;
        const order = this.state.orders.find(findOrderByKey);

        if (!order) {
            return; // This should never happen but just in case
        }

        if (e.target.checked && !checkedOrders.find((e) => _.isEqual(e, order))) {
            checkedOrders.push(order);
        } else if (!e.target.checked && checkedOrders.find(e => _.isEqual(e, order))) {
            checkedOrders.splice(checkedOrders.findIndex(e => _.isEqual(e, order)), 1);
        }

        this.setState({ checkedOrders });
    }

    batchOperation = (newStatus) => {
        if (!window.confirm('Are you sure you want to make the changes?')) {
            return;
        }
        let pendingResults = [];
        const checkedOrders = this.state.checkedOrders;
        checkedOrders.forEach((order) => {
            const customerId = order.customer._id;
            const listingId = order.listing._id;
            const quantity = order.quantity;

            const body = JSON.stringify({
                customerId,
                listingId,
                quantity,
                status: newStatus
            });

            pendingResults.push(fetch('/orders', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'access-token': this.props.token
                },
                body
            }));

        });
        Promise.all(pendingResults)
            .then((result) => {
                return result
            }).then((results) => {
                let failedResult = -1;
                results.forEach((r, i) => {
                    if (r.status !== 200) {
                        // Keep track of 1st failed result to display error
                        failedResult = i;
                    }
                })
                if (failedResult === -1) {
                    this.props.addNotification({
                        title: <span data-notify="icon" className="pe-7s-like2" />,
                        message: <strong>Successfully updated orders</strong>,
                        level: 'success'
                    });
                } else {
                    this.props.addNotification({
                        title: <span data-notify="icon" className="pe-7s-attention" />,
                        message: <strong>{results[failedResult].details}</strong>,
                        level: 'error'
                    });
                }
                this.setAllCheckboxes(false)
                this.loadOrders();
            });
    }

    setAllCheckboxes = (value) => {
        // Set all the checkboxes
        for (const checkbox of document.getElementsByName('order-checkbox')) {
            checkbox.checked = value;
        }
        // Also set the state
        let checkedOrders = [];
        if (value) {
            let currentStatus = this.state.currentTab === 1 ? 'PENDING' : 'CONFIRMED';
            // Only check the orders for the currently open tab
            checkedOrders = this.state.orders.filter(order => order.status === currentStatus);
        }
        this.setState({ checkedOrders })
    }

    handleTabChange = (key) => {
        this.setState({ currentTab: key }, this.setAllCheckboxes(false));
    }

    render() {
        const isAdmin = this.props.roles.includes('admin');

        const pendingOrders = [];
        const confirmedOrders = [];
        const completedOrders = [];

        this.state.orders.forEach((order) => {
            if (order.status === ORDER_STATUS.PENDING) {
                pendingOrders.push(order);
            } else if (order.status === ORDER_STATUS.CONFIRMED) {
                confirmedOrders.push(order);
            } else if (order.status === ORDER_STATUS.COMPLETED) {
                completedOrders.push(order);
            }
        });
        console.log(this.state.orders);

        const pendingOrdersTab = (
            <Tab eventKey={1} title="Pending Orders">
                <h4>Pending Orders</h4>
                <Table hover bordered responsive className="orders-table">
                    <thead>
                        <tr>
                            <th className="checkbox-cell"><input type="checkbox" name="order-checkbox" onChange={(e) => this.setAllCheckboxes(e.target.checked)} /></th>
                            <th>#</th>
                            <th>Order Id</th>
                            <th>Customer Id</th>
                            <th>Customer Email</th>
                            <th>Customer Username</th>
                            <th>Branch Name</th>
                            <th>Photo</th>
                            <th>Name</th>
                            <th>App Price ($)</th>
                            <th>Qty</th>
                            <th>Ordered At</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    {
                        pendingOrders.map((order, i) => {
                            return <OrderRow {...this.props} key={order.listing._id + order.customer._id} order={order} i={i} loadOrders={this.loadOrders}
                                loadBranch={() => { }} token={this.props.token} showCheckbox={true} checkboxType={"order-checkbox"} handleCheck={this.handleCheck}
                                hidePrice isAdmin={isAdmin}/>;
                        })
                    }
                </Table>
                <br />
                <Button className="btn-fill multi-button " bsStyle="warning" type="button"
                    onClick={() => this.batchOperation('CONFIRMED')}>
                    <i className="fas fa-check"></i> Mark as confirmed
                </Button>
                <Button className="btn-fill multi-button" bsStyle="success" type="button"
                    onClick={() => this.batchOperation('COMPLETED')}>
                    <i className="fas fa-check-double"></i> Mark as completed
                </Button>
                <ExcelDownloadButton orders={pendingOrders}
                    filename={"Combined_Pending_Orders_" + moment().format('DMMM_HHmm')}
                    sheetName="Pending Orders" isAdmin={isAdmin} />
            </Tab>
        );

        const confirmedOrdersTab = (
            <Tab eventKey={2} title="Confirmed Orders">
                <h4>Confirmed Orders</h4>
                <Table hover bordered responsive className="orders-table">
                    <thead>
                        <tr>
                            <th className="checkbox-cell"><input type="checkbox" name="order-checkbox" onChange={(e) => this.setAllCheckboxes(e.target.checked)} /></th>
                            <th>#</th>
                            <th>Order Id</th>
                            <th>Customer Id</th>
                            <th>Customer Email</th>
                            <th>Customer Username</th>
                            <th>Branch Name</th>
                            <th>Photo</th>
                            <th>Name</th>
                            <th>App Price ($)</th>
                            <th>Quantity</th>
                            <th>Ordered At</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    {
                        confirmedOrders.map((order, i) => {
                            return <OrderRow {...this.props} key={order.listing._id + order.customer._id} order={order} i={i} loadOrders={this.loadOrders}
                                loadBranch={() => { }} showCheckbox={true} checkboxType={"order-checkbox"} 
                                token={this.props.token} handleCheck={this.handleCheck} hidePrice 
                                isAdmin={isAdmin}
                                />;
                        })
                    }
                </Table>
                <br />
                <Button className="btn-fill multi-button " bsStyle="warning" type="button"
                    onClick={() => this.batchOperation('PENDING')}>
                    <i className="fas fa-pause"></i> Mark as pending
                </Button>
                <Button className="btn-fill multi-button" bsStyle="success" type="button"
                    onClick={() => this.batchOperation('COMPLETED')}>
                    <i className="fas fa-check-double"></i> Mark as completed
                </Button>
                <ExcelDownloadButton orders={confirmedOrders}
                    filename={"Combined_Pending_Orders_" + moment().format('DMMM_HHmm')}
                    sheetName="Pending Orders" isAdmin={isAdmin} />
            </Tab>
        );

        const completedOrdersTab = (
            <Tab eventKey={3} title="Completed Orders">
                <h4>Completed Orders</h4>
                <Table hover bordered responsive className="orders-table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Order Id</th>
                            <th>Customer Id</th>
                            <th>Customer Email</th>
                            <th>Customer Username</th>
                            <th>Branch Name</th>
                            <th>Photo</th>
                            <th>Name</th>
                            <th>App Price ($)</th>
                            <th>Quantity</th>
                            <th>Ordered At</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    {
                        completedOrders.map((order, i) => {
                            return <OrderRow {...this.props} key={order.listing._id + order.customer._id} order={order} i={i} loadOrders={() => { }}
                                loadBranch={() => { }} showCheckbox={false} checkboxType={"order-checkbox"} 
                                token={this.props.token} hidePrice 
                                isAdmin={isAdmin}
                                />;
                        })
                    }
                </Table>
            </Tab>
        );
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title="Orders"
                                content={
                                    <div>
                                        <h4>Add order</h4>
                                        <form id="add-order-form" className={this.state.isLoading ? "hidden" : ""}>
                                            <FormGroup>
                                                <ControlLabel>Enter Customer Id</ControlLabel>
                                                <FormControl
                                                    type="text" placeholder="Customer Id" name="customerId"
                                                    value={this.state.customerId} onChange={this.handleChange} required />
                                            </FormGroup>
                                            <FormGroup>
                                                <ControlLabel>Select Branch</ControlLabel>
                                                <Select
                                                    placeholder="Select Branch"
                                                    name="branchId"
                                                    value={this.state.branchId}
                                                    onChange={(target) => {
                                                        this.setState({ branchId: target ? target.value : '' }, this.updateListingOptions);
                                                    }}
                                                    options={this.state.branchOptions}
                                                    required>
                                                </Select>
                                            </FormGroup>
                                            <FormGroup>
                                                <ControlLabel>Select Listing</ControlLabel>
                                                <Select
                                                    placeholder="Select Listing"
                                                    name="listingId"
                                                    value={this.state.listingId}
                                                    onChange={(target) => {
                                                        this.setState({ listingId: target ? target.value : '' });
                                                    }}
                                                    options={this.state.listingOptions}
                                                    required>
                                                </Select>
                                            </FormGroup>
                                            <FormGroup>
                                                <ControlLabel>Enter Quantity</ControlLabel>
                                                <FormControl
                                                    type="number" placeholder="Quantity" name="quantity" min="1" step="1"
                                                    value={this.state.quantity} onChange={this.handleChange} required />
                                            </FormGroup>
                                            <Button className="table-action-button action-button btn-fill"
                                                bsStyle="warning"
                                                type="button"
                                                onClick={this.handleAdd}>
                                                <i className="fas fa-plus"></i> Add order
                                            </Button>
                                        </form>
                                        <br />
                                        <form>
                                            {this.state.isLoading ? <div className="loader"></div> : null}
                                            <Tabs defaultActiveKey={1} id="orders-tab" className={this.state.isLoading ? "hidden" : ""}
                                                activeKey={this.state.currentTab} onSelect={this.handleTabChange}>
                                                {pendingOrdersTab}
                                                {confirmedOrdersTab}
                                                {completedOrdersTab}
                                            </Tabs>
                                        </form>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

function ExcelDownloadButton(props) {

    let { orders, filename, sheetName, isAdmin } = props;

    let columns = [
        { title: "Order ID", width: { wpx: 150 } },
        ...(isAdmin ?
            [{ title: "Customer ID", width: { wpx: 150 } },
            { title: "Customer Email", width: { wpx: 150 } },
            { title: "Customer Username", width: { wpx: 150 } }] : []
        ),
        { title: "Product Name", width: { wpx: 150 } },
        { title: "Price", width: { wpx: 60 } },
        { title: "App Price", width: { wpx: 60 } },
        { title: "Quantity", width: { wpx: 60 } },
        { title: "Ordered At", width: { wpx: 150 } },
        { title: "Status", width: { wpx: 100 } },
    ]

    let data = orders.map((order) => (
        [
            { value: order.Id + order.CreatedAt },
            ...(isAdmin ?
                [{ value: order.customer._id },
                { value: order.customer.email },
                { value: order.customer.username }] : []
            ),
            { value: order.listing.product.name },
            { value: order.listing.product.price, style: { numFmt: "$0.00" } },
            { value: order.listing.offer, style: { numFmt: "$0.00" } },
            { value: order.quantity },
            { value: unixToExcelTime(order.CreatedAt), style: { numFmt: "dd/mm/yyyy   hh:mm AM/PM" } },
            { value: order.status }
        ]
    ))

    const button = (
        <Button className="btn-fill multi-button" bsStyle="success" type="button" >
            <i className="fas fa-table"></i> Export to Excel
        </Button>);

    let dataSet = [
        { columns, data }
    ];
    return (
        <ExcelFile element={button} filename={filename}>
            <ExcelSheet dataSet={dataSet} name={sheetName} />
        </ExcelFile>
    );
}

export default connect(state => ({
    auth: state.auth
}))(CombinedOrders);
