import React, { Component } from 'react';
import { Thumbnail, Button, Well } from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import api from '../../api';


class Feedbacks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feedbacks: [],
      page: 1,
      pageSize: 10,
    };
  }

  componentDidMount() {
    this.loadFeedbacks();
  }

  loadFeedbacks = async () => {
    try {
      const { token } = this.props.auth;
      const { feedbacks, page, pageSize } = this.state;
      const data = await api.fetchFeedbacks(token, page, pageSize);
      const customers = await Promise.all(data.map(fb => api.fetchCustomer(Number(fb.CustomerId), token)));
      customers.map((c, index) => data[index].customer = c);
      data.sort((a, b) => b.CreatedAt - a.CreatedAt);
      this.setState({ feedbacks: feedbacks.concat(data), page: page + 1 });
    } catch (err) {
      console.log(err);
    }
  }

  deleteFeedback = async (feedbackId) => {
    try {
      const { token } = this.props.auth;
      const result = await api.deleteFeedback(feedbackId, token);
      console.log(result);
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-like2" />,
        message: <strong>Feedback deleted.</strong>,
        level: 'success'
      });
    } catch (error) {
      console.log(error);
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{error.message}</strong>,
        level: 'error'
      });
    }
  }

  render() {
    return (
      <div className="content">
        {this.state.feedbacks.map(feedback => (
          <FeedbackRow feedback={feedback} key={feedback.customerFeedbackId} onDelete={this.deleteFeedback} />)
        )}
        <Button
          className="table-action-button action-button btn-fill"
          bsStyle="warning"
          type="button"
          onClick={this.loadFeedbacks}
        >
          More
        </Button>
      </div>
    );
  }
}

const FeedbackRow = (props) => {
  const { feedback, onDelete } = props;
  return (
    <Thumbnail>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', marginBottom: 5 }}>
        <div><b>{feedback.customer.Username}</b>{` (${feedback.customer.Email})`}</div>
        <i>{moment(props.feedback.CreatedAt * 1000).format('ddd, D MMM YYYY, HH:mm')}</i>
      </div>
      <Well bsSize="small">
        <p>{feedback.Description}</p>
      </Well>
      <Button
        className="table-action-button action-button btn-fill"
        bsStyle="danger"
        type="button"
        onClick={() => onDelete(feedback.Id)}
      >
        <i className="fas fa-trash" /> Delete
      </Button>
    </Thumbnail>
  )
}

export default connect(state => ({
  auth: state.auth
}))(Feedbacks);
