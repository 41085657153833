import axios from "axios";
import Emitter from "./Emitter";

function createInstance() {
  const baseURL = null;
  const instance = axios.create({
    baseURL,
    validateStatus: () => {
      return true;
    },
  });

  instance.interceptors.response.use(
    async (response) => {
      let tokenExpired =
        response.status === 401 &&
        response.data.error.includes("Unauthorized") &&
        response.data.statusCode === 401;

      if (!tokenExpired) {
        return response;
      }

      console.log("token is expired");
      const refreshToken = localStorage.getItem("refreshToken");
      console.log("refreshToken", refreshToken);

      if (!refreshToken) {
        Emitter.emit("token-expired");
        return response;
      }

      try {
        const refreshResponse = await instance.post(
          "/auth/merchant-refresh",
          null,
          {
            headers: {
              Authorization: `Bearer ${refreshToken}`,
            },
          }
        );
        const accessToken = refreshResponse.data.access_token;
        response.config.headers["Authorization"] = `Bearer ${accessToken}`;
        Emitter.emit("token-refreshed", accessToken);
        localStorage.setItem("refreshToken", refreshResponse.data.refresh_token);
        return instance(response.config);
      } catch (error) {
        console.log("error", error);
        Emitter.emit("token-expired");
        return response;
      }
    },
    (error) => {
      if (error.code === "ECONNABORTED" || _.get(error, ["response", "status"], "") === 408) {
        Toast.error("Network error");
      }
      return Promise.reject(error);
    }
  );

  return instance;
}

const axiosInstance = createInstance();

export default axiosInstance;
