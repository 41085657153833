import React, {Component} from 'react';
import { NavItem, Nav } from 'react-bootstrap';
import { connect } from 'react-redux';


class HeaderLinks extends Component{
    render(){
        return (
            <div>
                <Nav pullRight>
                    {this.props.auth.roles.includes('admin') ? <NavItem eventKey={2} href="#/admin">Admin</NavItem> : null}
                    <NavItem eventKey={2} href="#/select">Select Branch</NavItem>
                    <NavItem eventKey={3} href="#" onClick={this.props.logout}>Logout</NavItem>
                </Nav>
            </div>
        );
    }
}

export default connect(state => ({
    auth: state.auth
}))(HeaderLinks);
