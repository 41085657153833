import axios from "../service/HttpManager";

let branchesMap = {};
let branchesByStoreTypeId = {};

const branchFullfillmentSlots = [];

export const fetchBranch = async (branchId, accessToken) => {
  let branch = branchesMap[branchId];
  if (!!branch) return branch;

  const response = await axios.post(
    "/merchant/branches/getOne",
    {
      id: branchId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  branchesMap[branchId] = response.data;
  return response.data;
};

export const fetchBranches = async (accessToken, page = 1, pageSize = 200) => {
  const response = await axios.post("/merchant/branches/all", null, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: { page, pageSize },
  });
  if (response.status !== 201) throw new Error(response.data.message);
  const branches = response.data;
  branches.forEach((branch) => {
    branchesMap[branch.Id] = branch;
  });
  return branches;
};

export const fetchBranchesByMerchantId = async (merchantId, accessToken, page = 1, pageSize = 30) => {
  const cacheBranches = Object.values(branchesMap).filter((branch) => branch.MerchantId === merchantId);
  if (cacheBranches.length > 1) return cacheBranches;

  const response = await axios.post(
    "/merchant/branches/getByMerchantId",
    {
      id: merchantId,
      countryCode: "SGP",
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: { page, pageSize },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  const branches = response.data;
  branches.forEach((branch) => {
    branchesMap[branch.Id] = branch;
  });
  return branches;
};

export const fetchBranchesByStoreTypeId = async (storeTypeId, accessToken, page = 1, pageSize = 10) => {
  if (branchesByStoreTypeId[storeTypeId]) return branchesByStoreTypeId[storeTypeId];

  const response = await axios.post(
    "/merchant/branches/getByStoreTypeIdAndCountryCode",
    {
      id: storeTypeId,
      countryCode: "SGP",
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: { page, pageSize },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  branchesByStoreTypeId[storeTypeId] = response.data;
  return response.data;
};

export const createBranch = async (data, accessToken) => {
  const response = await axios.post("/merchant/branches/create", data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (response.status !== 201) throw new Error(response.data.message);
  const branch = response.data;
  branchesMap[branch.Id] = branch;
  return branch;
};

export const updateBranch = async (branchId, data, accessToken) => {
  const response = await axios.post(
    "/merchant/branches/update",
    {
      id: branchId,
      ...data,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  const branch = response.data;
  branchesMap[branch.Id] = branch;
  return branch;
};

export const deleteBranch = async (branchId, accessToken) => {
  const response = await axios.post(
    "/merchant/branches/delete",
    { id: branchId },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  const branch = response.data;
  delete branchesMap[branch.Id];
  return branch;
};

export const fetchBranchStoreTypes = async (branchId, accessToken) => {
  const response = await axios.post(
    "/merchant/merchant-branch-store-type/merchant-branch-id",
    {
      merchantBranchId: branchId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const createBranchStoreType = async (branchId, storeTypeId, accessToken) => {
  const response = await axios.post(
    "/merchant/merchant-branch-store-type/create",
    {
      merchantBranchId: branchId,
      storeTypeId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const updateBranchStoreType = async (id, storeTypeId, accessToken) => {
  const response = await axios.post(
    "/merchant/merchant-branch-store-type/update",
    {
      id,
      storeTypeId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const deleteBranchStoreType = async (id, accessToken) => {
  const response = await axios.post(
    "/merchant/merchant-branch-store-type/delete",
    {
      id,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const fetchBranchFoodCategories = async (branchId, accessToken) => {
  const response = await axios.post(
    "/merchant/merchant-branch-food-category/merchant-branch-id",
    {
      merchantBranchId: branchId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const createBranchFoodCategory = async (branchId, foodCategoryId, accessToken) => {
  const response = await axios.post(
    "/merchant/merchant-branch-food-category/create",
    {
      merchantBranchId: branchId,
      foodCategoryId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const updateBranchFoodCategory = async (id, foodCategoryId, accessToken) => {
  const response = await axios.post(
    "/merchant/merchant-branch-food-category/update",
    {
      id,
      foodCategoryId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const deleteBranchFoodCategory = async (id, foodCategoryId, accessToken) => {
  const response = await axios.post(
    "/merchant/merchant-branch-food-category/update",
    {
      id,
      foodCategoryId,
      isActive: false,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const updateBranchPositions = async (data, accessToken) => {
  const responses = await Promise.all(
    data.map((item) => {
      return axios.post(
        "/merchant/branches/update",
        { id: item.branchId, position: item.position, locationType: item.locationType },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    })
  );

  if (responses.some((response) => response.status !== 201)) {
    throw new Error(responses.find((response) => response.status !== 201).data.message);
  }
  return responses.map((r) => r.data);
};

export const createBranchLink = async (branchId, link, mediaType, accessToken) => {
  const response = await axios.post(
    "/merchant/merchant-branch-media-link/create",
    {
      merchantBranchId: branchId,
      mediaType,
      link,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const updateBranchLink = async (branchId, linkId, link, type, accessToken) => {
  const response = await axios.post(
    "/merchant/merchant-branch-media-link/create",
    {
      merchantBranchId: branchId,
      id: linkId,
      mediaType: type,
      link,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const fetchBranchQr = async (branchId, start, end, accessToken) => {
  const response = await axios.get(`/branches/${branchId}/qrCode?start=${start}&end=${end}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const createBranchDisclaimer = async (branchId, disclaimer, accessToken) => {
  const termResponse = await axios.post(
    "code/term",
    {
      description: disclaimer,
      isActive: true,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (termResponse.status !== 201) throw new Error(termResponse.data.message);
  const response = await axios.post(
    "merchant/merchant-branch-term/create",
    {
      merchantBranchId: branchId,
      termId: response.data.Id,
      isActive: true,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  branchesMap = {};
  branchesByStoreTypeId = {};
  return response.data;
};

export const updateBranchDisclaimer = async (branchId, termId, disclaimer, accessToken) => {
  const response = await axios.post(
    "code/term/update",
    {
      id: termId,
      description: disclaimer,
      isActive: true,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  branchesMap = {};
  branchesByStoreTypeId = {};
  return response.data;
};

export const fetchBranchSchedule = async (branchId, accessToken, page = 1, pageSize = 50) => {
  const response = await axios.post(
    "/merchant/merchant-branch-schedule/merchant-branch-id",
    {
      merchantBranchId: branchId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: { page, pageSize },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const updateBranchSchedule = async (schedule, accessToken) => {
  const response = await axios.post("/merchant/merchant-branch-schedule/update", schedule, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const createBranchSchedule = async (branchId, schedule, accessToken) => {
  const response = await axios.post(
    "/merchant/merchant-branch-schedule/create",
    {
      merchantBranchId: branchId,
      ...schedule,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const deleteBranchSchedule = async (scheduleId, accessToken) => {
  const response = await axios.post(
    "/merchant/merchant-branch-schedule/delete",
    {
      id: scheduleId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const fetchBranchFulfilmentSlot = async (slotId, accessToken) => {
  const slot = branchFullfillmentSlots.find((slot) => slot.Id === slotId);
  if (!!slot) return slot;

  const response = await axios.post(
    "/merchant/merchant-branch-fulfilment-slot/getById",
    {
      id: slotId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  branchFullfillmentSlots.push(response.data);
  return response.data;
};

export const fetchBranchFulfilmentSlots = async (branchId, accessToken, page = 1, pageSize = 10) => {
  const response = await axios.post(
    "/merchant/merchant-branch-fulfilment-slot/getByMerchantBranchIdAndFulfilmentTypeId",
    {
      id: branchId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: { page, pageSize },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const createBranchFulfilmentSlot = async (
  branchId,
  fulfilmentTypeId,
  description,
  accessToken
) => {
  const response = await axios.post(
    "/merchant/merchant-branch-fulfilment-slot/create",
    {
      merchantBranchId: branchId,
      fulfilmentTypeId: fulfilmentTypeId,
      description,
      dateTime: new Date().toISOString(),
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const updateBranchFulfilmentSlot = async (slotId, description, accessToken) => {
  const response = await axios.post(
    "/merchant/merchant-branch-fulfilment-slot/create",
    {
      id: slotId,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const deleteBranchFulfilmentSlot = async (slotId, accessToken) => {
  const response = await axios.post(
    "/merchant/merchant-branch-fulfilment-slot/delete",
    {
      id: slotId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const uploadBranchImage = async (branchId, image, accessToken) => {
  const formData = new FormData();
  formData.append("merchantBranchId", branchId);
  formData.append("image", image);
  const response = await axios.post("/merchant/merchant-branch-image/upload", formData, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const deleteBranchImage = async (imageId, accessToken) => {
  const response = await axios.post(
    "/merchant/merchant-branch-image/delete",
    {
      id: imageId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};
