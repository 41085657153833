import React, { Component } from 'react';
import { Grid, Row, Col, FormGroup, FormControl, ControlLabel } from 'react-bootstrap';

import { Card } from '../../components/Card/Card.jsx';
import { FormInputs } from '../../components/FormInputs/FormInputs.jsx';
import { ContentCard } from '../../components/ContentCard/ContentCard.jsx';
import Button from '../../elements/CustomButton/CustomButton.jsx';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import api from '../../api';
import { connect } from 'react-redux';

class UserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFormLoading: false,
            merchantName: '',
            username: '',
            email: '',
            website: '',
            address1: '',
            address2: '',
            address3: '',
            city: '',
            state: '',
            postal: '',
            area: '',
            placeType: '',
            storeTypes: [],
            officeNumber: '',
            mobileNumber: '',
            faxNumber: '',
            operatingHoursDescription: '',
            logo: '',
            branchFoodCategories: [],
            foodCategories: [],
            foodCategoryOptions: [],
        };
    }

    componentDidMount() {
        this.loadBranch();
    }

    loadBranch = async () => {
        this.setState({ isFormLoading: true });

        const { branchId, token } = this.props.auth;
        try {
            const branch = await api.fetchBranch(branchId, token);
            const merchant = await api.fetchMerchant(branch.MerchantId, token);
            const storeTypeList = await api.fetchStoreTypes(token);
            const storeTypes = (await api.fetchBranchStoreTypes(branchId, token)).map(item => {
                const storeType = storeTypeList.find(t => t.Id === item.StoreTypeId) || null;
                return storeType;
            }).filter(t => t != null);
            const foodCategoryOptions = (await api.fetchFoodCategories(token)).map(c => ({value: c.Id, label: c.Type}));
            const branchFoodCategories = await api.fetchBranchFoodCategories(branchId, token);
            const foodCategories = branchFoodCategories.map(foodCategory => {
                const type = foodCategoryOptions.find(t => t.value === foodCategory.FoodCategoryId)
                return { value: foodCategory.FoodCategoryId, label: type?.label };
            }).filter(t => t != null);

            this.setState({
                merchantName: merchant.Name,
                branchName: branch.BranchName || '',
                email: branch.Email || '',
                address1: branch.AddressLine1,
                address2: branch.AddressLine2,
                address3: branch.AddressLine3,
                city: branch.City,
                state: branch.State,
                postal: branch.PostalCode,
                area: branch.Area,
                placeType: branch.LocationType,
                storeTypes: storeTypes,
                officeNumber: branch.OfficeNumber,
                mobileNumber: branch.MobileNumber,
                operatingHoursDescription: branch.AvailabilityDescription,
                logo: merchant.Logo,
                branchFoodCategories,
                foodCategories,
                foodCategoryOptions,
            });
        } catch (error) {
            console.log(error);
        } finally {
            this.setState({ isFormLoading: false });
        }
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onPressUpdate = async () => {
        const branchId = this.props.auth.branchId;

        this.setState({ isFormLoading: true });

        const data = {
            email: this.state.email,
            branchName: this.state.branchName,
        }

        if (this.props.roles.includes('admin')) {
            data.operatingHoursDescription = this.state.operatingHoursDescription || '';
        }

        const foodCategories = this.state.foodCategories;
        const addedFoodCategories = foodCategories.filter(type => !(this.state.branchFoodCategories.find(t => t.FoodCategoryId === type.value)));
        const removedFoodCategories = this.state.branchFoodCategories.filter(t => !foodCategories.find(type => type.value === t.FoodCategoryId));

        try {
            const result = await api.updateBranch(branchId, data, this.props.token);

            await Promise.all([
                ...addedFoodCategories.map(type => api.createBranchFoodCategory(branchId, type.value, this.props.token)),
                ...removedFoodCategories.map(type => api.deleteBranchFoodCategory(type.Id, this.props.token)),
            ])
            
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-like2" />,
                message: <strong>Branch updated.</strong>,
                level: 'success'
            });
        } catch (err) {
            console.log(err);
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-attention" />,
                message: <strong>{err.message}</strong>,
                level: 'error'
            });
        } finally {
            this.setState({
                isFormLoading: false
            });
        }
    }

    render() {
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={8}>
                            <Card
                                title="User Profile"
                                content={
                                    <form>
                                        <div className={'loader-overlay ' + (this.state.isFormLoading ? '' : 'hidden')}><div className="loader"></div></div>
                                        <FormInputs
                                            ncols={["col-md-12"]}
                                            proprieties={[
                                                {
                                                    label: "merchant",
                                                    type: "text",
                                                    bsClass: "form-control",
                                                    placeholder: "merchant",
                                                    disabled: true,
                                                    value: this.state.merchantName
                                                }
                                            ]}
                                        />
                                        <FormInputs
                                            ncols={["col-md-6", "col-md-6"]}
                                            proprieties={[
                                                {
                                                    label: "branchName",
                                                    bsClass: "form-control",
                                                    placeholder: "branch name",
                                                    value: this.state.branchName,
                                                    name: "branchName",
                                                    onChange: this.handleChange,
                                                    spellCheck: false
                                                },
                                                {
                                                    label: "email address",
                                                    type: "email",
                                                    bsClass: "form-control",
                                                    placeholder: "email address",
                                                    value: this.state.email,
                                                    name: "email",
                                                    onChange: this.handleChange,
                                                    spellCheck: false
                                                }
                                            ]}
                                        />
                                        <FormInputs
                                            ncols={["col-md-4", "col-md-4", "col-md-4"]}
                                            proprieties={[
                                                {
                                                    label: "address line 1",
                                                    type: "text",
                                                    bsClass: "form-control",
                                                    placeholder: "address line 1",
                                                    value: this.state.address1,
                                                    disabled: true
                                                },
                                                {
                                                    label: "address line 2",
                                                    type: "text",
                                                    bsClass: "form-control",
                                                    placeholder: "address line 2",
                                                    value: this.state.address2,
                                                    disabled: true
                                                },
                                                {
                                                    label: "address line 3",
                                                    type: "text",
                                                    bsClass: "form-control",
                                                    placeholder: "address line 3",
                                                    value: this.state.address3,
                                                    disabled: true
                                                }
                                            ]}
                                        />
                                        <FormInputs
                                            ncols={["col-md-4", "col-md-4", "col-md-4"]}
                                            proprieties={[
                                                {
                                                    label: "city",
                                                    type: "text",
                                                    bsClass: "form-control",
                                                    placeholder: "city",
                                                    value: this.state.city,
                                                    disabled: true
                                                },
                                                {
                                                    label: "state",
                                                    type: "text",
                                                    bsClass: "form-control",
                                                    placeholder: "state",
                                                    value: this.state.state,
                                                    disabled: true
                                                },
                                                {
                                                    label: "postal code",
                                                    type: "text",
                                                    bsClass: "form-control",
                                                    placeholder: "postal code",
                                                    value: this.state.postal,
                                                    disabled: true
                                                }
                                            ]}
                                        />
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <ControlLabel>area</ControlLabel>
                                                    <Select
                                                        options={[{ value: this.state.area, label: this.state.area }]}
                                                        value={this.state.area}
                                                        placeholder="select area"
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <ControlLabel>place type</ControlLabel>
                                                    <Select.Creatable
                                                        options={[{ value: this.state.placeType, label: this.state.placeType }]}
                                                        value={this.state.placeType}
                                                        placeholder="select place type"
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <ControlLabel>store types</ControlLabel>
                                                    <Select.Creatable
                                                        options={this.state.storeTypes.map((storeType) => {
                                                            return { value: storeType.Id, label: storeType.Type };
                                                        })}
                                                        multi={true}
                                                        value={this.state.storeTypes.map((storeType) => {
                                                            return { value: storeType.Id, label: storeType.Type };
                                                        })}
                                                        placeholder="select store types"
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <FormInputs
                                            ncols={["col-md-4", "col-md-4"]}
                                            proprieties={[
                                                {
                                                    label: "office number",
                                                    type: "text",
                                                    bsClass: "form-control",
                                                    placeholder: "office number",
                                                    value: this.state.officeNumber,
                                                    disabled: true
                                                },
                                                {
                                                    label: "mobile number",
                                                    type: "text",
                                                    bsClass: "form-control",
                                                    placeholder: "mobile number",
                                                    value: this.state.mobileNumber,
                                                    disabled: true
                                                },
                                                {
                                                    label: "fax number",
                                                    type: "text",
                                                    bsClass: "form-control",
                                                    placeholder: "fax number",
                                                    value: this.state.faxNumber,
                                                    disabled: true
                                                }
                                            ]}
                                        />
                                        <FormGroup>
                                            <ControlLabel>Enter Schedule</ControlLabel>
                                            <FormControl
                                                componentClass="textarea"
                                                placeholder="schedule"
                                                name="operatingHoursDescription"
                                                value={this.state.operatingHoursDescription}
                                                onChange={this.handleChange}
                                                disabled={!this.props.roles.includes('admin')}
                                            />
                                        </FormGroup>
                                        <Row>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <ControlLabel>food categories</ControlLabel>
                                                    <Select.Creatable
                                                        options={this.state.foodCategoryOptions}
                                                        multi={true}
                                                        value={this.state.foodCategories}
                                                        placeholder="select food categories"
                                                        disabled={!this.props.roles.includes('admin')}
                                                        onChange={(val) => {
                                                            this.setState({ foodCategories: val });
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {/*<Row>
                                            <Col md={12}>
                                                <span className="editable-table-label">redemption schedule</span>
                                                <Table striped hover responsive bordered>
                                                    <thead>
                                                        <tr>
                                                            <th>day</th>
                                                            <th>begin</th>
                                                            <th>end</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <Select
                                                                    multi={true}
                                                                    options={dayOptions}
                                                                    onChange={this.handleDaysChange}
                                                                    value={days}
                                                                    placeholder="select days"
                                                                />
                                                            </td>
                                                            <td>
                                                                <FormControl
                                                                type="time"
                                                                placeholder="begin time"
                                                                step={60}
                                                                />
                                                            </td>
                                                            <td>
                                                                <FormControl
                                                                type="time"
                                                                placeholder="end time"
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>mon</td>
                                                            <td>12:00</td>
                                                            <td>13:00</td>
                                                        </tr>
                                                        <tr>
                                                            <td>mon</td>
                                                            <td>15:00</td>
                                                            <td>17:00</td>
                                                        </tr>
                                                        <tr>
                                                            <td>wed</td>
                                                            <td>13:00</td>
                                                            <td>16:00</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Col>                                        
                                        </Row>*/}
                                        <Button
                                            bsStyle="info"
                                            pullRight
                                            fill
                                            onClick={this.onPressUpdate}
                                        >
                                            Update Profile
                                        </Button>
                                        <div className="clearfix"></div>
                                    </form>
                                }
                            />
                        </Col>
                        <Col md={4}>
                            <ContentCard
                                content={<img className="branch-logo" src={this.state.logo} alt="branch logo" />}
                            />
                        </Col>

                    </Row>
                </Grid>
            </div>
        );
    }
}

export default connect(state => ({
    auth: state.auth
}))(UserProfile);
