import Merchants from '../views/Merchants/Merchants';
import Branches from '../views/Branches/Branches';
import GenerateListings from '../views/GenerateListings/GenerateListings';
import Accounts from '../views/Accounts/Accounts';
import Emails from '../views/Emails/Emails';
import Notifications from '../views/Notifications/Notifications';
import Promocodes from '../views/Promocodes/Promocodes';
import CombinedOrders from '../views/CombinedOrders/CombinedOrders';
import Feedbacks from '../views/Feedbacks/Feedbacks';
import Users from '../views/Users/Users';
import CombinedTransactions from '../views/CombinedTransactions/CombinedTransactions';
import Dashboard from '../views/Dashboard/Dashboard';

const adminRoutes = [
    // { path: "/admin/dashboard", name: "Dashboard", icon: "pe-7s-graph2", component: Dashboard },
    { path: "/admin/merchants", name: "Merchants", icon: "pe-7s-culture", component: Merchants },
    { path: "/admin/branches", name: "Branches", icon: "pe-7s-shopbag", component: Branches },
    { path: "/admin/generatelistings", name: "Generate Listings", icon: "pe-7s-edit", component: GenerateListings },
    { path: "/admin/accounts", name: "Accounts", icon: "pe-7s-door-lock", component: Accounts },
    { path: "/admin/users", name: "Users", icon: "pe-7s-users", component: Users },
    { path: "/admin/emails", name: "Emails", icon: "pe-7s-mail", component: Emails },
    { path: "/admin/marketing", name: "Marketing", icon: "pe-7s-bell", component: Notifications },
    { path: "/admin/feedbacks", name: "Feedbacks", icon: "pe-7s-comment", component: Feedbacks },
    { path: "/admin/promocodes", name: "Promocodes", icon: "pe-7s-cash", component: Promocodes },
    { path: "/admin/combinedtransactions", name: "Combined Transactions", icon: "pe-7s-repeat", component: CombinedTransactions },
    // { path: "/admin/combinedorders", name: "Combined Orders", icon: "pe-7s-gift", component: CombinedOrders },
    { redirect: true, path:"/admin", to:"/admin/merchants" }
];

export default adminRoutes;
