import * as auth from './auth';
import * as branch from './branch';
import * as code from './code';
import * as customer from './customer';
import * as feedbacks from './feedbacks';
import * as generationSettings from './generationSettings';
import * as images from './images';
import * as listing from './listing';
import * as map from './map';
import * as merchant from './merchant';
import * as order from './order';
import * as product from './product';
import * as promo from './promo';
import * as steal from './steal';
import * as transaction from './transaction';

export default {
    ...auth,
    ...branch,
    ...code,
    ...customer,
    ...feedbacks,
    ...generationSettings,
    ...images,
    ...listing,
    ...map,
    ...merchant,
    ...order,
    ...product,
    ...promo,
    ...steal,
    ...transaction,
}
