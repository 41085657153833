import axios from "../service/HttpManager";

let merchants = [];
let singleMerchants = [];

export const fetchMerchants = async (accessToken, page = 1, pageSize = 200, forceRefresh = false) => {
  if (!forceRefresh && merchants.length > 0) return merchants;

  const response = await axios.post("/merchant/all", null, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: { page, pageSize },
  });
  if (response.status !== 201) throw new Error(response.data.message);
  merchants = response.data;
  return merchants;
};

export const fetchMerchant = async (merchantId, accessToken) => {
  const merchant = [...merchants, ...singleMerchants].find((merchant) => merchant.Id === merchantId);
  if (!!merchant) return merchant;

  const response = await axios.post(
    "/merchant/getOne",
    { id: merchantId },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  singleMerchants.push(response.data);
  return response.data;
};

export const createMerchant = async (data, logo, accessToken) => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    formData.append(key, value);
  }
  if (logo) {
    formData.append('logo', logo);
  } else {
    formData.append('logo', null);
  }
  const response = await axios.post("/merchant/create", formData, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const updateMerchant = async (data, logo, accessToken) => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    formData.append(key, value);
  }
  if (logo) {
    formData.append('logo', logo);
  } else {
    formData.append('logo', null);
  }
  formData.append('isActive', true);
  const response = await axios.post("/merchant/update", formData, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (response.status != 201) throw new Error(response.data.message);
  return response.data;
};

export const updateMerchantPositions = async (data, accessToken) => {
  const responses = await Promise.all(
    data.map((item) => {
      return axios.post(
        "/merchant/update",
        { id: item.merchantId, position: item.position },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    })
  );

  if (responses.some((response) => response.status !== 201)) {
    throw new Error(responses.find((response) => response.status !== 201).data.message);
  }
  return responses.map(r => r.data);
};
