import React, { Component } from 'react';
import { Grid, Row, Col, Table, Button, FormControl, ControlLabel, FormGroup, Tabs, Tab } from 'react-bootstrap';

import Card from '../../components/Card/Card.jsx';
import OrderEmailRow from '../../components/OrderEmailRow/OrderEmailRow.jsx';

import Select from 'react-select';
import 'react-select/dist/react-select.css';
import {validateInputs} from '../../helper';

class Emails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isTableLoading: false,
            text: '',
            orderEmails: [],
            emailAddresses: [],
            subject: '',
            image: '',
            isAll: true
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.loadOrderEmails = this.loadOrderEmails.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.handleSend = this.handleSend.bind(this);

        this.loadOrderEmails();
    }

    handleSend(e) {
        if (!window.confirm('Are you sure you want to send?')) {
            return;
        }
        if (!this.state.isAll && this.state.emailAddresses.length === 0) {
            const input = document.getElementById('email-addresses-select');
            input.setCustomValidity('Please fill out this field.');
            input.reportValidity();
            return;
        }

        const isInputsValid = validateInputs(e.target.closest('form'), this);
        if (!isInputsValid) {
            return;
        }

        const subject = this.state.subject;
        const text = this.state.text;
        const image = this.state.image;
        const emailAddresses = this.state.isAll ? [] : this.state.emailAddresses;       

        const body  = JSON.stringify({
            subject,
            text,
            image,
            emailAddresses
        });

        fetch('/emails/edms/sendBulk', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'access-token': this.props.token
            },
            body
        }).then((result) => {
            return result.json();
        }).then((result) => {
            if (result.status !== 200) {
                this.props.addNotification({
                    title: <span data-notify="icon" className="pe-7s-attention" />,
                    message: <strong>{result.details}</strong>,
                    level: 'error'
                });
                return;
            }
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-like2" />,
                message: <strong>{result.details}</strong>,
                level: 'success'
            });
        });
    }

    handleCheck(e) {
        this.setState({[e.target.name]: e.target.checked});
    }

    handleSelectChange(name, val) {
        this.setState({[name]: val});
    }

    loadOrderEmails() {
        return fetch('/orderEmails', {
            method: 'GET',
            headers: {
                'access-token': this.props.token
            }
        }).then((result) => {
            if (result.ok) {
                return result.json();
            }
            return null;
        }).then((result) => {            
            if (result.status !== 200) {
                return;
            }

            const orderEmails = result.result.orderEmails;
            this.setState({orderEmails});
            return orderEmails;
        });
    }
    
    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleAdd(e) {
        const isInputsValid = validateInputs(e.target.closest('form'), this);
        if (!isInputsValid) {
            return;
        }

        const email = this.state.email;
        const textMessage = this.state.textMessage;


        const body  = JSON.stringify({
            email,
            textMessage
        });

        fetch('/orderEmails', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'access-token': this.props.token
            },
            body
        }).then((result) => {
            return result.json();
        }).then((result) => {
            if (result.status !== 201) {
                this.props.addNotification({
                    title: <span data-notify="icon" className="pe-7s-attention" />,
                    message: <strong>{result.details}</strong>,
                    level: 'error'
                });
                return;
            }
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-like2" />,
                message: <strong>{result.details}</strong>,
                level: 'success'
            });

            this.setState({
                email: '',
                textMessage: ''
            });

            this.loadOrderEmails();
        });
    }

    render() {
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title="Emails"
                                ctTableResponsive
                                content={
                                    <Tabs defaultActiveKey={1} id="emails-tab">
                                        <Tab eventKey={1} title="Send Email">
                                            <div>
                                                <h4>Send Email</h4>
                                                <form>
                                                    <div className={'loader-overlay ' + (this.state.isFormLoading ? '' : 'hidden')}><div className="loader"></div></div>
                                                    <FormGroup>
                                                        <ControlLabel>Enter Subject</ControlLabel>
                                                        <FormControl
                                                            type="text"
                                                            placeholder="Subject"
                                                            name="subject"
                                                            value={this.state.subject}
                                                            onChange={this.handleChange}
                                                            required
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <ControlLabel>Enter Text</ControlLabel>
                                                        <FormControl
                                                            componentClass="textarea"
                                                            placeholder="Text"
                                                            name="text"
                                                            value={this.state.text}
                                                            onChange={this.handleChange}
                                                            required
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <ControlLabel>Enter Image URL (optional)</ControlLabel>
                                                        <FormControl
                                                            type="text"
                                                            placeholder="Image URL"
                                                            name="image"
                                                            value={this.state.image}
                                                            onChange={this.handleChange}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <input className="all-checkbox" type="checkbox"
                                                            id="all-checkbox"
                                                            name="isAll"
                                                            checked={this.state.isAll}
                                                            onChange={this.handleCheck} />
                                                        <span className="all-label" >Send to all</span>
                                                    </FormGroup>
                                                    <FormGroup className={this.state.isAll ? 'hidden' : ''}>
                                                        <ControlLabel>Enter Email Addresses</ControlLabel>
                                                        <Select.Creatable
                                                            id="email-addresses-select" 
                                                            multi={true}
                                                            value={this.state.emailAddresses.map((email) => {
                                                                return {value: email, label: email};
                                                            })}
                                                            placeholder="Email Addresses"
                                                            onChange={(val) => {
                                                                val = val.map((emailVal) => {
                                                                    return emailVal.value;
                                                                });
                                                                this.handleSelectChange('emailAddresses', val);
                                                            }}
                                                            onInputChange={(val) => {
                                                                document.getElementById('email-addresses-select').setCustomValidity('');
                                                                return val
                                                            }}
                                                        />
                                                    </FormGroup>
                                                    <Button className="table-action-button action-button btn-fill"
                                                            bsStyle="warning"
                                                            type="button"
                                                            onClick={this.handleSend}>
                                                        <i className="fa fa-envelope"></i> Send Email
                                                    </Button>
                                                </form>
                                            </div>
                                        </Tab>
                                        <Tab eventKey={2} title="Manage Order Emails">
                                            <div>
                                                <h4>Add an Order Email</h4>
                                                <form>
                                                    <div className={'loader-overlay ' + (this.state.isFormLoading ? '' : 'hidden')}><div className="loader"></div></div>                                                    
                                                    <FormGroup>
                                                        <ControlLabel>Enter Email Address</ControlLabel>
                                                        <FormControl
                                                            type="email"
                                                            placeholder="Email Address"
                                                            name="email"
                                                            value={this.state.email}
                                                            onChange={this.handleChange}
                                                            required
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <ControlLabel>Enter Email Message</ControlLabel> 
                                                        <FormControl                
                                                            componentClass="textarea"
                                                            placeholder="Email Message"
                                                            name="textMessage"
                                                            value={this.state.textMessage}
                                                            onChange={this.handleChange}
                                                            required
                                                        />
                                                    </FormGroup>
                                                    <Button className="table-action-button action-button btn-fill"
                                                            bsStyle="warning"
                                                            type="button"
                                                            onClick={this.handleAdd}>
                                                        <i className="fas fa-plus" /> Add Order Email
                                                    </Button>
                                                </form>
                                                <h4>Order Emails</h4>
                                                <form>
                                                    <Table hover bordered className="emails-table" responsive>
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Email Address</th>
                                                                <th>Email Message</th>
                                                                <th>Actions</th>
                                                            </tr>
                                                        </thead>
                                                        {   
                                                            this.state.orderEmails.map((orderEmail, i) => {
                                                                return <OrderEmailRow
                                                                    {...this.props} key={orderEmail._id} orderEmail={orderEmail} i={i} loadOrderEmails={this.loadOrderEmails} token={this.props.token} />;
                                                            })
                                                        }
                                                    </Table>
                                                </form>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default Emails;
