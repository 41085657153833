import axios from "../service/HttpManager";

let productsMap = {};
let productsByBranch = {};

export const fetchProducts = async (branchId, accessToken, page = 1, pageSize = 10) => {
  if (productsByBranch[branchId]) {
    return productsByBranch[branchId];
  }

  const response = await axios.post(
    "/merchant/merchant-branch-product/merchant-branch-id",
    {
      merchantBranchId: branchId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: { page, pageSize },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  productsByBranch[branchId] = response.data;
  return response.data;
};

export const fetchProduct = async (productId, accessToken) => {
  let product = productsMap[productId];
  if (!!product) return product;
  product = Object.values(productsByBranch).flat().find((product) => product.Id === productId);
  if (!!product) return product;

  const response = await axios.post(
    "/merchant/merchant-branch-product/getOne",
    {
      id: productId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  product = response.data;
  productsMap[productId] = product
  return product;
};

export const createProduct = async (data, accessToken) => {
  const response = await axios.post("/merchant/merchant-branch-product/create", data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (response.status !== 201) throw new Error(response.data.message);
  const product = response.data;
  productsMap[product.Id] = product;
  let currentProducts = productsByBranch[product.MerchantBranchId] || [];
  currentProducts.push(product);
  productsByBranch[product.MerchantBranchId] = currentProducts;
  return response.data;
};

export const updateProduct = async (productId, data, accessToken) => {
  const response = await axios.post(
    "/merchant/merchant-branch-product/update",
    {
      id: productId,
      ...data,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  const product = response.data;
  let currentProducts = productsByBranch[product.MerchantBranchId] || [];
  const oldProduct = productsMap[productId];
  currentProducts = currentProducts.filter((p) => p.Id !== productId);
  const updateProduct = { ...oldProduct, ...product };
  currentProducts.push(updateProduct);
  productsByBranch[product.MerchantBranchId] = currentProducts;
  productsMap[productId] = updateProduct;
  return product;
};

export const deleteProduct = async (productId, accessToken) => {
  const response = await axios.post(
    "/merchant/merchant-branch-product/delete",
    {
      id: productId,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  const product = response.data;
  productsMap[productId] = null;
  productsByBranch[product.MerchantBranchId] = null;
  return product;
};

export const uploadProductImage = async (productId, image, accessToken) => {
  const formData = new FormData();
  formData.append("merchantBranchProductId", productId);
  formData.append("image", image);
  const response = await axios.post("/merchant/merchant-branch-product-image/upload", formData, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const deleteProductImage = async (imageId, accessToken) => {
  const response = await axios.post(
    "/merchant/merchant-branch-product-image/setInactive",
    {
      id: imageId,
      isActive: false,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const createProductTag = async (productId, tag, accessToken) => {
  const response = await axios.post(
    "/merchant/merchant-branch-product-tag/create",
    {
      merchantBranchProductId: productId,
      tag,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  const productTag = response.data;
  const product = productsMap[productId];
  let currentTags = product.MerchantBranchProductTag || [];
  currentTags.push(productTag);
  productsMap[productId] = { ...product, MerchantBranchProductTag: currentTags };
  return productTag;
};

export const deleteProductTag = async (productTagId, accessToken) => {
  const response = await axios.post(
    "/merchant/merchant-branch-product-tag/delete",
    {
      id: productTagId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  const productTag = response.data;
  const product = productsMap[productTag.MerchantBranchProductId];
  let currentTags = product.MerchantBranchProductTag || [];
  currentTags = currentTags.filter((tag) => tag.Id !== productTagId);
  productsMap[productTag.MerchantBranchProductId] = { ...product, MerchantBranchProductTag: currentTags };
  return productTag;
};
