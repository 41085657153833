import React, { Component } from 'react';
import { Grid, Row, Col, Table, Button, FormControl, ControlLabel, FormGroup, Carousel, Tabs, Tab } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import moment from 'moment';
import Compress from "react-image-file-resizer";

import ProperCard from '../../components/Card/ProperCard.jsx';
import { validateInputs } from '../../helper.js';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { connect } from 'react-redux';

import api from '../../api/index.js';
import FormInputs from '../../components/FormInputs/FormInputs.jsx';
import EventRow from '../../components/EventRow/EventRow.jsx';

const EVENT_TYPES = {
  CURRENT: {
    index: 0,
    tabTitle: 'Current Events',
    checkboxType: 'current-events-checkbox'
  },
  UPCOMING: {
    index: 1,
    tabTitle: 'Upcoming Events',
    checkboxType: 'upcoming-events-checkbox'
  },
  PAST: {
    index: 2,
    tabTitle: 'Past Events',
    checkboxType: 'past-events-checkbox'
  }
};

const EVENT_TAB_TITLES = [
  'Current Events',
  'Upcoming Events',
  'Past Events'
]

class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      name: '',
      currency: 'SGD',
      price: '',
      quantity: '',
      payAsYouWish: false,
      unitOfMeasure: 'Event',
      description: '',
      locationName: '',
      locationInstruction: '',
      address: '',
      postal: '',
      productImages: [null],
      productImageIndex: 0,
      instructions: '',
      eventDate: null,
      openingHour: null,
      openingMinute: null,
      closingHour: null,
      closingMinute: null,
      productTags: ['event'],
      productsToDelete: {},
      productsToUpload: [],
    };
  }

  componentDidMount() {
    this.loadEvents();
  }

  loadEvents = async () => {
    const { token, branchId } = this.props.auth;
    try {
      const events = await api.fetchEventListings(branchId, token);
      console.log(events);
      this.setState({ events });
    } catch (error) {
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{error.message}</strong>,
        level: 'error'
      });
    }
  }

  handleEventDateChange = (selectedDay) => {
    const date = moment(selectedDay).format('DD/MM/YYYY');
    this.setState({ eventDate: date });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleCheckbox = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  }

  handleProductImageSelect = async (file, index) => {
    try {
      const image = await resizeFile(file);
      document.getElementById(`product-img-${index}`).src = URL.createObjectURL(image);
      this.setState(oldState => {
        oldState.productImages[index] = image;
        return { productImages: oldState.productImages };
      });
    } catch (err) {
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{err.message}</strong>,
        level: 'error'
      });
    }
  }

  handleCarouselSelect = (selectedIndex) => {
    this.setState({ productImageIndex: selectedIndex })
  }

  handleAddProductImage = () => {
    this.setState(oldState => {
      oldState.productImages.push(null);
      return {
        productImages: oldState.productImages,
        productImageIndex: oldState.productImages.length - 1
      };
    });
  }

  handleCheckAll = (e) => {
    const checkboxes = document.getElementsByName('product-checkbox');
    const productsToDelete = {};
    for (const checkbox of checkboxes) {
      const checked = e.target.checked;
      checkbox.checked = checked;
      productsToDelete[checkbox.value] = checked;
    }
    this.setState({ productsToDelete });
  }

  handleCheck = (e) => {
    const productsToDelete = JSON.parse(JSON.stringify(this.state.productsToDelete));
    productsToDelete[e.target.value] = e.target.checked;
    this.setState({ productsToDelete });
  }

  handleAdd = async (e) => {
    const isInputsValid = validateInputs(e.target.closest('form'), this);
    if (!isInputsValid) {
      return;
    }

    const branchId = this.props.auth.branchId;

    try {
      const location = await api.fetchAddressGeocoding(this.state.postal);

      const description = {
        description: this.state.description,
        location: this.state.locationName,
        lat: location.lat.toString(),
        lng: location.lng.toString(),
        instruction: this.state.locationInstruction,
        address: this.state.address,
        postalCode: this.state.postal,
      };

      const productData = {
        name: this.state.name,
        merchantBranchId: branchId,
        description: JSON.stringify(description),
        currency: this.state.currency,
        price: this.state.price,
        instructions: '',
        surcharge: 0,
        unit: this.state.unitOfMeasure,
        expiry: null,
        isActive: true
      };

      const productId = result.Id;

      await Promise.all(this.state.productImages.filter(i => i !== null)
        .map(image => api.uploadProductImage(productId, image, this.props.token)));

      await Promise.all(this.state.productTags.map(tag => api.createProductTag(productId, tag, this.props.token)));

      this.state.productImages.map((image, index) => document.getElementById(`product-img-${index}`).src = '');

      const eventDate = this.state.eventDate;
      const openingHour = this.state.openingHour;
      const openingMinute = this.state.openingMinute;
      let postAt = moment(eventDate, 'DD/MM/YYYY').startOf('day').unix();
      postAt = postAt + openingHour * 3600 + openingMinute * 60;
      const closingHour = this.state.closingHour;
      const closingMinute = this.state.closingMinute;
      let removeAt = moment(eventDate, 'DD/MM/YYYY').startOf('day').unix();
      removeAt = removeAt + closingHour * 3600 + closingMinute * 60;

      const listingData = {
        merchantBranchProductId: productId,
        offer: this.state.price,
        itemCount: this.state.quantity,
        itemRemaining: this.state.quantity,
        isAddOn: false,
        postAt: postAt,
        position: 0,
        removeAt: removeAt,
        partnerMerchantBranchId: branchId,
      };
      const listingResult = await api.createListing(listingData, this.props.token);
      console.log(listingResult);

      this.setState({
        events: [],
        name: '',
        currency: 'SGD',
        price: '',
        quantity: '',
        payAsYouWish: false,
        unitOfMeasure: 'Event',
        description: '',
        locationName: '',
        locationInstruction: '',
        address: '',
        postal: '',
        productImages: [null],
        productImageIndex: 0,
        instructions: '',
        eventDate: null,
        openingHour: null,
        openingMinute: null,
        closingHour: null,
        closingMinute: null,
        productTags: ['event'],
      }, () => {
        setTimeout(() => {
          this.setState({
            productImages: [null]
          })
        }, 1000);
      });

      const result = await api.createProduct(productData, this.props.token);
      console.log(result);
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-like2" />,
        message: <strong>Event created.</strong>,
        level: 'success'
      });

      this.loadEvents();
    } catch (err) {
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{err.message}</strong>,
        level: 'error'
      });
    }
  }

  handleDelete = async () => {
    if (!window.confirm('Are you sure you want to delete?')) {
      return;
    }

    const productIds = []
    Object.keys(this.state.productsToDelete).forEach((productId) => {
      if (this.state.productsToDelete[productId]) {
        productIds.push(productId);
      }
    });

    if (productIds.length === 0) {
      return;
    }

    try {
      await Promise.all(productIds.map(productId => api.deleteProduct(productId, this.props.token)));
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-like2" />,
        message: <strong>Products deleted.</strong>,
        level: 'success'
      });

      this.setState({ productsToDelete: {} });
      this.loadProducts();
    } catch (error) {
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{error.message}</strong>,
        level: 'error'
      });
    }
  }

  renderTab = (type) => {
    const now = Math.floor(Date.now() / 1000);
    const listings = this.state.events.filter(listing => {
      switch (type) {
        case EVENT_TYPES.CURRENT.index:
          return listing.PostAt <= now && listing.RemoveAt > now;
        case EVENT_TYPES.UPCOMING.index:
          return listing.PostAt > now;
        case EVENT_TYPES.PAST.index:
          return listing.RemoveAt <= now;
      }
    });
    const tabTitle = EVENT_TAB_TITLES[type];
    return (<Tab eventKey={type} title={tabTitle}>
      <h4>{tabTitle}</h4>
      <Table hover bordered id="products-table" responsive>
        <thead>
          <tr>
            <th className="checkbox-cell"><input type="checkbox" onChange={this.handleCheckAll} /></th>
            <th>#</th>
            <th>Name</th>
            <th>Redeem Price</th>
            <th>Description</th>
            <th>Event Date</th>
            <th>Opening Time</th>
            <th>Closing Time</th>
            <th>Total</th>
            <th>Remaining</th>
            <th>Image</th>
            <th>Actions</th>
          </tr>
        </thead>
        {
          listings.map((event, i) => {
            return <EventRow
              {...this.props}
              key={event.Id}
              listing={event}
              i={i}
              loadEvents={this.loadEvents}
              token={this.props.token}
              handleCheck={this.handleCheck} />;
          })
        }
      </Table>
      {/* <form>
        <Button className="btn-fill action-button"
          bsStyle="warning"
          type="button"
          onClick={this.handleDelete}>
          <i className="fas fa-trash"></i> Delete Rows
        </Button>
      </form> */}
    </Tab>);
  }

  render() {
    return (
      <div className="content" style={{ paddingLeft: 30, paddingRight: 30 }}>
        <Grid fluid>
          <Row>
            <ProperCard
              title="Add an Event">
              <form>
                <div className={`loader-overlay ${this.state.isFormLoading ? '' : 'hidden'}`}>
                  <div className="loader" />
                </div>
                <FormGroup>
                  <ControlLabel>Enter Event Name</ControlLabel>
                  <FormControl
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Enter Description</ControlLabel>
                  <FormControl
                    componentClass="textarea"
                    placeholder="Description"
                    name="description"
                    value={this.state.description}
                    onChange={this.handleChange}
                  />
                </FormGroup>
                <FormInputs
                  ncols={["col-md-6", "col-md-6"]}
                  proprieties={[
                    {
                      label: "location name",
                      type: "text",
                      bsClass: "form-control",
                      placeholder: "location name",
                      value: this.state.locationName,
                      name: "locationName",
                      onChange: this.handleChange,
                      spellCheck: false
                    },
                    {
                      label: "location instruction",
                      type: "text",
                      bsClass: "form-control",
                      placeholder: "location instruction",
                      value: this.state.locationInstruction,
                      name: "locationInstruction",
                      onChange: this.handleChange,
                      spellCheck: false
                    },
                  ]}
                />
                <FormInputs
                  ncols={["col-md-6", "col-md-6"]}
                  proprieties={[
                    {
                      label: "location address",
                      type: "text",
                      bsClass: "form-control",
                      placeholder: "address",
                      value: this.state.address,
                      name: "address",
                      onChange: this.handleChange,
                      spellCheck: false
                    },
                    {
                      label: "postal code",
                      type: "text",
                      bsClass: "form-control",
                      placeholder: "postal code",
                      value: this.state.postal,
                      name: "postal",
                      onChange: this.handleChange,
                      spellCheck: false
                    }
                  ]}
                />
                <Row>
                  <Col sm={1}>
                    <FormGroup>
                      <ControlLabel>Currency</ControlLabel>
                      <FormControl
                        type="text"
                        placeholder="Currency"
                        name="currency"
                        value={this.state.currency}
                        onChange={this.handleChange}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={4}>
                    <FormGroup>
                      <ControlLabel>Enter Redeem Price</ControlLabel>
                      <FormControl
                        type="number"
                        placeholder="Price"
                        name="price"
                        step="0.01"
                        min="0"
                        value={this.state.price}
                        onChange={this.handleChange}
                        disabled={this.state.payAsYouWish}
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <input className="admin-checkbox" type="checkbox"
                    name="payAsYouWish"
                    onChange={this.handleCheckbox}
                    checked={this.state.payAsYouWish} />
                  <span className="admin-label" style={{ marginBottom: 0 }}>Pay as you wish</span>
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Enter Quantity</ControlLabel>
                  <FormControl
                    type="number"
                    placeholder="Quantity"
                    name="quantity"
                    step="1"
                    min="0"
                    value={this.state.quantity}
                    onChange={this.handleChange}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel className="label-block" >Enter Collection Date & Time</ControlLabel>
                  <span className="admin-label" >Collect on</span>
                  <DayPickerInput
                    inputProps={{
                      className: 'form-control date-input datetime-input',
                      name: 'eventDate'
                    }}
                    value={this.state.eventDate}
                    onDayChange={this.handleEventDateChange}
                    placeholder="DD/MM/YYYY"
                    format="DD/MM/YYYY"
                    formatDate={formatDate}
                    parseDate={parseDate}
                  />
                  <span className="admin-label" >From</span>
                  <FormControl
                    className="time-input datetime-input"
                    type="number"
                    name="openingHour"
                    placeholder="hh"
                    min="0"
                    max="23"
                    step="1"
                    value={this.state.openingHour}
                    onChange={this.handleChange}
                  />
                  <span className="time-separator">:</span>
                  <FormControl
                    className="time-input datetime-input"
                    type="number"
                    name="openingMinute"
                    placeholder="mm"
                    min="0"
                    max="59"
                    step="1"
                    value={this.state.openingMinute}
                    onChange={this.handleChange}
                  />
                  <span className="admin-label" >To</span>
                  <FormControl
                    className="time-input datetime-input"
                    type="number"
                    name="closingHour"
                    placeholder="hh"
                    min="0"
                    max="23"
                    step="1"
                    value={this.state.closingHour}
                    onChange={this.handleChange}
                  />
                  <span className="time-separator">:</span>
                  <FormControl
                    className="time-input datetime-input"
                    type="number"
                    name="closingMinute"
                    placeholder="mm"
                    min="0"
                    max="59"
                    step="1"
                    value={this.state.closingMinute}
                    onChange={this.handleChange}
                  />
                </FormGroup>
                <h4>Event Images</h4>
                <Carousel interval={null} activeIndex={this.state.productImageIndex} onSelect={this.handleCarouselSelect}>
                  {
                    this.state.productImages.map((image, index) => {
                      return (<Carousel.Item key={index}>
                        <Dropzone onDropAccepted={files => this.handleProductImageSelect(files[0], index)} accept="image/png, image/jpeg">
                          {({ getRootProps, getInputProps, isDragActive }) => (
                            <section>
                              <div {...getRootProps({ style: { ...dragNDropStyle, ...(isDragActive ? { borderColor: '#ff5c0e' } : {}) } })}>
                                <img className="branch-product-image" style={{ height: 100, marginLeft: 'auto', marginRight: 'auto' }} id={`product-img-${index}`} alt="" />
                                <input {...getInputProps()} />
                                <p>Drag 'n' drop, or click to select file</p>
                              </div>
                            </section>
                          )}
                        </Dropzone>
                      </Carousel.Item>)
                    })
                  }
                </Carousel>
                <br />
                <Button className="table-action-button action-button btn-fill"
                  bsStyle="warning"
                  style={{ marginBottom: 10 }}
                  type="button"
                  onClick={this.handleAddProductImage}>
                  <i className="fas fa-plus"></i> Add Event Image
                </Button>
                <br />
                <Button className="table-action-button action-button btn-fill"
                  bsStyle="warning"
                  type="button"
                  onClick={this.handleAdd}>
                  Create Event
                </Button>
              </form>
            </ProperCard>
          </Row>
          <Row>
            <ProperCard
              title="Events"
              ctTableResponsive
            >
              <form id="edit-listing-form">
                <Tabs defaultActiveKey={1} id={1} ref={this.tabsRef}>
                  {this.renderTab(EVENT_TYPES.CURRENT.index)}
                  {this.renderTab(EVENT_TYPES.UPCOMING.index)}
                  {this.renderTab(EVENT_TYPES.PAST.index)}
                </Tabs>
              </form>
            </ProperCard>
          </Row>
        </Grid>
      </div>
    );
  }
}

function ellipsize(text, length) {
  text = text || '';
  text = text.trim();
  return text.length > length ? text.substring(0, length) + '...' : text;
}

const dragNDropStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '5px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const resizeFile = (file) =>
  new Promise((resolve) => {
    Compress.imageFileResizer(
      file,
      480,
      480,
      "PNG",
      70,
      0,
      (uri) => {
        resolve(uri);
      },
      "blob"
    );
  });

export default connect(state => ({
  auth: state.auth
}))(Events);
