import React, { Component } from 'react';
import { Grid, Row, Table, FormControl, FormGroup, ControlLabel, Button, Tabs, Tab } from 'react-bootstrap';
import moment from 'moment';

import ProperCard from '../../components/Card/ProperCard.jsx';
import TransactionRow, { VOID_TRANSACTION_STATUS } from '../../components/TransactionRow/TransactionRow.jsx';
import { generateTreatsureReportExcelFile, unixToExcelTime } from '../../helper';
import 'react-day-picker/lib/style.css';
import { connect } from 'react-redux';

import ReactExport from "react-data-export";
import api from '../../api';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class CombinedTransactions extends Component {
  constructor(props) {
    super(props);

    this.years = [];
    for (let i = 2018; i <= moment().year(); i += 1) {
      this.years.push(i);
    }

    this.state = {
      merchants: [],
      transactionList: [],
      transactions: [],
      voidTransactions: [],
      month: moment().format('MMM'),
      year: moment().year(),
      filterType: '',
      filterValue: '',
      pageSize: 30,
      page: 1,
      isFull: false,
      isLoading: false,
      isUserAlphaSorted: null
    };
  }

  componentDidMount() {
    this.loadMerchants();
    this.loadTransactions();
  }

  loadMerchants = async () => {
    const { token } = this.props.auth;
    try {
      const merchants = await api.fetchMerchants(token, 1, 100);
      this.setState({ merchants });
    } catch (error) {
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{error.message}</strong>,
        level: 'error'
      });
    }
  }

  loadTransactions = async (shouldLoadMore = false) => {
    const { token } = this.props.auth;
    const { pageSize, page, month, year } = this.state;
    this.setState({ isLoading: true })
    try {
      const from = moment(`01 ${month} ${year}`, 'DD MMM YYYY');
      const to = moment(from).add(1, 'months');
      const newPage = shouldLoadMore ? page + 1 : 1;
      let transactions = await api.fetchTransactionsByTimeFrame(from.unix(), to.unix(), token, newPage, pageSize);
      if (shouldLoadMore) {
        transactions.unshift(...this.state.transactionList);
      }
      const isFull = transactions.length < pageSize * newPage;
      this.setState({
        transactionList: transactions,
        transactions: getSortedBranchTransactions(transactions
          .filter(t => t.OrderStatus.length === 0 || t.OrderStatus[t.OrderStatus.length - 1].OrderStatusTypeId !== VOID_TRANSACTION_STATUS), this.state.isUserAlphaSorted),
        voidTransactions: getSortedBranchTransactions(transactions
          .filter(t => t.OrderStatus.length > 0 && t.OrderStatus[t.OrderStatus.length - 1].OrderStatusTypeId === VOID_TRANSACTION_STATUS), this.state.isUserAlphaSorted),
        page: newPage,
        isLoading: false,
        isFull
      });
    } catch (err) {
      console.log(err);
      this.setState({ isLoading: false })
    }
  }

  handleChangePeriod = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => this.loadTransactions());
  }

  toggleSortByUsers = () => {
    let isUserAlphaSorted = false;

    if (!this.state.isUserAlphaSorted) {
      isUserAlphaSorted = 'AZ';
    } else if (this.state.isUserAlphaSorted === 'AZ') {
      isUserAlphaSorted = 'ZA';
    } else {
      isUserAlphaSorted = false;
    }

    this.setState({ isUserAlphaSorted })
    this.loadTransactions();
  }

  exportValidTransactions = async (transactions) => {
    const { merchants, month, year } = this.state;
    const buffer = await generateTreatsureReportExcelFile(transactions, merchants, month, year);
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    const fileName = `[treatsure] Transaction tally - ${month} ${year}`;
    link.download = fileName;;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  render() {
    const months = moment.monthsShort();

    const transactionsInPeriod = this.state.transactions;

    const voidTransactionsInPeriod = this.state.voidTransactions;

    return (
      <div className="content" style={{ paddingLeft: 30, paddingRight: 30 }}>
        <div className={`loader-overlay ${this.state.isLoading ? '' : 'hidden'}`}>
          <div className='loader-container'>
            <div className="loader-circle" />
            <p className="loader-text">Loading in progress, please wait a few seconds</p>
          </div>
        </div>
        <Grid fluid>
          <Row>
            <ProperCard
              title="Combined Transactions"
            >
              <FormGroup>
                <ControlLabel>Select Period</ControlLabel>
                <FormControl
                  className="month-select"
                  componentClass="select"
                  placeholder="Select Month"
                  name="month"
                  value={this.state.month}
                  onChange={this.handleChangePeriod}
                  required >
                  {
                    months.map((option) => {
                      return <option key={option} value={option}>{option}</option>;
                    })
                  }
                </FormControl>
                <FormControl
                  className="year-select"
                  componentClass="select"
                  placeholder="Select Year"
                  name="year"
                  value={this.state.year}
                  onChange={this.handleChangePeriod}
                  required >
                  {
                    this.years.map((option) => {
                      return <option key={option} value={option}>{option}</option>;
                    })
                  }
                </FormControl>
                <Button className="table-action-button action-button btn-fill"
                  bsStyle="warning"
                  type="button"
                  style={{ marginLeft: 10, minWidth: 86 }}
                  disabled={this.state.isFull || this.state.isLoading !== false}
                  onClick={() => this.loadTransactions(true)}>
                  {!this.state.isFull ? 'Load More' : 'Loaded All'}
                </Button>
                <Button className="table-action-button action-button btn-fill"
                  bsStyle="warning"
                  type="button"
                  style={{ marginLeft: 10, minWidth: 40 }}
                  disabled={this.state.isLoading !== false}
                  onClick={this.toggleSortByUsers}>
                  {this.state.isUserAlphaSorted === 'AZ' && <i className="fas fa-sort-alpha-down"></i>}
                  {this.state.isUserAlphaSorted === 'ZA' && <i className="fas fa-sort-alpha-down-alt"></i>}
                  {!this.state.isUserAlphaSorted && <div><i className="fas fa-sort"></i> Sort</div>}
                </Button>
                {this.state.isLoading !== false ?
                  <span style={{ marginLeft: 10 }}>
                    Loading...
                  </span> : ''
                }
              </FormGroup>
              <Tabs defaultActiveKey={1} id="trans-tabs">
                <Tab eventKey={1} title="Valid Transactions">
                  <br />
                  {/* <ExcelDownloadButton
                    transactions={this.state.transactions}
                    year={this.state.year}
                    month={this.state.month}
                    isAdmin={this.props.roles.includes('admin')}
                  /> */}
                  <Button className="table-action-button action-button btn-fill"
                    bsStyle="success"
                    onClick={() => this.exportValidTransactions(transactionsInPeriod)}
                    type="button" >
                    <i className="fas fa-table"></i> Export selected month to excel
                  </Button>
                  <TransactionTab
                    {...this.props}
                    merchants={this.state.merchants}
                    transactions={transactionsInPeriod}
                    accountId={this.props.accountId}
                    token={this.props.token}
                    loadTransactions={this.loadTransactions}
                  />
                </Tab>
                <Tab eventKey={2} title="Void Transactions">
                  <TransactionTab
                    {...this.props}
                    merchants={this.state.merchants}
                    transactions={voidTransactionsInPeriod}
                    accountId={this.props.accountId}
                    isVoid={true}
                    token={this.props.token}
                    loadTransactions={this.loadTransactions} />
                </Tab>
              </Tabs>
              <br />
            </ProperCard>
          </Row>
        </Grid>
      </div>
    );
  }
}

const compareTransactions = (a, b) => {
  // Use redeemeedAt by default, use createdAt as a fallback
  // let aTime = a.redemptions[0] ? a.redemptions[0].redeemedAt : a.CreatedAt;
  // let bTime = b.redemptions[0] ? b.redemptions[0].redeemedAt : b.createdAt;
  return b.CreatedAt - a.CreatedAt;
};

function getSortedBranchTransactions(branchTransactions, isUserAlphaSorted = false) {
  const transactionsToSort = [];
  for (const transaction of branchTransactions) {
    transactionsToSort.push(JSON.parse(JSON.stringify(transaction)));
  }

  const compareTransactions = (a, b) => {
    // Use redeemeedAt by default, use createdAt as a fallback
    // let aTime = a.redemptions[0] ? a.redemptions[0].redeemedAt : a.CreatedAt;
    // let bTime = b.redemptions[0] ? b.redemptions[0].redeemedAt : b.CreatedAt;
    return b.CreatedAt - a.CreatedAt;
  };

  const compareTransactionsType = (a, b) => {
    if ((a.TransactionType || 'NA') < (b.TransactionType || 'NA')) { return -1; }
    if ((a.TransactionType || 'NA') > (b.TransactionType || 'NA')) { return 1; }
    return 0;
  }

  const compareUsersAZ = (a, b) => {
    if (a.Customer.Username < b.Customer.Username) { return -1; }
    if (a.Customer.Username > b.Customer.Username) { return 1; }
    return 0;
  };

  const compareUsersZA = (a, b) => {
    if (a.Customer.Username < b.Customer.Username) { return 1; }
    if (a.Customer.Username > b.Customer.Username) { return -1; }
    return 0;
  };

  if (isUserAlphaSorted === 'AZ') {
    transactionsToSort.sort(compareTransactionsType);
    transactionsToSort.sort(compareUsersAZ);
  } else if (isUserAlphaSorted === 'ZA') {
    transactionsToSort.sort(compareTransactionsType);
    transactionsToSort.sort(compareUsersZA);
  } else {
    transactionsToSort.sort(compareTransactions);
  }

  return transactionsToSort;
}

function TransactionTab(props) {
  const isVoid = props.isVoid;
  const transactions = props.transactions || [];

  let transactionGross = 0;
  let transactionNett = 0;
  for (const transaction of transactions) {
    transactionGross += (transaction.OfferTotal ? Number(transaction.OfferTotal) : 0);
    transactionNett += (transaction.NettTotal ? Number(transaction.NettTotal) : 0);
  }
  return (
    <div>
      <h4>{isVoid ? 'Void Transactions' : 'Valid Transactions'}</h4>
      {transactions.length <= 0 ? <div className="not-found-text"><i>No transactions were found for the selected period</i></div> :
        <Table hover bordered responsive className="transactions-table">
          <thead>
            <tr>
              <th rowSpan={2}>#</th>
              {props.roles.includes('admin') ? <th rowSpan={2}>Customer Username</th> : null}
              {props.roles.includes('admin') ? <th rowSpan={2}>Customer Email</th> : null}
              <th rowSpan={2}>Order ID</th>
              <th rowSpan={2}>Branch Name</th>
              <th rowSpan={2}>Created At</th>
              <th colSpan={3}>Order Details</th>
              <th rowSpan={2}>Payment Type</th>
              <th rowSpan={2}>Transaction Type</th>
              <th rowSpan={2}>Gross Total</th>
              <th rowSpan={2}>Nett Total</th>
              <th rowSpan={2}>Status</th>
              <th rowSpan={2}>Actions</th>
            </tr>
            <tr>
              <th>Product Name</th>
              <th>App price</th>
              <th>Quantity</th>
            </tr>
          </thead>
          {
            transactions.map((transaction, i) => {
              return (
                <TransactionRow {...props} transaction={transaction} i={i}
                  key={transaction.Id} merchants={props.merchants}
                  isVoid={isVoid} token={props.token} accountId={props.accountId}
                  loadTransactions={props.loadTransactions}
                  isAdmin={props.roles.includes('admin')} />
              )
            })
          }
          <tbody>
            <tr>
              {/*Different number of columns seen by admins vs regular users*/}
              <td colSpan={props.roles.includes('admin') ? 10 : 8}></td>
              <td>Total</td>
              <td>{transactionGross.toFixed(2)}</td>
              <td>{transactionNett.toFixed(2)}</td>
              <td></td>
            </tr>
          </tbody>
        </Table>
      }
    </div>
  );
}

function ExcelDownloadButton(props) {
  const yearMonth = props.year + props.month;
  let cellColor = "ffffffff";

  const button = (
    <Button className="table-action-button action-button btn-fill"
      bsStyle="success"
      type="button" >
      <i className="fas fa-table"></i> Export selected month to Excel
    </Button>);
  let dataSet = [
    {
      columns: [
        { title: "Order Id", width: { wpx: 100 } },
        { title: "Branch Name", width: { wpx: 100 } },
        { title: "Created At", width: { wpx: 180 } },
        { title: "Product Name", width: { wpx: 200 } },
        { title: "App price", width: { wpx: 100 } },
        { title: "Quantity", width: { wpx: 100 } },
        { title: "Payment Type", width: { wpx: 100 } },
        { title: "Transaction Type", width: { wpx: 100 } },
        { title: "Gross Total", width: { wpx: 100 } },
        { title: "Nett Total", width: { wpx: 100 } },
      ],
      data: []
    }
  ];
  if (props.isAdmin) {
    dataSet[0].columns.splice(0, 0,
      { title: "Customer username", width: { wpx: 150 } },
      { title: "Customer Email", width: { wpx: 200 } }
    );
  }

  let filename = 'Transactions' + yearMonth;

  let monthTransactions = props.transactions;
  if (monthTransactions) {
    let totalGross = 0;
    let totalNett = 0;

    monthTransactions.sort(compareTransactions);
    monthTransactions.forEach((transaction) => {
      if (transaction.OrderLineItems.length === 0) {
        // This transaction has no redemption => no row in the table
        return;
      }
      let rows = [];
      cellColor = cellColor === "ffffc9a6" ? "ffffffff" : "ffffc9a6"; // alternate row colour
      transaction.OrderLineItems.forEach((lineItem) => {
        let excelTime = unixToExcelTime(transaction.CreatedAt);

        rows.push([
          { value: transaction.OrderNumber.substring(8, 18).toUpperCase(), style: { fill: { patternType: "solid", fgColor: { rgb: cellColor } } } },
          { value: lineItem.MerchantBranch.BranchName, style: { fill: { patternType: "solid", fgColor: { rgb: cellColor } } } },
          { value: excelTime, style: { fill: { patternType: "solid", fgColor: { rgb: cellColor } }, numFmt: "dd/mm/yyyy   hh:mm AM/PM" } },
          { value: lineItem.ProductNameAtOrder, style: { fill: { patternType: "solid", fgColor: { rgb: cellColor } } } },
          { value: Number(lineItem.OfferAtOrder), style: { numFmt: "$0.00", fill: { patternType: "solid", fgColor: { rgb: cellColor } } } },
          { value: lineItem.Quantity, style: { fill: { patternType: "solid", fgColor: { rgb: cellColor } } } },
          { value: transaction.PaymentType && transaction.PaymentType.startsWith('card') ? 'Card' : 'Others', style: { fill: { patternType: "solid", fgColor: { rgb: cellColor } } } },
          { value: transaction.TransactionType || "", style: { fill: { patternType: "solid", fgColor: { rgb: cellColor } } } },
          { value: "", style: { fill: { patternType: "solid", fgColor: { rgb: cellColor } } } },
          { value: "", style: { fill: { patternType: "solid", fgColor: { rgb: cellColor } } } },
        ]);
        if (props.isAdmin) {
          rows[rows.length - 1].splice(0, 0,
            { value: transaction.Customer.Username, style: { fill: { patternType: "solid", fgColor: { rgb: cellColor } } } },
            { value: transaction.Customer.Email, style: { fill: { patternType: "solid", fgColor: { rgb: cellColor } } } }
          );
        }
      })
      totalGross += Number(transaction.OfferTotal);
      totalNett += Number(transaction.NettTotal);


      // Conditional to handle extra columns for admin
      rows[rows.length - 1][props.isAdmin ? 10 : 8] = { value: Number(transaction.OfferTotal), style: { numFmt: "$0.00", fill: { patternType: "solid", fgColor: { rgb: cellColor } } } };
      rows[rows.length - 1][props.isAdmin ? 11 : 9] = { value: Number(transaction.NettTotal), style: { numFmt: "$0.00", fill: { patternType: "solid", fgColor: { rgb: cellColor } } } };
      dataSet[0].data.push(...rows);
    });

    dataSet[0].data.push(["", "", "", "", "", "", "", ...(props.isAdmin ? ["", ""] : []), "Total",
      { value: totalGross, style: { numFmt: "$0.00" } },
      { value: totalNett, style: { numFmt: "$0.00" } }
    ])
  }


  return (
    <ExcelFile element={button} filename={filename}>
      <ExcelSheet dataSet={dataSet} name="Transactions" />
    </ExcelFile>
  );
}

// A helper function to generate a CSS style that restricts width of <td> elements
function limitWidth(width) {
  return {
    maxWidth: width,
    wordWrap: 'break-word',
    whiteSpace: 'normal'
  }
}

export default connect(state => ({
  auth: state.auth
}))(CombinedTransactions);
