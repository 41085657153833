import React, { Component } from 'react';
import {
  Grid, Row, Col, Tab, Tabs, Tooltip, OverlayTrigger,
  FormGroup, ControlLabel, FormControl, Table, Carousel
} from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Dropzone from 'react-dropzone';
import Compress from "react-image-file-resizer";
import ProperCard from '../../components/Card/ProperCard.jsx';
import BranchRow from '../../components/BranchRow/BranchRow.jsx';
import { FormInputs } from '../../components/FormInputs/FormInputs.jsx';
import Button from '../../elements/CustomButton/CustomButton.jsx';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { validateInputs } from '../../helper';

import moment from 'moment';
import api from '../../api';
import { connect } from 'react-redux';

const AREA_OPTIONS = [
  { value: 'North', label: 'North' },
  { value: 'South', label: 'South' },
  { value: 'East', label: 'East' },
  { value: 'West', label: 'West' },
  { value: 'Central', label: 'Central' }
];

class Branches extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFormLoading: false,
      selectedMerchant: '',
      branchName: '',
      email: '',
      description: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      postal: '',
      area: '',
      latitude: '',
      longitude: '',
      locationType: '',
      storeTypes: [],
      storeTypeOptions: [],
      officeNumber: '',
      mobileNumber: '',
      website: '',
      facebook: '',
      instagram: '',
      foodCategories: [],
      foodCategoryOptions: [],
      operatingHoursDescription: '',
      currDays: [],
      currBegin: '',
      currEnd: '',
      currTimeSlots: [],
      branchImages: [null],
      branchImageIndex: 0,
      selectedStoreType: '',
      merchants: [],
      merchantOptions: [],
      branches: [],
      filteredBranches: [],
      branchPositions: []
    };
  }

  componentDidMount() {
    this.loadBranches();
  }

  loadBranches = async () => {
    this.setState({ isFormLoading: true });

    const { token, branchesPermissions, roles } = this.props.auth;
    try {
      let { storeTypeOptions, selectedStoreType, foodCategoryOptions } = this.state;
      if (storeTypeOptions.length === 0) {
        const storeTypes = await api.fetchStoreTypes(token);
        storeTypeOptions = storeTypes.map(type => ({ value: type.Id, label: type.Type }));
        storeTypeOptions.push({ value: '', label: 'N/A' });
        selectedStoreType = storeTypeOptions[0].value;
      }

      if (foodCategoryOptions.length === 0) {
        const foodCategories = await api.fetchFoodCategories(token);
        foodCategoryOptions = foodCategories.map(type => ({ value: type.Id, label: type.Type }));
      }

      let branches = [];
      let filteredBranches = [];
      let merchants = [];
      if (roles.includes('admin')) {
        const results = await Promise.all([
          api.fetchBranches(token, 1, 200),
          api.fetchMerchants(token)
        ]);
        branches = results[0];
        filteredBranches = branches.filter(b => {
          if (!selectedStoreType) return b.MerchantBranchStoreType.length === 0;
          return b.MerchantBranchStoreType.some(s => s.StoreTypeId === Number(selectedStoreType));
        });
        merchants = results[1];
      } else {
        branches = (await Promise.all(branchesPermissions.map(permission => api.fetchBranch(permission.id, token))))
          .filter(r => r !== null);
        merchants = (await Promise.all(branches.map(branch => api.fetchMerchant(branch.MerchantId, token))))
          .reduce((list, item) => {
            return list.some(m => m.Id === item.Id) ? list : [...list, item]
          }, []);
        filteredBranches = branches;
      }

      this.setState({
        merchantOptions: merchants.map(m => ({ value: m.Id, label: m.Name })),
        merchants,
        branches,
        filteredBranches,
        selectedStoreType,
        storeTypeOptions,
        foodCategoryOptions,
        branchPositions: branches.sort((a, b) => a.Position - b.Position),
        isFormLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{error.message}</strong>,
        level: 'error'
      });
      this.setState({ isFormLoading: false });
    }
  }

  handlePhotoSelect = async (file, index) => {
    try {
      const image = await resizeFile(file);
      document.getElementById(`branch-img-${index}`).src = URL.createObjectURL(image);
      this.setState(oldState => {
        oldState.branchImages[index] = image;
        return { branchImages: oldState.branchImages };
      });
    } catch (error) {
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{error.message}</strong>,
        level: 'error'
      });
    }
  }

  handleCarouselSelect = (selectedIndex) => {
    this.setState({ branchImageIndex: selectedIndex })
  }

  handleAddBranchImage = () => {
    this.setState(oldState => {
      oldState.branchImages.push(null);
      return {
        branchImages: oldState.branchImages,
        branchImageIndex: oldState.branchImages.length - 1
      };
    });
  }

  handleAddTimeSlot = () => {
    const currBegin = this.state.currBegin;
    const currEnd = this.state.currEnd;

    if (this.state.currDays.length === 0 || !currBegin || !currEnd) {
      return;
    }

    const days = this.state.currDays.map((day) => {
      return day.value;
    });

    const currTimeSlots = this.state.currTimeSlots;
    currTimeSlots.push({
      days, begin: currBegin, end: currEnd
    });
    this.setState({ currTimeSlots });
  }

  handleSelectChange = (name, val) => {
    this.setState({ [name]: val });
  }

  deleteTimeSlot = (i) => {
    const currTimeSlots = this.state.currTimeSlots.slice();
    currTimeSlots.splice(i, 1);
    this.setState({ currTimeSlots });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleFoodCategoriesChange = (selectedCategories) => {
    this.setState({
      foodCategories: selectedCategories
    });
  }

  handleStoreTypeChange = async (e) => {
    const storeTypeId = e.target.value;
    const { token, branchesPermissions, roles } = this.props.auth;
    const { branches } = this.state;
    this.setState({ isFormLoading: true });
    try {
      let filteredBranches = [];
      if (roles.includes('admin')) {
        filteredBranches = branches.filter(b => {
          if (!storeTypeId) return b.MerchantBranchStoreType.length === 0;
          return b.MerchantBranchStoreType.some(s => s.StoreTypeId === Number(storeTypeId));
        });
      } else {
        filteredBranches = (await Promise.all(branchesPermissions.map(permission => api.fetchBranch(permission.id, token))))
          .filter(r => r !== null);
      }
      this.setState({
        selectedStoreType: storeTypeId,
        filteredBranches,
        branchPositions: filteredBranches.sort((a, b) => a.Position - b.Position),
        isFormLoading: false,
      })
    } catch (error) {
      this.setState({ isFormLoading: false });
    }
  }

  handleAdd = async (e) => {
    const isInputsValid = validateInputs(e.target.closest('form'), this);
    if (!isInputsValid) {
      return;
    }

    this.setState({ isFormLoading: true });

    const foodCategories = this.state.foodCategories;

    const branchImages = this.state.branchImages.filter(image => image !== null);

    const data = {
      merchantId: this.state.selectedMerchant,
      email: this.state.email,
      branchName: this.state.branchName,
      addressLine1: this.state.address1,
      addressLine2: this.state.address2,
      postalCode: this.state.postal,
      area: this.state.area,
      latitude: parseFloat(this.state.latitude),
      longitude: parseFloat(this.state.longitude),
      locationType: this.state.locationType,
      city: this.state.city,
      state: this.state.state,
      officeNumber: this.state.officeNumber,
      faxNumber: this.state.faxNumber,
      mobileNumber: this.state.mobileNumber,
      availabilityDescription: this.state.operatingHoursDescription,
      branchDescription: this.state.description,
    }

    try {
      const { token } = this.props.auth;
      const result = await api.createBranch(data, token);
      const branchId = result.Id;

      for (const timeSlot of this.state.currTimeSlots) {
        const begin = moment(timeSlot.begin, 'HH:mm').diff(moment().startOf('day'), 'seconds');
        const end = moment(timeSlot.end, 'HH:mm').diff(moment().startOf('day'), 'seconds');
        for (const day of timeSlot.days) {
          const schedule = {
            day,
            opening: begin,
            closing: end,
            lastRedeem: end
          };
          const result = await api.createBranchSchedule(branchId, schedule, token);
          console.log(result);
        }
      }

      const storeTypes = this.state.storeTypes;
      for (const storeType of storeTypes) {
        const storeTypeId = storeType.value;
        const result = await api.createBranchStoreType(branchId, storeTypeId);
        console.log(result);
      }

      if (this.state.website) {
        await api.createBranchLink(branchId, this.state.website, "website", token)
      }
      if (this.state.facebook) {
        await api.createBranchLink(branchId, this.state.facebook, "facebook", token)
      }
      if (this.state.instagram) {
        await api.createBranchLink(branchId, this.state.instagram, "instagram", token)
      }

      await Promise.all(foodCategories.map(foodCategory => api.createBranchFoodCategory(branchId, foodCategory.value, token)));

      await Promise.all(branchImages.map(image => api.uploadBranchImage(branchId, image, token)));

      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-like2" />,
        message: <strong>Branch created.</strong>,
        level: 'success'
      });
      this.state.branchImages.map((image, index) => document.getElementById(`branch-img-${index}`).src = '');
      this.setState({
        merchantId: '',
        email: '',
        description: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        postal: '',
        area: '',
        latitude: '',
        longitude: '',
        locationType: '',
        storeTypes: [],
        officeNumber: '',
        mobileNumber: '',
        website: '',
        facebook: '',
        instagram: '',
        operatingHoursDescription: '',
        foodCategories: [],
        branchImageIndex: 0,
        currTimeSlots: []
      }, () => {
        setTimeout(() => {
          this.setState({
            branchImages: [null]
          });
        }, 1000);
      });
      this.loadBranches();
    } catch (err) {
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{err.message}</strong>,
        level: 'error'
      });
    } finally {
      this.setState({ isFormLoading: false });
    }
  }

  handleSavePosition = async (e) => {
    this.setState({ isFormLoading: true });
    const branches = this.state.branchPositions.map((branch, index) => ({
      branchId: branch.Id,
      locationType: branch.LocationType,
      position: index + 1
    }));
    try {
      const result = await api.updateBranchPositions(branches, this.props.auth.token);
      console.log(result);
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-like2" />,
        message: <strong>Branch positions saved.</strong>,
        level: 'success'
      });
      this.setState({
        branchPositions: result.slice().sort((a, b) => a.Position - b.Position)
      });
    } catch (error) {
      this.props.addNotification({
        title: <span data-notify="icon" className="pe-7s-attention" />,
        message: <strong>{error.message}</strong>,
        level: 'error'
      });
    } finally {
      this.setState({ isFormLoading: false });
    }
  }

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const branchPositions = reorder(
      this.state.branchPositions,
      result.source.index,
      result.destination.index
    );

    this.setState({ branchPositions });
  }

  render() {
    const merchantOptions = this.state.merchantOptions;

    const storeTypeOptions = this.state.storeTypeOptions;

    const branchesTab = (
      <Tab eventKey={1} title="Branches">
        <h4>Branches</h4>
        <FormGroup>
          <ControlLabel>Select Store Type</ControlLabel>
          <FormControl
            className="month-select"
            componentClass="select"
            placeholder="Select Store Type"
            name="selectedStoreType"
            value={this.state.selectedStoreType}
            onChange={this.handleStoreTypeChange}>
            {
              storeTypeOptions.map((option) => {
                return <option key={option.value} value={option.value}>{option.label}</option>;
              })
            }
          </FormControl>
        </FormGroup>
        <Table hover bordered responsive className="branches-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Merchant</th>
              <th>Branch Name</th>
              <th>Email</th>
              <th>Address</th>
              <th>Area</th>
              <th>Actions</th>
            </tr>
          </thead>
          {
            this.state.filteredBranches.map((branch, i) => {
              return (
                <BranchRow
                  {...this.props}
                  key={branch.Id}
                  token={this.props.auth.token}
                  branch={branch} i={i}
                  merchant={this.state.merchants.find(m => m.Id === branch.MerchantId)}
                  loadBranches={this.loadBranches}
                  storeTypeOptions={storeTypeOptions}
                  foodCategoryOptions={this.state.foodCategoryOptions}
                />
              );
            })
          }
        </Table>
      </Tab>
    );

    const branchPositionsTab = (
      <Tab eventKey={2} title="Branch Positions">
        <h4>Branch Positions</h4>
        <FormGroup>
          <ControlLabel>Select Store Type</ControlLabel>
          <FormControl
            className="month-select"
            componentClass="select"
            placeholder="Select Store Type"
            name="selectedStoreType"
            value={this.state.selectedStoreType}
            onChange={this.handleStoreTypeChange}>
            {
              storeTypeOptions.map((option) => {
                return <option key={option.value} value={option.value}>{option.label}</option>;
              })
            }
          </FormControl>
        </FormGroup>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
              >
                {this.state.branchPositions.map((branch, i) => {
                  const merchant = this.state.merchants.find(m => m.Id === branch.MerchantId);
                  return BranchDraggableRow(branch, merchant, i)
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Button className="save-positions-button btn-fill"
          bsStyle="warning"
          type="button"
          onClick={this.handleSavePosition}>
          <i className="fas fa-save"></i> Save Positions
        </Button>
      </Tab>
    );

    return (
      <div className="content" style={{ paddingLeft: 30, paddingRight: 30 }}>
        <Grid fluid>
          <Row>
            <ProperCard
              title="Create Branch"
            >
              <form>
                <div className={'loader-overlay ' + (this.state.isFormLoading ? '' : 'hidden')}><div className="loader"></div></div>
                <FormGroup>
                  <ControlLabel>Select Merchant</ControlLabel>
                  <FormControl
                    componentClass="select"
                    placeholder="Select Merchant"
                    name="merchantId"
                    value={this.state.selectedMerchant}
                    onChange={this.handleChange}
                    required >
                    <option disabled value="">Select Merchant</option>
                    {
                      merchantOptions.map((option, i) => {
                        return <option key={option.value} value={option.value}>{option.label}</option>;
                      })
                    }
                  </FormControl>
                </FormGroup>
                <FormInputs
                  ncols={["col-md-12"]}
                  proprieties={[
                    {
                      label: "branch name",
                      type: "text",
                      bsClass: "form-control",
                      placeholder: "branch name",
                      value: this.state.branchName,
                      name: "branchName",
                      onChange: this.handleChange,
                      spellCheck: false
                    }
                  ]}
                />
                <FormInputs
                  ncols={["col-md-12"]}
                  proprieties={[
                    {
                      label: "email address",
                      type: "email",
                      bsClass: "form-control",
                      placeholder: "email address",
                      value: this.state.email,
                      name: "email",
                      onChange: this.handleChange,
                      spellCheck: false
                    }
                  ]}
                />
                <FormGroup>
                  <ControlLabel>Branch Description</ControlLabel>
                  <FormControl
                    componentClass="textarea"
                    placeholder="enter branch description"
                    name="description"
                    value={this.state.description}
                    onChange={this.handleChange}
                  />
                </FormGroup>
                <FormInputs
                  ncols={["col-md-6", "col-md-6"]}
                  proprieties={[
                    {
                      label: "address line 1",
                      type: "text",
                      bsClass: "form-control",
                      placeholder: "address line 1",
                      value: this.state.address1,
                      name: "address1",
                      onChange: this.handleChange,
                      spellCheck: false
                    },
                    {
                      label: "address line 2",
                      type: "text",
                      bsClass: "form-control",
                      placeholder: "address line 2",
                      value: this.state.address2,
                      name: "address2",
                      onChange: this.handleChange,
                      spellCheck: false
                    }
                  ]}
                />
                <Row>
                  <Col md={2}>
                    <FormGroup>
                      <ControlLabel>Select Area</ControlLabel>
                      <FormControl
                        componentClass="select"
                        placeholder="Select Area"
                        name="area"
                        value={this.state.area}
                        onChange={this.handleChange}
                        required >
                        <option disabled value="">Select Area</option>
                        {
                          AREA_OPTIONS.map((option, i) => {
                            return <option key={option.value} value={option.value}>{option.label}</option>;
                          })
                        }
                      </FormControl>
                    </FormGroup>
                  </Col>
                </Row>
                <FormInputs
                  ncols={["col-md-4", "col-md-4", "col-md-4"]}
                  proprieties={[
                    {
                      label: "city",
                      type: "text",
                      bsClass: "form-control",
                      placeholder: "city",
                      value: this.state.city,
                      name: "city",
                      onChange: this.handleChange,
                      spellCheck: false
                    },
                    {
                      label: "state",
                      type: "text",
                      bsClass: "form-control",
                      placeholder: "state",
                      value: this.state.state,
                      name: "state",
                      onChange: this.handleChange,
                      spellCheck: false
                    },
                    {
                      label: "postal code",
                      type: "text",
                      bsClass: "form-control",
                      placeholder: "postal code",
                      value: this.state.postal,
                      name: "postal",
                      onChange: this.handleChange,
                      spellCheck: false
                    }
                  ]}
                />
                <FormInputs
                  ncols={["col-md-6", "col-md-6"]}
                  proprieties={[
                    {
                      label: "latitude",
                      type: "text",
                      bsClass: "form-control",
                      placeholder: "latitude",
                      value: this.state.latitude,
                      name: "latitude",
                      onChange: this.handleChange,
                      spellCheck: false
                    },
                    {
                      label: "longitude",
                      type: "text",
                      bsClass: "form-control",
                      placeholder: "longitude",
                      value: this.state.longitude,
                      name: "longitude",
                      onChange: this.handleChange,
                      spellCheck: false
                    },
                    {
                      label: "location type",
                      type: "text",
                      bsClass: "form-control",
                      placeholder: "location type",
                      value: this.state.locationType,
                      name: "locationType",
                      onChange: this.handleChange,
                      spellCheck: false
                    }
                  ]}
                />
                <FormInputs
                  ncols={["col-md-6", "col-md-6"]}
                  proprieties={[
                    {
                      label: "office number",
                      type: "text",
                      bsClass: "form-control",
                      placeholder: "office number",
                      value: this.state.officeNumber,
                      name: "officeNumber",
                      onChange: this.handleChange,
                      spellCheck: false
                    },
                    {
                      label: "mobile number",
                      type: "text",
                      bsClass: "form-control",
                      placeholder: "mobile number",
                      value: this.state.mobileNumber,
                      name: "mobileNumber",
                      onChange: this.handleChange,
                      spellCheck: false
                    }
                  ]}
                />
                <FormInputs
                  ncols={["col-md-4", "col-md-4", "col-md-4"]}
                  proprieties={[
                    {
                      label: "website",
                      type: "text",
                      bsClass: "form-control",
                      placeholder: "website",
                      value: this.state.website,
                      name: "website",
                      onChange: this.handleChange,
                      spellCheck: false
                    },
                    {
                      label: "facebook",
                      type: "text",
                      bsClass: "form-control",
                      placeholder: "facebook",
                      value: this.state.facebook,
                      name: "facebook",
                      onChange: this.handleChange,
                      spellCheck: false
                    },
                    {
                      label: "instagram",
                      type: "text",
                      bsClass: "form-control",
                      placeholder: "instagram",
                      value: this.state.instagram,
                      name: "instagram",
                      onChange: this.handleChange,
                      spellCheck: false
                    }
                  ]}
                />
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <ControlLabel>store types</ControlLabel>
                      <Select.Creatable
                        options={storeTypeOptions}
                        multi={true}
                        value={this.state.storeTypes}
                        placeholder="select store types"
                        onChange={(val) => {
                          val = val.map((storeTypeVal) => {
                            return storeTypeVal.value;
                          });
                          this.handleSelectChange('storeTypes', val);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <ControlLabel>food categories</ControlLabel>
                      <Select.Creatable
                        options={this.state.foodCategoryOptions}
                        multi={true}
                        onChange={this.handleFoodCategoriesChange}
                        value={this.state.foodCategories}
                        placeholder="select food categories"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <h4>Branch Images</h4>
                <Carousel interval={null} activeIndex={this.state.branchImageIndex} onSelect={this.handleCarouselSelect}>
                  {
                    this.state.branchImages.map((image, index) => {
                      return (<Carousel.Item key={index}>
                        <Dropzone onDropAccepted={files => this.handlePhotoSelect(files[0], index)} accept="image/png, image/jpeg">
                          {({ getRootProps, getInputProps, isDragActive }) => (
                            <section>
                              <div {...getRootProps({ style: { ...dragNDropStyle, ...(isDragActive ? { borderColor: '#ff5c0e' } : {}) } })}>
                                <img className="branch-product-image" style={{ height: 100, marginLeft: 'auto', marginRight: 'auto' }} id={`branch-img-${index}`} alt="" />
                                <input {...getInputProps()} />
                                <p>Drag 'n' drop, or click to select file</p>
                              </div>
                            </section>
                          )}
                        </Dropzone>
                      </Carousel.Item>)
                    })
                  }
                </Carousel>
                <br />
                <Button className="table-action-button action-button btn-fill"
                  bsStyle="warning"
                  type="button"
                  onClick={this.handleAddBranchImage}>
                  <i className="fas fa-plus"></i> Add Image
                </Button>

                <h4>Schedule Description</h4>
                <FormGroup>
                  <ControlLabel>Enter Schedule Description</ControlLabel>
                  <FormControl
                    componentClass="textarea"
                    placeholder="schedule"
                    name="operatingHoursDescription"
                    value={this.state.operatingHoursDescription}
                    onChange={this.handleChange}
                  />
                </FormGroup>

                <h4>Schedule Settings</h4>
                <Row>
                  <Col sm={6}>
                    <FormGroup>
                      <ControlLabel>Select Days</ControlLabel>
                      <Select
                        options={[
                          { value: 'mon', label: 'Mon' },
                          { value: 'tue', label: 'Tue' },
                          { value: 'wed', label: 'Wed' },
                          { value: 'thu', label: 'Thu' },
                          { value: 'fri', label: 'Fri' },
                          { value: 'sat', label: 'Sat' },
                          { value: 'sun', label: 'Sun' },
                        ]}
                        multi={true}
                        placeholder="select days"
                        value={this.state.currDays}
                        onChange={(val) => {
                          this.setState({ currDays: val });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={3}>
                    <FormGroup>
                      <ControlLabel>Begin</ControlLabel>
                      <FormControl
                        type="time"
                        placeholder="begin time"
                        onChange={this.handleChange}
                        value={this.state.currBegin}
                        name="currBegin"
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={3}>
                    <FormGroup>
                      <ControlLabel>End</ControlLabel>
                      <FormControl
                        type="time"
                        placeholder="end time"
                        onChange={this.handleChange}
                        value={this.state.currEnd}
                        name="currEnd"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Button className="table-action-button action-button btn-fill"
                  bsStyle="warning"
                  type="button"
                  onClick={this.handleAddTimeSlot}>
                  <i className="fas fa-plus"></i> Add Time Slot
                </Button>
                <Row>
                  <Col md={12}>
                    <Table striped hover responsive>
                      <thead>
                        <tr>
                          <th>days</th>
                          <th>begin</th>
                          <th>end</th>
                          <th width="10%"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.currTimeSlots.map((timeSlot, i) => {
                            return (
                              <tr key={Math.random()}>
                                <td>{JSON.stringify(timeSlot.days).replace(/[[\]"]/g, '')}</td>
                                <td>{timeSlot.begin}</td>
                                <td>{timeSlot.end}</td>
                                <td width="10%">
                                  <Button className="table-action-button action-button btn-fill"
                                    bsStyle="danger"
                                    type="button"
                                    onClick={() => {
                                      this.deleteTimeSlot(i);
                                    }}
                                  >
                                    <i className="fas fa-trash"></i>
                                  </Button>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Button className="table-action-button action-button btn-fill"
                  bsStyle="warning"
                  type="button"
                  onClick={this.handleAdd}>
                  Create Branch
                </Button>
              </form>
            </ProperCard>
          </Row>
          <Row>
            <ProperCard>
              <form id="edit-branch-form">
                <Tabs defaultActiveKey={1} id="branches-tabs">
                  {branchesTab}
                  {branchPositionsTab}
                </Tabs>
              </form>
            </ProperCard>
          </Row>
        </Grid>
      </div>
    );
  }
}

const BranchDraggableRow = (branch, merchant, i) => {
  const logo = merchant.Logo;
  let merchantLogo;
  if (logo) {
    const tooltip = (
      <Tooltip className="custom-tooltip" id={`tooltip-${merchant.Id}`}>
        <img className="logo-zoomed" src={logo} alt="logo" />
      </Tooltip>
    );
    merchantLogo = (
      <OverlayTrigger placement="left" overlay={tooltip}>
        <img className="merchant-logo-drag" src={logo} alt="logo" />
      </OverlayTrigger>);
  } else {
    merchantLogo = <span className="no-field-text text-nowrap"><em>No Logo</em></span>;
  }

  return (
    <Draggable key={branch.Id} draggableId={branch.Id} index={i}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          <span className="merchant-position">{branch.Position}</span>
          {merchantLogo}
          <span className="merchant-name">{branch.BranchName}</span>
        </div>
      )}
    </Draggable>
  );
}

function reorder(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

function getItemStyle(isDragging, draggableStyle) {
  return {
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    margin: `0 0 8px 0`,
    border: '1px #ddd solid',

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'white',

    // styles we need to apply on draggables
    ...draggableStyle,
  };
}

const dragNDropStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '5px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const resizeFile = (file) =>
  new Promise((resolve) => {
    Compress.imageFileResizer(
      file,
      480,
      480,
      "PNG",
      70,
      0,
      (uri) => {
        resolve(uri);
      },
      "blob"
    );
  });

export default connect(state => ({
  auth: state.auth
}))(Branches);
