import React, { Component } from 'react';


export class ContentCard extends Component{
    render(){
        return (
            <div className={"card"+(this.props.plain ? " card-plain":"")}>
                <div className={"content"
                    + (this.props.ctAllIcons ? " all-icons":"")
                    + (this.props.ctTableFullWidth ? " table-full-width":"")
                    + (this.props.ctTableResponsive ? " table-responsive":"")
                    + (this.props.ctTableUpgrade ? " table-upgrade":"")}>

                    {this.props.content}
                </div>
            </div>
        );
    }
}

export default ContentCard;
