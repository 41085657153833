import React, { Component } from 'react';
import { FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import Button from '../../elements/CustomButton/CustomButton.jsx';
import {validateInputs} from '../../helper';
import 'react-day-picker/lib/style.css';

class OrderEmailRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditing: false,
            isFormLoading: false,
            email: this.props.orderEmail.email,
            textMessage: this.props.orderEmail.textMessage,
        };
        
        this.handleEdit = this.handleEdit.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    handleDelete() {
        fetch(`/orderEmails/${this.props.orderEmail._id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'access-token': this.props.token
            }
        }).then((result) => {
            return result.json();
        }).then((result) => {
            if (result.status !== 200) {
                this.props.addNotification({
                    title: <span data-notify="icon" className="pe-7s-attention" />,
                    message: <strong>{result.details}</strong>,
                    level: 'error'
                });
                return;
            }
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-like2" />,
                message: <strong>{result.details}</strong>,
                level: 'success'
            });

            this.setState({ isExpanded: false });
            this.props.loadOrderEmails();
        });
    }

    handleEdit() {
         this.setState({ isEditing: true });
    }

    handleSave(e) {
        const isInputsValid = validateInputs(e.target.closest('tbody'), this);
        if (!isInputsValid) {
            return;
        }
        const orderEmailId = this.props.orderEmail._id;
        const email = this.state.email;     
        const textMessage = this.state.textMessage;

        const body  = JSON.stringify({
            email,
            textMessage
        });
        
        fetch(`/orderEmails/${orderEmailId}` , {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'access-token': this.props.token
            },
            body
        }).then((result) => {
            return result.json();
        }).then((result) => {
            if (result.status !== 200) {
                this.props.addNotification({
                    title: <span data-notify="icon" className="pe-7s-attention" />,
                    message: <strong>{result.details}</strong>,
                    level: 'error'
                });
                return;
            }
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-like2" />,
                message: <strong>{result.details}</strong>,
                level: 'success'
            });

            this.setState({
                isEditing: false
            });
            this.props.loadOrderEmails();
        });
    }

    handleChange(e) {
          this.setState({ [e.target.name]: e.target.value });
    }

    handleCheck(e) {
        this.setState({[e.target.name]: e.target.checked});
    }

    handleCancel() {
        const orderEmail = this.props.orderEmail;
        this.setState({
            isEditing: false,
            email: orderEmail.email,
            textMessage: orderEmail.textMessage
        });
    }

    render() {
        const orderEmail = this.props.orderEmail;
        return (
            <tbody>
                <tr className={this.state.isEditing ? 'is-editing-row' : ''}>
                    <td>{this.props.i + 1}</td>
                    <td>{orderEmail.email}</td>
                    <td className="text-formatted">{orderEmail.textMessage}</td>
                    <td className="text-nowrap">
                        <Button className="table-action-button action-button btn-fill"
                                bsStyle="warning"
                                type="button"
                                onClick={this.state.isEditing ? this.handleSave : this.handleEdit}
                        >
                            {this.state.isEditing ? <i className="fas fa-check"></i> : <i className="fas fa-edit"></i>}
                        </Button>
                        {this.state.isEditing ?
                                <Button className="table-action-button action-button btn-fill"
                                        bsStyle="warning"
                                        type="button"
                                        onClick={this.handleCancel}>
                                    <i className="fas fa-times"></i>
                                </Button> : null
                        }
                        <Button className="table-action-button action-button btn-fill"
                                bsStyle="danger"
                                type="button"
                                onClick={this.handleDelete}
                        >
                            <i className="fas fa-trash"></i>
                        </Button>
                    </td>
                </tr>
                {this.state.isEditing ?
                    <tr>
                        <td colSpan={11}>
                        <FormGroup>
                            <ControlLabel>Enter Email Address</ControlLabel>
                            <FormControl
                                type="email"
                                placeholder="Email Address"
                                name="email"
                                value={this.state.email}
                                onChange={this.handleChange}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Enter Email Message</ControlLabel> 
                            <FormControl                
                                componentClass="textarea"
                                placeholder="Email Message"
                                name="textMessage"
                                value={this.state.textMessage}
                                onChange={this.handleChange}
                                required
                            />
                        </FormGroup>
                        </td>
                    </tr> : null
                }
            </tbody>
        );
    }
}

export default OrderEmailRow;
