import { LOGIN, LOGOUT, SELECT_BRANCH, LOAD_ACCOUNT, REFRESH_TOKEN } from "../actions/type";

const initialState = {
  token: null,
  accountId: null,
  branchId: null,
  branchesPermissions: [],
  roles: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        token: action.token,
        accountId: action.accountId,
        branchesPermissions: action.branchesPermissions,
        roles: action.roles,
      };
    case LOAD_ACCOUNT:
      return {
        ...state,
        branchesPermissions: action.branchesPermissions,
        roles: action.roles,
      };
    case REFRESH_TOKEN:
      return {
        ...state,
        token: action.token,
      };
    case LOGOUT:
      return {
        ...initialState,
      };
    case SELECT_BRANCH:
      return {
        ...state,
        branchId: action.branchId,
      };
    default:
      break;
  }
  return state;
}
