import React, { Component } from 'react';
import { OverlayTrigger, Tooltip, FormGroup, FormControl, ControlLabel, Label } from 'react-bootstrap';
import moment from 'moment';

import Button from '../../elements/CustomButton/CustomButton.jsx';
import { validateInputs } from '../../helper';

const STATUS_LABEL = {
    PENDING: 'default',
    CONFIRMED: 'warning',
    COMPLETED: 'success'
}

class OrderRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditing: false,
            status: this.props.order.status,
            quantity: this.props.order.quantity
        };

        this.handleEdit = this.handleEdit.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.updateListing = this.updateListing.bind(this);
    }

    handleEdit() {
        this.setState({ isEditing: true });
    }

    handleSave(e) {

        if (!window.confirm('Are you sure you want to save?')) {
            return;
        }

        if (this.state.quantity <= 0) {
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-attention" />,
                message: <strong>Quantity must be greater than 0</strong>,
                level: 'error'
            });
            return;
        }

        const isInputsValid = validateInputs(e.target.closest('tr'), this);
        if (!isInputsValid) {
            return;
        }
        const customerId = this.props.order.customer._id;
        const listingId = this.props.order.listing._id;
        const quantity = this.state.quantity;
        const status = this.state.status;
        const delta = this.props.order.quantity - quantity;

        const body = JSON.stringify({
            customerId,
            listingId,
            quantity,
            status
        });

        fetch('/orders', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'access-token': this.props.token
            },
            body
        }).then((result) => {
            return result.json();
        }).then((result) => {
            if (result.status !== 200) {
                this.props.addNotification({
                    title: <span data-notify="icon" className="pe-7s-attention" />,
                    message: <strong>{result.details}</strong>,
                    level: 'error'
                });
                return;
            }
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-like2" />,
                message: <strong>{result.details}</strong>,
                level: 'success'
            });

            this.setState({ isEditing: false });

        }).then(() => this.updateListing(listingId, delta))
            .then(this.props.loadOrders);
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleCancel() {
        this.setState({
            isEditing: false,
            status: this.props.order.status,
            quantity: this.props.order.quantity
        });
    }

    handleDelete() {

        if (!window.confirm('Are you sure you want to delete this item?')) {
            return;
        }

        const body = JSON.stringify({
            customerId: this.props.order.customer._id,
            listingId: this.props.order.listing._id
        });
        const delta = this.props.order.quantity;

        fetch('/orders', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'access-token': this.props.token
            },
            body
        }).then((result) => {
            return result.json();
        }).then((result) => {
            if (result.status !== 200) {
                this.props.addNotification({
                    title: <span data-notify="icon" className="pe-7s-attention" />,
                    message: <strong>{result.details}</strong>,
                    level: 'error'
                });
                return;
            }
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-like2" />,
                message: <strong>{result.details}</strong>,
                level: 'success'
            });

            this.setState({ isExpanded: false });
        }).then(() => this.updateListing(this.props.order.listing._id, delta))
            .then(this.props.loadOrders).then(this.props.loadBranch);
    }

    // Update a listing's quantity by +delta 
    // Used to update the listings's remaining quantity after editing an order
    updateListing(listingId, delta) {
        let newQuantity;
        return fetch('/listings/' + listingId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'access-token': this.props.token
            }
        }).then((result) => {
            return result.json();
        }).then((result) => {
            newQuantity = result.result.listing.remaining + delta;
            if (newQuantity < 1) {
                newQuantity = 1;
            }
            // 2. Update the remaining quantity in the listing
            const oldListing = result.result.listing;
            let listingBody = JSON.stringify({
                offer: oldListing.offer,
                total: oldListing.total,
                remaining: newQuantity,
                postAt: oldListing.postAt,
                removeAt: oldListing.removeAt
            });
            return fetch('/listings/' + oldListing._id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'access-token': this.props.token
                },
                body: listingBody
            });

        }).then((result) => {
            return result.json();
        }).then((result) => {
            if (result.status !== 200) {
                this.props.addNotification({
                    title: <span data-notify="icon" className="pe-7s-attention" />,
                    message: <strong>{result.details}</strong>,
                    level: 'error'
                });
                return;
            }
            // Notification to show new remaining quantity
            this.props.addNotification({
                title: <span data-notify="icon" className="pe-7s-like2" />,
                message: <strong>{`Updated listing, remaining quantity = ${newQuantity}`}</strong>,
                level: 'warning'
            });
        })
    }

    render() {
        const order = this.props.order;
        const product = order.listing.product;
        let productImageCellContents;
        const photo = product.photo;
        if (!photo) {
            productImageCellContents = <div className="no-field-text text-nowrap"><em>No Photo</em></div>;
        } else {
            const tooltip = (
                <Tooltip className="custom-tooltip" id={'tooltip-' + product._id}>
                    <img className="product-image-zoomed" src={photo} alt="" />
                </Tooltip>
            );
            productImageCellContents = (
                <OverlayTrigger placement="right" overlay={tooltip}>
                    <img className="product-image" src={photo} alt="" />
                </OverlayTrigger>
            );
        }

        const i = this.props.i;
        return (
            <tbody>
                <tr className={this.state.isEditing ? 'is-editing-row' : ''}>
                    {this.props.showCheckbox ? <td className="checkbox-cell"><input type="checkbox" name={this.props.checkboxType} data-listing-id={this.props.order.listing.listingId}
                        data-customer-id={this.props.order.customer.customerId} onChange={this.props.handleCheck} /></td> : null}
                    <td>{i + 1}</td>
                    <td style={limitWidth(120)}>{order.listing._id + this.props.order.CreatedAt}</td>
                    <td style={limitWidth(120)}>{order.customer._id}</td>
                    {this.props.isAdmin && <td style={limitWidth(150)}>{order.customer.email}</td>}
                    <td style={limitWidth(150)}>{order.customer.username}</td>
                    {/* {this.props.isAdmin && <td>{order.branchName || <div className="no-field-text text-nowrap"><em>NA</em></div>}</td>} */}
                    <td>{productImageCellContents}</td>
                    <td>{product.name}</td>
                    {!this.props.hidePrice ? <td>{product.price.toFixed(2)}</td> : null}
                    <td>{order.listing.offer.toFixed(2)}</td>
                    <td>{order.quantity}</td>
                    <td>{moment.unix(this.props.order.CreatedAt).format('ddd, D MMM YYYY, HH:mm')}</td>
                    <td><Label bsStyle={STATUS_LABEL[order.status]}>{order.status}</Label></td>
                    {this.props.isAdmin &&
                    <td className="text-nowrap">
                        {//order.status !== 'COMPLETED' ?
                            <span>
                                <Button className="table-action-button action-button btn-fill"
                                    bsStyle="warning"
                                    type="button"
                                    onClick={this.state.isEditing ? this.handleSave : this.handleEdit}
                                >
                                    {this.state.isEditing ? <i className="fas fa-check"></i> : <i className="fas fa-edit"></i>}
                                </Button>
                                {this.state.isEditing ?
                                    <Button className="table-action-button action-button btn-fill"
                                        bsStyle="warning"
                                        type="button"
                                        onClick={this.handleCancel}>
                                        <i className="fas fa-times"></i>
                                    </Button> : null
                                }
                            </span>// : null
                        }
                        <Button className="table-action-button action-button btn-fill"
                            bsStyle="danger"
                            type="button"
                            onClick={this.handleDelete}
                        >
                            <i className="fas fa-trash"></i>
                        </Button>
                    </td>}
                </tr>
                {this.state.isEditing ?
                    <tr>
                        <td colSpan={12}>
                            <FormGroup>
                                <ControlLabel>Enter Quantity</ControlLabel>
                                <FormControl
                                    type="number"
                                    placeholder="Quantity"
                                    name="quantity"
                                    onChange={this.handleChange}
                                    value={this.state.quantity}
                                    step="1"
                                    min="1"
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>Select Status</ControlLabel>
                                <FormControl
                                    componentClass="select"
                                    placeholder="Select Status"
                                    name="status"
                                    value={this.state.status}
                                    onChange={this.handleChange}
                                    required >
                                    <option value="PENDING">PENDING</option>
                                    <option value="CONFIRMED">CONFIRMED</option>
                                    <option value="COMPLETED">COMPLETED</option>
                                </FormControl>
                            </FormGroup>
                        </td>
                    </tr> : null
                }
            </tbody>
        );
    }
}

// A helper function to generate a CSS style that restricts width of <td> elements
function limitWidth(width) {
    return {
        maxWidth: width,
        wordWrap: 'break-word',
        whiteSpace: 'normal'
    }
}

export default OrderRow;
