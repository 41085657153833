import axios from "../service/HttpManager";

export const fetchBranchOrders = async (branchId, accessToken) => {
  const response = await axios.get(`/branches/${branchId}/orders`, {
    headers: {
        Authorization: `Bearer ${accessToken}`,
    },
  });
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const fetchOrders = async (accessToken) => {
  const response = await axios.get(`/orders`, {
    headers: {
        Authorization: `Bearer ${accessToken}`,
    },
  });
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const createOrder = async (customerId, data, accessToken) => {
  const response = await axios.post(`/customers/${customerId}/order`, data, {
    headers: {
        Authorization: `Bearer ${accessToken}`,
    },
  });
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
}