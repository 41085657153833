import axios from "../service/HttpManager";

export const fetchRoles = async (accessToken, page = 1, pageSize = 10) => {
  const response = await axios.post("/code/role/all", null, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: { page, pageSize },
  });
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const fetchStoreTypes = async (accessToken, page = 1, pageSize = 20) => {
  const response = await axios.post("/code/store-type/all", null, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: { page, pageSize },
  });
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const fetchFoodCategories = async (accessToken, page = 1, pageSize = 200) => {
  const response = await axios.post("/code/food-category/all", null, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: { page, pageSize },
  });
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
}

export const fetchAllProductTags = async (accessToken, page = 1, pageSize = 150) => {
  const response = await axios.post("/merchant/merchant-branch-product-tag/get-all-tags", null, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: { page, pageSize },
  });
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
}
