import axios from "../service/HttpManager";

export const fetchBanners = async (bannerType, accessToken, page = 1, pageSize = 20) => {
  const response = await axios.post(
    "/banner/getByType",
    {
      type: bannerType,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: { page, pageSize },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const createBanner = async (bannerType, accessToken) => {
  const response = await axios.post(
    "/banner/create",
    {
      type: bannerType,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const uploadBanner = async (bannerId, image, accessToken) => {
  const formData = new FormData();
  formData.append("bannerId", bannerId);
  formData.append("image", image);
  console.log(formData);
  const response = await axios.post("/banner/banner-image/upload", formData, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};

export const deleteBannerImage = async (bannerImageId, accessToken) => {
  const response = await axios.post(
    "/banner/banner-image/setInactive",
    {
      id: bannerImageId,
      isActive: false,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status !== 201) throw new Error(response.data.message);
  return response.data;
};
