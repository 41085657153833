import moment from "moment";

export function validateInputs(parent, component) {
  const inputs = parent.getElementsByTagName("input");
  const textareas = parent.getElementsByTagName("textarea");
  const selects = parent.getElementsByTagName("select");
  for (const select of Array.from(selects)) {
    if (!select.checkValidity()) {
      return select.reportValidity();
    }
  }

  const passwordValues = {};
  for (const input of Array.from(inputs)) {
    if (input.type === "password") {
      passwordValues[input.name] = input;
    }

    if (input.type === "text" || input.type === "email") {
      input.value = input.value.trim();
      component.setState({ [input.name]: input.value });
    }

    if (input.name === "removeDate") {
      const removeDate = input.value;
      const postDate = parent.querySelector("[name=postDate]").value;
      if (moment(removeDate, "DD/MM/YYYY").isBefore(moment(postDate, "DD/MM/YYYY"))) {
        input.setCustomValidity("Invalid date range");
      } else {
        input.setCustomValidity("");
      }
    }

    if (input.name === "endDate") {
      const endDate = input.value;
      const startDate = parent.querySelector("[name=startDate]").value;
      if (moment(endDate, "DD/MM/YYYY").isBefore(moment(startDate, "DD/MM/YYYY"))) {
        input.setCustomValidity("Invalid date range");
      } else {
        input.setCustomValidity("");
      }
    }

    if (input.name === "latidue") {
      const latReg = new RegExp("^-?([0-8]?[0-9]|90)(.[0-9]{1,10})?$");
      const value = input.value;
      if (latReg.exec(value)) {
        input.setCustomValidity("");
      } else {
        input.setCustomValidity("Invalid latitude value");
      }
    }

    if (input.name === "longitude") {
      const lngReg = new RegExp("^-?([0-9]{1,2}|1[0-7][0-9]|180)(.[0-9]{1,10})?$");
      const value = input.value;
      if (lngReg.exec(value)) {
        input.setCustomValidity("");
      } else {
        input.setCustomValidity("Invalid longitude value");
      }
    }

    if (!input.checkValidity()) {
      return input.reportValidity();
    }
  }

  if (
    Object.keys(passwordValues).length > 1 &&
    passwordValues.password.value !== passwordValues.confirmPassword.value
  ) {
    passwordValues.confirmPassword.setCustomValidity("Password does not match");
    return passwordValues.confirmPassword.reportValidity();
  }

  for (const textarea of Array.from(textareas)) {
    if (!textarea.checkValidity()) {
      return textarea.reportValidity();
    }
  }
  return true;
}

export function resetValidity(parent) {
  const inputs = parent.getElementsByTagName("input");
  const selects = parent.getElementsByTagName("select");
  for (const input of inputs) {
    input.setCustomValidity("");
  }
  for (const select of selects) {
    select.setCustomValidity("");
  }
}

export function isProductAddon(productName) {
  const lowerProductName = productName.toLowerCase();
  return (
    lowerProductName.includes("add-on") ||
    lowerProductName.includes("addon") ||
    lowerProductName.includes("add on")
  );
}

export function getPaymentType(transaction) {
  if (transaction.PaymentType) {
    if (transaction.PaymentType.startsWith("card")) {
      return "card";
    }
    if (transaction.PaymentType === "cash") {
      return "cashier";
    }
    return transaction.PaymentType;
  }
  if (transaction.CreatedAt < 1735689600) {
    return "cashier";
  }
}

// Converts UNIX timestamp (in seconds) to the format used by Excel
export function unixToExcelTime(unixTimestamp) {
  // Excel uses Days since 1900 (fractional value to represent time of day)
  // Unix timestamp is seconds since 1/1/1970

  const DAYS_OFFSET = 25569; // Days from 1 Jan 1900 to 1 Jan 1970
  const SECONDS_PER_DAY = 86400;
  // Gets the timezone offset in seconds (e.g. for GMT+8, offset is 8*3600 seconds)
  const TIMEZONE_OFFSET_SECONDS = -new Date().getTimezoneOffset() * 60;

  return DAYS_OFFSET + (unixTimestamp + TIMEZONE_OFFSET_SECONDS) / SECONDS_PER_DAY;
}

export const generateBranchReportExcelFile = async (
  transactions,
  month,
  year,
  branchName,
  merchantName,
  isAdmin = false
) => {
  const ExcelJS = require("exceljs");

  const workbook = new ExcelJS.Workbook();

  const worksheet = workbook.addWorksheet("Tally");

  const rowStyle = {
    alignment: {
      vertical: "middle",
      horizontal: "left",
      wrapText: true,
    },
    font: {
      name: "Arial",
      size: 11,
    },
  };
  const centerRowStyle = {
    alignment: {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    },
    font: {
      name: "Arial",
      size: 11,
    },
  };

  const columnStyle = {
    font: {
      name: "Arial",
      color: { argb: "9A9A9A" },
      size: 9,
    },
  };

  worksheet.columns = [
    { header: "#", key: "no", width: 6, style: rowStyle },
    { header: "Order ID", key: "transactionId", width: 18, style: rowStyle },
    { header: "Branch Name", key: "branchName", width: 28, style: rowStyle },
    { header: "Redeemed At", key: "redeemedAt", width: 23, style: rowStyle },
    { header: "Product Name", key: "redemptionDetails", width: 58, style: rowStyle },
    { header: "App Price", key: "appPrice", width: 12, style: { ...centerRowStyle, numFmt: "$0.00" } },
    { header: "Quantity", key: "quantity", width: 12, style: centerRowStyle },
    { header: "Sale Type", key: "saleType", width: 12, style: centerRowStyle },
    { header: "Transaction Type", key: "transactionType", width: 14, style: centerRowStyle },
    {
      header: "Gross Total",
      key: "grossTotal",
      width: 12,
      style: { ...centerRowStyle, numFmt: "$0.00" },
    },
    { header: "Nett Total", key: "nettTotal", width: 12, style: { ...centerRowStyle, numFmt: "$0.00" } },
  ];

  const TRANSACTION_TYPE_MAP = {
    card: "e-payment",
    paynow: "e-payment",
    cashier: "direct",
  };
  const rows = transactions
    .map((transaction, i) => {
      const transactionDetails = transaction.OrderLineItems.map((lineItem) => {
        const orderNumber =
          transaction.OrderNumber.length === 36
            ? transaction.OrderNumber.substring(transaction.OrderNumber.length - 12).toUpperCase()
            : transaction.OrderNumber.substring(8, 18).toUpperCase();
        return {
          no: i + 1,
          transactionId: orderNumber,
          branchName: `${merchantName} (${branchName})`,
          redeemedAt: moment.unix(transaction.CreatedAt).format("ddd, DD MMM yyyy, HH:mm"),
          redemptionDetails: lineItem?.ProductNameAtOrder,
          appPrice: parseFloat(lineItem?.OfferAtOrder ?? 0),
          quantity: lineItem?.Quantity,
          saleType: transaction.TransactionType,
          transactionType: TRANSACTION_TYPE_MAP[transaction.PaymentType],
          grossTotal: parseFloat(transaction.OfferTotal ?? 0),
          nettTotal: parseFloat(transaction.NettTotal ?? 0),
        };
      });
      return transactionDetails;
    })
    .flat();

  worksheet.addRows(rows);

  worksheet.insertRow(2, []);
  worksheet.insertRows(1, [[], [], []]);

  const title = `[treatsure] Transaction tally for ${merchantName} (${branchName}) - ${month} ${year}`;
  const cell = worksheet.getCell("A2");
  cell.value = title;
  cell.font = {
    name: "Arial",
    size: 17,
  };
  cell.alignment = {
    vertical: "middle",
    horizontal: "left",
    wrapText: false,
  };

  const e4Cell = worksheet.getCell("E4");
  e4Cell.value = "Redemtion Details";
  e4Cell.style = columnStyle;

  worksheet.mergeCells("A4:A5");
  worksheet.mergeCells("B4:B5");
  worksheet.mergeCells("C4:C5");
  worksheet.mergeCells("D4:D5");
  worksheet.mergeCells("E4:G4");
  worksheet.mergeCells("H4:H5");
  worksheet.mergeCells("I4:I5");
  worksheet.mergeCells("J4:J5");
  worksheet.mergeCells("K4:K5");

  let currentTransId = "";
  for (let i = 0; i < rows.length; i++) {
    if (currentTransId === rows[i].transactionId) {
      continue;
    }
    currentTransId = rows[i].transactionId;
    const transaction = transactions.find((t) => {
      const orderNumber =
        t.OrderNumber.length === 36
          ? t.OrderNumber.substring(t.OrderNumber.length - 12).toUpperCase()
          : t.OrderNumber.substring(8, 18).toUpperCase();
      return orderNumber === currentTransId;
    });
    if (!transaction) {
      continue;
    }
    const productCount = transaction.OrderLineItems.length;
    if (productCount === 1) {
      continue;
    }
    worksheet.mergeCells(`A${6 + i}:A${6 + i + productCount - 1}`);
    worksheet.mergeCells(`B${6 + i}:B${6 + i + productCount - 1}`);
    worksheet.mergeCells(`C${6 + i}:C${6 + i + productCount - 1}`);
    worksheet.mergeCells(`D${6 + i}:D${6 + i + productCount - 1}`);
    worksheet.mergeCells(`H${6 + i}:H${6 + i + productCount - 1}`);
    worksheet.mergeCells(`I${6 + i}:I${6 + i + productCount - 1}`);
    worksheet.mergeCells(`J${6 + i}:J${6 + i + productCount - 1}`);
    worksheet.mergeCells(`K${6 + i}:K${6 + i + productCount - 1}`);
  }

  worksheet.getCell("A4").style = columnStyle;
  worksheet.getCell("B4").style = columnStyle;
  worksheet.getCell("C4").style = columnStyle;
  worksheet.getCell("D4").style = columnStyle;

  worksheet.getCell("E5").value = "Product Name";
  worksheet.getCell("E5").style = columnStyle;
  worksheet.getCell("F5").value = "App Price";
  worksheet.getCell("F5").style = columnStyle;
  worksheet.getCell("G5").value = "Quantity";
  worksheet.getCell("G5").style = columnStyle;

  worksheet.getCell("H4").style = columnStyle;
  worksheet.getCell("I4").style = columnStyle;
  worksheet.getCell("J4").style = columnStyle;
  worksheet.getCell("K4").style = columnStyle;

  worksheet.addRow([]);

  worksheet.addConditionalFormatting({
    ref: `I6:I${6 + rows.length - 1}`,
    rules: [
      {
        type: "cellIs",
        operator: "equal",
        formulae: ['"direct"'],
        style: {
          font: {
            name: "Arial",
            color: { argb: "888888" },
            size: 11,
          },
        },
      },
    ],
  });

  const disposableProductMap = {};
  const addOnProductMap = {};
  const otherProductMap = {};

  transactions.forEach((transaction) => {
    transaction.OrderLineItems.forEach((lineItem) => {
      const isDisposable = lineItem.ProductNameAtOrder.toLowerCase().includes("treatsure box");
      const isAddon = isProductAddon(lineItem.ProductNameAtOrder);
      if (isDisposable) {
        if (!disposableProductMap[lineItem.ProductNameAtOrder]) {
          disposableProductMap[lineItem.ProductNameAtOrder] = 0;
        }
        disposableProductMap[lineItem.ProductNameAtOrder] += lineItem.Quantity;
      } else if (isAddon) {
        if (!addOnProductMap[lineItem.ProductNameAtOrder]) {
          addOnProductMap[lineItem.ProductNameAtOrder] = 0;
        }
        addOnProductMap[lineItem.ProductNameAtOrder] += lineItem.Quantity;
      } else {
        if (!otherProductMap[lineItem.ProductNameAtOrder]) {
          otherProductMap[lineItem.ProductNameAtOrder] = 0;
        }
        otherProductMap[lineItem.ProductNameAtOrder] += lineItem.Quantity;
      }
    });
  });

  const disposableProductCount = Object.keys(disposableProductMap).length;
  const addOnProductCount = Object.keys(addOnProductMap).length;
  const otherProductCount = Object.keys(otherProductMap).length;
  const productCount = disposableProductCount + addOnProductCount + otherProductCount;

  const bottomDefaultFill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFF2CC" },
  };

  const rowCount = worksheet.rowCount;

  if (isAdmin) {
    const bottomCell10 = worksheet.getCell(`E${rowCount + 1}`);
    bottomCell10.value = "Meals of food saved via treatsure";
    bottomCell10.font = {
      name: "Arial",
      bold: true,
    };
    bottomCell10.fill = bottomDefaultFill;

    const bottomCell11 = worksheet.getCell(`F${rowCount + 1}`);
    bottomCell11.value = {
      formula: `SUM(F${rowCount + 4}:F${rowCount + 4 + disposableProductCount - 1})`,
      result: Object.values(disposableProductMap).reduce((acc, val) => acc + val, 0),
    };
    bottomCell11.font = {
      name: "Arial",
      bold: true,
    };
    bottomCell11.fill = bottomDefaultFill;
    bottomCell11.numFmt = "0";

    const bottomCell20 = worksheet.getCell(`E${rowCount + 2}`);
    bottomCell20.value = "Estimated weight of food saved via treatsure";
    bottomCell20.font = {
      name: "Arial",
      bold: true,
    };
    bottomCell20.fill = bottomDefaultFill;

    const bottomCell21 = worksheet.getCell(`F${rowCount + 2}`);
    bottomCell21.value = 0;
    bottomCell21.font = {
      name: "Arial",
      bold: true,
    };
    bottomCell21.fill = bottomDefaultFill;
    bottomCell21.numFmt = "0\\k\\g";
  }

  const pivotTableOffset = isAdmin ? 4 : 1;

  Object.keys(disposableProductMap)
    .sort(treatsureBoxComparator)
    .forEach((productName, i) => {
      const productCell = worksheet.getCell(`E${rowCount + pivotTableOffset + i}`);
      productCell.value = productName;
      productCell.font = {
        name: "Arial",
      };
      productCell.fill = bottomDefaultFill;

      const valueCell = worksheet.getCell(`F${rowCount + pivotTableOffset + i}`);
      valueCell.value = disposableProductMap[productName];
      valueCell.font = {
        name: "Arial",
      };
      valueCell.fill = bottomDefaultFill;
      valueCell.numFmt = "0";
    });

  Object.keys(addOnProductMap)
    .sort()
    .forEach((productName, i) => {
      const productCell = worksheet.getCell(
        `E${rowCount + disposableProductCount + pivotTableOffset + i}`
      );
      productCell.value = productName;
      productCell.font = {
        name: "Arial",
      };
      productCell.fill = bottomDefaultFill;

      const valueCell = worksheet.getCell(
        `F${rowCount + disposableProductCount + pivotTableOffset + i}`
      );
      valueCell.value = addOnProductMap[productName];
      valueCell.font = {
        name: "Arial",
      };
      valueCell.fill = bottomDefaultFill;
      valueCell.numFmt = "0";
    });

  Object.keys(otherProductMap)
    .sort()
    .forEach((productName, i) => {
      const productCell = worksheet.getCell(
        `E${rowCount + disposableProductCount + addOnProductCount + pivotTableOffset + i}`
      );
      productCell.value = productName;
      productCell.font = {
        name: "Arial",
      };
      productCell.fill = bottomDefaultFill;

      const valueCell = worksheet.getCell(
        `F${rowCount + disposableProductCount + addOnProductCount + pivotTableOffset + i}`
      );
      valueCell.value = otherProductMap[productName];
      valueCell.font = {
        name: "Arial",
      };
      valueCell.fill = bottomDefaultFill;
      valueCell.numFmt = "0";
    });

  const totalBottomFill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FCE5CD" },
  };

  if (isAdmin) {
    const bottomOffset = rowCount + productCount + pivotTableOffset + (productCount == 0 ? 0 : 1);
    const bottomCell90 = worksheet.getCell(`E${bottomOffset}`);
    bottomCell90.value = `Total sales for ${month} ${year}`;
    bottomCell90.font = {
      name: "Arial",
      bold: true,
    };
    bottomCell90.fill = totalBottomFill;

    const bottomCell91 = worksheet.getCell(`F${bottomOffset}`);
    const totalSales = transactions.reduce(
      (acc, transaction) => acc + parseFloat(transaction.NettTotal ?? 0),
      0
    );
    bottomCell91.value = { formula: `SUM(K6:K${6 + rows.length - 1})`, result: totalSales };
    bottomCell91.font = {
      name: "Arial",
      bold: true,
    };
    bottomCell91.fill = totalBottomFill;

    const bottomCell100 = worksheet.getCell(`E${bottomOffset + 1}`);
    bottomCell100.value = "treatsure transaction fees (18%)";
    bottomCell100.font = {
      name: "Arial",
    };
    bottomCell100.fill = totalBottomFill;

    const bottomCell101 = worksheet.getCell(`F${bottomOffset + 1}`);
    const transactionFees = totalSales * 0.18;
    bottomCell101.value = { formula: `F${bottomOffset}*0.18`, result: transactionFees };
    bottomCell101.font = {
      name: "Arial",
    };
    bottomCell101.fill = totalBottomFill;

    const bottomCell110 = worksheet.getCell(`E${bottomOffset + 2}`);
    bottomCell110.value = "Total app e-payment ";
    bottomCell110.font = {
      name: "Arial",
    };
    bottomCell110.fill = totalBottomFill;

    const bottomCell111 = worksheet.getCell(`F${bottomOffset + 2}`);
    const totalCardPayment = transactions.reduce((acc, transaction) => {
      if (transaction.PaymentType === "card") {
        return acc + parseFloat(transaction.NettTotal ?? 0);
      }
      return acc;
    }, 0);
    bottomCell111.value = {
      formula: `SUMIF(I6:I${6 + rows.length - 1},"e-payment",K6:K${6 + rows.length - 1})`,
      result: totalCardPayment,
    };
    bottomCell111.font = {
      name: "Arial",
    };
    bottomCell111.fill = totalBottomFill;

    const bottomCell120 = worksheet.getCell(`E${bottomOffset + 3}`);
    bottomCell120.value = `treatsure to reimburse ${merchantName}`;
    bottomCell120.font = {
      name: "Arial",
      bold: true,
    };
    bottomCell120.fill = totalBottomFill;

    const bottomCell121 = worksheet.getCell(`F${bottomOffset + 3}`);
    bottomCell121.value = {
      formula: `F${bottomOffset + 2}-F${bottomOffset + 1}`,
      result: totalCardPayment - transactionFees,
    };
    bottomCell121.font = {
      name: "Arial",
      bold: true,
    };
    bottomCell121.fill = totalBottomFill;
  }

  return workbook.xlsx.writeBuffer();
};

export const generateTreatsureReportExcelFile = async (transactions, merchants, month, year) => {
  const ExcelJS = require("exceljs");

  const workbook = new ExcelJS.Workbook();

  const worksheet = workbook.addWorksheet("Tally");

  const rowStyle = {
    alignment: {
      vertical: "middle",
      horizontal: "left",
      wrapText: true,
    },
    font: {
      name: "Arial",
      size: 11,
    },
  };
  const centerRowStyle = {
    alignment: {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    },
    font: {
      name: "Arial",
      size: 11,
    },
  };

  const columnStyle = {
    font: {
      name: "Arial",
      color: { argb: "9A9A9A" },
      size: 9,
    },
  };

  worksheet.columns = [
    { header: "#", key: "no", width: 6, style: rowStyle },
    { header: "Order ID", key: "transactionId", width: 18, style: rowStyle },
    { header: "Customer Username", key: "customerUsername", width: 18, style: rowStyle },
    { header: "Customer Email", key: "customerEmail", width: 18, style: rowStyle },
    { header: "Branch Name", key: "branchName", width: 28, style: rowStyle },
    { header: "Redeemed At", key: "redeemedAt", width: 23, style: rowStyle },
    { header: "Product Name", key: "productName", width: 58, style: rowStyle },
    { header: "App Price", key: "appPrice", width: 12, style: { ...centerRowStyle, numFmt: "$0.00" } },
    { header: "Quantity", key: "quantity", width: 12, style: centerRowStyle },
    { header: "Sale Type", key: "saleType", width: 12, style: centerRowStyle },
    { header: "Transaction Type", key: "transactionType", width: 14, style: centerRowStyle },
    {
      header: "Gross Total",
      key: "grossTotal",
      width: 12,
      style: { ...centerRowStyle, numFmt: "$0.00" },
    },
    { header: "Nett Total", key: "nettTotal", width: 12, style: { ...centerRowStyle, numFmt: "$0.00" } },
  ];

  const TRANSACTION_TYPE_MAP = {
    card: "e-payment",
    paynow: "e-payment",
    cashier: "direct",
  };

  const rows = transactions
    .map((transaction, i) => {
      const transactionDetails = transaction.OrderLineItems.map((lineItem) => {
        const merchant = merchants.find((m) => m.Id === lineItem.MerchantBranch?.MerchantId);
        const orderNumber =
          transaction.OrderNumber.length === 36
            ? transaction.OrderNumber.substring(transaction.OrderNumber.length - 12).toUpperCase()
            : transaction.OrderNumber.substring(8, 18).toUpperCase();
        return {
          no: i + 1,
          transactionId: orderNumber,
          customerUsername: transaction.Customer.Username,
          customerEmail: transaction.Customer.Email,
          branchName: `${merchant?.Name} (${transaction.OrderLineItems[0].MerchantBranch.BranchName})`,
          redeemedAt: moment.unix(transaction.CreatedAt).format("ddd, DD MMM yyyy, HH:mm"),
          productName: lineItem?.ProductNameAtOrder,
          appPrice: parseFloat(lineItem?.OfferAtOrder ?? 0),
          quantity: lineItem?.Quantity,
          saleType: transaction.TransactionType,
          transactionType: TRANSACTION_TYPE_MAP[transaction.PaymentType],
          grossTotal: parseFloat(transaction.OfferTotal ?? 0),
          nettTotal: parseFloat(transaction.NettTotal ?? 0),
        };
      });
      return transactionDetails;
    })
    .flat();

  worksheet.addRows(rows);

  worksheet.insertRow(2, []);
  worksheet.insertRows(1, [[], [], []]);

  const title = `[treatsure] Transaction tally - ${month} ${year}`;
  const cell = worksheet.getCell("A2");
  cell.value = title;
  cell.font = {
    name: "Arial",
    size: 17,
  };
  cell.alignment = {
    vertical: "middle",
    horizontal: "left",
    wrapText: false,
  };

  worksheet.getCell("G4").value = "Redemtion Details";
  worksheet.getCell("G4").style = columnStyle;

  worksheet.mergeCells("A4:A5");
  worksheet.mergeCells("B4:B5");
  worksheet.mergeCells("C4:C5");
  worksheet.mergeCells("D4:D5");
  worksheet.mergeCells("E4:E5");
  worksheet.mergeCells("F4:F5");
  worksheet.mergeCells("G4:I4");
  worksheet.mergeCells("J4:J5");
  worksheet.mergeCells("K4:K5");
  worksheet.mergeCells("L4:L5");
  worksheet.mergeCells("M4:M5");

  let currentTransId = "";
  for (let i = 0; i < rows.length; i++) {
    if (currentTransId === rows[i].transactionId) {
      continue;
    }
    currentTransId = rows[i].transactionId;
    const transaction = transactions.find((t) => {
      const orderNumber =
        t.OrderNumber.length === 36
          ? t.OrderNumber.substring(t.OrderNumber.length - 12).toUpperCase()
          : t.OrderNumber.substring(8, 18).toUpperCase();
      return orderNumber === currentTransId;
    });
    if (!transaction) {
      continue;
    }
    const productCount = transaction.OrderLineItems.length;
    if (productCount === 1) {
      continue;
    }
    worksheet.mergeCells(`A${6 + i}:A${6 + i + productCount - 1}`);
    worksheet.mergeCells(`B${6 + i}:B${6 + i + productCount - 1}`);
    worksheet.mergeCells(`C${6 + i}:C${6 + i + productCount - 1}`);
    worksheet.mergeCells(`D${6 + i}:D${6 + i + productCount - 1}`);
    worksheet.mergeCells(`E${6 + i}:E${6 + i + productCount - 1}`);
    worksheet.mergeCells(`F${6 + i}:F${6 + i + productCount - 1}`);
    worksheet.mergeCells(`J${6 + i}:J${6 + i + productCount - 1}`);
    worksheet.mergeCells(`K${6 + i}:K${6 + i + productCount - 1}`);
    worksheet.mergeCells(`L${6 + i}:L${6 + i + productCount - 1}`);
    worksheet.mergeCells(`M${6 + i}:M${6 + i + productCount - 1}`);
  }

  worksheet.getCell("A4").style = columnStyle;
  worksheet.getCell("B4").style = columnStyle;
  worksheet.getCell("C4").style = columnStyle;
  worksheet.getCell("D4").style = columnStyle;
  worksheet.getCell("E4").style = columnStyle;
  worksheet.getCell("F4").style = columnStyle;

  worksheet.getCell("G5").value = "Product Name";
  worksheet.getCell("G5").style = columnStyle;
  worksheet.getCell("H5").value = "App Price";
  worksheet.getCell("H5").style = columnStyle;
  worksheet.getCell("I5").value = "Quantity";
  worksheet.getCell("I5").style = columnStyle;

  worksheet.getCell("J4").style = columnStyle;
  worksheet.getCell("K4").style = columnStyle;
  worksheet.getCell("L4").style = columnStyle;
  worksheet.getCell("M4").style = columnStyle;

  worksheet.addRow([]);

  worksheet.addConditionalFormatting({
    ref: `K6:K${6 + rows.length - 1}`,
    rules: [
      {
        type: "cellIs",
        operator: "equal",
        formulae: ['"direct"'],
        style: {
          font: {
            name: "Arial",
            color: { argb: "888888" },
            size: 11,
          },
        },
      },
    ],
  });

  const disposableProductMap = {};
  const addOnProductMap = {};
  const otherProductMap = {};

  transactions.forEach((transaction) => {
    transaction.OrderLineItems.forEach((lineItem) => {
      const isDisposable = lineItem.ProductNameAtOrder.toLowerCase().includes("treatsure box");
      const isAddon = isProductAddon(lineItem.ProductNameAtOrder);
      if (isDisposable) {
        if (!disposableProductMap[lineItem.ProductNameAtOrder]) {
          disposableProductMap[lineItem.ProductNameAtOrder] = 0;
        }
        disposableProductMap[lineItem.ProductNameAtOrder] += lineItem.Quantity;
      } else if (isAddon) {
        if (!addOnProductMap[lineItem.ProductNameAtOrder]) {
          addOnProductMap[lineItem.ProductNameAtOrder] = 0;
        }
        addOnProductMap[lineItem.ProductNameAtOrder] += lineItem.Quantity;
      } else {
        if (!otherProductMap[lineItem.ProductNameAtOrder]) {
          otherProductMap[lineItem.ProductNameAtOrder] = 0;
        }
        otherProductMap[lineItem.ProductNameAtOrder] += lineItem.Quantity;
      }
    });
  });

  const disposableProductCount = Object.keys(disposableProductMap).length;
  const addOnProductCount = Object.keys(addOnProductMap).length;
  const otherProductCount = Object.keys(otherProductMap).length;
  const productCount = disposableProductCount + addOnProductCount + otherProductCount;

  const bottomDefaultFill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFF2CC" },
  };

  const rowCount = worksheet.rowCount;

  const bottomCell10 = worksheet.getCell(`G${rowCount + 1}`);
  bottomCell10.value = "Meals of food saved via treatsure";
  bottomCell10.font = {
    name: "Arial",
    bold: true,
  };
  bottomCell10.fill = bottomDefaultFill;

  const bottomCell11 = worksheet.getCell(`H${rowCount + 1}`);
  bottomCell11.value = {
    formula: `SUM(H${rowCount + 4}:H${rowCount + 4 + disposableProductCount - 1})`,
    result: Object.values(disposableProductMap).reduce((acc, val) => acc + val, 0),
  };
  bottomCell11.font = {
    name: "Arial",
    bold: true,
  };
  bottomCell11.fill = bottomDefaultFill;
  bottomCell11.numFmt = "0";

  const bottomCell20 = worksheet.getCell(`G${rowCount + 2}`);
  bottomCell20.value = "Estimated weight of food saved via treatsure";
  bottomCell20.font = {
    name: "Arial",
    bold: true,
  };
  bottomCell20.fill = bottomDefaultFill;

  const bottomCell21 = worksheet.getCell(`H${rowCount + 2}`);
  bottomCell21.font = {
    name: "Arial",
    bold: true,
  };
  bottomCell21.fill = bottomDefaultFill;

  Object.keys(disposableProductMap)
    .sort(treatsureBoxComparator)
    .forEach((productName, i) => {
      const productCell = worksheet.getCell(`G${rowCount + 4 + i}`);
      productCell.value = productName;
      productCell.font = {
        name: "Arial",
      };
      productCell.fill = bottomDefaultFill;

      const valueCell = worksheet.getCell(`H${rowCount + 4 + i}`);
      valueCell.value = disposableProductMap[productName];
      valueCell.font = {
        name: "Arial",
      };
      valueCell.fill = bottomDefaultFill;
      valueCell.numFmt = "0";
    });

  Object.keys(addOnProductMap)
    .sort()
    .forEach((productName, i) => {
      const productCell = worksheet.getCell(`G${rowCount + disposableProductCount + 4 + i}`);
      productCell.value = productName;
      productCell.font = {
        name: "Arial",
      };
      productCell.fill = bottomDefaultFill;

      const valueCell = worksheet.getCell(`H${rowCount + disposableProductCount + 4 + i}`);
      valueCell.value = addOnProductMap[productName];
      valueCell.font = {
        name: "Arial",
      };
      valueCell.fill = bottomDefaultFill;
      valueCell.numFmt = "0";
    });

  Object.keys(otherProductMap)
    .sort()
    .forEach((productName, i) => {
      const productCell = worksheet.getCell(
        `G${rowCount + disposableProductCount + addOnProductCount + 4 + i}`
      );
      productCell.value = productName;
      productCell.font = {
        name: "Arial",
      };
      productCell.fill = bottomDefaultFill;

      const valueCell = worksheet.getCell(
        `H${rowCount + disposableProductCount + addOnProductCount + 4 + i}`
      );
      valueCell.value = otherProductMap[productName];
      valueCell.font = {
        name: "Arial",
      };
      valueCell.fill = bottomDefaultFill;
      valueCell.numFmt = "0";
    });

  const totalBottomFill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FCE5CD" },
  };
  const bottomCell90 = worksheet.getCell(`G${rowCount + productCount + 5}`);
  bottomCell90.value = `Total sales for ${month} ${year}`;
  bottomCell90.font = {
    name: "Arial",
    bold: true,
  };
  bottomCell90.fill = totalBottomFill;

  const bottomCell91 = worksheet.getCell(`H${rowCount + productCount + 5}`);
  const totalSales = transactions.reduce(
    (acc, transaction) => acc + parseFloat(transaction.NettTotal ?? 0),
    0
  );
  bottomCell91.value = { formula: `SUM(M6:M${6 + rows.length - 1})`, result: totalSales };
  bottomCell91.font = {
    name: "Arial",
    bold: true,
  };
  bottomCell91.fill = totalBottomFill;

  const bottomCell110 = worksheet.getCell(`G${rowCount + productCount + 7}`);
  bottomCell110.value = "Total app e-payment ";
  bottomCell110.font = {
    name: "Arial",
  };
  bottomCell110.fill = totalBottomFill;

  const bottomCell111 = worksheet.getCell(`H${rowCount + productCount + 7}`);
  const totalCardPayment = transactions.reduce((acc, transaction) => {
    if (transaction.PaymentType === "card") {
      return acc + parseFloat(transaction.NettTotal ?? 0);
    }
    return acc;
  }, 0);
  bottomCell111.value = {
    formula: `SUMIF(K6:K${6 + rows.length - 1},"e-payment",M6:M${6 + rows.length - 1})`,
    result: totalCardPayment,
  };
  bottomCell111.font = {
    name: "Arial",
  };
  bottomCell111.fill = totalBottomFill;

  return workbook.xlsx.writeBuffer();
};

function treatsureBoxComparator(a, b) {
  const order = ["breakfast", "brunch", "early lunch", "lunch", "high-tea", "dinner"];
  const indexA = order.findIndex((item) => a.toLowerCase().includes(item));
  const indexB = order.findIndex((item) => b.toLowerCase().includes(item));
  return indexA - indexB;
}
